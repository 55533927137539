import React, { useEffect, useState } from 'react'
import { Box, Divider, Paper, Snackbar, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import { selectIsAuthenticated } from '../../redux/slices/auth';
import { removeFromFavorites, fetchFavoritesPagination } from '../../redux/slices/produse';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSearchParams } from 'react-router-dom';
import FavoriteComponent from '../../components/Client/FavoriteList/FavoriteComponent';

const FavoriteList = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const favorites = useAppSelector((state: RootState) => state.products.favorites.items);
    const FavoritesLoadingStatus = useAppSelector((state: RootState) => state.products.favorites.status);
    const FavoritesCount = useAppSelector((state: RootState) => state.products.favorites.count);
    const [searchParams, setSearchParams] = useSearchParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [pagination, setPagination] = useState({
        page: parseInt(searchParams.get('page') || '1', 10),
        perPage: parseInt(searchParams.get('perPage') || '10', 10)
    });


    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        params.set('page', pagination.page.toString());
        params.set('perPage', pagination.perPage.toString());

        const newParamsString = params.toString();
        // Обновляем URL только если строка параметров изменилась
        if (newParamsString !== searchParams.toString()) {
            setSearchParams(params, { replace: true });
        }

    }, [pagination, searchParams]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchFavoritesPagination(pagination));
        }
    }, [dispatch, pagination, isAuthenticated]);

    useEffect(() => {
        const maxPage = Math.ceil(FavoritesCount / pagination.perPage);
        if (pagination.page > maxPage && maxPage !== 0) {
            setPagination(prev => ({
                ...prev,
                page: maxPage
            }));
        }
    }, [FavoritesCount, pagination]);


    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setPagination(prev => ({ ...prev, page: value }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleRemoveFromFavorites = async (productId: number) => {
        try {
            const actionResult = await dispatch(removeFromFavorites(productId));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
                setSnackbarOpen(true);

                // After successful removal, check the number of favorites left
                if (favorites.length === 1 && pagination.page > 1) {
                    // If no favorites left on the current page and it's not the first page
                    setPagination(prev => ({
                        ...prev,
                        page: prev.page - 1  // Move to the previous page
                    }));
                }
            }
        } catch (error) {
            setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
            setSnackbarOpen(true);
        }
    };


  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',  
        width: '100%',
        p: '1%',
    }}>
        <Paper elevation={24} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            height: '100%',  
            p: '1%',
        }}>
            {/* Breadcrumbs */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                
                <Typography variant="h2" sx={{ flex: 1, textAlign: 'center' }}>
                    Favorite
                </Typography>

                <Divider sx={{ width: '100%', my: 1 }} />

            </Box>

            {/* Products */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',  
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%', 
                flexGrow: 1,
            }}>
                <FavoriteComponent
                    favorites={favorites}
                    count={FavoritesCount}
                    status={FavoritesLoadingStatus}
                    pagination={pagination}
                    handlePaginationChange={handlePaginationChange}
                    handleRemoveFromFavorites={handleRemoveFromFavorites}
                />
            </Box>
        </Paper>

        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    </Box>
  )
}

export default FavoriteList