import { useState, useEffect } from 'react';
import { Box, Button, Divider, IconButton, Paper, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { assignOrderToUser, fetchPaginationOrders, FilterParams } from '../../../../redux/slices/order';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store'
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface OrderOnlineListAdminProps {
    filters?: FilterParams;
}

const AdminOrders: React.FC<OrderOnlineListAdminProps> = ({ filters }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const orders = useSelector((state: RootState) => state.orders.orders.items); 
    const orderStatus = useAppSelector((state: RootState) => state.orders.orders.status);
    const Count = useAppSelector((state: RootState) => state.orders.orders.count);
    const isDataGridLoading = orderStatus === 'loading';
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
    }); 
    

    useEffect(() => {
        const params = {
            ...filters,
            page: paginationModel.page + 1,
            perPage: paginationModel.pageSize,
        };    
        const queryString = new URLSearchParams(
            Object.entries(params).map(([key, value]) => {
                if (typeof value === 'boolean') {
                    return [key, value.toString()];
                }
                return [key, value ? value.toString() : ''];
            })
        ).toString();
    
        dispatch(fetchPaginationOrders(queryString));
    }, [dispatch, paginationModel, filters]);
    
    const handleViewOrderDetails = (orderId: number) => {
        navigate(`/admin/OrderDetailAdmin/${orderId}`);
    }; 

    const handleAssignOrder = async (orderId: number) => {
        await dispatch(assignOrderToUser(orderId));
    };

    const columns: GridColDef[] = [
        {
            field: 'Comanda',
            headerName: 'Comanda Nr.',
            flex: 1,
        },
        {
            field: 'Date',
            headerName: 'Data',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                if (typeof params.row.Date === 'string') {
                    const rawDateTime = params.row.Date;
                    const [date, time] = rawDateTime.split(' ');
    
                    const formattedDate = date ? new Date(date).toLocaleDateString('ro-RO') : '-';
                    const formattedTime = time ? time.slice(0, 8) : '-';
    
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start', 
                            }}
                        >
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>{formattedDate}</Typography>
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>{formattedTime}</Typography>
                        </Box>
                    );
                } else {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start', 
                            }}
                        >
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>-</Typography>
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>-</Typography>
                        </Box>
                    );
                }
            },
        },
        {
            field: 'Cost',
            headerName: 'Totalul comenzii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.Cost.toLocaleString('ro-RO')} Lei
                    </Typography>
                );
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                let color;
                switch (params.row.Status) {
                    case 'Nou':
                        color = 'red';
                        break;
                    case 'In verificare':
                        color = 'brown';
                        break;
                    case 'In livrare':
                        color = 'orange';
                        break;
                    case 'Livrat':
                        color = 'green';
                        break;
                    default:
                        color = 'black'; 
                }
    
                return (
                    <Typography variant='h4' style={{ color }}>
                        {params.row.Status}
                    </Typography>
                );
            },
        },
        {
            field: 'assignedUser',
            headerName: 'Raspunzator',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                const assignedUser = params.row.assignedUser;
                if (assignedUser) {
                    return (
                        <Typography variant='h4'>
                            {assignedUser.FullName} 
                        </Typography>
                    );
                } else {
                    return (
                        <Box display="flex" alignItems="center">
                            <Typography variant="h4" sx={{ mr: 1 }}>
                                Liber
                            </Typography>
                            <IconButton 
                              onClick={(event) => {
                                  event.stopPropagation();
                                  handleAssignOrder(params.row.id);
                              }}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Box>
                    );
                }
            },
        },        
        {
            field: 'CodLivrare',
            headerName: 'Cod Livrare',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.CodLivrare || '-'} 
                    </Typography>
                );
            },
        },
        {
            field: 'Detalii',
            headerName: 'Detalii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
                const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); 
                    handleViewOrderDetails(params.row.id);
                };
        
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleViewClick}
                        >
                            Vezi Comanda
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const rows = orders.filter(order => order.Id != null).map(order => ({
        id: order.Id,
        Comanda: order.Id, 
        Date: order.createdAt, 
        Cost: order.finalCost, 
        Status: order.status, 
        CodLivrare: order.codLivrare,
        assignedUser: order.assignedUser,
    }));
    
    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    }; 


    return (
        <Paper elevation={3} sx={{ padding: 2, width: '100%',  height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
         
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'space-between',
            }}>
                <Typography variant="h3">Comenzi noi</Typography>
            </Box>

            <Divider /> 

            <Box sx={{ flexGrow: 1, height: 0 }}>
                <DataGrid 
                    paginationMode="server"
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: perPage,
                                page: page
                            },
                        },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    rowCount={Count}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    loading={isDataGridLoading}
                    sx={{ fontSize: '22px' }}
                />
            </Box>
        </Paper>
    );
}

export default AdminOrders;
