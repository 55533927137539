import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Roles from '../../components/Admin/roles/Roles';
import AccessUsers from '../../components/Admin/roles/AccessUsers';

const Role: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const tabValue = tab === 'roluri' ? 1 : 0;
    setValue(tabValue);
  }, [location.search]);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
    const tabParam = newValue === 1 ? 'roluri' : 'lucratori';
    navigate(`?tab=${tabParam}`);
  };

  return (
    <Box sx={{ 
      height: "100vh", 
      overflow: "auto", 
      p: '2%', 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center', 
    }}>
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider', 
        alignSelf: 'center', 
        width: '100%',
        display: 'flex', 
        justifyContent: 'space-between'
      }}>

        <Box sx={{ flex: 1 }} />

        <Tabs 
          value={value} 
          onChange={handleChange} 
          centered
          TabIndicatorProps={{ style: { backgroundColor: 'black' } }} 
          sx={{
            '.MuiTabs-indicator': {
              backgroundColor: 'black',
            },
            justifyContent: 'center',
          }}
        >
          <Tab label={<Typography variant="h2" style={{ color: 'black' }}>Lucratori</Typography>} />
          <Tab label={<Typography variant="h2" style={{ color: 'black' }}>Roluri</Typography>} />
        </Tabs>

        <Box sx={{ flex: 1 }} />
      </Box>

      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        {value === 0 && <AccessUsers/>}
        {value === 1 && <Roles/>}
      </Box>
    </Box>
  );
};

export default Role;
