import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import ColorEditor from '../../components/Admin/ColorAndSize/Color/ColorEditor';
import SizeEditor from '../../components/Admin/ColorAndSize/Size/SizeEditor';
import DeliveryList from '../../components/Admin/ColorAndSize/Plata&Livrare/DeliveryList';
import PaymentList from '../../components/Admin/ColorAndSize/Plata&Livrare/PaymentList';
import CategoryList from '../../components/Admin/ColorAndSize/Category/CategoryList';

const ColorAndSize = () => {
    const [activeTab, setActiveTab] = useState<string>('Culori');

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: "2%", width: '100%', height: '100vh' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    centered
                >
                    <Tab label={<Typography variant="h3">Culori</Typography>} value="Culori" />
                    <Tab label={<Typography variant="h3">Marimi</Typography>} value="Marimi" />
                    <Tab label={<Typography variant="h3">Categorii</Typography>} value="Categorii" />
                    <Tab label={<Typography variant="h3">Plata</Typography>} value="Plata" />
                    <Tab label={<Typography variant="h3">Livrare</Typography>} value="Livrare" />
                </Tabs>
            </Box>

            <Box sx={{ width: '100%', height: '100%', p: 2, overflowY: 'auto' }}>
                {activeTab === 'Culori' && <ColorEditor />}
                {activeTab === 'Marimi' && <SizeEditor />}
                {activeTab === 'Categorii' && <CategoryList />}
                {activeTab === 'Plata' && <PaymentList />}
                {activeTab === 'Livrare' && <DeliveryList />}
            </Box>
        </Box>
    );
};

export default ColorAndSize;
