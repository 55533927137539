import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';

export interface Discount {
  discountId: number;
  userId: number;
  discount_percentage: number;
  is_subscribed_discount: boolean;
  is_used: boolean;
}

interface DiscountState {
  items: Discount[];
  status: 'loading' | 'loaded' | 'error';
  userHasDiscount?: boolean;
}

const initialState: DiscountState = {
  items: [],
  status: 'loading',
  userHasDiscount: undefined,
};

export interface CreateDiscountData {
    discount_percentage: number;
    is_subscribed_discount: boolean;
}

export const createDiscount = createAsyncThunk<Discount, CreateDiscountData, { rejectValue: string }>(
  'discounts/createDiscount',
  async (discountData, { rejectWithValue }) => {
    const [error, response] = await API<Discount>('POST', '/discounts/create', discountData);

    if (error || !response) {
      return rejectWithValue('Failed to create discount');
    }

    return response.data;
  }
);

export const fetchDiscountByUserId = createAsyncThunk<{discounts: Discount[]}, void, { rejectValue: string }>(
  'discounts/fetchDiscountById',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{discounts: Discount[]}>('GET', `/discounts/user`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch discounts by user ID');
    }

    return response.data;
  }
);

export const fetchAllDiscounts = createAsyncThunk<Discount[], void, { rejectValue: string }>(
  'discounts/fetchAllDiscounts',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<Discount[]>('GET', '/discounts/all');

    if (error || !response) {
      return rejectWithValue('Failed to fetch all discounts');
    }

    return response.data;
  }
);

export const deleteDiscountById = createAsyncThunk<void, number, { rejectValue: string }>(
  'discounts/deleteDiscountById',
  async (discountId, { rejectWithValue }) => {
    const [error, response] = await API<void>('DELETE', `/discounts/delete/${discountId}`);

    if (error || !response) {
      return rejectWithValue('Failed to delete discount');
    }
  }
);

export const updateIsUsed = createAsyncThunk<Discount, { discountId: number, is_used: boolean }, { rejectValue: string }>(
  'discounts/updateIsUsed',
  async ({ discountId, is_used }, { rejectWithValue }) => {
    const [error, response] = await API<Discount>('PATCH', `/discounts/used/${discountId}`, { is_used });

    if (error || !response) {
      return rejectWithValue('Failed to update discount usage status');
    }

    return response.data;
  }
);

export const checkUserDiscount = createAsyncThunk<boolean, void, { rejectValue: string }>(
  'discounts/checkUserDiscount',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ userHasDiscount: boolean }>('GET', '/discounts/check/user');

    if (error || !response) {
      return rejectWithValue('Failed to check user discount');
    }

    return response.data.userHasDiscount;
  }
);


const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDiscountByUserId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDiscountByUserId.fulfilled, (state, action: PayloadAction<{discounts: Discount[]}>) => {
        state.status = 'loaded';
        state.items = action.payload.discounts;
      })
      .addCase(fetchDiscountByUserId.rejected, (state) => {
        state.status = 'error';
        state.items = [];
      })
      .addCase(fetchAllDiscounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllDiscounts.fulfilled, (state, action: PayloadAction<Discount[]>) => {
        state.status = 'loaded';
        state.items = action.payload;
      })
      .addCase(fetchAllDiscounts.rejected, (state) => {
        state.status = 'error';
        state.items = [];
      })
      .addCase(deleteDiscountById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDiscountById.fulfilled, (state) => {
        state.status = 'loaded';
      })
      .addCase(deleteDiscountById.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(updateIsUsed.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateIsUsed.fulfilled, (state, action: PayloadAction<Discount>) => {
        state.status = 'loaded';
        state.items = [action.payload];
      })
      .addCase(updateIsUsed.rejected, (state) => {
        state.status = 'error';
        state.items = [];
      })
      .addCase(checkUserDiscount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkUserDiscount.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.status = 'loaded';
        state.userHasDiscount = action.payload;
      })
      .addCase(checkUserDiscount.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const discountReducer = discountsSlice.reducer;
