import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, RootState } from '../../../redux/store';
import { Dialog, DialogTitle, Box, Typography, Button, DialogContent, DialogActions, Paper, TextField, Divider } from '@mui/material';
import { fetchUsersEmails, fetchUserDetails, clearSelectedUser } from '../../../redux/slices/user';
import { fetchRoles } from '../../../redux/slices/roles';
import { createUserRole } from '../../../redux/slices/userrole';
import UserList from './UserList';
import UserDetails from './UserDetails';
import AddNewUser from './AddNewUser';

interface CreateNewUserProps {
    open: boolean;
    onClose: () => void;
  }

  const CreateNewUser: React.FC<CreateNewUserProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
    const { emailItems, status } = useAppSelector((state: RootState) => state.user);
    const userDetails = useAppSelector((state: RootState) => state.user.selectedUser);
    const { roles, status: rolesStatus } = useAppSelector((state) => state.roles);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (open) {
            dispatch(fetchUsersEmails({ search: searchQuery }));
            dispatch(fetchRoles());
        }
    }, [open, dispatch]);

    useEffect(() => {
        if (selectedUserId !== null) {
            dispatch(fetchUserDetails(selectedUserId));
        }
    }, [selectedUserId, dispatch]);
    
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleUserSelect = (userId: number) => {
        setSelectedUserId(userId);
    };

    const handleSelectRole = (roleId: number) => {
        setSelectedRoleId(roleId);
    };

    const handleReset = () => {
        setSearchTerm('');
        setSelectedUserId(null);
        setSelectedRoleId(null);
        dispatch(clearSelectedUser());
    };

    const handleClose = () => {
        handleReset();
        onClose();
    };

    const handleSave = async () => {
        // Проверка, все ли условия для сохранения удовлетворены
        if (isSaveDisabled) {
          console.error("Data validation failed or passwords do not match.");
          return;
        }
      
        // Вызов асинхронного действия для создания роли пользователя
        const actionResult = await dispatch(createUserRole({
          userId: selectedUserId,
          roleId: selectedRoleId,
        }));
      
        // Проверка результатов выполнения асинхронного действия
        if (createUserRole.fulfilled.match(actionResult)) {
          // Успешное создание роли пользователя
          handleClose();
        } else if (createUserRole.rejected.match(actionResult)) {
          // Ошибка при создании роли пользователя
          console.error("Failed to create user role:", actionResult.error?.message || "Unknown error");
        }
    };
      
    const isSaveDisabled =  !selectedUserId || !selectedRoleId;
    
    
      return (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="xl"
          PaperProps={{
            sx: {
              maxWidth: "80vw", 
              minHeight: "80vh", 
              maxHeight: "80vh", 
              overflow: "hidden",
              p: 1
            }
          }}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h2">Add new User</Typography>
                    <Box>
                        <Button 
                            onClick={handleReset} 
                            variant="outlined" 
                            size="large"
                            sx={{ 
                                marginRight: 1, 
                                color: 'gray', 
                                borderColor: 'gray' 
                            }}>
                            Reset
                        </Button>
                        <Button 
                            onClick={handleSave} 
                            variant="contained" 
                            size="large"
                            disabled = {isSaveDisabled}
                            sx={{ 
                                backgroundColor: '#2e7d32', 
                                '&:hover': { backgroundColor: '#1b5e20' },
                            }}>
                            Save
                        </Button>
            
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent sx={{
                display: 'flex', 
                flexDirection: 'column', 
                flexGrow: 1,
                overflow: 'hidden', 
            }}>
                <Box sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    p: 1,
                    overflow: 'hidden', 
                }}>
                    <Paper elevation={3} sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden', 
                        p: 1,
                    }}>
                        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', gap: 1, p: 2 }}>

                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    p: 1
                                }}>
                                    <TextField 
                                        label="Search" 
                                        fullWidth 
                                        autoComplete="none" 
                                        value={searchTerm} 
                                        onChange={handleSearchChange} 
                                    />
                                </Box>

                                <Box sx={{ flexGrow: 1, overflow: 'hidden', p: 1 }}>
                                    <UserList 
                                        emailItems={emailItems} 
                                        loading={status === 'loading'} 
                                        onSelectUser={handleUserSelect} 
                                        selectedUserId={selectedUserId}
                                    />
                                </Box>
                            </Box>

                            <Divider orientation="vertical" flexItem />
                            
                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                            }}>
                                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                    <UserDetails userDetails={userDetails}/>
                                </Box>
                            </Box>

                            <Divider orientation="vertical" flexItem />

                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                            }}>
                                 <AddNewUser
                                    roles={roles}
                                    loading={rolesStatus === 'loading'}
                                    onSelectRole={handleSelectRole}
                                    selectedRoleId={selectedRoleId}
                                />
                            </Box>

                        </Box>
                    </Paper>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button 
                    onClick={onClose} 
                    variant="contained" 
                    color="secondary" 
                    size='large'
                    sx={{ fontWeight: 'bold' }}>
                        Close
                </Button>
            </DialogActions>
        </Dialog>
      );
    };

export default CreateNewUser