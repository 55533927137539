import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline, ThemeProvider} from "@mui/material";
import { Provider } from "react-redux";
import {store} from './redux/store';
import theme from "./utils/theme";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
  <CssBaseline/>
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
  </ThemeProvider>
);
