import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import { useNavigate, useSearchParams } from 'react-router-dom'; 
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Color, fetchColors } from '../../redux/slices/color';
import { Size, fetchSizes } from '../../redux/slices/size';
import { City, fetchCities } from '../../redux/slices/city';
import { ProductClass, fetchProductClasses } from '../../redux/slices/class';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ProductDepozit from '../../components/Admin/Depozit/ProductDepozit';
import Spinner from '../../components/spinner/Spinner';
import { GetProductPagination, Filter } from '../../redux/slices/productAdmin';


const DepozitProduse = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); 
  const [searchParams, setSearchParams] = useSearchParams();
  const Products = useAppSelector((state: RootState) => state.productsAdmin.products.items);
  const ProductsCount = useAppSelector((state: RootState) => state.productsAdmin.products.count);
  const overallInventoryCount = useAppSelector((state: RootState) => state.productsAdmin.products.overallInventoryCount);
  const productStatus = useAppSelector((state: RootState) => state.productsAdmin.products.status);
  const { colors, sizes, productClasses, cities, isLoading } = useAppSelector((state: RootState) => ({
    colors: state.colors.items,
    sizes: state.sizes.items,
    productClasses: state.productClass.items,
    cities: state.cities.items,
    isLoading:  state.colors.status === 'loading' || state.sizes.status === 'loading' ||
                state.productClass.status === 'loading' || state.cities.status === 'loading'
  }));
  const [filters, setFilters] = useState<Filter>({
      colorId: null,
      sizeId: null,
      productClassId: null,
      cityId: null,
      sort: null,
      query: null,
      onSale: null
  });
  const [pagination, setPagination] = useState({
    page: parseInt(searchParams.get('page') || '1', 10),
    perPage: parseInt(searchParams.get('perPage') || '10', 10)
  });
  const totalPages = Math.ceil(ProductsCount / pagination.perPage);

  function getNameById<T extends { id: number; name: string }>(data: T[], id: number | null | undefined): string | null {
    if (id == null) return null; 
    const found = data.find(item => item.id === id);
    return found ? found.name : null;
  }

  useEffect(() => {
    dispatch(fetchProductClasses());
    dispatch(fetchCities());
    dispatch(fetchColors());
    dispatch(fetchSizes());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      pagination: {
        page: pagination.page,
        perPage: pagination.perPage,
      },
      filters: {
        colorId: filters.colorId,
        sizeId: filters.sizeId,
        productClassId: filters.productClassId,
        cityId: filters.cityId,
        sort: filters.sort,
        query: filters.query,
        onSale: filters.onSale,
      }
    };
  
    dispatch(GetProductPagination(params));
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    if (!isLoading) {
      const initialFilters = {
        colorId: colors.find(c => c.name === searchParams.get('color'))?.id || null,
        sizeId: sizes.find(s => s.name === searchParams.get('size'))?.id || null,
        productClassId: productClasses.find(pc => pc.name === searchParams.get('category'))?.id || null,
        cityId: cities.find(c => c.name === searchParams.get('city'))?.id || null,
        sort: searchParams.get('sort') as 'asc' | 'desc' | null,
        query: searchParams.get('query') || null,
        onSale: searchParams.get('onSale') === 'true' ? true : searchParams.get('onSale') === 'false' ? false : null,
      };

      const initialPagination = {
        page: parseInt(searchParams.get('page') || '1', 10),
        perPage: parseInt(searchParams.get('perPage') || '10', 10)
      };
  
      setFilters(initialFilters);
      setPagination(initialPagination);
    }
  }, [isLoading, searchParams, colors, sizes, productClasses, cities]);
  
  useEffect(() => {
    if (!isLoading) {
      // Создаем URLSearchParams на основе текущих параметров URL
      const params = new URLSearchParams(searchParams);
  
      // Функция для обновления параметра, если значение не null
      const updateParamIfNotNull = (paramName: string, value: string | null | undefined) => {
        if (value != null) { 
          params.set(paramName, value);
        }
      };
      
  
      // Получаем имя по ID, если значение ID не null
      let colorName = filters.colorId != null ? getNameById<Color>(colors, filters.colorId) : null;
      let sizeName = filters.sizeId != null ? getNameById<Size>(sizes, filters.sizeId) : null;
      let categoryName = filters.productClassId != null ? getNameById<ProductClass>(productClasses, filters.productClassId) : null;
      let cityName = filters.cityId != null ? getNameById<City>(cities, filters.cityId) : null;

  
      // Обновляем параметры URL, только если соответствующие значения не null
      updateParamIfNotNull('color', colorName);
      updateParamIfNotNull('size', sizeName);
      updateParamIfNotNull('category', categoryName);
      updateParamIfNotNull('city', cityName);
      updateParamIfNotNull('sort', filters.sort);
      updateParamIfNotNull('query', filters.query);
      updateParamIfNotNull('onSale', filters.onSale != null ? filters.onSale.toString() : null);

      params.set('page', pagination.page.toString());
      params.set('perPage', pagination.perPage.toString());
  
      const newParamsString = params.toString();
      // Обновляем URL только если строка параметров изменилась
      if (newParamsString !== searchParams.toString()) {
        setSearchParams(params, { replace: true });
      }
    }
  }, [filters, pagination, setSearchParams, searchParams, colors, sizes, productClasses, cities, isLoading]);
  
  const handleFilterChange = (filterName: keyof Filter, value: string | number | boolean | null) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPagination(prev => ({ ...prev, page: value }));
  };

  const handlePerPageChange = (event: SelectChangeEvent<number>): void => {
    const perPage = parseInt(event.target.value as string, 10);
    setPagination(prev => ({ ...prev, perPage }));
  };

  const handleResetFilters = () => {
    const initialFilters = {
      colorId: null,
      sizeId: null,
      productClassId: null,
      cityId: null,
      sort: null,
      query: null,
      onSale: null
    };
    const initialPagination = {
      page: 1,  
      perPage: 10  
    };
  
    setFilters(initialFilters);
    setPagination(initialPagination);
  
    setSearchParams({
      page: initialPagination.page.toString(),
      perPage: initialPagination.perPage.toString()
    });
  };

  const openProductInNewTab = (productId: number) => {
    const url = `${window.location.origin}/ProductPage/${productId}`;
    window.open(url, '_blank');
  };

  const editProduct = (productId: number) => {
    navigate(`/admin/creare/${productId}`);
  };

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100vh',
        p: "1%",
        bgcolor: '#f5f5f5',
      }}>
        <Paper elevation={3} sx={{
          p: 2,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2
        }}>
           <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1, 
          }}>
            <Typography variant="h2">
              Depozit: {ProductsCount}
            </Typography>
            <Typography variant="h4">
              Total Inventory: {overallInventoryCount}
            </Typography>
          </Box>


          {/* Wrapper Box for two columns */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            maxWidth: { 
              xs: '100%', 
              sm: '100%',  
              md: '75%',  
              lg: '50%'  
            },
            width: { 
              xs: '100%',
              sm: '100%',  
              md: '75%', 
              lg: '50%' 
            },
            gap: 2,
          }}>
            <Box>
              <IconButton
                onClick={handleResetFilters}
                >
                  <FilterListOffIcon sx={{ fontSize: '30px' }}/>
              </IconButton>
            </Box>
            {/* Left Column for Search and Select Filters */}
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1 }}>
              <TextField 
                  label="Cauta Produs" 
                  variant="outlined"
                  fullWidth
                  value={filters.query || ""}
                  onChange={(e) => handleFilterChange('query', e.target.value)}
                  InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <SearchIcon />
                          </InputAdornment>
                      ),
                  }}
              />
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="on-sale-label">On Sale</InputLabel>
                  <Select
                    labelId="on-sale-label"
                    id="on-sale-select"
                    value={filters.onSale != null ? filters.onSale.toString() : ""}
                    label="On Sale"
                    onChange={(e) => handleFilterChange('onSale', e.target.value === "true")}
                  >
                    <MenuItem value="true">Da</MenuItem>
                    <MenuItem value="false">Nu</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="per-page-label">Per Page</InputLabel>
                  <Select
                      labelId="per-page-label"
                      id="per-page-select"
                      value={pagination.perPage}
                      label="Per Page"
                      onChange={handlePerPageChange}
                  >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* Right Column for City, Category, Size, Color Selects */}
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="city-label">Oras</InputLabel>
                  <Select
                      labelId="city-label"
                      id="city-select"
                      value={filters.cityId || ""}
                      label="Oras"
                      onChange={(e) => handleFilterChange('cityId', e.target.value)}
                  >
                      {cities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Categorie</InputLabel>
                  <Select
                      labelId="category-label"
                      id="category-select"
                      value={filters.productClassId || ""}
                      label="Categorie"
                      onChange={(e) => handleFilterChange('productClassId', e.target.value)}
                  >
                      {productClasses.map((cls) => (
                          <MenuItem key={cls.id} value={cls.id}>{cls.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="size-label">Marime</InputLabel>
                  <Select
                      labelId="size-label"
                      id="size-select"
                      value={filters.sizeId || ""}
                      label="Marime"
                      onChange={(e) => handleFilterChange('sizeId', e.target.value)}
                  >
                      {sizes.map((size) => (
                          <MenuItem key={size.id} value={size.id}>{size.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="color-label">Culoare</InputLabel>
                  <Select
                      labelId="color-label"
                      id="color-select"
                      value={filters.colorId || ""}
                      label="Culoare"
                      onChange={(e) => handleFilterChange('colorId', e.target.value)}
                  >
                      {colors.map((color) => (
                          <MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            flexGrow: 1, 
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '8px', 
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', 
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.6)', 
                borderRadius: '4px', 
            },
            scrollbarWidth: 'thin', 
            scrollbarColor: 'rgba(0, 0, 0, 0.6) transparent',
        }}>
          {/* Бокс, который занимает все доступное пространство до пагинации */}
          <Box sx={{ 
              flexGrow: 1,
              display: 'flex',
              flexWrap: 'wrap',  
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: 2, 
              py: 2,
          }}>
              {productStatus === 'loading' ? (
                  <Spinner />
              ) : (
                  Products.map(product => (
                      <Box key={product.id}>
                          <ProductDepozit 
                            product={product}
                            onOpenNewTab={openProductInNewTab}
                            onEdit={editProduct}
                          />
                      </Box>
                  ))
              )}
          </Box>

          {/* Пагинация находится внизу, внутри Paper */}
          <Box sx={{ mt: 'auto', p: 2, display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={totalPages}
              page={pagination.page}
              onChange={handlePaginationChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>

    </Box>
    );
};

export default DepozitProduse