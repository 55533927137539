import React from 'react';
import { Card, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProductClass } from '../../../redux/slices/class';

interface CategoryCardProps {
  productClass: ProductClass;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ productClass }) => {
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';

    const handleCardClick = () => {
      navigate(`/Products?category=${productClass.name}`);
    };
    
  return (
    <Card
    onClick={handleCardClick}
      sx={{
        width: 330,
        height: 330,
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Box sx={{ position: 'relative', height: '100%' }}>
        <CardMedia
          component="img"
          height="auto"
          image={productClass.imageUrl ? `${baseUrl}${productClass.imageUrl}` : 'path/to/placeholder-image.jpg'} 
          alt={productClass.name}
        />
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '10px',
          textAlign: 'center',
        }}>
          <Typography variant="h4">
            {productClass.name}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CategoryCard;
