import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import AdminStatistic from '../../components/Admin/adminStats/AdminStatistic';
import AdminPieCharts from '../../components/Admin/adminStats/AdminPieCharts';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchAllOrderStats } from '../../redux/slices/stats';

const Statistic = () => {
    const dispatch = useAppDispatch();
    const stats = useAppSelector(state => state.orderStats.data);
    const onlineTotal = stats.reduce((acc, stat) => acc + stat.onlineTotalAmount, 0);
    const offlineTotal = stats.reduce((acc, stat) => acc + stat.offlineTotalAmount, 0);
    const onlineCount = stats.reduce((acc, stat) => acc + stat.onlineOrderCount, 0);
    const offlineCount = stats.reduce((acc, stat) => acc + stat.offlineOrderCount, 0);
    const [activeTab, setActiveTab] = useState<string>('Azi');

    useEffect(() => {
        let days: number;
        switch (activeTab) {
            case 'Azi': days = 1; break;
            case 'O Saptamana': days = 7; break;
            case 'O Luna': days = 30; break;
            case 'Un An': days = 365; break;
            default: days = 1;
        }

        dispatch(fetchAllOrderStats(days));
    }, [dispatch, activeTab]);


    const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: "2%",
            width: '100%'
        }}>
            {/* HEADER */}
            <Box sx={{ marginBottom: 2 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h1">Statistica</Typography>
                </Box>
                
                {/* Tabs */}
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab label={<Typography variant="h4">Azi</Typography>} value="Azi" />
                    <Tab label={<Typography variant="h4">O Saptamana</Typography>} value="O Saptamana" />
                    <Tab label={<Typography variant="h4">O Luna</Typography>} value="O Luna" />
                    <Tab label={<Typography variant="h4">Un An</Typography>} value="Un An" />
                </Tabs>
            </Box>

            {/* Statistic Content */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
            }}>
                {/* Контейнер для AdminStatistic */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 4,
                    width: '100%',
                    height: '70vh' 
                }}>
                    <AdminStatistic data={stats} />
                </Box>

                {/* Контейнер для AdminPieCharts */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    width: '100%', 
                    height: '40vh' 
                }}>
                     <AdminPieCharts 
                        onlineOrderCount={onlineCount}
                        offlineOrderCount={offlineCount}
                        onlineTotalRevenue={onlineTotal}
                        offlineTotalRevenue={offlineTotal}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Statistic;
