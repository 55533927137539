import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

interface FaqItem {
  question: string;
  answer: string;
}

const FAQ: React.FC = () => {
  const faqs: FaqItem[] = [
    {
      question: 'Ce metode de plată acceptați?',
      answer: `Acceptăm atât plata în numerar, cât și plata cu cardul, inclusiv Visa, MasterCard și American Express.
      În cazul în care întâmpinați probleme, vă rugăm să ne contactați.`
    },
    {
      question: 'Cât costă livrarea și cât timp durează?',
      answer: `Costul livrării depinde de metoda de livrare aleasă.
      Oferim livrare prin Poșta Moldovei la prețul de 50 lei, 
      sau prin Nova Poșta, cu livrare la adresa aleasă de dumneavoastră, la prețul de 100 lei.`
    },
    {
      question: 'Pot returna produsul dacă acesta nu mi se potrivește?',
      answer: `Da, puteți returna produsul în termen de 14 zile de la recepție, cu condiția ca acesta să nu fi fost folosit, 
      și să se afle în starea originală și în ambalajul original.`
    },
    {
      question: 'Cum pot urmări comanda mea?',
      answer: `După ce comanda dumneavoastră a fost expediată, vă vom trimite un e-mail cu starea comenzii, 
      și la fiecare schimbare a stării acesteia. Puteți verifica starea actuală a comenzii în secțiunea 'Detalii' la fiecare comandă plasată.`
    },
    {
      question: 'Ce fac dacă am primit un produs deteriorat?',
      answer: `Ne cerem scuze pentru orice inconvenient. Vă rugăm să contactați serviciul nostru de asistență pentru 
      clienți în termen de 48 de ore de la recepția produsului, atașând fotografii ale produsului deteriorat, și vom 
      aborda problema cât mai rapid posibil.`
    },
  ];
  
  
  
  

  return (
    <Box sx={{ textAlign: 'center', margin: '20px' }}>
      <Typography variant="h2" sx={{ marginBottom: '20px' }}>
        Intrebari Fregvente
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index} 
          defaultExpanded 
          sx={{ backgroundColor: '#f7f7f7' }} 
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography sx={{ fontSize: '1.2rem' }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '1.1rem' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
