import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Message } from '../../../redux/slices/produse';

interface QuestionSectionProps {
    messages: Message[];
}

const QuestionSection: React.FC<QuestionSectionProps> = ({ messages }) => {
    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: '100%', 
            height: '100%', 
            justifyContent: 'center',
        }}>
            <Button 
                variant="contained" 
                sx={{ 
                    bgcolor: 'orange', 
                    color: 'black',
                    fontSize: '1.2rem', 
                    padding: '8px 20px',
                    '&:hover': {
                        bgcolor: 'darkorange',  
                    },
                }}
            >
                Adauga Intrebarea Ta
            </Button>
            {messages.length === 0 && (
                <Typography variant='h4' sx={{ mt: 2, color: 'text.secondary' }}>
                    Nu există încă întrebări. Fii primul care pune o întrebare!
                </Typography>
            )}
        </Box>
    )
}

export default QuestionSection;
