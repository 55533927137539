import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  Typography
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const Search: React.FC<SearchProps> = ({ onOpen, onClose, isOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery('(max-width:1300px)');
  const [searchTerm, setSearchTerm] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();


  const handleSearch = () => {
    if (searchTerm) {
      const existingSearch = searchParams.get('search');
      if (existingSearch) {
        setSearchParams({ search: searchTerm }); 
        navigate(`/Products?search=${searchTerm}`); 
      } else {
        navigate(`/Products?search=${searchTerm}`);
      }
    }
    onClose();
  };

  const handleResetSearch = () => {
    setSearchTerm('');
    if (window.location.pathname === '/Products') {
      setSearchParams({ search: '' }); 
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    isTabletOrMobile ? (
      <Box>
        <IconButton onClick={onOpen} sx={{ color: 'black' }}>
          <SearchIcon />
        </IconButton>

        <Dialog open={isOpen} onClose={onClose} fullWidth>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4">Cautare Produse</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              placeholder="Cauta produse"
              autoComplete="none"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleSearch} color="primary" variant="contained" sx={{ minWidth: '20%' }}>
              Cauta
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ) : (
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Cauta produse"
        autoComplete='none'
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && ( 
                <IconButton onClick={handleResetSearch}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    )
  );
}

export default Search;
