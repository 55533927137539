import React from 'react';
import { Box, Button } from '@mui/material';
import { ProductClass } from '../../../redux/slices/class';
import { Filter } from '../../../redux/slices/posts';

interface CategorySelectorProps {
    productClasses: ProductClass[];
    onFilterChange: (key: keyof Filter, value: any) => void;
    selectedProductClassId?: number | null;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
    productClasses,
    onFilterChange,
    selectedProductClassId
}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
            <Button
                key="all"
                variant="text"
                sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: selectedProductClassId === null ? 'bold' : 'normal',
                    borderBottom: selectedProductClassId === null ? '3px solid black' : 'none'
                }}
                onClick={() => onFilterChange('productClassId', null)}
            >
                Toate
            </Button>
            {productClasses.map(productClass => (
                <Button
                    key={productClass.id}
                    variant="text"
                    sx={{
                        color: 'black',
                        fontSize: '1.2rem',
                        fontWeight: productClass.id === selectedProductClassId ? 'bold' : 'normal',
                        borderBottom: productClass.id === selectedProductClassId ? '3px solid black' : 'none'
                    }}
                    onClick={() => onFilterChange('productClassId', productClass.id)}
                >
                    {productClass.name}
                </Button>
            ))}
        </Box>
    );
}

export default CategorySelector;
