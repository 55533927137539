import React from 'react';
import { Box, CircularProgress, Pagination, Typography } from '@mui/material';
import { Product, ProductsLoadingStatus } from '../../../redux/slices/produse';
import ProductCard from './ProductCard';

interface ProductsProps {
  products: Product[];
  count: number;
  status: ProductsLoadingStatus;
  pagination: {
    page: number;
    perPage: number;
  };
  handlePaginationChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  handleAddToFavorites: (productId: number) => void;
  handleRemoveFromFavorites: (productId: number) => void;
}

const ProductsComponent: React.FC<ProductsProps> = ({
  products,
  count,
  status,
  pagination,
  handlePaginationChange,
  handleAddToFavorites,
  handleRemoveFromFavorites,
}) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flexGrow: 1,
      p: '1%',
    }}>
      {status === 'loading' ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, width: '100%', }}>
          <CircularProgress />
        </Box>
      ) : (
        products.length === 0 ? (
          <Typography variant="h3" sx={{
            textAlign: 'center',
            wordWrap: 'break-word',
            maxWidth: '90%',
          }}>
            La moment nu avem produse disponibile in categoria data
          </Typography>
        ) : (
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            width: '100%',
            flexGrow: 1,
            mt: 1 
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap', 
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              p: 1,
              gap: 4
            }}>
              {products.map((product) => (
                <ProductCard 
                  key={product.id} 
                  product={product} 
                  handleAddToFavorites={handleAddToFavorites}
                  handleRemoveFromFavorites={handleRemoveFromFavorites}
                />
              ))}
            </Box>

            <Pagination
              page={pagination.page}
              count={Math.ceil(count / pagination.perPage)}
              onChange={handlePaginationChange}
              siblingCount={0} 
              showFirstButton
              showLastButton
              color="primary"
              sx={{ mt: 1 }}
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default ProductsComponent;
