import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AssignedUser } from './order';
import { API } from '../service';
import { DeliveryType } from './delivery';
import { PaymentType } from './payment';
import { Product } from './productAdmin';

export type OfflineOrderStatus = 'Nou' | 'In verificare' | 'In livrare' | 'Livrat';

export interface User {
    FullName: string;
    Email: string;
}

export interface OfflineOrder {
    Id: number; 
    assignedUser: AssignedUser;
    customerId?: number;
    customer?: User;
    customerName: string;
    customerContact?: string;
    city: string;
    address: string;
    status: OfflineOrderStatus;
    comments?: string;
    codLivrare?: string;
    deliveryType: DeliveryType; 
    paymentType: PaymentType;
    nominalPrice?: number;
    originalPrice?: number;
    totalAmount?: number;
    finalCost?: number;
    priceDifference?: number;
    revenue?: number;
    createdAt?: string; 
    updatedAt?: string;
    orderDetails?: OfflineOrderDetail[];
}

export interface OfflineOrderDetail {
    Id: number;
    Product: Product;
    inventoryId: number;
    quantity: number;
    originalPrice: number; 
    hasDiscount?: boolean;
    discountedPrice?: number; 
    discountPercentage?: number;
    totalPrice: number;
    createdAt: string;
    updatedAt: string;
}

export interface OfflineOrderDetailWithoutId {
    localId: string;
    productId: number;
    colorId: number | null;
    sizeId: number | null;
    cityId: number | null;
    quantity: number;
}

export interface CreateOfflineOrderData {
    customerId?: number | null;
    customerName: string;
    customerContact: string;
    comments?: string;
    city: string;
    address: string;
    deliveryTypeId: number; 
    paymentTypeId: number; 
    finalCost: number;
    items: OfflineOrderDetailWithoutId[];
}

interface UpdateOfflineOrderData {
    customerName?: string;
    customerContact?: string;
    productId?: number;
    quantity?: number;
    colorId?: number;
    sizeId?: number;
    itemsDescription?: string;
    totalAmount?: number;
    comments?: string;
}

export interface UpdateOfflineOrderStatusArgs {
    orderId: number;
    status?: OfflineOrderStatus;
    codLivrare?: string;
}

export interface Filter {
    searchTerm?: string;
    dateFrom?: string;
    dateTo?: string;
    status?: OfflineOrderStatus;
    isAssigned?: boolean;
    assignedUserId?: number; 
    sortByPrice: 'ASC' | 'DESC' | '' | undefined;
}
  
export interface Pagination {
    page: number;
    perPage: number;
}

export interface GetOfflineOrdersPaginationParams {
    pagination: Pagination;
    filters: Filter;
}

export interface UpdateOrderStatusResponse {
    success: boolean;
    message: string;
    status: OfflineOrderStatus;
    codLivrare?: string;
}

  
interface OfflineOrdersState {
    offorders: {
        items: OfflineOrder[];
        status: 'loading' | 'loaded' | 'error';
        count: number;
    };
    selectedOrder: {
        item: OfflineOrder | null;
        status: 'loading' | 'loaded' | 'error',
      }
    createStatus: 'idle' | 'loading' | 'success' | 'error',
}
  
const offlineInitialState: OfflineOrdersState = {
    offorders: {
      items: [],
      status: 'loading',
      count: 0,
    },
    selectedOrder: {
        item: null,
        status: 'loading',
    },
    createStatus: 'idle',
};



export const createOfflineOrder = createAsyncThunk<OfflineOrder, CreateOfflineOrderData, { rejectValue: string }>(
    'offlineOrders/createOfflineOrder',
    async (OfflineOrderData, { rejectWithValue }) => {
      const [error, response] = await API<OfflineOrder>('POST', '/admin/offlineorders', OfflineOrderData);
  
      if (error || !response) {
        return rejectWithValue('Failed to create offline order');
      }
  
      return response.data;
    }
);

export const fetchPaginationOfflineOrders = createAsyncThunk<{ orders: OfflineOrder[], count: number }, GetOfflineOrdersPaginationParams, { rejectValue: string }>(
    'offlineOrders/fetchPaginationOfflineOrders',
    async ({ pagination, filters }, { rejectWithValue }) => {
        const [error, response] = await API<{ orders: OfflineOrder[], count: number }>(
            'GET',
            `/admin/pagination/offlineorders`,
            null,
            { pagination, ...filters }
        );

        if (error || !response) {
            return rejectWithValue('Failed to fetch pagination offline orders');
        }

        return {
            orders: response.data.orders,
            count: response.data.count,
        };
    }
);

export const getSingleOfflineOrder = createAsyncThunk<OfflineOrder, number, { rejectValue: string }>(
    'offlineOrders/getSingleOfflineOrder',
    async (id, { rejectWithValue }) => {
      const [error, response] = await API<OfflineOrder>('GET', `/admin/offlineorders/${id}`);
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch single offline order');
      }
        return response.data;
    }
);

export const updateOfflineOrder = createAsyncThunk<OfflineOrder, { id: number; updateData: UpdateOfflineOrderData }, { rejectValue: string }>(
    'offlineOrders/updateOfflineOrder',
    async ({ id, updateData }, { rejectWithValue }) => {
      const [error, response] = await API<OfflineOrder>('PATCH', `/admin/offlineorders/${id}`, updateData);
  
      if (error || !response) {
        return rejectWithValue('Failed to update offline order');
      }
  
      return response.data;
    }
);

export const updateOfflineOrderStatus = createAsyncThunk<UpdateOrderStatusResponse & { orderId: number }, UpdateOfflineOrderStatusArgs, { rejectValue: string }>(
    'offlineOrders/updateOfflineOrderStatus',
    async (args, { rejectWithValue }) => {
        const { orderId, status, codLivrare } = args;

        const [error, response] = await API<UpdateOrderStatusResponse>(
            'PATCH',
            `/admin/offlineorderstatus/${orderId}`,
            { status, codLivrare }
        );

        if (error || !response) {
            return rejectWithValue('Failed to update offline order status');
        }

        return { orderId, ...response.data };
    }
);

export const deleteOfflineOrder = createAsyncThunk<void, number, { rejectValue: string }>(
    'offlineOrders/deleteOfflineOrder',
    async (id, { rejectWithValue }) => {
      const [error] = await API<void>('DELETE', `/admin/offlineorders/${id}`);
  
      if (error) {
        return rejectWithValue('Failed to delete offline order');
      }
    }
);



const offlineOrdersSlice = createSlice({
    name: 'offlineOrders',
    initialState: offlineInitialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(createOfflineOrder.pending, (state) => {
                state.createStatus = 'loading';
            })
            .addCase(createOfflineOrder.fulfilled, (state, action: PayloadAction<OfflineOrder>) => {
                state.createStatus = 'success'; 
            })
            .addCase(createOfflineOrder.rejected, (state) => {
                state.createStatus = 'error'; 
            })
            .addCase(fetchPaginationOfflineOrders.pending, (state) => {
                state.offorders.status = 'loading';
            })
            .addCase(fetchPaginationOfflineOrders.fulfilled, (state, action: PayloadAction<{ orders: OfflineOrder[], count: number }>) => {
                state.offorders.status = 'loaded';
                state.offorders.items = action.payload.orders;
                state.offorders.count = action.payload.count;
            })
            .addCase(fetchPaginationOfflineOrders.rejected, (state) => {
                state.offorders.status = 'error';
            })
            .addCase(getSingleOfflineOrder.pending, (state) => {
                state.selectedOrder.status = 'loading';
            })
            .addCase(getSingleOfflineOrder.fulfilled, (state, action: PayloadAction<OfflineOrder>) => {
                state.selectedOrder.status = 'loaded';
                state.selectedOrder.item = action.payload
            })            
            .addCase(getSingleOfflineOrder.rejected, (state) => {
                state.selectedOrder.status = 'error';
            })
            .addCase(updateOfflineOrder.pending, (state) => {
                state.offorders.status = 'loading';
            })
            .addCase(updateOfflineOrder.fulfilled, (state, action: PayloadAction<OfflineOrder>) => {
                state.offorders.status = 'loaded';
                const index = state.offorders.items.findIndex(offorders => offorders.Id === action.payload.Id);
                if (index !== -1) {
                    state.offorders.items[index] = action.payload;
                }
            })
            .addCase(updateOfflineOrder.rejected, (state) => {
                state.offorders.status = 'error';
            })
            .addCase(deleteOfflineOrder.pending, (state, action) => {
                state.offorders.items = state.offorders.items.filter((obj) => obj.Id !== action.meta.arg);
            })
            .addCase(updateOfflineOrderStatus.pending, (state) => {
                state.offorders.status = 'loading';
            })
            .addCase(updateOfflineOrderStatus.fulfilled, (state, action: PayloadAction<UpdateOrderStatusResponse & { orderId: number }>) => {
                state.offorders.status = 'loaded';
                const { orderId, status, codLivrare } = action.payload;
                const orderToUpdate = state.offorders.items.find(order => order.Id === orderId);
                if (orderToUpdate) {
                    orderToUpdate.status = status;
                    if (codLivrare) {
                        orderToUpdate.codLivrare = codLivrare;
                    }
                }
                if (state.selectedOrder.item && state.selectedOrder.item.Id === orderId) {
                    state.selectedOrder.item.status = status;
                    if (codLivrare) {
                        state.selectedOrder.item.codLivrare = codLivrare;
                    }
                }
            })
            .addCase(updateOfflineOrderStatus.rejected, (state) => {
                  state.offorders.status = 'error';
            })
    }
});

export const offlineOrdersReducer = offlineOrdersSlice.reducer;

  