import React, { ChangeEvent, useEffect, useState } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { sendEmail } from '../../../redux/slices/sendemail';


const NeedHelp: React.FC = () => {
  const dispatch = useAppDispatch();
  const emailStatus = useAppSelector((state) => state.email.status);
  const emailMessage = useAppSelector((state) => state.email.message);  
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const isFormEmpty = email === '' || message === '';
  const isSubmitDisabled = email === '' || message === '' || emailError !== '' || phoneError !== '';
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  useEffect(() => {
    if (emailStatus === 'loaded' && emailMessage) {
      setEmail('');
      setPhoneNumber('');
      setMessage('');
      setShowSuccessMessage(true); 
    }
  }, [emailStatus, emailMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => setShowSuccessMessage(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const isValidEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (!isValidEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newPhone = event.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(newPhone);
    if (newPhone && !/^\d+$/.test(newPhone)) {
      setPhoneError('Only numbers are allowed');
    } else {
      setPhoneError('');
    }
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (emailError === '' && phoneError === '') {
      setShowSuccessMessage(false);
      const payload = {
        userEmail: email,
        userPhone: phoneNumber,
        userMessage: message,
      };
  
      dispatch(sendEmail(payload)); 
    }
  };

  const handleCancel = () => {
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setEmailError('');
    setPhoneError('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '10px' }}>

    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showSuccessMessage}
      autoHideDuration={3000}
      onClose={() => setShowSuccessMessage(false)}
      message="Mesaj Trimis"
    />  

      <Typography variant="h2" sx={{ marginBottom: '8px' }}>
        Suport Clienti
      </Typography>
      <Box sx={{

      }}> 
        <Typography variant="h4" sx={{ marginBottom: '16px' }}>
          Daca v-ati confruntat cu o problema sau aveti intrebari ne puteti contacta telefonic la numarul 
          <Box component="span" sx={{ color: 'red', fontWeight: 'bold' }}> 069927805 </Box>
          sau ne puteti trimite mesaj pe adresa noastra de email: 
          <Box component="span" sx={{ color: 'red', fontWeight: 'bold' }}> milivnik@gmail.com</Box>
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
            label="Adresa de email"
            type="email"
            variant="outlined"
            sx={{ width: '40%', marginRight: '8px' }}
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError || ' '}
            autoComplete='none'
        />
        <TextField
            label="Numar de telefon *"
            type="tel"
            variant="outlined"
            sx={{ width: '20%' }}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!phoneError}
            helperText={phoneError || 'Optional'}
            autoComplete='none'
        />
      </Box>
      
      <TextField
        label="Mesajul dumneavoastra"
        variant="outlined"
        fullWidth
        multiline
        rows={8}
        value={message}
        onChange={handleMessageChange}
        sx={{ marginBottom: '16px', flexGrow: 1 }} 
      />
      
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Trimite
        </Button>
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={handleCancel}
          disabled={isFormEmpty}
        >
          Anuleaza
        </Button>
      </Box>
    </Box>
  );
};

export default NeedHelp;
