import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axios';
import { API } from '../service';

export interface Post {
  id: number;
  title: string;
}

export interface User {
  id: number;
  FullName: string;
}

export interface Replies {
  id: number;
  text: string;
  createdAt: string;
  User: User;
}

export interface Message {
  Id: number;
  text: string;
  userId: number;
  User: User;
  postId: number;
  Post: Post;
  isAnswer: boolean;
  parentMessageId: number | null;
  createdAt: string;
  Replies?: Replies[];
  isHidden: Boolean;
}

export interface CreateMessagePayload {
  postId: number;
  text: string;
  isAnswer?: boolean;
  parentMessageId?: number | null;
}

interface CreateMessageState {
  items: Message[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  count: number;
}

const initialCreateMessageState: CreateMessageState = {
  items: [],
  status: 'idle',
  error: null,
  count: 0,
};

interface MessageRequestParams {
    page: number;
    perPage: number;
  }

  export interface UpdateHiddenStatePayload {
    messageId: number;
    isHidden: boolean;
  }

  export const createMessage = createAsyncThunk<Message, CreateMessagePayload>(
    'createMessage/createMessage',
    async ({ postId, text, isAnswer = false, parentMessageId = null }, { rejectWithValue }) => {
      const [error, response] = await API<Message>('POST', '/message/create', { postId, text, isAnswer, parentMessageId });
  
      if (error || !response) {
        return rejectWithValue('Failed to create message');
      }
      return response.data;
    }
  );

  export const getAllMessagesForPost = createAsyncThunk<Message[], number, { rejectValue: string }>(
    'getAllMessagesForPost',
    async (postId, { rejectWithValue }) => {
      const [error, response] = await API<Message[]>('GET', `/message/get/${postId}`);
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch messages for post');
      }
  
      return response.data;
    }
  );

  export const getAllMessages = createAsyncThunk<{ count: number, messages: Message[] }, MessageRequestParams, { rejectValue: string }>(
    'getAllMessages',
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number, messages: Message[] }>('GET', '/message/getall', null, {
          page,
          perPage,
      });
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch all messages');
      }
  
      return response.data;
    }
  );

  export const updateMessage = createAsyncThunk<Message, { messageId: number, text: string }, { rejectValue: string }>(
    'updateMessage',
    async ({ messageId, text }, { rejectWithValue }) => {
      const [error, response] = await API<Message>('PATCH', `/message/update/${messageId}`, { text });
  
      if (error || !response) {
        return rejectWithValue('Failed to update the message');
      }
  
      return response.data;
    }
  );

  export const updateHiddenState = createAsyncThunk<{ message: string; updatedMessage: Message }, UpdateHiddenStatePayload, { rejectValue: string }>(
    'updateHiddenState',
    async ({ messageId, isHidden }, { rejectWithValue }) => {
      const [error, response] = await API<{ message: string; updatedMessage: Message }>('PATCH', `/message/hidden/${messageId}`, { isHidden });
  
      if (error || !response) {
        return rejectWithValue('Failed to update the hidden state of the message');
      }
  
      return response.data;
    }
  );


export const MessageSlice = createSlice({
  name: 'createMessage',
  initialState: initialCreateMessageState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createMessage.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(createMessage.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(createMessage.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = 'An error occurred';
        }
        state.items = [];
        state.status = 'failed';
      })
      .addCase(getAllMessagesForPost.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(getAllMessagesForPost.fulfilled, (state, action: PayloadAction<Message[]>) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.error = null;
      })
      .addCase(getAllMessagesForPost.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = 'An error occurred';
        }
        state.status = 'failed';
        state.items = [];
      })
      .addCase(getAllMessages.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(getAllMessages.fulfilled, (state, action: PayloadAction<{ count: number, messages: Message[] }>) => {
        state.items = action.payload.messages
        state.error = null;
        state.count = action.payload.count;
        state.status = 'succeeded';
      })
      .addCase(getAllMessages.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = 'An error occurred';
        }
        state.status = 'failed';
        state.items = [];
      })
      .addCase(updateMessage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMessage.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(updateMessage.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = 'An error occurred';
        }
        state.status = 'failed';
      })
      .addCase(updateHiddenState.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateHiddenState.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(updateHiddenState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      });
  },
});

export const selectCreateMessageStatus = (state: { createMessage: CreateMessageState }) => state.createMessage.status;
export const selectCreateMessageError = (state: { createMessage: CreateMessageState }) => state.createMessage.error;

export const MessageReducer = MessageSlice.reducer;
