import { configureStore, ThunkAction, Action, AnyAction, getDefaultMiddleware, Middleware } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { authReducer } from "./slices/auth";
import { postsReducer } from "./slices/posts";
import { cartReducer } from './slices/cart';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { sizesReducer } from './slices/size';
import { colorsReducer } from './slices/color';
import { inventoryReducer } from './slices/inventory';
import { ordersReducer } from './slices/order';
import { productClassReducer } from './slices/class';
import { favoritesReducer } from './slices/favorites';
import { citiesReducer } from './slices/city';
import { notificationsReducer } from './slices/notification';
import { offlineOrdersReducer } from './slices/orderoffline';
import { userReducer } from './slices/user';
import { discountReducer } from './slices/discount';
import { newsletterReducer } from './slices/sendnewsletter';
import { userordersReducer } from './slices/userorders';
import { productsReducer } from './slices/produse';
import { ratingsReducer } from './slices/rating';
import { MessageReducer } from './slices/message';
import { canReviewReducer } from './slices/checkCanReview';
import orderStatsReducer from './slices/stats'
import { emailReducer } from './slices/sendemail';
import userRoleSlice from './slices/userrole';
import rolesSlice from './slices/roles';
import selectedWorkerSlice from './slices/staff'
import adminStatsSlice from './slices/generalstats'
import { paymentTypesReducer } from './slices/payment'
import { deliveryTypesReducer } from './slices/delivery'
import { productsAdminReducer } from './slices/productAdmin';
import { onlineOrderReducer } from './slices/onlineOrderClient';


let _store = configureStore({
  reducer: {
    productsAdmin: productsAdminReducer,
    posts: postsReducer,
    auth: authReducer,
    cart: cartReducer,
    sizes: sizesReducer,
    colors: colorsReducer,
    inventory: inventoryReducer,
    orders: ordersReducer,
    onlineOrders: onlineOrderReducer,
    productClass: productClassReducer,
    favorites: favoritesReducer,
    cities: citiesReducer,
    notifications: notificationsReducer,
    offlineOrders: offlineOrdersReducer,
    user: userReducer,
    discount: discountReducer,
    newsletter: newsletterReducer,
    user_orders: userordersReducer,
    products: productsReducer,
    rating: ratingsReducer,
    message: MessageReducer,
    canReview: canReviewReducer,
    orderStats: orderStatsReducer,
    email: emailReducer,
    userRole: userRoleSlice,
    roles: rolesSlice,
    staff: selectedWorkerSlice,
    adminStats: adminStatsSlice,
    payments: paymentTypesReducer,
    deliveries: deliveryTypesReducer,
  },
});

export type RootState = ReturnType<typeof _store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof _store.dispatch & ThunkDispatch<RootState, any, AnyAction>;

const middleware: Middleware<{}, any, AppDispatch>[] = [...getDefaultMiddleware()];

_store = configureStore({
  reducer: {
    productsAdmin: productsAdminReducer,
    posts: postsReducer,
    auth: authReducer,
    cart: cartReducer,
    sizes: sizesReducer,
    colors: colorsReducer,
    inventory: inventoryReducer,
    orders: ordersReducer, 
    onlineOrders: onlineOrderReducer,
    productClass: productClassReducer,
    favorites: favoritesReducer,
    cities: citiesReducer,
    notifications: notificationsReducer,
    offlineOrders: offlineOrdersReducer,
    user: userReducer,
    discount: discountReducer,
    newsletter: newsletterReducer,
    user_orders: userordersReducer,
    products: productsReducer,
    rating: ratingsReducer,
    message: MessageReducer,
    canReview: canReviewReducer,
    orderStats: orderStatsReducer,
    email: emailReducer,
    userRole: userRoleSlice,
    roles: rolesSlice,
    staff: selectedWorkerSlice,
    adminStats: adminStatsSlice,
    payments: paymentTypesReducer,
    deliveries: deliveryTypesReducer,
  },
  middleware,
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { _store as store };

