import React, { useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, CircularProgress, Snackbar } from '@mui/material';
import Detalii from '../../components/Client/DetaliiComanda/Detalii';
import Sumar from '../../components/Client/DetaliiComanda/Sumar';
import { fetchPaymentTypes } from '../../redux/slices/payment';
import { fetchDeliveryTypes } from '../../redux/slices/delivery';
import { useAppDispatch, RootState, useAppSelector } from '../../redux/store';
import { fetchCreateOnlineOrder, CreateOnlineOrderData } from '../../redux/slices/onlineOrderClient';
import { fetchDiscountByUserId } from '../../redux/slices/discount';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { GetStockCartItems, removeFromCart, updateCartQuantity, addToFavorites, removeFromFavorites } from '../../redux/slices/produse';
import { selectIsAuthenticated } from '../../redux/slices/auth';
import { unwrapResult } from '@reduxjs/toolkit';


const DetaliiComanda: React.FC = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const deliveryTypes = useAppSelector((state: RootState) => state.deliveries.items);
    const paymentTypes = useAppSelector((state: RootState) => state.payments.items);
    const cartSummary = useAppSelector((state: RootState) => state.products.cart);
    const cartItems = useAppSelector((state: RootState) => state.products.cart.items);
    const cartStatus = useAppSelector((state: RootState) => state.products.cart.status);
    const discounts = useAppSelector((state: RootState) => state.discount.items);
    const orderStatus = useAppSelector((state: RootState) => state.onlineOrders.createStatus);
    const [activeStep, setActiveStep] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const steps = ['Detalii Comanda', 'Sumar Comanda'];
    const [orderData, setOrderData] = useState<CreateOnlineOrderData>({
        deliveryAddress: '',
        deliveryCity: '',
        deliveryPostalCode: '',
        deliveryCountry: 'Moldova',
        deliveryPhone: '',
        deliveryTypeId: 0,
        paymentTypeId: 0,
        discountId: undefined,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [priceWithDiscount, setPriceWithDiscount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);

    useEffect(() => {
        dispatch(fetchDeliveryTypes());
        dispatch(fetchPaymentTypes());
        dispatch(GetStockCartItems());;
        dispatch(fetchDiscountByUserId());
    }, [dispatch]);

    useEffect(() => {
        if (orderData.discountId) {
            const selectedDiscount = discounts.find(d => d.discountId === orderData.discountId);
            if (selectedDiscount) {
                const calculatedDiscount = cartSummary.totalPrice * (selectedDiscount.discount_percentage / 100);
                setDiscountAmount(calculatedDiscount);
                setPriceWithDiscount(cartSummary.totalPrice - calculatedDiscount);
            }
        } else {
            setPriceWithDiscount(cartSummary.totalPrice);
            setDiscountAmount(0);
        }
    }, [orderData.discountId, cartSummary.totalPrice, discounts]);

    useEffect(() => {
        if (orderData.deliveryTypeId) {
            const selectedDelivery = deliveryTypes.find(dt => dt.id === orderData.deliveryTypeId);
            if (selectedDelivery) {
                setDeliveryPrice(selectedDelivery.price)
                setFinalPrice(priceWithDiscount + selectedDelivery.price);
            } else {
                setFinalPrice(priceWithDiscount);
            }
        } else {
            setFinalPrice(priceWithDiscount);
        }
    }, [priceWithDiscount, orderData.deliveryTypeId, deliveryTypes]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
        case 0:
            return (
                <Detalii 
                    deliveryTypes={deliveryTypes} 
                    paymentTypes={paymentTypes} 
                    orderData={orderData}
                    handleDeliveryAddressChange={handleDeliveryAddressChange}
                    handleDeliveryCityChange={handleDeliveryCityChange}
                    handleDeliveryPostalCodeChange={handleDeliveryPostalCodeChange}
                    handleDeliveryPhoneChange={handleDeliveryPhoneChange}
                    handleDeliveryTypeIdChange={handleDeliveryTypeIdChange}
                    handlePaymentTypeIdChange={handlePaymentTypeIdChange}
                />
            );
        case 1:
            return (
                <Sumar 
                    deliveryTypes={deliveryTypes} 
                    paymentTypes={paymentTypes} 
                    orderData={orderData}
                    discounts={discounts}
                    handleDiscountChange={handleDiscountChange}
                    cartItems={cartItems}
                    totalItems={cartSummary.totalItems}
                    cartPrice={cartSummary.totalPrice}
                    discountAmount={discountAmount}
                    deliveryPrice={deliveryPrice}
                    finalPrice={finalPrice}
                    handleAddToFavorites={handleAddToFavorites}
                    handleRemoveFromFavorites={handleRemoveFromFavorites}
                    handleRemoveFromCart={handleRemoveFromCart}
                    handleUpdateCartQuantity={handleUpdateCartQuantity}
                />
            );
        default:
            return "Unknown step";
        }
    };

    const handleAddToFavorites = async (productId: number) => {
        if (!isAuthenticated) {
            alert('Please log in to add products to favorites');
            return;
        }
        try {
            const actionResult = await dispatch(addToFavorites({ productId }));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost adaugat in favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparaut o eroare in procesul de adauagre in faovorite.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveFromFavorites = async (productId: number) => {
        try {
            const actionResult = await dispatch(removeFromFavorites(productId));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveFromCart = async (cartId: number) => {
    try {
        const actionResult = await dispatch(removeFromCart(cartId));
        const result = unwrapResult(actionResult);
        if (result.success) {
            setSnackbarMessage('Produsul a fost sters cu succes din cos!');
            setSnackbarOpen(true);
            setSnackbarKey(prevKey => prevKey + 1);
        }
    } catch (error) {
        setSnackbarMessage('A aparut o problema, va rugam incercati mai tarziu.');
        setSnackbarOpen(true);
        setSnackbarKey(prevKey => prevKey + 1);
    }
    };

    const handleUpdateCartQuantity = async (cartId: number, quantity: number) => {
    try {
        const actionResult = await dispatch(updateCartQuantity({ cartId, quantity }));
        const result = unwrapResult(actionResult);
        if (result.success) {
            setSnackbarMessage('Cantitatea din coș a fost actualizată cu succes!');
            setSnackbarOpen(true);
            setSnackbarKey(prevKey => prevKey + 1);
        }
    } catch (error) {
        setSnackbarMessage('Actualizarea cantității din coș a eșuat.');
        setSnackbarOpen(true);
        setSnackbarKey(prevKey => prevKey + 1);
    }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleDeliveryAddressChange = (address: string) => {
        setOrderData(prevData => ({
            ...prevData,
            deliveryAddress: address
        }));
    };

    const handleDeliveryCityChange = (city: string) => {
        setOrderData(prevData => ({
            ...prevData,
            deliveryCity: city
        }));
    };
    
    const handleDeliveryPostalCodeChange = (postalCode: string) => {
        setOrderData(prevData => ({
            ...prevData,
            deliveryPostalCode: postalCode
        }));
    };
    
    const handleDeliveryPhoneChange = (phone: string) => {
        setOrderData(prevData => ({
            ...prevData,
            deliveryPhone: phone
        }));
    };

    const handleDeliveryTypeIdChange = (deliveryTypeId: number) => {
        setOrderData(prevData => ({
            ...prevData,
            deliveryTypeId: deliveryTypeId
        }));
    };
    
    const handlePaymentTypeIdChange = (paymentTypeId: number) => {
        setOrderData(prevData => ({
            ...prevData,
            paymentTypeId: paymentTypeId
        }));
    };

    const handleDiscountChange = (discountId: number | undefined) => {
        setOrderData(prevData => ({
          ...prevData,
          discountId
        }));
    };

    const isOrderDataValid = () => {
        return orderData.deliveryAddress.trim() !== '' &&
               orderData.deliveryCity.trim() !== '' &&
               orderData.deliveryPostalCode.trim() !== '' &&
               orderData.deliveryPhone.trim() !== '' &&
               orderData.deliveryTypeId !== 0 &&
               orderData.paymentTypeId !== 0;
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSuccessClose = () => {
        setOpenSuccessDialog(false);
        navigate('/Orders');
    };

    const handleFinalizeOrder = async () => {
        handleCloseDialog();
    
        dispatch(fetchCreateOnlineOrder(orderData)).then(action => {
            if (fetchCreateOnlineOrder.fulfilled.match(action)) {
                cartItems.forEach(item => {
                    dispatch(removeFromCart(item.Id));
                });
                setOpenSuccessDialog(true);
            } else if (fetchCreateOnlineOrder.rejected.match(action)) {
                setOpenErrorDialog(true);
            }
        });
    };
    
    //disabled={!isOrderDataValid()}


    
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh', 
        p: '2%',
    }}>
        {orderStatus === 'loading' && (
            <Box
                sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 2000, 
                }}
            >
                <CircularProgress />
            </Box>
        )}

        <Stepper activeStep={activeStep} alternativeLabel sx={{
            width: '50%', 
            mb: 4, 
            '.MuiStepIcon-root': { fontSize: '2rem' }, 
            '.MuiStepLabel-label': { fontSize: '1.25rem', fontWeight: 'bold' }
        }}>
        {steps.map((label) => (
            <Step key={label}>
            <StepLabel>{label}</StepLabel>
            </Step>
        ))}
        </Stepper>

        {/* Этот Box теперь займет все доступное место, кроме места для кнопок */}
        <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ width: '100%' }}>
                {getStepContent(activeStep)}
            </Box>

            <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                    startIcon={<ArrowBackIosNewIcon />}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, px: 2, py: 1 }} 
                >
                    Inapoi
                </Button>
                {activeStep === steps.length - 1 ? (
                    <Button
                    startIcon={<ShoppingBasketIcon />}
                    variant="contained"
                    onClick={handleOpenDialog}
                    disabled
                    sx={{ px: 2, py: 1 }} 
                    >
                        Finalizeaza
                    </Button>
                ) : (
                    <Button
                    endIcon={<ArrowForwardIosIcon />}
                    variant="contained"
                    onClick={handleNext}
                    disabled={!isOrderDataValid()}
                    sx={{ px: 2, py: 1 }} 
                    >
                        Urmatorul
                    </Button>
                )}
            </Box>

        </Box>

        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">
                <Typography variant='h3'>
                    Confirmare comanda
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    <Typography variant='h4'>
                        Sunteți sigur că doriți să finalizați comanda?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    startIcon={<CancelIcon />}
                    onClick={handleCloseDialog}
                    sx={{
                        px: 2, 
                        py: 1, 
                        color: 'white',
                        backgroundColor: 'red',
                        '&:hover': {
                            backgroundColor: 'darkred',
                        },
                    }}
                >
                    Anulează
                </Button>
                <Button
                    startIcon={<CheckCircleIcon />}
                    onClick={handleFinalizeOrder} 
                    autoFocus
                    sx={{
                        px: 2, 
                        py: 1, 
                        color: 'white', 
                        backgroundColor: 'green',
                        '&:hover': {
                            backgroundColor: 'darkgreen',
                        },
                    }}
                >
                    Confirmă
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openSuccessDialog} onClose={handleSuccessClose}>
            <DialogTitle>{"Comanda a fost creată cu succes!"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Comanda dvs. a fost creată cu succes. Vă mulțumim pentru cumpărături!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSuccessClose}>OK</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
            <DialogTitle>{"Eroare la crearea comenzii"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Din păcate, a apărut o eroare la crearea comenzii dvs. Vă rugăm să încercați din nou.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenErrorDialog(false)}>OK</Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />

    </Box>
  );
};

export default DetaliiComanda;
