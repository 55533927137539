import { useState, useEffect } from 'react';
import { Box, useTheme, Typography, TextField, Paper } from "@mui/material"; 
import useMediaQuery from '@mui/material/useMediaQuery';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { OrderStatus } from '../../redux/slices/order';
import { Tabs, Tab } from '@mui/material';
import OrderOnlineListAdmin from '../../components/Admin/Order/OrderOnlineListAdmin';
import OrderOfflineListAdmin from '../../components/Admin/Order/OrderOfflineListAdmin';
import { useSearchParams } from 'react-router-dom';
import { Filter } from '../../redux/slices/orderoffline';

const Comenzi = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams, setSearchParams] = useSearchParams();
    const initialTab = searchParams.get('tab') || 'online';
    const [activeTab, setActiveTab] = useState(initialTab);
    const [filters, setFilters] = useState<Filter>({
        searchTerm: '',
        dateFrom: '',
        dateTo: '',
        status: undefined,
        sortByPrice: undefined,
        isAssigned: undefined,
        assignedUserId: undefined
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
        searchParams.set('tab', newValue);
        setSearchParams(searchParams);
    };

    return (
        <Box sx={{ 
            height: "100vh",
            overflow: "auto", 
            p: '1%', 
            display: 'flex', 
            flexDirection: 'column' 
        }}>
            <Paper elevation={3} sx={{
                width: '100%', 
                height: '100%', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center',
                p: 2
            }}>

                {/* Header */}
                <Box
                    sx={{
                        padding: 1,
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                }}>
                    {/* Left */}
                    <Typography variant="h1" sx={{ flex: 1, textAlign: isMobile ? 'center' : 'left' }}>
                        Comenzi
                    </Typography>

                    {/* Вкладки "Online" и "Offline"  */}
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{ mb: isMobile ? 1 : 0 }}
                    >
                        <Tab label="Online" value="online" sx={{ fontSize: '1.5rem' }} />
                        <Tab label="Offline" value="offline" sx={{ fontSize: '1.5rem' }} />
                    </Tabs>

                    {/* Right */}
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isMobile ? 'center' : 'flex-end',
                            width: '100%'
                        }}
                    >
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            sx={{ width: isMobile ? '100%' : '40%' }} 
                            value={filters.searchTerm}
                            onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                mt: 1,
                                width: isMobile ? '100%' : '40%',
                            }}
                        >
                            <FormControl variant="outlined" size="small" fullWidth sx={{ flex: 1 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filters.status || ''}
                                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value as OrderStatus }))}
                                    label="Status"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Nou">Nou</MenuItem>
                                    <MenuItem value="In verificare">In verificare</MenuItem>
                                    <MenuItem value="In livrare">In livrare</MenuItem>
                                    <MenuItem value="Livrat">Livrat</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" size="small" fullWidth sx={{ flex: 1 }}>
                                <InputLabel>Data</InputLabel>
                                <Select
                                    value={filters.dateFrom || ''}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev, 
                                            dateFrom: e.target.value as string,
                                            sortByPrice: '' 
                                        }))
                                    }}
                                    label="Data"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="newToOld">New to Old</MenuItem>
                                    <MenuItem value="oldToNew">Old to New</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" size="small" fullWidth sx={{ flex: 1 }}>
                                <InputLabel>Price</InputLabel>
                                <Select
                                    value={filters.sortByPrice || ''}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            sortByPrice: e.target.value as 'ASC' | 'DESC' | '',
                                            dateFrom: '' 
                                        }))
                                    }}
                                    label="Price"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="ASC">Low to High</MenuItem>
                                    <MenuItem value="DESC">High to Low</MenuItem>
                                </Select>
                            </FormControl>

                            </Box>
                    </Box>

                </Box>

                {/* Orders */}
                <Box sx={{ flex: 1, width: '100%', overflow: 'auto' }}>
                    {activeTab === 'online' && <OrderOnlineListAdmin filters={filters} />}
                    {activeTab === 'offline' && <OrderOfflineListAdmin filters={filters} />}
                </Box>
                
            </Paper>
        </Box>
    );
}

export default Comenzi;
