import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { API } from '../service';
import { InventoryItem, InventoryDataCreate } from './inventory';

export interface Post {
  id: number;
  barcode?: string;
  title: string;
  text: string;
  totalInventoryCount?: number;
  viewsCount: number;
  imageUrl: string[]; 
  price: number;
  originalPrice?: number;
  discount?: Discount;  
  inventories?: InventoryItem[];
  hasActiveDiscount?: boolean; 
  averageRating: number;
  productClassId?: number;
  createdAt: string; 
  updatedAt: string; 
}

export interface Discount {
  percentage: number;
  startDate: string;
  endDate: string;
}

export interface PostData {
  title: string;
  text: string;
  price: number;
  originalPrice: number;
  discount?: Discount;
  productClassId: number;
  inventories?: InventoryDataCreate[];
  images: File[];
  deletedImageUrl?: string[];
  updatedInventories?: InventoryDataCreate[];
  deletedInventoryIds?: number[];
}


export interface Filter {
  colorId?: number | null;
  sizeId?: number | null;
  productClassId?: number | null;
  cityId?: number | null;
  sort?: 'asc' | 'desc' | null;
  query?: string | null; 
  onSale?: boolean | null;
}

export interface Pagination {
  page: number;
  perPage: number;
  total: number;
}

interface PostsState {
  posts: {
    items: Post[],
    status: 'loading' | 'loaded' | 'error',
  },
  filters: Filter,
  pagination: Pagination;
  searchResults?: Post[];
  totalProducts: number;
  createStatus: 'idle' | 'loading' | 'success' | 'error';
  selectedProduct?: Post | null; 
}

const initialState: PostsState = {
  posts: {
    items: [],
    status: 'loading',
  },
  filters: {
    colorId: null,
    sizeId: null,
    productClassId: null,
    cityId: null,
  },
  pagination: {
    page: 1,
    perPage: 10,
    total: 0,
  },
  searchResults: [],
  totalProducts: 0,
  createStatus: 'idle',
  selectedProduct: null
};

export interface FetchPostsPaginationParams {
  page: number;
  perPage: number;
  filters: Filter;
}



export const fetchGetProductForClient = createAsyncThunk<Post, number, { rejectValue: string }>(
  'posts/fetchGetProductForClient',
  async (id, { rejectWithValue }) => {
    const [error, response] = await API<Post>('GET', `/product/get/${id}`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch the product details for guests');
    }

    return response.data;
  }
);

export const fetchPost = createAsyncThunk<Post, number, { rejectValue: string }>(
  'posts/fetchPost', 
  async (id, { rejectWithValue }) => {
    const [error, response] = await API<Post>('GET', `/posts/${id}`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch the post');
    }

    return response.data;
  }
);

export const fetchRemovePost = createAsyncThunk<void, string, { rejectValue: string }>(
  'posts/fetchRemovePost',
  async (id, { rejectWithValue }) => {
    const [error, response] = await API<void>('DELETE', `/posts/${id}`);

    if (error || !response) {
      return rejectWithValue('Failed to remove post');
    }
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<Post[]>) => {
      state.searchResults = action.payload;
    },
    resetSelectedProduct(state) {
      state.selectedProduct = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetProductForClient.pending, (state) => {
        state.selectedProduct = null;
        state.posts.status = 'loading';
      })
      .addCase(fetchGetProductForClient.fulfilled, (state, action: PayloadAction<Post>) => {
        state.selectedProduct = action.payload;
        state.posts.status = 'loaded';
      })
      .addCase(fetchGetProductForClient.rejected, (state, action) => {
        state.posts.status = 'error';
      })
      .addCase(fetchPost.pending, (state) => {
        state.posts.status = 'loading';
      })
      .addCase(fetchPost.fulfilled, (state, action: PayloadAction<Post>) => {
        state.posts.status = 'loaded';
        const index = state.posts.items.findIndex(post => post.id === action.payload.id);
        if (index !== -1) {
          state.posts.items[index] = action.payload;
        } else {
          state.posts.items.push(action.payload);
        }
      })
      .addCase(fetchPost.rejected, (state) => {
        state.posts.status = 'error';
      }) 
  }
});


export const { resetSelectedProduct, setSearchResults } = postsSlice.actions;
export const postsReducer = postsSlice.reducer;