import React, { useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Typography, Paper, StepIconProps, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import SelectProduct from '../../components/Admin/OfflineOrder/CreateOfflineOrder/SelectProduct';
import OfflineOrderData from '../../components/Admin/OfflineOrder/CreateOfflineOrder/OfflineOrderData';
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import SelectProductDialog from '../../components/Admin/OfflineOrder/CreateOfflineOrder/SelectProductDialog';
import { OfflineOrderDetailWithoutId, CreateOfflineOrderData, createOfflineOrder } from '../../redux/slices/orderoffline';
import { fetchProductClasses } from '../../redux/slices/class';
import { fetchColors } from '../../redux/slices/color';
import { fetchSizes } from '../../redux/slices/size';
import SelectedProductInfoDialog from '../../components/Admin/OfflineOrder/CreateOfflineOrder/SelectedProductInfoDialog';
import { fetchPaymentTypes } from '../../redux/slices/payment';
import { fetchDeliveryTypes } from '../../redux/slices/delivery';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';  
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { GetProductForOfflineOrder, Filter, Product } from '../../redux/slices/productAdmin';
import { UserEmailInfo, fetchUserDetails, fetchUsersEmails } from '../../redux/slices/user';
import { clearSelectedUser } from '../../redux/slices/user';

const CreateOfflineOrder = () => {
    const dispatch = useAppDispatch();
    const products = useAppSelector((state: RootState) => state.productsAdmin.products.items);
    const usersEmail = useAppSelector((state: RootState) => state.user.emailItems);
    const selectedUser = useAppSelector((state: RootState) => state.user.selectedUser);
    const colors = useAppSelector((state: RootState) => state.colors.items);
    const sizes = useAppSelector((state: RootState) => state.sizes.items);
    const productClasses = useAppSelector((state: RootState) => state.productClass.items);
    const deliveryTypes = useAppSelector((state: RootState) => state.deliveries.items);
    const paymentTypes = useAppSelector((state: RootState) => state.payments.items);
    const orderCreationStatus = useAppSelector((state: RootState) => state.offlineOrders.createStatus);
    const steps = ['Produse selectate', 'Date comanda'];
    const [activeStep, setActiveStep] = useState(0);
    const [filters, setFilters] = useState<Filter>({
        colorId: null,
        sizeId: null,
        productClassId: null,
        query: '',
        cityId: null,
        sort: null,
        onSale: null
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
    const [selectedProductDetails, setSelectedProductDetails] = useState<OfflineOrderDetailWithoutId[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    const [offlineOrderData, setOfflineOrderData] = useState<CreateOfflineOrderData>({
        customerId: null,
        customerName: '',
        customerContact: '',
        city: '',
        address: '',
        comments: '',
        deliveryTypeId: 0, 
        paymentTypeId: 0,
        finalCost: 0,  
        items: [],
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalWithDelivery, setTotalWithDelivery] = useState(0);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedEmail, setSelectedEmail] = useState<UserEmailInfo | null>(null);

    useEffect(() => {
        dispatch(GetProductForOfflineOrder({ filters }));
    }, [dispatch, filters]);

    useEffect(() => {
        dispatch(fetchColors());
        dispatch(fetchSizes());
        dispatch(fetchPaymentTypes());
        dispatch(fetchDeliveryTypes());
        dispatch(fetchProductClasses());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUsersEmails({ search: searchQuery }));
    }, [dispatch, searchQuery]);

    useEffect(() => {
        setOfflineOrderData(prevData => ({
            ...prevData,
            items: selectedProductDetails
        }));
    }, [selectedProductDetails]);

    useEffect(() => {
        const total = selectedProductDetails.reduce((acc, detail) => {
          const product = selectedProducts.find(p => p.id === detail.productId);
          if (!product) return acc;
      
          let price = product.price;
          if (product.hasActiveDiscount && product.discount && product.discount.percentage) {
            const discount = price * (product.discount.percentage / 100);
            price -= discount;
          }
      
          return acc + price * detail.quantity;
        }, 0);
      
        setTotalAmount(total);
      
        const deliveryPrice = deliveryTypes.find(type => type.id === offlineOrderData.deliveryTypeId)?.price || 0;
        setTotalWithDelivery(total + deliveryPrice);
      
        const totalQty = selectedProductDetails.reduce((acc, detail) => acc + detail.quantity, 0);
        setTotalQuantity(totalQty);
    }, [selectedProductDetails, products, offlineOrderData.deliveryTypeId, deliveryTypes]);

    useEffect(() => {
        if (selectedUser) {
            setOfflineOrderData(prevData => ({
                ...prevData,
                customerId: selectedUser.id,
                customerName: selectedUser.FullName,
                customerContact: selectedUser.Telefon,
            }));

        }
    }, [selectedUser]);

    const handleEmailSearch = () => {
        dispatch(fetchUsersEmails({ search: searchQuery }));
    };

    const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            dispatch(GetProductForOfflineOrder({ filters }));
        }
    };

    const handleSearchClick = () => {
        dispatch(GetProductForOfflineOrder({ filters }));
    };
      
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };
    
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleOpenInfoDialog = (productId: number | undefined) => {
        if (productId !== undefined) {
          setSelectedProductId(productId);
          setIsInfoDialogOpen(true);
        } else {
          console.error('ProductId is undefined');
        }
    };
    
    const handleCloseInfoDialog = () => {
        setIsInfoDialogOpen(false);
    };

    const updateFilter = (field: keyof Filter, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleResetSelections = () => {
        setFilters({
            colorId: null,
            sizeId: null,
            productClassId: null,
            query: '',
            cityId: null,
            sort: null,
            onSale: null
        });
    };

    const handleToggleProductDetail = (productId: number) => {
        const isProductSelected = selectedProductDetails.some(detail => detail.productId === productId);
        if (!isProductSelected) {
            const newDetail = {
                localId: generateLocalId(),
                productId: productId,
                colorId: null,
                sizeId: null,
                cityId: null,
                quantity: 1,
            };
            setSelectedProductDetails([...selectedProductDetails, newDetail]);
            const product = products.find(p => p.id === productId);
            if (product) {
                setSelectedProducts([...selectedProducts, product]);
            }
        } else {
            setSelectedProductDetails(selectedProductDetails.filter(detail => detail.productId !== productId));
            setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
        }
    };

    const handleDetailChange = (localId: string, field: 'colorId' | 'sizeId' | 'cityId' | 'quantity', value: number | null) => {
        setSelectedProductDetails(currentDetails =>
            currentDetails.map(detail => {
                if (detail.localId === localId) {
                    const updatedDetail = { ...detail, [field]: value };
                    if (field === 'colorId') {
                        updatedDetail.sizeId = null;
                        updatedDetail.cityId = null;
                        updatedDetail.quantity = 1;
                    } else if (field === 'sizeId') {
                        updatedDetail.cityId = null;
                        updatedDetail.quantity = 1;
                    } else if (field === 'cityId') {
                        updatedDetail.quantity = 1;
                    }
                    return updatedDetail;
                }
                return detail;
            })
        );
    };

    const handleAddNewCombination = (productId: number) => {
        const newCombination = {
            localId: generateLocalId(), 
            productId: productId,
            colorId: null,
            sizeId: null,
            cityId: null,
            quantity: 1,
        };
        setSelectedProductDetails([...selectedProductDetails, newCombination]);
    };

    const handleRemoveCombination = (localId: string) => {
        setSelectedProductDetails(currentDetails =>
            currentDetails.filter(detail => detail.localId !== localId)
        );
    };

    const generateLocalId = () => {
        return `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
    };

    const handleInputChange = (field: keyof CreateOfflineOrderData, value: any) => {
        setOfflineOrderData(prevData => ({
            ...prevData,
            [field]: value,
        }));
    };

    const allDetailsComplete = selectedProductDetails.length > 0 && selectedProductDetails.every(detail =>
        detail.productId > 0 && 
        detail.colorId != null && detail.sizeId != null && detail.cityId != null && 
        detail.quantity > 0
    );

    const isOfflineOrderDataComplete = () => {
        return offlineOrderData.customerName.trim() !== '' &&
               offlineOrderData.customerContact.trim() !== '' &&
               offlineOrderData.city.trim() !== '' &&
               offlineOrderData.address.trim() !== '' &&
               offlineOrderData.deliveryTypeId !== 0 &&
               offlineOrderData.paymentTypeId !== 0 &&
               offlineOrderData.finalCost !== 0;  
    };

    const isAllDataComplete = () => {
        return allDetailsComplete && isOfflineOrderDataComplete();
    };

    const ColorlibStepIcon = (props: StepIconProps) => {
        const { icon } = props;
    
        const errorCondition = selectedProductDetails.some(detail =>
            !detail.productId || detail.colorId == null || detail.sizeId == null || detail.cityId == null || detail.quantity <= 0
        );
    
        const icons: { [index: string]: React.ReactElement } = {
            1: allDetailsComplete ? <CheckCircleOutlineIcon color="primary" style={{ fontSize: 30 }} /> : errorCondition ? <ErrorIcon color="error" style={{ fontSize: 30 }} /> : <RadioButtonUncheckedIcon style={{ fontSize: 30 }} />,
            2: isOfflineOrderDataComplete() ? <CheckCircleOutlineIcon color="primary" style={{ fontSize: 30 }} /> : <RadioButtonUncheckedIcon style={{ fontSize: 30 }} />
        };
        
        return (
            <div>
                {icons[String(icon)]}
            </div>
        );
    };

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };
    
    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmOrder = () => {
        const itemsToSubmit = selectedProductDetails.map(item => ({
            ...item
        }));

        const orderDataToSubmit = {
            ...offlineOrderData,
            items: itemsToSubmit
        };

        dispatch(createOfflineOrder(orderDataToSubmit))
            .unwrap()
            .then(() => {
                setOpenSuccessDialog(true);
                setOpenConfirmDialog(false);
            })
            .catch(() => {
                setOpenErrorDialog(true);
            });
    };
    
    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleSuccessClose = () => {
        setOpenSuccessDialog(false);
        setOfflineOrderData({
            customerName: '',
            customerContact: '',
            city: '',
            address: '',
            comments: '',
            deliveryTypeId: 0,
            paymentTypeId: 0,
            finalCost: 0,
            items: []
        });
        setSelectedProductDetails([]);
        setSelectedProducts([]);
        dispatch(clearSelectedUser())
        setSelectedEmail(null)
        setSearchQuery('')
    };

    const handleEmailChange = (event: any, newValue: UserEmailInfo | null) => {
        setSelectedEmail(newValue);
        setSearchQuery(newValue ? newValue.Email : '');
        if (newValue) {
            dispatch(fetchUserDetails(newValue.id));
        }
    };

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        p: '1%', 
    }}>

        {orderCreationStatus === 'loading' && (
            <Box
                sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 2000,
                }}
            >
                <CircularProgress />
            </Box>
        )}

        {/* Обертка для заголовка и Stepper */}
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 2, 
        }}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant="h2">
                    Comanda Offline
                </Typography>
            </Box>
            
            <Box sx={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep} sx={{ '.MuiStepIcon-root': { fontSize: '2rem' }, '.MuiStepLabel-label': { fontSize: '1.25rem', fontWeight: 'bold' }, minWidth: '80%' }}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            gap: 2, 
            p: 1,
            overflow: 'hidden',
        }}>
            <Paper elevation={3} sx={{ 
                flex: 1, 
                p: 2, 
                height: '100%',
                display: 'flex', 
                justifyContent: 'center', 
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px', 
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent', 
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', 
                    borderRadius: '2px', 
                },
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.6) transparent',
            }}>
                <SelectProduct 
                    openDialog={handleOpenDialog}
                    openInfoDialog={handleOpenInfoDialog}
                    selectedProducts={selectedProducts}
                    selectedProductDetails={selectedProductDetails}
                />
            </Paper>
            <Paper elevation={3} sx={{ 
                flex: 1, 
                p: 2, 
                display: 'flex', 
                justifyContent: 'center', 
                overflow: 'auto', 
            }}>
                <OfflineOrderData
                    offlineOrderData={offlineOrderData}
                    payments={paymentTypes}
                    delivery={deliveryTypes}
                    totalAmount={totalAmount}
                    totalQuantity={totalQuantity}
                    totalWithDelivery={totalWithDelivery}
                    usersEmail={usersEmail}
                    selectedEmail={selectedEmail}
                    handleEmailSearch={handleEmailSearch}
                    handleInputChange={handleInputChange}
                    isAllDataComplete={isAllDataComplete()}
                    handleConfirmOrder={handleOpenConfirmDialog}
                    setSearchQuery={setSearchQuery}
                    setSelectedEmail={handleEmailChange}
                />
            </Paper>
        </Box>

        <SelectProductDialog
            isOpen={isDialogOpen}
            onClose={handleCloseDialog}
            onResetSelections={handleResetSelections}
            filters={filters}
            updateFilter={updateFilter}
            products={products}
            productClasses={productClasses}
            colors={colors}
            sizes={sizes}
            selectedProductDetails={selectedProductDetails}
            onToggleProductDetail={handleToggleProductDetail}
            handleDetailChange={handleDetailChange}
            handleAddNewCombination={handleAddNewCombination}
            handleRemoveCombination={handleRemoveCombination}
            handleSearchKeyDown={handleSearchKeyDown}
            handleSearchClick={handleSearchClick}
        />

        <SelectedProductInfoDialog
            isOpen={isInfoDialogOpen}
            onClose={handleCloseInfoDialog}
            productId={selectedProductId}
            selectedProducts={selectedProducts}
            colors={colors}
            sizes={sizes}
            selectedProductDetails={selectedProductDetails}
        />

        <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">
                <Typography variant='h3'>
                    Confirmare Comandă
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    <Typography variant='h4'>
                        Doriți să adăugați comanda dată?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCloseConfirmDialog}
                    sx={{ backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }} 
                    startIcon={<CloseIcon />}
                    variant="contained"
                    fullWidth
                >
                    Anulare
                </Button>
                <Button
                    onClick={handleConfirmOrder}
                    color="success"
                    sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: '#2e7d32' } }} 
                    startIcon={<CheckIcon />}
                    variant="contained"
                    fullWidth
                >
                    Confirmă
                </Button>
            </DialogActions>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={openSuccessDialog} onClose={handleSuccessClose}>
            <DialogTitle>{"Comanda a fost creată cu succes!"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Comanda dvs. a fost creată cu succes. Vă mulțumim pentru cumpărături!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSuccessClose}>OK</Button>
            </DialogActions>
        </Dialog>

        {/* Error Dialog */}
        <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle>{"Eroare la crearea comenzii"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Din păcate, a apărut o eroare la crearea comenzii dvs. Vă rugăm să încercați din nou.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseErrorDialog}>OK</Button>
            </DialogActions>
        </Dialog>

    </Box>
  );
};

export default CreateOfflineOrder;
