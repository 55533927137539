import { Box, Divider } from "@mui/material"
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./Home"
import ShoppingCart from "./ShoppingCart";
import MyAccount from "./MyAccount";
import DetaliiComanda from "./DetaliiComanda";
import Orders from "./Orders";
import OrderDetails from "./OrderDetails";
import { selectIsAuthenticated, selectIsEmailVerified } from "../../redux/slices/auth";
import { useSelector } from "react-redux";
import { ReactNode } from "react";
import TermeniConditii from "./TermeniConditii";
import ScrollToTop from "../../utils/ScrolllToTop";
import Auth from "./Auth";
import VerifcareEmail from "./VerifcareEmail";
import ProductPage from "./ProductPage";
import NavBar from "../../components/Client/NavigationBar/NavBar";
import ProductList from "./ProductList";
import FavoriteList from "./FavoriteList";
import Footer from "../../components/Client/footer/Footer";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isEmailVerified = useSelector(selectIsEmailVerified);

    if (!isAuthenticated) {
      // Если пользователь не аутентифицирован, перенаправляем на страницу входа
      return <Navigate to="/Authentication/Login" />;
    } else if (!isEmailVerified) {
      // Если электронная почта не подтверждена, перенаправляем на страницу подтверждения электронной почты
      return <Navigate to="/EmailVerification" />;
    }
    
  return <>{children}</>;
};

const Main = () => {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" width={"100%"} >
        <NavBar/>
        <ScrollToTop />
          <Box flexGrow={1}>  
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="Authentication/:authType?" element={<Auth />} />
                <Route path="Products" element={<ProductList />} />
                <Route path="FavoriteList" element={<FavoriteList />} />
                <Route path="ShoppingCart" element={<ShoppingCart />} />
                <Route path="EmailVerification" element={<VerifcareEmail />} />
                <Route path="Termeni&Conditii" element={<TermeniConditii />} />
                <Route path="ShoppingCart/DetaliiComanda" element={<ProtectedRoute><DetaliiComanda/></ProtectedRoute>} />
                <Route path="MyAccount/:section?" element={<ProtectedRoute><MyAccount /></ProtectedRoute>} />
                <Route path="ProductPage/:id?" element={<ProductPage />} />
                <Route path="Orders" element={<ProtectedRoute><Orders/></ProtectedRoute>} />
                <Route path="OrderDetails/:orderId" element={<ProtectedRoute><OrderDetails/></ProtectedRoute>} />
              </Routes>
          </Box>
          <Divider/>
      <Footer/>
    </Box>
  )
}

export default Main