import React, { useState } from 'react';
import { Box, TextField, Button, IconButton, InputAdornment, FormHelperText, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff, Refresh } from '@mui/icons-material';

interface LoginProps {
  signIn: (email: string, password: string) => void;
}

const Login = ({ signIn }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  const handleEmailBlur = () => {
    setEmailTouched(true);
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const isFormFilled = email !== '' && password !== '' && validateEmail(email);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormFilled) {
      signIn(email, password);
    }
  };

  const handlePasswordRecovery = () => {
    console.log("Password recovery process initiated");
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1, 
        width: '95%',
        margin: '0 auto',
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        label="Email"
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={handleEmailBlur}
        error={emailTouched && !validateEmail(email)}
        fullWidth
      />
      <FormHelperText
        sx={{ height: '18px', margin: '0', fontSize: '0.75rem' }} 
        error={emailTouched && !validateEmail(email)}
      >
        {emailTouched && email === '' ? "E-mailul este obligatoriu" : !validateEmail(email) && email !== '' ? "E-mailul este incorect" : ''}
      </FormHelperText>

      <TextField
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onBlur={handlePasswordBlur}
        error={passwordTouched && password.trim() === ''}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText
        sx={{ height: '18px', margin: '0', fontSize: '0.75rem' }} 
        error={passwordTouched && password.trim() === ''}
      >
        {passwordTouched && password.trim() === '' ? "Parola este obligatorie" : ''}
      </FormHelperText>


      <Tooltip 
        title={!isFormFilled ? "Introduceți datele necesare pentru autentificare" : ""}
        disableHoverListener={isFormFilled}
        arrow
      >
        <span style={{ width: '100%' }}> 
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormFilled}
            sx={{
              mt: 1,
              width: '100%',
              fontSize: '0.9rem',
              backgroundColor: '#000',
              color: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.8)',
              },
            }}
          >
            Sign In
          </Button>
        </span>
      </Tooltip>

      <Box sx={{
        mt: 0.5
      }}>
        <Button 
          onClick={handlePasswordRecovery} 
          startIcon={<Refresh />} 
          > 
          Recuperare parola
        </Button>
      </Box>

    </Box>
  );
};

export default Login;
