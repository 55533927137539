import React from 'react';
import { Paper, List, ListItem, ListItemText, CircularProgress, Box, Typography } from '@mui/material';

interface UserEmailInfo {
  id: number;
  Email: string;
}

interface UserListProps {
  emailItems: UserEmailInfo[];
  loading: boolean;
  onSelectUser: (id: number) => void;
  selectedUserId: number | null;
}

const UserList: React.FC<UserListProps> = ({ emailItems, loading, onSelectUser, selectedUserId }) => {
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3} 
    sx={{ 
        maxHeight: '100%', 
        overflow: 'auto',
        '&::-webkit-scrollbar': {
        width: '4px',
        },
        '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
        background: 'gray',
        borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
        background: '#b3b3b3',
        }
    }}
    >
        <List>
            {emailItems.map((user) => (
            <ListItem 
                key={user.id} 
                button 
                onClick={() => onSelectUser(user.id)}
                sx={{
                backgroundColor: selectedUserId === user.id ? 'lightgreen' : 'transparent',
                '&:hover': {
                    backgroundColor: selectedUserId === user.id ? 'lightgreen' : 'lightgray',
                },
                }}
            >
                <ListItemText 
                  primary={<Typography variant='h4'>{`ID: ${user.id} --- ${user.Email}`}</Typography>} 
                />
            </ListItem>
            ))}
        </List>
    </Paper>
  );
};

export default UserList;
