import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Post } from './posts';
import { API } from '../service';

export interface User {
  id: number;
  FullName: string;
}

export interface Rating {
  Id: number;
  value: number;
  User: User;
  UserId?: number;
  comment?: string;
  createdAt?: string;
  updatedAt?: string;
  imageUrl: string[]; 
  Post?: Post;
  isHidden?: Boolean;
}

export interface CreateRatingPayload {
  postId: number;
  value: number;
  comment?: string;
  imageUrl?: string[]; 
}

interface RatingRequestParams {
  page: number;
  perPage: number;
}

interface RatingsState {
  items: Rating[];
  status: 'loading' | 'loaded' | 'error';
  count: number;
}

const initialRatingsState: RatingsState = {
  items: [],
  status: 'loading',
  count: 0,
};

export const fetchRatings = createAsyncThunk<Rating[], number, { rejectValue: string }>(
  'ratings/fetchRatings',
  async (postId, { rejectWithValue }) => {
    const [error, response] = await API<Rating[]>('GET', `/rating/get/${postId}`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch ratings');
    }

    return response.data;
  }
);

export const createRating = createAsyncThunk<Rating, CreateRatingPayload, { rejectValue: string }>(
  'ratings/createRating',
  async ({ postId, value, comment, imageUrl }, { rejectWithValue }) => {
    const [error, response] = await API<Rating>('POST', '/rating/create', { postId, value, comment, imageUrl });

    if (error || !response) {
      return rejectWithValue('Failed to create rating');
    }

    return response.data;
  }
);

export const getProductAndRating = createAsyncThunk<{ count: number; ratingsWithProductAndUser: Rating[] }, RatingRequestParams, { rejectValue: string }>(
  'ratings/getProductAndRating',
  async ({ page, perPage }, { rejectWithValue }) => {
    const [error, response] = await API<{ count: number; ratingsWithProductAndUser: Rating[] }>('GET', '/rating/getall', null, {
      page,
      perPage,
    });

    if (error || !response) {
      return rejectWithValue('Failed to fetch product and ratings');
    }

    return response.data;
  }
);

export const updateRatingVisibility = createAsyncThunk<Rating, { ratingId: number; isHidden: boolean }, { rejectValue: string }>(
  'ratings/updateRatingVisibility',
  async ({ ratingId, isHidden }, { rejectWithValue }) => {
    const [error, response] = await API<Rating>('PATCH', `/rating/hidden/${ratingId}`, { isHidden });

    if (error || !response) {
      return rejectWithValue('Failed to update rating visibility');
    }

    return response.data;
  }
);

const ratingsSlice = createSlice({
  name: 'ratings',
  initialState: initialRatingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatings.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(fetchRatings.fulfilled, (state, action: PayloadAction<Rating[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchRatings.rejected, (state) => {
        state.items = [];
        state.status = 'error';
      })
      .addCase(createRating.fulfilled, (state, action: PayloadAction<Rating>) => {
        state.items.push(action.payload);
      })
      .addCase(getProductAndRating.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(getProductAndRating.fulfilled, (state, action: PayloadAction<{ count: number, ratingsWithProductAndUser: Rating[] }>) => {
        state.items = action.payload.ratingsWithProductAndUser;
        state.status = 'loaded';
        state.count = action.payload.count;
      })
      .addCase(getProductAndRating.rejected, (state) => {
        state.items = [];
        state.status = 'error';
      })
      .addCase(updateRatingVisibility.fulfilled, (state, action: PayloadAction<Rating>) => {
        const index = state.items.findIndex(rating => rating.Id === action.payload.Id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      });
  },
});

export const selectRatings = (state: { ratings: RatingsState }) => state.ratings.items;
export const selectRatingsStatus = (state: { ratings: RatingsState }) => state.ratings.status;

export const ratingsReducer = ratingsSlice.reducer;
