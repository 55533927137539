import React from 'react';
import { Button, Box, useTheme, useMediaQuery } from '@mui/material';
import { ProductClass } from '../../redux/slices/class'; 

interface CategoryProps {
  categories: ProductClass[];
  selectedCategory?: number | null;
  onSelect: (category: ProductClass | null) => void;
}

const getButtonStyles = (isActive: boolean) => ({
  '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      backgroundColor: 'black',
      position: 'absolute',
      bottom: '0',
      left: '10%',
      right: '10%',
      transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
      transition: 'transform 0.3s ease-in-out',
  }
});

const CategoryFilter: React.FC<CategoryProps> = ({ categories, onSelect, selectedCategory }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px" justifyContent="center">
      <Button 
        sx={{ 
            color: 'black', 
            border: 0, 
            transform: 'scale(1.4)', 
            position: 'relative', 
            ...getButtonStyles(!selectedCategory),
            width: isMobile ? '48%' : 'auto',
            '&:hover': {
                border: 0, 
            }
        }} 
        onClick={() => onSelect(null)}
      >
      Toate
      </Button>

{categories.map((category) => (
    <Button 
        key={category.id} 
        variant="outlined" 
        sx={{
            color: 'black', 
            border: 0, 
            transform: 'scale(1.4)', 
            position: 'relative', 
            ...getButtonStyles(selectedCategory === category.id),
            width: isMobile ? '48%' : 'auto',
            '&:hover': {
                border: 0, 
            }
        }} 
        onClick={() => onSelect(category)}
    >
        {category.name}
    </Button>
))}
    </Box>
  );
}

export default CategoryFilter;
