import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { API } from '../service';
import { AxiosError } from 'axios';
import { Role } from './roles';

export interface User {
  id: number;
  FullName: string;
  Email: string;
  Telefon: string;
  avatarUrl: string;
  createdAt: string;
  isVerified: boolean;
  updatedAt: string;
  verificationToken?: string;
  isSubscribedToNewsletter?: boolean;
  totalOrdersCount?: number; 
  newOrdersCount?: number;   
}

interface Params {
  Email: string;
  password: string;
}

type RegistrationParams = {
  Email: string;
  password: string;
  FullName: string;
  Telefon?: string;
  isSubscribedToNewsletter?: boolean;
};

export interface AuthResponse {
  Email: string;
  token: string;
  isVerified?: string | boolean;
  isSubscribedToNewsletter?: boolean;
  user?: User;
  totalOrdersCount?: number;
  newOrdersCount?: number;
  hasRole?: boolean;
  role?: Role; 
}

interface VerificationResponse {
  isVerified: boolean;
}

interface UpdateUserParams {
  FullName?: string;
  Telefon?: string;
}

interface UpdateUserResponse {
  user: User;
}

interface AuthState {
  data: AuthResponse | null;
  status: 'loading' | 'loaded' | 'error';
  isEmailVerified?: boolean;
  isAuthenticated?: boolean;
  totalOrdersCount?: number; 
  newOrdersCount?: number; 
  passwordChangeMessage: string | null;
}

const initialState: AuthState = {
  data: null,
  status: 'loading',
  isEmailVerified: false,
  isAuthenticated: false,
  totalOrdersCount: undefined,
  newOrdersCount: undefined,
  passwordChangeMessage: null,
};

interface ErrorResponse {
  message: string;
}

export const fetchAuth = createAsyncThunk<AuthResponse, Params>(
  'auth/fetchAuth',
  async (params: Params, { rejectWithValue }) => {
    const [error, response] = await API<AuthResponse>('POST', '/auth/login', params);

    if (error && (error as AxiosError).response) {
        return rejectWithValue((error as AxiosError).response?.data);
    }

    return response?.data;
  }
);

export const fetchRegister = createAsyncThunk<AuthResponse, RegistrationParams>(
  'auth/fetchRegister',
  async (params: RegistrationParams, { rejectWithValue }) => {
    const [error, response] = await API<AuthResponse>('POST', '/auth/register', params);

    if (error) {
      // Обработка ошибки, если ошибка связана с ответом сервера
      if ((error as AxiosError).response) {
        return rejectWithValue((error as AxiosError).response?.data);
      }
      // Для других типов ошибок можно вернуть или обработать их здесь
      return rejectWithValue('An unexpected error occurred');
    }

    return response?.data;
  }
);

export const fetchAuthMe = createAsyncThunk(
  'auth/fetchAuthMe',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API('GET', '/auth/me');

    if (error || !response) {
      return rejectWithValue(error?.message || 'An unexpected error occurred');
    }

    // Предполагаем, что API возвращает данные пользователя в response.data
    const data = response.data;

    // Применяем преобразование к полям createdAt и updatedAt
    if (data.user) {
      data.user.hasRole = Array.isArray(data.user.roles) && data.user.roles.length > 0;
    }

    if (data.token) {
      localStorage.setItem('token', data.token);
    }

    return data;
  }
);

export const resendVerificationEmail = createAsyncThunk(
  'auth/resendVerificationEmail',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API('POST', '/user/resend-verification');

    if (error && (error as AxiosError).response) {
      return rejectWithValue((error as AxiosError).response?.data);
    }

    return response?.data;
  }
);
  
export const verifyUserEmail = createAsyncThunk<VerificationResponse, string>(
  'auth/verifyEmail',
  async (token: string, { rejectWithValue }) => {
    const [error, response] = await API<{ isVerified: boolean }>('GET', `/auth/verify-email?token=${token}`);

    if (error || !response) {
      return rejectWithValue('Failed to verify email');
    }

    // Ensure the response data is structured as required
    return { isVerified: response.data.isVerified };
  }
);

export const updateUser = createAsyncThunk<UpdateUserResponse, UpdateUserParams, { state: RootState }>(
  'auth/updateUser',
  async (updateData, { getState, rejectWithValue }) => {
    const [error, response] = await API<UpdateUserResponse>('POST', '/user/update', updateData);
    if (error || !response) {
      return rejectWithValue('Failed to update user');
    }
    return response.data;
  }
);

export const changeUserPassword = createAsyncThunk<string, { oldPassword: string; newPassword: string }>(
  'auth/changeUserPassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    const [error, response] = await API<{ message: string }>('POST', `/user/update/password`, {
      oldPassword,
      newPassword,
    });
    if (error || !response) {
      return rejectWithValue('Failed to change password');
    }
    return response.data.message;
  }
);


  
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
      state.isEmailVerified = false;
      state.isAuthenticated = false;
      state.totalOrdersCount = undefined;
      state.newOrdersCount = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        state.status = 'loading';
        state.data = null;
      })
      .addCase(fetchAuth.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.status = 'loaded';
        state.data = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.status = 'error';
        state.data = null;
        state.isAuthenticated = false;
      })
      .addCase(fetchAuthMe.pending, (state) => {
        state.status = 'loading';
        state.data = null;
      })
      .addCase(fetchAuthMe.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.status = 'loaded';
        state.data = action.payload;
        state.isAuthenticated = true;
        state.totalOrdersCount = action.payload.totalOrdersCount;
        state.newOrdersCount = action.payload.newOrdersCount; 
        if (typeof action.payload.isVerified === "string") {
          state.isEmailVerified = action.payload.isVerified === "1";
        } else if (typeof action.payload.isVerified === "boolean") {
          state.isEmailVerified = action.payload.isVerified;
        }
      
        if (action.payload.isSubscribedToNewsletter !== undefined) {
          state.data.isSubscribedToNewsletter = action.payload.isSubscribedToNewsletter;
        }
      })      
      .addCase(fetchAuthMe.rejected, (state) => {
        state.status = 'error';
        state.data = null;
        state.isAuthenticated = false;
      })
      .addCase(fetchRegister.pending, (state) => {
        state.status = 'loading';
        state.data = null;
      })
      .addCase(fetchRegister.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.status = 'loaded';
        state.data = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(fetchRegister.rejected, (state) => {
        state.status = 'error';
        state.data = null;
      })
      .addCase(verifyUserEmail.pending, (state) => {
        state.status = 'loading';
      })
     .addCase(verifyUserEmail.fulfilled, (state, action: PayloadAction<VerificationResponse>) => {
      if (action.payload.isVerified) {
        state.isEmailVerified = true;
        state.status = 'loaded';
      }
      })
      .addCase(verifyUserEmail.rejected, (state) => {
        state.status = 'error';
        state.isEmailVerified = false;
      })
      .addCase(logout, (state) => {
        state.data = null;
        state.isEmailVerified = false;
        state.isAuthenticated = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<UpdateUserResponse>) => {
        if (state.data && state.data.user) {
          state.data.user = { ...state.data.user, ...action.payload.user };
        }
        state.status = 'loaded';
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeUserPassword.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'loaded';
        state.passwordChangeMessage = action.payload; 
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.status = 'error';
      
        // Проверяем и приводим тип action.payload к ErrorResponse
        const errorResponse = action.payload as ErrorResponse | undefined;
        const errorMessage = errorResponse?.message || "A aparut o eroare";
        
        state.passwordChangeMessage = errorMessage;
      });      
  },
});

export const selectIsEmailVerified = (state: { auth: AuthState }) => state.auth.isEmailVerified;
export const selectIsAuth = (state: { auth: AuthState }) => Boolean(state.auth.data);
export const selectHasRole = (state: { auth: AuthState }) => state.auth.data?.hasRole;
export const selectRole = (state: { auth: AuthState }) => state.auth.data?.role;
export const selectIsLoading = (state: { auth: AuthState }) => state.auth.status === 'loading';
export const selectUserId = (state: { auth: AuthState }) => state.auth.data?.user?.id;
export const authReducer = authSlice.reducer;
export const selectIsAuthenticated = (state: { auth: AuthState }) => state.auth.isAuthenticated;

export const { logout } = authSlice.actions;
