import React, { useState } from 'react';
import { IconButton, Box, ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ImageCarouselProps {
    imageUrls: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
    imageUrls,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
    const [activeIndex, setActiveIndex] = useState(0);

    const Images = [
        ...imageUrls.map(url => `${baseUrl}${url}`), 
    ];    

    const handlePrevImage = () => {
        setActiveIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1);
    };

    const handleNextImage = () => {
        setActiveIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
    };

  return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',  
            width: '100%',
            overflow: 'hidden'
        }}>
            <Box sx={{
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: isSmallScreen ? 'column-reverse' : 'row',
                justifyContent: 'center', 
                alignItems: 'center',
                width: '100%', 
                height: '100%',
                overflow: 'hidden', 
                position: 'relative',
                gap: 2,
                p: '2%'
            }}>
                {/* Photo List*/}
                <Box sx={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'row' : 'column',
                    justifyContent: 'center',
                    height: '100%',
                    width: isSmallScreen ? '100%' : 110,
                }}>
                    <ImageList sx={{
                        width: '100%', 
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center', 
                        flexWrap: 'wrap', 
                        '&::-webkit-scrollbar': {
                            width: '8px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.4)', 
                            borderRadius: '10px' 
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: 'rgba(0,0,0,0.6)'
                        }
                    }} 
                        cols={isSmallScreen ? 3 : 1} 
                        rowHeight={140} 
                        gap={8}
                    >
                        {Images.map((src, index) => (
                            <ImageListItem key={index} sx={{ width: isSmallScreen ? '30%' : '100%' }}>
                                <img
                                    src={src}
                                    alt={`Image ${index + 1}`}
                                    style={{
                                        width: '100%', 
                                        height: '100%',
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onLoad={() => src.startsWith('blob:') && URL.revokeObjectURL(src)}
                                    onClick={() => setActiveIndex(index)}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>

                {/* Photo */}
                <Box sx={{
                    height: '100%',
                    position: 'relative', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                }}>

                    <img
                        src={Images[activeIndex]}
                        alt={`Selected ${activeIndex + 1}`}
                        style={{
                            maxWidth: isSmallScreen ? '300px' : '450px',
                            maxHeight: '100%',
                            objectFit: 'contain', 
                            width: 'auto', 
                            height: 'auto' 
                        }}
                        onLoad={() => Images[activeIndex].startsWith('blob:') && URL.revokeObjectURL(Images[activeIndex])}
                    />

                    {/* Иконка "Влево" */}
                    <IconButton
                        onClick={handlePrevImage}
                        sx={{ 
                            position: 'absolute',
                            left: 0, 
                            top: '50%', 
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowBackIosIcon sx={{ color: 'primary.main',fontSize: '2rem' }} />
                    </IconButton>

                    {/* Иконка "Вправо" */}
                    <IconButton
                        onClick={handleNextImage}
                        sx={{ 
                            position: 'absolute',
                            right: 0, 
                            top: '50%',
                            transform: 'translateY(-50%)' 
                        }}
                    >
                        <ArrowForwardIosIcon sx={{ color: 'primary.main',fontSize: '2rem' }} />
                    </IconButton>

                </Box>
            </Box>

        </Box>
  )
}

export default ImageCarousel