import React, { useEffect } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { Discount, fetchDiscountByUserId } from '../../../redux/slices/discount';
import { useAppDispatch, useAppSelector, RootState } from '../../../redux/store';
import Foto1 from '../../../image/Discount/discount.png';

const Discounts = () => {
  const dispatch = useAppDispatch();
  const discounts = useAppSelector((state: RootState) => state.discount.items);

  useEffect(() => {
    dispatch(fetchDiscountByUserId());
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        p: '2%',
      }}
    >
      <Typography variant="h2" gutterBottom>
        Cupoane de reducere
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {discounts?.map((discount: Discount) => (
          <Tooltip
            key={discount.discountId}
            title={`Reducere: ${discount.discount_percentage}%`}
            arrow
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                m: 2,
                p: 2,
                borderRadius: '8px',
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.01)',
                },
                backgroundColor: discount.is_used
                  ? 'rgba(255, 0, 0, 0.1)'
                  : 'rgba(0, 128, 0, 0.1)',
              }}
            >
              <img
                src={Foto1}
                alt="Discount Image"
                style={{ width: '150px', height: 'auto', marginBottom: '8px' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3">Reducere: {discount.discount_percentage}%</Typography>
                <Typography variant="h3">Folosit: {discount.is_used ? 'Da' : 'Nu'}</Typography>
              </Box>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

export default Discounts;
