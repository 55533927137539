import { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material"
import { DataGrid, GridColDef, GridRowId, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Filter } from '../../../redux/slices/orderoffline';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteOfflineOrder, fetchPaginationOfflineOrders } from '../../../redux/slices/orderoffline';

interface OrderOfflineListAdminProps {
    filters: Filter;
}

const OrderOfflineListAdmin: React.FC<OrderOfflineListAdminProps> = ({ filters }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const offlineOrders = useSelector((state: RootState) => state.offlineOrders.offorders.items);
    const orderOfflineStatus = useAppSelector((state: RootState) => state.offlineOrders.offorders.status);
    const Count = useAppSelector((state: RootState) => state.offlineOrders.offorders.count);
    const isDataGridLoading = orderOfflineStatus === 'loading';
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
    }); 
    const [checkState, setCheckState] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
    const isMobile = useMediaQuery('(max-width: 1000px)');

    
    useEffect(() => {
        const params = {
            pagination: {
                page: paginationModel.page + 1,
                perPage: paginationModel.pageSize,
            },
            filters
        };
        
        dispatch(fetchPaginationOfflineOrders(params));
    }, [dispatch, paginationModel, filters]);

    const columns: GridColDef[] = [
        {
            field: 'Comanda',
            headerName: 'Comanda Nr.',
            flex: 1,
        },
        {
            field: 'User',
            headerName: 'Utilizator',
            flex: 1,
        },
        {
            field: 'Date',
            headerName: 'Data',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                if (typeof params.row.Date === 'string') {
                    const rawDateTime = params.row.Date;
                    const [date, time] = rawDateTime.split(' ');
    
                    const formattedDate = date ? new Date(date).toLocaleDateString('ro-RO') : '-';
                    const formattedTime = time ? time.slice(0, 8) : '-';
    
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start', 
                            }}
                        >
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>{formattedDate}</Typography>
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>{formattedTime}</Typography>
                        </Box>
                    );
                } else {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start', 
                            }}
                        >
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>-</Typography>
                            <Typography variant='h4' sx={{ textAlign: 'left' }}>-</Typography>
                        </Box>
                    );
                }
            },
        },
        {
            field: 'Cost',
            headerName: 'Totalul comenzii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.Cost.toLocaleString('ro-RO')} Lei
                    </Typography>
                );
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                let color;
                switch (params.row.Status) {
                    case 'Nou':
                        color = 'red';
                        break;
                    case 'In verificare':
                        color = 'brown';
                        break;
                    case 'In livrare':
                        color = 'orange';
                        break;
                    case 'Livrat':
                        color = 'green';
                        break;
                    default:
                        color = 'black'; 
                }
    
                return (
                    <Typography variant='h4' style={{ color }}>
                        {params.row.Status}
                    </Typography>
                );
            },
        },
        {
            field: 'CodLivrare',
            headerName: 'Cod Livrare',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.CodLivrare || '-'} 
                    </Typography>
                );
            },
        },
        {
            field: 'Detalii',
            headerName: 'Detalii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
                const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); 
                    handleViewOrderDetails(params.row.id);
                };
        
                const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    handleDelete(params.row.id);
                };
        
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleViewClick}
                        >
                            Vezi Comanda
                        </Button>
                        <IconButton onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                );
            },
        }
    ];

    const rows = offlineOrders.map((offlineOrder, index) => ({
        id: offlineOrder.Id,
        Comanda: offlineOrder.Id, 
        User: offlineOrder.customerName, 
        Date: offlineOrder.createdAt, 
        Cost: offlineOrder.finalCost, 
        Status: offlineOrder.status, 
        CodLivrare: offlineOrder.codLivrare,
    }));

    const MyCustomToolbar = () => {
        return (
          <GridToolbarContainer>
            {isMobile ? (
              <GridToolbarColumnsButton
                style={{ fontSize: '15px' }}
              />
            ) : (
              <>
                <GridToolbarColumnsButton
                  style={{ fontSize: '15px' }}
                />
                <GridToolbarFilterButton
                  style={{ fontSize: '15px' }}
                />
                <GridToolbarDensitySelector
                  style={{ fontSize: '15px' }}
                />
              </>
            )}
          </GridToolbarContainer>
        );
    };

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPage(newPaginationModel.page);
        setPerPage(newPaginationModel.pageSize);
    };

    const handleViewOrderDetails = (orderId: number) => {
        navigate(`/admin/OfflineOrderDetailAdmin/${orderId}`);
    };
    
    const handleDelete = async (orderId: number) => {
        if (window.confirm("Вы действительно хотите удалить этот заказ?")) {
            await dispatch(deleteOfflineOrder(orderId));
        }
    };

    return (
        <Box style={{ height: "100%", width: '100%' }}>
            <DataGrid
                paginationMode="server"
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: perPage,
                            page: page
                        },
                    },
                }}
                pageSizeOptions={[10, 15, 25]}
                rowCount={Count}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                checkboxSelection={isMobile ? false : checkState}
                onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                    setRowSelectionModel(newSelectionModel as string[]);
                }}
                rowSelectionModel={rowSelectionModel}
                loading={isDataGridLoading}
                slots={{
                    toolbar: MyCustomToolbar,
                }}
                sx={{ fontSize: '22px' }}
            />
        </Box>
    );
}

export default OrderOfflineListAdmin