import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, InputAdornment, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changeUserPassword } from '../../../redux/slices/auth';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';

const PasswordEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state: RootState) => state.auth.passwordChangeMessage);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
    old: false,
    new: false,
    confirm: false
  });
  const [error, setError] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const isSaveDisabled = !oldPassword || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword;
  const isCancelDisabled = !oldPassword && !newPassword && !confirmNewPassword;  

  useEffect(() => {
    // Если сообщение обновилось и не пустое, открываем диалог
    if (message) {
      setDialogOpen(true);
    }
  }, [message]);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (field: string) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const handleConfirmNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value);
    if (newPassword && e.target.value !== newPassword) {
      setError('Parola noua si confirmarea nu se potrivesc');
    } else {
      setError('');
    }
  };

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError('Parola noua si confirmarea nu se potrivesc');
      return;
    }
    setError('');

    // Отправляем запрос на изменение пароля
    dispatch(changeUserPassword({ oldPassword, newPassword }));
  };

  const handleCancel = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setError('');
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Закрываем диалоговое окно
    // Очистка полей формы и ошибок при закрытии диалога
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setError('');
  };

  const inputStyle = { fontSize: '1.15rem',};  
  const buttonStyle = { fontSize: '1.15rem' }; 

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h2" sx={{ marginBottom: 2, textAlign: 'center', fontSize: '2rem' }}>
        Editare parola
      </Typography>
      <Box component="form" onSubmit={handleSave} sx={{ width: '100%', maxWidth: 400 }}>
        {/* Поля для паролей с иконкой */}
        <TextField
          label="Parola veche"
          variant="outlined"
          type={showPassword.old ? 'text' : 'password'}
          fullWidth
          required
          margin="normal"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            style: inputStyle,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('old')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.old ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Parola noua"
          variant="outlined"
          type={showPassword.new ? 'text' : 'password'}
          fullWidth
          required
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            style: inputStyle,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('new')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.new ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirma parola noua"
          variant="outlined"
          type={showPassword.confirm ? 'text' : 'password'}
          fullWidth
          required
          margin="normal"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          InputProps={{
            style: inputStyle,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('confirm')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
            type="submit"
            variant="contained" 
            color="primary" 
            style={buttonStyle}
            disabled={isSaveDisabled}
          >
            Salveaza
          </Button>
          <Button 
            variant="outlined" 
            color="secondary" 
            onClick={handleCancel} 
            style={buttonStyle}
            disabled={isCancelDisabled}
          >
            Renunta
          </Button>
        </Box>
      </Box>

       {/* Диалоговое окно */}
       <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{"Status schimbării parolei"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PasswordEdit;
