import React from 'react';
import { Box, Typography, CardMedia, Card, CardContent, CardHeader, IconButton, Rating } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DiscountIcon from '@mui/icons-material/Discount';
import { Product } from '../../../redux/slices/produse';
import { selectIsAuthenticated } from '../../../redux/slices/auth';
import { useAppSelector } from '../../../redux/store';

interface ProductCardProps {
  product: Product;
  handleAddToFavorites: (productId: number) => void;
  handleRemoveFromFavorites: (productId: number) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ 
    product, 
    handleAddToFavorites,
    handleRemoveFromFavorites
}) => {
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
    const imageUrl = product.imageUrl[0] ? `${baseUrl}${product.imageUrl[0]}` : '/path/to/default/image.jpg'; 
    const discountedPrice = product.hasActiveDiscount && product.discount ? (product.price - (product.price * product.discount.percentage / 100)).toFixed(2) : product.price.toFixed(2);

    const handleImageClick = () => {
        navigate(`/ProductPage/${product.id}`);
    };

    const handleFavoriteClick = () => {
        if (product.isFavorite) {
            handleRemoveFromFavorites(product.id);
        } else {
            handleAddToFavorites(product.id);
        }
    };

  return (
    <Card sx={{
        maxWidth: 320,
        transition: '0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          '.MuiCardHeader-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }
        }
      }}>
      <CardHeader
        action={
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: product.hasActiveDiscount ? 'center' : 'flex-end',
                    width: '100%' 
                }}>
                    {product.hasActiveDiscount && (
                        <IconButton aria-label="discount">
                            <DiscountIcon color="error" />
                        </IconButton>
                    )}
                     <IconButton
                        aria-label="add to favorites"
                        onClick={handleFavoriteClick}
                        disabled={!isAuthenticated}
                    >
                        <FavoriteIcon color={product.isFavorite && isAuthenticated ? 'error' : 'disabled'} />
                    </IconButton>
                </Box>
                <Rating
                    name={`product-rating-${product.id}`}
                    value={product.averageRating}
                    precision={0.5}
                    readOnly={true}
                />
            </Box>
        }
        title={product.title}
      />
      <CardMedia
        component="img"
        height="auto"
        image={imageUrl}
        alt={product.title}
        onClick={handleImageClick}
        sx={{ cursor: 'pointer' }}
      />
      <CardContent>
        <Box sx={{ 
            display: 'flex',
            justifyContent: product.hasActiveDiscount ? 'space-between' : 'center',
            alignItems: 'center',
            width: '100%',
         }}>
            {product.hasActiveDiscount && (
                <Typography variant="h5" color="text.primary" sx={{ textDecoration: 'line-through', flexGrow: 1, textAlign: 'left' }}>
                    {product.price.toFixed(2)} Lei
                </Typography>
            )}
            <Typography variant="h5" color={product.hasActiveDiscount ? 'error' : 'text.primary'} sx={{ flexGrow: product.hasActiveDiscount ? 1 : 0, textAlign: 'right' }}>
                {discountedPrice} Lei
            </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
