import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FonPage from '../../../image/Home/section1/fon3.png';

const HomeSection1 = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/Products');
    };

    return (
        <Box sx={{
            width: '100%',
            height: '70vh',
            backgroundImage: `url(${FonPage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            padding: '2rem 0',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'auto', 
                position: 'absolute',
                top: '1rem',
                px: 2, 
                py: 1, 
                backgroundColor: 'rgba(255, 255, 255, 0.4)', 
                borderRadius: '8px',
            }}>
                <Typography variant="h1" sx={{
                    fontWeight: 'bold',
                    color: '#000',
                    fontSize: 'clamp(46px, 5vw, 72px)', 
                    textAlign: 'center',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', 
                }}>
                    Women's Collection
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                position: 'absolute',
                bottom: '2rem',
            }}>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: 'clamp(12px, 2vw, 16px) clamp(24px, 4vw, 32px)', 
                        fontSize: '20px', 
                        borderRadius: '50px',
                        '&:hover': {
                            backgroundColor: '#333',
                        },
                    }}
                >
                    Shop Now &rarr;
                </Button>
            </Box>
        </Box>
    );
}

export default HomeSection1;
