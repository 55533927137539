import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CommentIcon from '@mui/icons-material/Comment';



const AdminNavBarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const section1 = (
    <List>
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </Link>
    </List>
  );

  const section2 = (
    <List>
      <Link to="/admin/Principala" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><DashboardOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Principala" />
        </ListItem>
      </Link>
      <Link to="/admin/Comenzi" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><AddShoppingCartOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Comenzi" />
        </ListItem>
      </Link>
      <Link to="/admin/Produse" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><InventoryOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Produse" />
        </ListItem>
      </Link>
      <Link to="/admin/Users" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Link>
      <Link to="/admin/Societate" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><CommentIcon /></ListItemIcon>
          <ListItemText primary="Societate" />
        </ListItem>
      </Link>
    </List>
    
  );
  
  const section3 = (
    <List>
      <Link to="/admin/Email" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><EmailIcon/></ListItemIcon>
          <ListItemText primary="Email" />
        </ListItem>
      </Link>
      <Link to="/admin/OfflineOrder" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><WifiOffIcon/></ListItemIcon>
          <ListItemText primary="Offline Order" />
        </ListItem>
      </Link>
      <Link to="/admin/CreateProductForm" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem button>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText primary="Adauga Marfa" />
        </ListItem>
      </Link>
    </List>
  );

  return (
    <Box sx={{ position: 'fixed'}}>
      <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" />
      </IconButton>

      <Drawer 
        anchor="left" 
        open={isOpen} 
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            backgroundColor: 'grey',
            display: 'flex',       
            flexDirection: 'column',  
            justifyContent: 'center', 
          }
        }}
      >
        <div>
          {section1}
          <Divider />
          {section2}
          <Divider />
          {section3}
        </div>
      </Drawer>
    </Box>
  );
}

export default AdminNavBarMobile;
