import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector, RootState } from '../../../../redux/store';
import { fetchSizesPagination, removeSize } from '../../../../redux/slices/size';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface Size {
  id: number;
  name: string;
}

interface SizeDataProps {
  onSelectSize: (size: Size) => void;
}

const SizeData: React.FC<SizeDataProps> = ({ onSelectSize }) => {
  const dispatch = useAppDispatch();
  const sizes = useAppSelector((state: RootState) => state.sizes.items); 
  const Count = useAppSelector((state: RootState) => state.sizes.count);
  const sizeStatus = useAppSelector((state: RootState) => state.sizes.status);
  const isDataGridLoading = sizeStatus === 'loading';
  const updateStatus = useAppSelector((state: RootState) => state.sizes.updateStatus);
  const createStatus = useAppSelector((state: RootState) => state.sizes.createStatus);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
      page: page,
      pageSize: perPage,
  });

  useEffect(() => {
      dispatch(fetchSizesPagination({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
  }, [dispatch, paginationModel]);

  useEffect(() => {
    if (createStatus === 'succeeded' || updateStatus === 'succeeded') {
        dispatch(fetchSizesPagination({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
    }
}, [createStatus, updateStatus, dispatch, paginationModel]);

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
      setPaginationModel(newPaginationModel);
  };

  const handleDeleteSize = (sizeId: number) => {
    if(window.confirm("Вы уверены, что хотите удалить этот размер?")) {
      dispatch(removeSize(sizeId));
    }
  };

  const handleEditSize = (sizeId: number) => {
    const selectedSize = sizes.find(size => size.id === sizeId);
    if (selectedSize) {
      onSelectSize(selectedSize);
    }
  };
  

  const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'Id',
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Marimi',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actiuni',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              <IconButton onClick={() => handleEditSize(params.row.id)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteSize(params.row.id)}>
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
  ];

  const rows = sizes.map((size) => ({
      id: size.id,
      name: size.name,
  }));

  return (
    <Box sx={{ height: "100%", width: '100%', p: '2%' }}>
      <DataGrid 
          paginationMode="server"
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 15, 25]}
          rowCount={Count}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          loading={isDataGridLoading}
          sx={{ fontSize: '22px' }}
      />
    </Box>
  );
};

export default SizeData;
