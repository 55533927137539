import React from 'react';
import { Box, Divider, Paper, Typography, Grid } from '@mui/material';
import { UserDetails as UserDetailsInterface } from '../../../redux/slices/user';

interface UserDetailsProps {
    userDetails: UserDetailsInterface | null;
}

const UserDetails: React.FC<UserDetailsProps> = ({ userDetails }) => {

    const formatDate = (isoString: string): string => {
        return new Date(isoString).toLocaleString('ro-RO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: 1, p: 1 }}>
            <Paper sx={{ flex: 1, p: 2 }} elevation={3}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="h4">Nume:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h4">{userDetails?.FullName}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h4">Email:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h4">{userDetails?.Email}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h4">Telefon:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h4">{userDetails?.Telefon}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h4">Data Creării:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h4">{userDetails ? formatDate(userDetails.createdAt) : ''}</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ flex: 1, p: 2 }} elevation={3}>

            </Paper>
        </Box>
    );
};

export default UserDetails;
