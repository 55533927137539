import React, { useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useAppDispatch } from '../../../../redux/store';
import { createSize, updateSize } from '../../../../redux/slices/size';

interface Size {
  id: number;
  name: string;
}

interface SizeCreateProps {
  selectedSize?: Size | null;
  onResetSelectedSize?: () => void; 
}


const SizeCreate: React.FC<SizeCreateProps> = ({ selectedSize, onResetSelectedSize }) => {
  const [sizeName, setSizeName] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedSize) {
      setSizeName(selectedSize.name.toUpperCase());
    } else {
      setSizeName('');
    }
  }, [selectedSize]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSizeName(event.target.value.toUpperCase());
  };

  const handleSubmit = async () => {
    if (!sizeName) {
      alert('Введите название размера');
      return;
    }
    const action = selectedSize && selectedSize.id !== undefined
      ? updateSize({ id: selectedSize.id, name: sizeName })
      : createSize({ name: sizeName });
      
    await dispatch(action);
    setSizeName('');
    if (onResetSelectedSize) {
      onResetSelectedSize();
    }
  };
  
  

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%' 
      }}
    >
      <TextField
        label="Marime"
        variant="outlined"
        value={sizeName}
        onChange={handleNameChange}
        sx={{ mb: 2, '& input': { textTransform: 'uppercase' } }}
        autoComplete='none'
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSubmit}
        disabled={!sizeName}
      >
        {selectedSize ? 'Update' : 'Creaza'}
      </Button>
    </Box>
  );
};

export default SizeCreate;
