import React from 'react';
import { Filter } from '../../../redux/slices/produse';
import { Box, Stack, Chip, Avatar, Typography } from '@mui/material';
import { Color } from '../../../redux/slices/color';
import { Size } from '../../../redux/slices/size';
import RefreshIcon from '@mui/icons-material/Refresh';

interface SelectedFiltersProps {
    filters: Filter;
    onFilterChange: (key: keyof Filter, value: any) => void;
    onResetFilters: () => void;
    colors: Color[];
    sizes: Size[];
}

const SelectedFilters: React.FC<SelectedFiltersProps> = ({ 
    filters, 
    onFilterChange, 
    onResetFilters,
    colors, 
    sizes, 
}) => {
    const getNameById = <T extends { id: number; name: string }>(data: T[], id: number): string => {
        const item = data.find(item => item.id === id);
        return item ? item.name : 'Unknown';
    };

    const getColorCodeById = (id: number): string => {
        const color = colors.find(color => color.id === id);
        return color ? color.colorCode : '#ffffff'; 
    };


    const getLabel = (key: string, value: any): JSX.Element => {
        switch (key) {
            case 'colorId':
                const colorName = getNameById(colors, value);
                const colorCode = getColorCodeById(value);
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ 
                            width: 20, 
                            height: 20, 
                            bgcolor: colorCode, 
                            borderRadius: '50%' 
                        }} />
                        <Typography>{`Culoare: ${colorName}`}</Typography>
                    </Box>
                );
            case 'sizeId':
                return <Typography>{`Mărime: ${getNameById(sizes, value)}`}</Typography>;
            case 'sort':
                if (value === 'asc') return <Typography>Preț Mic spre Mare</Typography>;
                if (value === 'desc') return <Typography>Preț Mare spre Mic</Typography>;
                return <Typography>{value}</Typography>;
            case 'onSale':
                return <Typography>{value ? 'La reducere' : 'Fără reducere'}</Typography>;
            default:
                return <Typography>{value.toString()}</Typography>;
        }
    };

    const hasActiveFilters = Object.entries(filters).some(([key, value]) => {
        return key !== 'productClassId' && value !== null && value !== undefined;
    });
    
    
      return (
        <Box>
            <Stack direction="row" spacing={1}>
                {hasActiveFilters && (
                    <Chip
                        icon={<RefreshIcon />}
                        label="Reset"
                        onClick={onResetFilters}
                        color="default"
                        variant="outlined"
                    />
                )}
                {Object.entries(filters).filter(([key, value]) => value !== null && value !== undefined && key !== 'productClassId').map(([key, value]) => (
                    <Chip
                        key={key}
                        label={getLabel(key, value)}
                        onDelete={() => onFilterChange(key as keyof Filter, null)}
                        color="default"
                        variant="outlined"
                    />
                ))}
            </Stack>
        </Box>
      );
    }
  
export default SelectedFilters;
