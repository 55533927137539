import React, { useState } from 'react';
import { Box } from '@mui/material';
import ColorData from './ColorData';
import ColorPallete from './ColorPallete';

const ColorEditor = () => {
    const [editingColor, setEditingColor] = useState<{ id: number, name: string, colorCode: string } | null>(null);

    const handleEditColor = (color: { id: number, name: string, colorCode: string }) => {
        setEditingColor(color);
    };
    
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', }}>
            <Box sx={{ width: '50%', height: '100%' }}>
                <ColorData onEditColor={handleEditColor}/>
            </Box>
            <Box sx={{ width: '50%', height: '100%' }}>
                <ColorPallete editingColor={editingColor} resetEditing={() => setEditingColor(null)} />
            </Box>
        </Box>
    );
};

export default ColorEditor;
