import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service'; 

export interface PaymentType {
  id: number;
  name: string;
}

interface PaymentTypesRequestParams {
    page: number;
    perPage: number;
}
  

interface PaymentTypesState {
  items: PaymentType[];
  status: 'loading' | 'loaded' | 'error';
  count: number;
}

const initialPaymentTypesState: PaymentTypesState = {
  items: [],
  status: 'loading',
  count: 0,
};

export const fetchPaymentTypes = createAsyncThunk<PaymentType[], void, { rejectValue: string }>(
  'paymentTypes/fetchPaymentTypes', 
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<PaymentType[]>('GET', '/payment');
    if (error || !response) {
      return rejectWithValue('Failed to fetch payment types');
    }
    return response.data;
  }
);

// Получение всех типов оплаты
export const fetchPaymentTypesPagination = createAsyncThunk<{ count: number, paymentTypes: PaymentType[] }, PaymentTypesRequestParams, { rejectValue: string }>(
    'paymentTypes/fetchPaymentTypesPagination',
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number, paymentTypes: PaymentType[] }>('GET', '/payment/pagiantion', null, {
        page,
        perPage,
      });
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch payment types with pagination');
      }
  
      return response.data;
    }
);

// Создание нового типа оплаты
export const createPaymentType = createAsyncThunk<PaymentType, Omit<PaymentType, 'id'>, { rejectValue: string }>(
  'paymentTypes/createPaymentType', 
  async (paymentTypeData, { rejectWithValue }) => {
    const [error, response] = await API<PaymentType>('POST', '/payment/create', paymentTypeData);
    if (error || !response) {
      return rejectWithValue('Failed to create payment type');
    }
    return response.data;
  }
);

const paymentTypesSlice = createSlice({
  name: 'paymentTypes',
  initialState: initialPaymentTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentTypes.fulfilled, (state, action: PayloadAction<PaymentType[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchPaymentTypes.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchPaymentTypesPagination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentTypesPagination.fulfilled, (state, action: PayloadAction<{ count: number, paymentTypes: PaymentType[] }>) => {
        state.items = action.payload.paymentTypes;
        state.count = action.payload.count;
        state.status = 'loaded';
      })
      .addCase(fetchPaymentTypesPagination.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createPaymentType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPaymentType.fulfilled, (state, action: PayloadAction<PaymentType>) => {
        state.items.push(action.payload);
        state.status = 'loaded';
      })
      .addCase(createPaymentType.rejected, (state) => {
        state.status = 'error';
      });
  }
});

export const paymentTypesReducer = paymentTypesSlice.reducer;
