import React, { useState } from 'react';
import { Box, TextField, Button, FormHelperText, IconButton, InputAdornment, FormControlLabel, Switch } from '@mui/material';
import { RegistrationData } from '../../../pages/Client/Auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface RegistrationProps {
  register: (registrationData: RegistrationData) => void;
}

const Registration = ({ register }: RegistrationProps) => {
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);

  const [touched, setTouched] = useState({
    email: false,
    fullname: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
  });


  const validateEmail = (email: string) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());

  const validatePassword = (password: string) => {
    const hasMinLength = password.length >= 7;
    const hasNumbers = /\d{3,}/.test(password);
    const hasLetters = password.match(/[a-zA-Z]/g) || [];
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return hasMinLength && hasNumbers && hasLetters.length >= 3 && hasUpperCase && hasSpecialChar;
  };
  
  const passwordErrorText = () => {
    if (!password) return "Parola este obligatorie";
    if (!validatePassword(password)) return "Parola trebuie să conțină min. 7 caractere, inclusiv 3 cifre, min. 3 litere (cu o majusculă) și un simbol special";
    return "";
  };

  const passwordMatchError = touched.password && touched.confirmPassword && password !== confirmPassword;

  const isFormValid = email && fullname && mobileNumber && password && confirmPassword && password === confirmPassword && validateEmail(email);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid) {
      const registrationData: RegistrationData = {
        Email: email,
        FullName: fullname,
        Telefon: mobileNumber,
        password: password,
        isSubscribedToNewsletter: subscribeNewsletter,
      };
      register(registrationData);
    } else {
      setTouched({
        email: true,
        fullname: true,
        mobileNumber: true,
        password: true,
        confirmPassword: true,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box 
      component="form" 
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '95%', margin: '0 auto' }} 
      onSubmit={handleSubmit}
    >

      {/* Email Field */}
      <TextField 
        label="Email" 
        type="email" 
        value={email} 
        onChange={e => setEmail(e.target.value)} 
        onBlur={() => setTouched({...touched, email: true})} 
        error={touched.email && (!email || !validateEmail(email))} 
        required 
        fullWidth 
      />
      {touched.email && (!email || !validateEmail(email)) && (
        <FormHelperText error>{!email ? "E-mailul este obligatoriu" : "E-mailul este incorect"}</FormHelperText>
      )}
      
      {/* Full Name Field */}
      <TextField 
        label="Nume" 
        value={fullname} 
        onChange={e => setFullname(e.target.value)} 
        onBlur={() => setTouched({...touched, fullname: true})} 
        error={touched.fullname && !fullname} 
        required 
        fullWidth 
      />
      {touched.fullname && !fullname && (
        <FormHelperText error>Numele este obligatoriu</FormHelperText>
      )}
        
      <TextField 
        label="Numar de Telefon" 
        value={mobileNumber} 
        onChange={e => setMobileNumber(e.target.value)} 
        onBlur={() => setTouched(prev => ({ ...prev, mobileNumber: true }))} 
        error={touched.mobileNumber && !mobileNumber} 
        required 
        fullWidth 
      />
      {touched.mobileNumber && !mobileNumber && (
        <FormHelperText error>Numarul de telefon este obligaoriu</FormHelperText>
      )}

       {/* Поле для пароля */}
      <TextField 
        label="Parola" 
        variant="outlined" 
        type={showPassword ? 'text' : 'password'} 
        value={password} 
        onChange={e => setPassword(e.target.value)} 
        onBlur={() => setTouched(prev => ({ ...prev, password: true }))} 
        error={touched.password && !validatePassword(password)} 
        required 
        fullWidth 
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {touched.password && !validatePassword(password) && (
        <FormHelperText error>{passwordErrorText()}</FormHelperText>
      )}

      {/* Confirm Password Field */}
      <TextField 
        label="Confirmare Parola" 
        variant="outlined" 
        type={showPassword ? 'text' : 'password'} 
        value={confirmPassword} 
        onChange={e => setConfirmPassword(e.target.value)} 
        onBlur={() => setTouched(prev => ({ ...prev, confirmPassword: true }))} 
        error={passwordMatchError} 
        required 
        fullWidth 
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {passwordMatchError && (
        <FormHelperText error>Parolele nu se potrivesc</FormHelperText>
      )}

      <FormControlLabel
        control={<Switch checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />}
        label={
          <span>
            Sunt de acord cu {' '}
            <a href="/Termeni&Conditii" target="_blank" rel="noopener noreferrer" style={{ color: 'red', textDecoration: 'none' }}>
              Termenii si conditiile de utilizare a site-ului
            </a>
          </span>
        }
        sx={{ mb: 2 }}
      />

      <FormControlLabel
        control={<Switch checked={subscribeNewsletter} onChange={(e) => setSubscribeNewsletter(e.target.checked)} />}
        label="Aboneaza-te la newsletter-ul Milivnik pentru a primi cele mai bune oferte!"
        sx={{ mb: 2 }}
      />

      <Button 
        type="submit" 
        variant="contained" 
        disabled={!isFormValid || !agreeTerms}
        sx={{
          mt: 1,
          width: '100%',
          fontSize: '0.9rem',
          backgroundColor: '#000',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.8)',
          },
        }}
      >
        Register
      </Button>

    </Box>
  );
};

export default Registration;
