import React from 'react';
import { Box, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface AdminPieChartsProps {
    onlineOrderCount: number;
    offlineOrderCount: number;
    onlineTotalRevenue: number;
    offlineTotalRevenue: number;
}

const AdminPieCharts: React.FC<AdminPieChartsProps> = ({
    onlineOrderCount,
    offlineOrderCount,
    onlineTotalRevenue,
    offlineTotalRevenue,
}) => {
    const ordersData = {
        labels: ['Comenzi Online', 'Comenzi Offline'],
        datasets: [
            {
                label: 'Numar Comenzi',
                data: [onlineOrderCount, offlineOrderCount],
                backgroundColor: ['rgba(54, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)'],
            }
        ]
    };

    const revenueData = {
        labels: ['Venituri Online', 'Venituri Offline'],
        datasets: [
            {
                label: 'Venituri',
                data: [onlineTotalRevenue, offlineTotalRevenue],
                backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)'],
            }
        ]
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '100%' }}>
            <Box sx={{ padding: '10px' }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>Numar Comenzi</Typography>
                <Pie data={ordersData} />
            </Box>
            <Box sx={{ padding: '10px' }}> 
                <Typography variant="h6" sx={{ textAlign: 'center' }}>Venituri</Typography>
                <Pie data={revenueData} />
            </Box>
        </Box>
    );
}

export default AdminPieCharts;
