import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Button, Checkbox, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import CategoryFilter from '../../../filters/CategoryFilter';
import { ProductClass } from '../../../../redux/slices/class';
import { Color } from '../../../../redux/slices/color';
import { Size } from '../../../../redux/slices/size';
import { OfflineOrderDetailWithoutId } from '../../../../redux/slices/orderoffline';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { InventoryItem } from '../../../../redux/slices/inventory';
import { Filter, Product } from '../../../../redux/slices/productAdmin';
import SearchIcon from '@mui/icons-material/Search';

type OptionType = 'color' | 'size' | 'city';

interface SelectProductDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onResetSelections: () => void;
    products: Product[];
    productClasses: ProductClass[];
    colors: Color[];
    sizes: Size[];
    filters: Filter;
    updateFilter: (field: keyof Filter, value: any) => void;
    selectedProductDetails: OfflineOrderDetailWithoutId[];
    onToggleProductDetail: (productId: number) => void;
    handleDetailChange: (localId: string, field: 'colorId' | 'sizeId' | 'cityId' | 'quantity', value: number | null) => void;
    handleAddNewCombination: (postId: number) => void;
    handleRemoveCombination: (localId: string) => void;
    handleSearchKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleSearchClick: () => void;
}


const LazyAvatar = ({ src, alt }: { src?: string; alt?: string }) => (
    <Avatar sx={{ width: 'auto', height: 200, borderRadius: '10%' }}>
      {src && <img src={src} alt={alt} loading="lazy" style={{ width: '100%', height: '100%' }} />}
    </Avatar>
);

const SelectProductDialog: React.FC<SelectProductDialogProps> = ({
    isOpen,
    onClose,
    onResetSelections,
    products,
    productClasses,
    colors,
    sizes,
    filters,
    updateFilter,
    selectedProductDetails,
    onToggleProductDetail,
    handleDetailChange,
    handleAddNewCombination,
    handleRemoveCombination,
    handleSearchKeyDown,
    handleSearchClick,
}) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 

    
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilter('query', event.target.value);
    };

    const handleCategorySelect = (category: ProductClass | null) => {
        updateFilter('productClassId', category ? category.id : null);
    };

    const handleColorChange = (event: SelectChangeEvent<number | string>) => {
        const value = event.target.value === "" ? null : Number(event.target.value);
        updateFilter('colorId', value);
    };

    const handleSizeChange = (event: SelectChangeEvent<number | string>) => {
        const value = event.target.value === "" ? null : Number(event.target.value);
        updateFilter('sizeId', value);
    };

    const isOptionAvailable = (
        optionType: OptionType,
        optionId: number,
        currentDetail: OfflineOrderDetailWithoutId,
        selectedProductDetails: OfflineOrderDetailWithoutId[],
        inventoryOptions: InventoryItem[]
    ): boolean => {
        switch (optionType) {
            case 'color':
                // Прямое возвращение boolean значения
                return inventoryOptions.some(option => 
                    option.ColorId === optionId && 
                    !selectedProductDetails.some(selectedDetail => 
                        selectedDetail.colorId === option.ColorId && 
                        selectedDetail.sizeId === option.SizeId && 
                        selectedDetail.cityId === option.CityId &&
                        selectedDetail.productId === currentDetail.productId
                    )
                );
            case 'size':
                // Условие для размера с учетом выбранного цвета
                return !!currentDetail.colorId && inventoryOptions.some(option =>
                    option.ColorId === currentDetail.colorId && 
                    option.SizeId === optionId &&
                    // Проверяем, что для данного размера и цвета существуют еще не выбранные комбинации
                    inventoryOptions.filter(io => io.ColorId === option.ColorId && io.SizeId === optionId)
                        .some(io => 
                            !selectedProductDetails.some(detail => 
                                detail.colorId === io.ColorId && 
                                detail.sizeId === io.SizeId &&
                                detail.cityId === io.CityId && // Уточнение условия для проверки города
                                detail.productId === currentDetail.productId
                            )
                        )
                );
            case 'city':
                // Условие для города с учетом выбранного цвета и размера
                return !!currentDetail.colorId && !!currentDetail.sizeId && inventoryOptions.some(option =>
                    option.ColorId === currentDetail.colorId &&
                    option.SizeId === currentDetail.sizeId &&
                    option.CityId === optionId &&
                    !selectedProductDetails.some(detail => 
                        detail.colorId === currentDetail.colorId &&
                        detail.sizeId === currentDetail.sizeId &&
                        detail.cityId === optionId &&
                        detail.productId === currentDetail.productId &&
                        detail.localId !== currentDetail.localId
                    )
                );
            default:
                return true;
        }
    };
    

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          maxWidth: "80vw",
          height: "90vh",
          overflow: "hidden",
          p: 1,
        }
      }}
    >
        <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2">Alege Produsele</Typography>
                <Box>
                    <Button
                     onClick={onResetSelections}
                        variant="outlined"
                        size="large"
                        sx={{ marginRight: 1, color: 'gray', borderColor: 'gray' }}
                    >
                        Reset
                    </Button>
                </Box>
            </Box>
        </DialogTitle>

        <DialogContent>
            {/* Поле поиска */}
            <Box sx={{ padding: 2 }}>
                <Box sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Cautare Produse"
                            variant="outlined"
                            value={filters.query || ''}
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSearchClick}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="color-select-label">Culoare</InputLabel>
                                <Select
                                    labelId="color-select-label"
                                    id="color-select"
                                    value={filters.colorId || ''}
                                    label="Culoare"
                                    onChange={handleColorChange}
                                >
                                    <MenuItem value="">
                                        <em>Niciuna</em>
                                    </MenuItem>
                                    {colors.map((color) => (
                                        <MenuItem key={color.id} value={color.id}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: color.colorCode, marginRight: 1 }} />
                                                {color.name}
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="size-select-label">Marime</InputLabel>
                                <Select
                                    labelId="size-select-label"
                                    id="size-select"
                                    value={filters.sizeId || ''}
                                    label="Marime"
                                    onChange={handleSizeChange}
                                >
                                    <MenuItem value="">
                                        <em>Niciuna</em>
                                    </MenuItem>
                                    {sizes.map((size) => (
                                        <MenuItem key={size.id} value={size.id}>
                                            {size.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <CategoryFilter 
                    categories={productClasses} 
                    onSelect={handleCategorySelect} 
                    selectedCategory={filters.productClassId}
                />
            </Box>

            {/* Список продуктов */}
            <Box sx={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {products.map((product, productIndex) => {
                    const inventoryOptions = product.inventories || [];
                    const isSelected = selectedProductDetails.some(detail => detail.productId === product.id);
                    const discountedPrice = product.hasActiveDiscount && product.discount
                                            ? product.price - (product.price * product.discount.percentage / 100)
                                            : product.price;
                    const findMaxQuantity = (colorId: number | null, sizeId: number | null, cityId: number | null, inventory: InventoryItem[]): number => {
                        const inventoryItem = inventory.find(inv =>
                            inv.ColorId === colorId && inv.SizeId === sizeId && inv.CityId === cityId
                        );
                        return inventoryItem ? inventoryItem.count : 0;
                    };
                                            

                    return (
                        <Box key={product.id}>
                            <Box key={product.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <LazyAvatar
                                    src={product.imageUrl.length > 0 ? `${baseUrl}${product.imageUrl[0]}` : undefined}
                                    alt={product.title}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
                                    <Typography variant="h4">{product.title}</Typography>
                                    {/* Условное отображение цен */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        {product.hasActiveDiscount && (
                                            <Typography variant="h5" component="span" sx={{ textDecoration: 'line-through' }}>
                                                {product.price.toFixed(2)} Lei
                                            </Typography>
                                        )}
                                        <Typography variant="h5" component="span" sx={{ color: product.hasActiveDiscount ? 'red' : 'inherit' }}>
                                            {discountedPrice.toFixed(2)} Lei
                                        </Typography>
                                    </Box>
                                </Box>
                                <Checkbox
                                    checked={isSelected}
                                    onChange={() => onToggleProductDetail(product.id)}
                                />
                            </Box>
                            {isSelected && selectedProductDetails.filter(detail => detail.productId === product.id).map((detail, index, array) => (
                                <React.Fragment key={detail.localId}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 1 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            {/* Цвет */}
                                            <Grid item xs={12} sm={6} md={3}>
                                                <FormControl fullWidth error={!detail.colorId}>
                                                    <InputLabel id={`color-select-label-${index}`}>Culoare</InputLabel>
                                                    <Select
                                                        labelId={`color-select-label-${index}`}
                                                        id={`color-select-${index}`}
                                                        label="Culoare"
                                                        value={detail.colorId || ''}
                                                        onChange={(e) => handleDetailChange(detail.localId, 'colorId', Number(e.target.value))}
                                                    >
                                                        {inventoryOptions.filter((inv, idx, self) =>
                                                            self.findIndex(t => t.ColorId === inv.ColorId) === idx
                                                        ).map(inv => {
                                                        const isDisabled = !isOptionAvailable('color', inv.ColorId, detail, selectedProductDetails, inventoryOptions);
                                                        return (
                                                            <MenuItem key={inv.ColorId} value={inv.ColorId} disabled={isDisabled}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: inv.Color?.colorCode, marginRight: 1 }} />
                                                                    {inv.Color?.name}
                                                                </Box>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* Размер */}
                                            <Grid item xs={12} sm={6} md={3}>
                                                <FormControl fullWidth error={!detail.sizeId}>
                                                    <InputLabel id={`size-select-label-${index}`}>Marime</InputLabel>
                                                    <Select
                                                        labelId={`size-select-label-${index}`}
                                                        id={`size-select-${index}`}
                                                        value={detail.sizeId || ''}
                                                        label="Marime"
                                                        onChange={(e) => handleDetailChange(detail.localId, 'sizeId', Number(e.target.value))}
                                                    >
                                                    {inventoryOptions.filter((inv, idx, self) =>
                                                        self.findIndex(t => t.SizeId === inv.SizeId) === idx 
                                                    ).map(inv => {
                                                        // Вызов функции isOptionAvailable для проверки доступности размера
                                                        const isDisabled = !isOptionAvailable('size', inv.SizeId, detail, selectedProductDetails, inventoryOptions);
                                                        return (
                                                            <MenuItem key={inv.SizeId} value={inv.SizeId} disabled={isDisabled}>
                                                                {inv.Size?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* Город */}
                                            <Grid item xs={12} sm={6} md={3}>
                                                <FormControl fullWidth error={!detail.cityId}>
                                                    <InputLabel id={`city-select-label-${index}`}>Oras</InputLabel>
                                                    <Select
                                                        labelId={`city-select-label-${index}`}
                                                        id={`city-select-${index}`}
                                                        value={detail.cityId || ''}
                                                        label="Oras"
                                                        onChange={(e) => handleDetailChange(detail.localId, 'cityId', Number(e.target.value))}
                                                    >
                                                    {inventoryOptions.filter((inv, idx, self) =>
                                                        self.findIndex(t => t.CityId === inv.CityId) === idx
                                                    ).map(inv => {
                                                        // Вызов функции isOptionAvailable для проверки доступности города
                                                        const isDisabled = !isOptionAvailable('city', inv.CityId, detail, selectedProductDetails, inventoryOptions);
                                                        return (
                                                            <MenuItem key={inv.CityId} value={inv.CityId} disabled={isDisabled}>
                                                                {inv.City?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* Количество */}
                                            <Grid item xs={12} sm={6} md={3}>
                                                <TextField
                                                    fullWidth
                                                    label="Cantitate"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    value={detail.quantity.toString()}
                                                    onChange={(e) => {
                                                        const newQuantity = Number(e.target.value);
                                                        const maxQuantity = findMaxQuantity(detail.colorId, detail.sizeId, detail.cityId, inventoryOptions);
                                                        if (newQuantity >= 1 && newQuantity <= maxQuantity) {
                                                            handleDetailChange(detail.localId, 'quantity', newQuantity);
                                                        }
                                                    }}
                                                    inputProps={{ 
                                                        min: "1", 
                                                        max: findMaxQuantity(detail.colorId, detail.sizeId, detail.cityId, inventoryOptions).toString() 
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Delete button in its own container to control its size independently */}
                                        <Box sx={{ ml: 2 }}>
                                            <IconButton onClick={() => handleRemoveCombination(detail.localId)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    {/* Кнопка для добавления новой комбинации, отображается только для последней детали продукта */}
                                    {index === array.length - 1 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <IconButton onClick={() => handleAddNewCombination(product.id)}>
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    )}
                                </React.Fragment>
                            ))}
                        {productIndex < products.length - 1 && <Divider sx={{ my: 2 }} />}
                        </Box>
                    );
                })}
            </Box>

        </DialogContent>

        <DialogActions>
            <Button onClick={onClose} variant="contained" color="secondary" size='large' sx={{ fontWeight: 'bold' }}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
  );
}

export default SelectProductDialog;
