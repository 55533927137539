import React, { useState } from 'react';
import { Box } from '@mui/material';
import SizeData from './SizeData';
import SizeCreate from './SizeCreate';

interface Size {
  id: number;
  name: string;
}

const SizeEditor = () => {
  const [selectedSize, setSelectedSize] = useState<Size | null>(null);

  // Функция для обновления selectedSize
  const handleSelectSize = (size: Size) => {
    setSelectedSize(size);
  };
  
  const resetSelectedSize = () => {
    setSelectedSize(null);
  };


  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <Box sx={{ width: '50%', height: '100%' }}>
        <SizeData onSelectSize={handleSelectSize} />
      </Box>
      <Box sx={{ width: '50%', height: '100%' }}>
        <SizeCreate selectedSize={selectedSize} onResetSelectedSize={resetSelectedSize} />
      </Box>
    </Box>
  );
};

export default SizeEditor;
