import React, { useEffect } from 'react';
import { Box, Paper, Avatar, Typography, Divider } from '@mui/material';
import { useAppSelector, RootState, useAppDispatch } from '../../../redux/store';
import { fetchSelectedWorker } from '../../../redux/slices/staff';
import Spinner from '../../spinner/Spinner';

interface UserInfoProps {
  id: number;
}

const UserRoleProfil: React.FC<UserInfoProps> = ( {id} ) => {
  const dispatch = useAppDispatch();
  const selectedWorker = useAppSelector((state: RootState) => state.staff.selectedWorker);
  const status = useAppSelector((state: RootState) => state.staff.status)
  const name = selectedWorker?.user?.FullName || "N";
  const roleName = selectedWorker?.role?.name || "Unknown";

  useEffect(() => {
    dispatch(fetchSelectedWorker(id));
  }, [dispatch, id]);


  const getColorFromName = (name: String) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colors = ['#FF5722', '#4CAF50', '#2196F3', '#9C27B0', '#E91E63', '#FFEB3B', '#673AB7', '#00BCD4'];
    return colors[Math.abs(hash) % colors.length];
  };

  const avatarColor = getColorFromName(name);

  if (status === 'loading') {
    return <Spinner />;
  }

  return (
    <Box display="flex" flexDirection={"column"} height="100%" width="100%">
      <Box flex="0 1 auto" width={"100%"}>
        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px'}}>
          {/* Левая часть с аватаром и информацией */}
          <Box sx={{ 
            padding: 2, 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center', 
            flex: '0 1 auto', 
            gap: 4,
            px: 6,
            height: '100%' 
          }}> 
            <Avatar sx={{ width: 100, height: 100, bgcolor: avatarColor }}>{name[0]}</Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h3">{name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="h3" sx={{ mr: 1 }}>Post:</Typography>
                <Typography variant="h3" color="primary">{roleName}</Typography> 
              </Box>
            </Box>
          </Box>

          {/* Вертикальный разделитель */}
          <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 'auto' }}/> 

          {/* Правая часть для дополнительной информации */}
          <Box sx={{
            padding: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
            width: '100%',
            height: '100%' 
          }}>
            {/* Контейнер для двух колонн */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '80%'
            }}>
              {/* Первая колонна */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 1,
                flex: '1 1 auto'
              }}>
                <Typography variant='h3'>Id:  {selectedWorker?.user?.id}</Typography>
                <Typography variant='h3'>Numar:  {selectedWorker?.user?.Telefon}</Typography>
              </Box>

              {/* Вторая колонна */}
              <Box sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 1,
                ml: 4,
              }}>
                <Typography variant='h3'>Email:  {selectedWorker?.user?.Email}</Typography>
                <Typography variant='h3'>Data Inregistrarii:  {selectedWorker?.user?.createdAt}</Typography>
              </Box>
            </Box>
          </Box>

        </Paper>
      </Box>
    </Box>
  );
}

export default UserRoleProfil;
