import React from 'react'
import { Product, ProductsLoadingStatus } from '../../../redux/slices/produse';
import { Box, CircularProgress, Typography, Pagination, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FavoriteCard from './FavoriteCard';
import { selectIsAuthenticated } from '../../../redux/slices/auth';
import { useSelector } from 'react-redux';

interface FavoriteProps {
    favorites: Product[];
    count: number;
    status: ProductsLoadingStatus;
    pagination: {
      page: number;
      perPage: number;
    };
    handlePaginationChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    handleRemoveFromFavorites: (productId: number) => void;
  }

const FavoriteComponent: React.FC<FavoriteProps> = ({
    favorites,
    count,
    status,
    pagination,
    handlePaginationChange,
    handleRemoveFromFavorites,
  }) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const handleWomenClick = () => {
        navigate('/Products');
    };

    const handleLogIn = () => {
      navigate('/Authentication');
  };

    // First check if the user is authenticated
    if (!isAuthenticated) {
      return (
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              flexGrow: 1,
              p: '1%',
          }}>
              <Typography variant="h3" align='center'>
                  Autentifica-te pentru a vedea produsele favorite
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginTop: 2,
                  borderRadius: '20px',
                  fontSize: '1.4rem', 
                  padding: '15px 30px', 
                  borderColor: 'black',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    borderColor: 'black', 
                  }
                }}
                onClick={handleLogIn}
              >
                Intra in cont
              </Button>
          </Box>
      );
    }

    // Show loading state if still fetching data
    if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, width: '100%', }}>
                <CircularProgress />
            </Box>
        );
    }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flexGrow: 1,
      p: '1%',
    }}>
      {favorites.length === 0 ? (
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
          }}>
              <Typography variant="h3" align='center'>
                  Lista de produse favorite este goala
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginTop: 2,
                  borderRadius: '20px',
                  fontSize: '1.4rem', 
                  padding: '15px 30px', 
                  borderColor: 'black',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    borderColor: 'black', 
                  }
                }}
                onClick={handleWomenClick}
              >
                Treci la Produse
              </Button>
          </Box>
      ) : (
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
          width: '100%',
          flexGrow: 1,
          mt: 1 
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap', 
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            p: 1,
            gap: 4
          }}>
              {favorites.map((favorite) => (
                  <FavoriteCard 
                      key={favorite.id} 
                      favorite={favorite} 
                      handleRemoveFromFavorites={handleRemoveFromFavorites}
                  />
            ))}
          </Box>

          <Pagination
            page={pagination.page}
            count={Math.ceil(count / pagination.perPage)}
            onChange={handlePaginationChange}
            siblingCount={0} 
            showFirstButton
            showLastButton
            color="primary"
            sx={{ mt: 1 }}
          />
        </Box>
      )}
    </Box>
  )
}

export default FavoriteComponent