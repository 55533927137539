import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import RecenziiSection from './RecenziiSection';
import QuestionSection from './QuestionSection';
import { SelectedProduct } from '../../../redux/slices/produse';

interface DetailSectionProps {
  selectedProduct: SelectedProduct;
}

const DetailSection: React.FC<DetailSectionProps> = ({ selectedProduct }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,  
      alignItems: 'center',
    }}>

      <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%', 
          display: 'flex',
          justifyContent: 'center', 
      }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="detail tabs"
          variant="fullWidth"
          sx={{ 
            '& .MuiTab-root': {
              color: '#000',
              '&:not(:last-child)': {
                borderRight: 1,
                borderColor: 'divider',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#000',
            },
          }}
        >
          <Tab label={<Typography variant="h3" sx={{ color: 'black' }}>Recenzii</Typography>} />
          <Tab label={<Typography variant="h3" sx={{ color: 'black' }}>Intrebari</Typography>} />
        </Tabs>
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%', 
        flexGrow: 1, 
        p: 2,
      }}>
        {value === 0 && (
            <RecenziiSection 
              ratings={selectedProduct.Ratings} 
              averageRating={selectedProduct.averageRating}
            />
        )}
        {value === 1 && (
            <QuestionSection messages={selectedProduct.Messages} />
        )}
      </Box>

    </Box>
  );
};

export default DetailSection;
