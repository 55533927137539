import React from 'react';
import { Box, Paper, Typography, Divider, TextField, Grid, Container } from '@mui/material';
import { CreateOnlineOrderData } from '../../../redux/slices/onlineOrderClient';
import { DeliveryType } from '../../../redux/slices/delivery';
import { PaymentType } from '../../../redux/slices/payment';
import CustomBox from '../../custom/CustomBox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoneyIcon from '@mui/icons-material/Money';

enum PaymentTypeName {
    Card = 'Card',
    Cash = 'Numerar',
}

const paymentTypeIcons: Record<PaymentTypeName, JSX.Element> = {
    [PaymentTypeName.Card]: <CreditCardIcon fontSize="large" />,
    [PaymentTypeName.Cash]: <MoneyIcon fontSize="large" />,
};

interface DetaliiProps {
    deliveryTypes: DeliveryType[];
    paymentTypes: PaymentType[];
    orderData: CreateOnlineOrderData; 
    handleDeliveryAddressChange: (address: string) => void;
    handleDeliveryCityChange: (city: string) => void;
    handleDeliveryPostalCodeChange: (postalCode: string) => void;
    handleDeliveryPhoneChange: (phone: string) => void;
    handleDeliveryTypeIdChange: (deliveryTypeId: number) => void;
    handlePaymentTypeIdChange: (paymentTypeId: number) => void;
}
  
const Detalii: React.FC<DetaliiProps> = ({ 
    deliveryTypes, 
    paymentTypes, 
    orderData, 
    handleDeliveryAddressChange, 
    handleDeliveryCityChange, 
    handleDeliveryPostalCodeChange, 
    handleDeliveryPhoneChange, 
    handleDeliveryTypeIdChange, 
    handlePaymentTypeIdChange,
}) => {

    const onDeliveryTypeClick = (id: number) => {
        handleDeliveryTypeIdChange(id);
    };
    
    const onPaymentTypeClick = (id: number) => {
        handlePaymentTypeIdChange(id);
    };

    return (
    <Container sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ width: '100%', flexGrow: 1, p: 2 }}>
            
            <Typography variant="h3" sx={{ mb: 2 }}>Adresa livrare</Typography>

            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2} width="100%">
                <Grid item xs={12} sm={6}>
                <TextField
                    label="Tara"
                    variant="outlined"
                    fullWidth
                    value="Moldova"
                    
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    label="Oras"
                    variant="outlined"
                    fullWidth
                    value={orderData.deliveryCity}
                    onChange={(e) => handleDeliveryCityChange(e.target.value)}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    label="Adresa"
                    variant="outlined"
                    fullWidth
                    value={orderData.deliveryAddress}
                    onChange={(e) => handleDeliveryAddressChange(e.target.value)}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Index Postal"
                        variant="outlined"
                        fullWidth
                        value={orderData.deliveryPostalCode}
                        onChange={(e) => handleDeliveryPostalCodeChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box sx={{ width: { sm: '50%' }, maxWidth: '100%' }}> 
                        <TextField
                            label="Telefon"
                            variant="outlined"
                            fullWidth
                            value={orderData.deliveryPhone}
                            onChange={(e) => handleDeliveryPhoneChange(e.target.value)}
                        />
                    </Box>
                </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                p: 2,
            }}>
                <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>Tip Livrare</Typography>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around', 
                    gap: 1, 
                }}>
                    {deliveryTypes.map((deliveryType) => (
                        <CustomBox
                            key={deliveryType.id}
                            icon={<LocalShippingIcon fontSize="large" />}
                            title={deliveryType.name}
                            subtitle={`Pret: ${deliveryType.price}`}
                            isSelected={orderData.deliveryTypeId === deliveryType.id}
                            onClick={() => onDeliveryTypeClick(deliveryType.id)}
                        />
                    ))}
                </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                p: 2,
            }}>
                <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>Tip Plata</Typography>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around', 
                    gap: 1, 
                }}>
                    {paymentTypes.map((paymentType) => (
                        <CustomBox
                            key={paymentType.id}
                            icon={paymentTypeIcons[paymentType.name as PaymentTypeName] || <LocalShippingIcon fontSize="large" />}
                            title={paymentType.name}
                            subtitle={""}
                            isSelected={orderData.paymentTypeId === paymentType.id}
                            onClick={() => onPaymentTypeClick(paymentType.id)}
                        />
                    ))}
                </Box>
            </Box>

        </Paper>
    </Container>
  );
};

export default Detalii;
