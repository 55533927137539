import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import RatingProductAdmin from '../../components/Admin/product/RatingProductAdmin';
import QuestionProductAdmin from '../../components/Admin/product/QuestionProductAdmin';
import { useTheme } from '@mui/material';

const Comments = () => {
  const [value, setValue] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: isMobile ? 1 : "2%", width: '100%', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Recenzii si Intrebari" centered>
          <Tab label={<Typography variant="h3">Recenzii</Typography>} />
          <Tab label={<Typography variant="h3">Intrebari</Typography>} />
        </Tabs>
      </Box>

      <Box width={'100%'}>
        {value === 0 && <RatingProductAdmin />}
        {value === 1 && <QuestionProductAdmin />}
      </Box>
    </Box>
  );
}

export default Comments;
