import React from 'react';
import { Box, Typography, Rating as MuiRating, LinearProgress, useMediaQuery, useTheme, Button } from '@mui/material';
import { Rating } from '../../../redux/slices/produse';
import { Star } from '@mui/icons-material';

interface RecenziiSectionProps {
    ratings: Rating[];
    averageRating: number; 
}

const RecenziiSection: React.FC<RecenziiSectionProps> = ({ ratings, averageRating }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: '100%', 
            height: '100%', 
            justifyContent: 'center',
        }}>
            {/* Top */}
            <Box sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
            }}>
                {/* Left side */}
                <Box sx={{
                    width: isSmallScreen ? '100%' : '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: isSmallScreen ? 2 : 0,
                    p: '2%'
                }}>
                    <Typography variant="h1" sx={{ mb: 1 }}>
                        {averageRating.toFixed(1)} din 5
                    </Typography>
                    <MuiRating 
                        name="read-only" 
                        value={averageRating} 
                        readOnly size="large" 
                        sx={{ fontSize: '2.5rem' }} 
                    />
                    <Button 
                        variant="contained" 
                        sx={{ 
                            mt: 1, 
                            bgcolor: 'orange', 
                            color: 'black',
                            fontSize: '1.2rem', 
                            padding: '8px 20px', 
                            '&:hover': {
                                bgcolor: 'darkorange',  
                            },
                        }}
                    >
                        Adauga Recenzia Ta
                    </Button>
                </Box>
                {/* Right side */}
                <Box sx={{
                    width: isSmallScreen ? '100%' : '50%',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: '2%'
                }}>
                    {Array.from({ length: 5 }, (_, i) => 5 - i).map(ratingValue => (
                        <Box key={ratingValue} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            mb: 1
                        }}>
                            <Typography variant="h3" sx={{ width: '50px', textAlign: 'center' }}>
                                {ratingValue}
                            </Typography>
                            {isSmallScreen ? (
                                <Star sx={{ color: 'orange', fontSize: '2rem' }} />
                            ) : (
                                <MuiRating
                                    value={ratingValue}
                                    readOnly
                                    size="large" 
                                    sx={{ mx: 1 }}
                                />
                            )}
                            <LinearProgress
                                variant="determinate"
                                value={ratings.length > 0 ? (ratings.filter(rating => rating.value === ratingValue).length / ratings.length) * 100 : 0}
                                sx={{
                                    flexGrow: 1,
                                    height: '10px',
                                    borderRadius: '4px',
                                    mx: 1
                                }}
                            />
                            <Box sx={{ width: '50px', textAlign: 'center' }}>
                                <Typography variant="h3">
                                    {ratings.filter(rating => rating.value === ratingValue).length}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            {/* Bottom */}
            <Box sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
                {ratings.length === 0 && (
                    <Typography variant="h3" sx={{ color: 'gray' }}>
                        Fiti primul care incearca acest produs
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default RecenziiSection;
