import React, { useState } from 'react';
import { Box, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import { PostData } from '../../../redux/slices/posts';
import { Color } from '../../../redux/slices/color';
import { Size } from '../../../redux/slices/size';
import { ProductClass } from '../../../redux/slices/class';
import { City } from '../../../redux/slices/city';
import { InventoryItem, InventoryDataCreate } from '../../../redux/slices/inventory';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


interface SetProductDataProps {
    productData: PostData;
    handleChange: (name: keyof PostData, value: any) => void;
    combinedInventoryItems: (InventoryItem | InventoryDataCreate)[];
    handleInventoryChange: (id: number, field: keyof InventoryDataCreate, value: any) => void;
    handleAddInventoryItem: () => void;
    handleRemoveInventoryItem: (id: number) => void;
    resetFormData: () => void;
    colors: Color[];
    sizes: Size[];
    productClasses: ProductClass[];
    cities: City[];
}

const SetProductData: React.FC<SetProductDataProps> = ({
    productData,
    handleChange,
    combinedInventoryItems,
    handleInventoryChange,
    handleAddInventoryItem,
    handleRemoveInventoryItem,
    resetFormData,
    colors,
    sizes,
    productClasses,
    cities
}) => {
    const [page, setPage] = useState(1);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    
    const handleNumericChange = (field: keyof PostData, value: string) => {
        // Удаляем все, кроме цифр, точки и запятой
        const filteredValue = value.replace(/[^0-9.,]/g, '').replace(/^0+/, '').replace(/,/g, '.');
    
        // Пытаемся преобразовать строку в число
        let numericValue = parseFloat(filteredValue);
    
        // Обрабатываем случай NaN, присваиваем 0 если значение не число
        numericValue = isNaN(numericValue) ? 0 : numericValue;
    
        // Обновляем состояние, используя обработанные значения
        if (field === 'discount') {
            handleChange('discount', { ...productData.discount, percentage: numericValue });
        } else {
            handleChange(field, numericValue);
        }
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange('text', event.target.value);
    };
    
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column', 
        height: '100%', 
        width: '100%',
        overflow: 'hidden'
    }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
            padding: 1
        }}>

            <Pagination
                count={2}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                '& .MuiPaginationItem-root': {
                    border: 2,
                    borderColor: 'primary.main',
                }
                }}
            />
            <IconButton color="error" sx={{ 
                    border: 2, 
                    borderColor: 'error.main', 
                    borderRadius: '50%' 
                }}
                onClick={resetFormData}
            >
                <DeleteIcon />
            </IconButton>
        </Box>

        {page === 1 && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column', 
                height: '100%', 
                overflow: 'hidden',
                p: 2,
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Titlu"
                            value={productData.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="product-class-label">Clasa Produsului</InputLabel>
                            <Select
                                labelId="product-class-label"
                                label="Clasa Produsului"
                                value={productData.productClassId}
                                onChange={(e) => handleChange('productClassId', Number(e.target.value))}
                            >
                                {productClasses.map((pc) => (
                                    <MenuItem key={pc.id} value={pc.id}>
                                        {pc.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Pret Nominal"
                        type="number"
                        inputProps={{ min: "0" }}  
                        value={productData.originalPrice.toString()}
                        onChange={(e) => handleNumericChange('originalPrice', e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Pret"
                            type="number"
                            inputProps={{ min: "0" }}  
                            value={productData.price.toString()}
                            onChange={(e) => handleNumericChange('price', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="discount-label">Reducere</InputLabel>
                            <Select
                                labelId="discount-label"
                                label="Reducere"
                                value={productData.discount ? "Yes" : "No"}
                                onChange={(e) => handleChange('discount', e.target.value === "Yes" ? { percentage: 0, startDate: '', endDate: '' } : undefined)}
                            >
                                <MenuItem value="Yes">Da</MenuItem>
                                <MenuItem value="No">Nu</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Procentaj"
                            type="number"
                            disabled={!productData.discount}
                            value={productData.discount ? productData.discount.percentage.toString() : ''}
                            onChange={(e) => handleNumericChange('discount', e.target.value)}
                            inputProps={{
                                min: "0",
                                max: "100" 
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Data Inceput"
                            type="date"
                            disabled={!productData.discount}
                            value={productData.discount ? productData.discount.startDate : ''}
                            onChange={(e) => handleChange('discount', { ...productData.discount, startDate: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Data Sfarsit"
                            type="date"
                            disabled={!productData.discount}
                            value={productData.discount ? productData.discount.endDate : ''}
                            onChange={(e) => handleChange('discount', { ...productData.discount, endDate: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>  
                <Divider sx={{ width: '100%', my: 2 }} />
                <Box sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    pt: 1,
                    marginBottom: 3,
                    '&::-webkit-scrollbar': {
                        width: '8px'  
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent'  
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,0.4)', 
                        borderRadius: '10px' 
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'rgba(0,0,0,0.6)'
                    }
                }}>
                    <Grid container spacing={2}>
                        {combinedInventoryItems.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id={`city-label-${index}`}>Oras</InputLabel>
                                        <Select
                                            labelId={`city-label-${index}`}
                                            label="Oras"
                                            value={item.CityId}
                                            onChange={(e) => handleInventoryChange(item.id, 'CityId', Number(e.target.value))}
                                        >
                                            {cities.map((city) => (
                                                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id={`color-label-${index}`}>Culoare</InputLabel>
                                        <Select
                                            labelId={`color-label-${index}`}
                                            label="Culoare"
                                            value={item.ColorId}
                                            onChange={(e) => handleInventoryChange(item.id, 'ColorId', Number(e.target.value))}
                                        >
                                            {colors.map((color) => (
                                                <MenuItem key={color.id} value={color.id}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box sx={{
                                                            width: 15,
                                                            height: 15,
                                                            borderRadius: '50%',
                                                            backgroundColor: color.colorCode,
                                                            marginRight: 1 
                                                        }} />
                                                        {color.name}
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id={`size-label-${index}`}>Marime</InputLabel>
                                        <Select
                                            labelId={`size-label-${index}`}
                                            label="Marime"
                                            value={item.SizeId}
                                            onChange={(e) => handleInventoryChange(item.id, 'SizeId', Number(e.target.value))}
                                        >
                                            {sizes.map((size) => (
                                                <MenuItem key={size.id} value={size.id}>{size.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    label="Cantitate"
                                    type="number"
                                    value={item.count}
                                    onChange={(e) => {
                                        const newCount = parseInt(e.target.value, 10);
                                        if (newCount >= 0) {
                                            handleInventoryChange(item.id, 'count', newCount);
                                        }
                                    }}
                                    inputProps={{ min: 0, step: 1 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </Grid>
                                <Grid item xs={1} container alignItems="center" justifyContent="center">
                                <IconButton
                                    onClick={() => handleRemoveInventoryItem(item.id)}
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <IconButton
                        color="primary"
                        onClick={handleAddInventoryItem}
                        sx={{ border: 2, borderColor: 'primary.main', borderRadius: '50%' }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
        )}

        {page === 2 && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column', 
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', 
                overflow: 'hidden',
                p: 2,
            }}>
                <TextField
                    label="Descriere"
                    multiline
                    rows={7}
                    fullWidth
                    variant="outlined"
                    value={productData.text}
                    onChange={handleTextChange}
                />
            </Box>
        )}
    </Box>
    );
}

export default SetProductData;