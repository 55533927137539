import React from 'react'
import { Box, Paper } from '@mui/material'

const SendEmail = () => {
  return (
    <Box sx={{ 
        height: "100vh",
        overflow: "auto", 
        p: '1%', 
        display: 'flex', 
        flexDirection: 'column' 
    }}>

        <Paper elevation={3} sx={{
            width: '100%', 
            height: '100%', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            p: 2
        }}>

        </Paper>
    </Box>
  )
}

export default SendEmail