import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axios'; 

interface AdminGeneralStats {
  totalOrders: number;
  assignedOrdersCount: number;
  totalUsers: number;
  totalPosts: number;
}

interface CombinedOrderStat {
  date: string;
  onlineOrderCount: number;
  onlineTotalAmount: number;
  offlineOrderCount: number;
  offlineTotalAmount: number;
}

interface CombinedOrderStatsState {
  data: CombinedOrderStat[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: CombinedOrderStatsState = {
  data: [],
  status: 'idle',
};

export const fetchAllOrderStats = createAsyncThunk(
  'orders/fetchAllOrderStats',
  async (days: number) => {
      const response = await axios.get<CombinedOrderStat[]>(`/admin/order/stats?days=${days}`);
      return response.data;
  }
);

const orderStatsSlice = createSlice({
  name: 'orderStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(fetchAllOrderStats.pending, (state) => {
              state.status = 'loading';
          })
          .addCase(fetchAllOrderStats.fulfilled, (state, action: PayloadAction<CombinedOrderStat[]>) => {
              state.status = 'succeeded';
              state.data = action.payload;
          })
          .addCase(fetchAllOrderStats.rejected, (state) => {
              state.status = 'failed';
          });
  },
});

export default orderStatsSlice.reducer;