import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';

// Определение типа уведомления
export type NotificationItem = {
    Id: number;
    userId: number;
    orderId?: number | null;
    message: string;
    type: string;
    isRead: boolean;
    createdAt: string;
};

// Интерфейс для состояния уведомлений
interface NotificationsState {
    items: NotificationItem[];
    status: 'idle' | 'loading' | 'loaded' | 'error';
    page: number;
    perPage: number;
    total: number;
}

// Начальное состояние
const initialState: NotificationsState = {
    items: [],
    status: 'idle',
    page: 1,
    perPage: 10,
    total: 0
};

// Асинхронные действия
export const fetchNotifications = createAsyncThunk<{ notifications: NotificationItem[], count: number },{ page: number; perPage: number },{ rejectValue: string }>(
    'notifications/fetchNotifications',
    async ({ page, perPage }, { rejectWithValue }) => {
        const queryString = `?page=${page}&perPage=${perPage}`;
        const [error, response] = await API<{ notifications: NotificationItem[], count: number }>('GET', `/user/get/notifications${queryString}`);

        if (error || !response) {
            console.error(error);
            return rejectWithValue('Failed to fetch notifications');
        }

        return response.data;
    }
);

export const markNotificationAsViewed = createAsyncThunk(
    'notifications/markAsViewed',
    async (notificationId: number, { rejectWithValue }) => {
        const [error, response] = await API('PATCH', `/user/notifications/${notificationId}/viewed`);

        if (error) {
            return rejectWithValue('Failed to mark notification as viewed');
        }

        return notificationId;
    }
);

// Создание среза
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addLiveNotification: (state, action: PayloadAction<NotificationItem>) => {
            state.items.unshift(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.items = action.payload.notifications;
                state.total = action.payload.count;
                state.status = 'loaded';
            })
            .addCase(fetchNotifications.rejected, (state) => {
                state.status = 'error';
            })
            .addCase(markNotificationAsViewed.fulfilled, (state, action: PayloadAction<number>) => {
                const index = state.items.findIndex(notification => notification.Id === action.payload);
                if (index !== -1) {
                    state.items[index].isRead = true;
                }
            });
    },
});

export const { addLiveNotification } = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
