import React from 'react';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Spinner from '../../spinner/Spinner';

// Регистрируем компоненты, необходимые для Bar Chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface CombinedOrderStat {
    date: string;
    onlineOrderCount: number;
    onlineTotalAmount: number;
    offlineOrderCount: number;
    offlineTotalAmount: number;
}


const AdminStatistic: React.FC<{ data: CombinedOrderStat[] }> = ({ data }) => {
    
    if (!data) {
        return <Spinner/>;
    }

    const dataForChart = {
        labels: data.map(stat => stat.date),
        datasets: [
            {
                label: 'Online Orders',
                data: data.map(stat => stat.onlineOrderCount),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Offline Orders',
                data: data.map(stat => stat.offlineOrderCount),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Orders Statistics',
            },
        },
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Bar data={dataForChart} options={options} />
        </Box>
    )
}

export default AdminStatistic;
