import { Box, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface CustomBoxProps {
    icon: ReactNode;
    title: string;
    subtitle: string;
    onClick?: () => void;
    isSelected?: boolean;
}

interface StyledBoxProps {
    isSelected?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, isSelected }) => ({
    display: 'flex',
    alignItems: 'center',
    border: isSelected ? '2px solid transparent' : '2px solid #ccc',
    backgroundColor: isSelected ? '#e0e0e0' : 'white',  
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    width: '210px', 
    height: '150px',
    transition: 'transform 0.2s, box-shadow 0.2s', 

    '&:hover': {
        transform: 'scale(1.05)', 
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', 
        cursor: 'pointer' 
    }
}));


const IconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
});

const TextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const Title = styled(Typography)({
    fontSize: '1.25rem',
    fontWeight: 500,
});

const Subtitle = styled(Typography)({
    color: 'gray',
});


const CustomBox: React.FC<CustomBoxProps> = ({ icon, title, subtitle, onClick, isSelected }) => {
    return (
        <StyledBox style={{ "--is-selected": isSelected ? 1 : 0 } as any} onClick={onClick}>
            <IconContainer>
                {isSelected ? <CheckCircleIcon fontSize="large" color="primary" /> : icon}
            </IconContainer>
            <TextContainer>
                <Title variant="h6">{title}</Title>
                <Subtitle variant="body2">{subtitle}</Subtitle>
            </TextContainer>
        </StyledBox>
    );
};

export default CustomBox;
