import React, { useEffect, useState } from 'react';
import { Box, styled, Tabs, Tab, Typography } from '@mui/material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Login from '../../components/Account/Authentication/Login';
import Registration from '../../components/Account/Authentication/Registration';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import { fetchAuthMe, selectIsAuthenticated } from "../../redux/slices/auth";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import MessageDialog from '../../components/Account/Authentication/MessageDialog';
import { fetchAuth, fetchRegister } from '../../redux/slices/auth';
import CircularProgress from '@mui/material/CircularProgress';


type AuthParams = {
    authType: 'Register' | 'Login';
};

interface PayloadError {
    message: string;
    lockoutTime?: number;
    attemptsLeft?: number; 
}

interface RegistrationPayloadError {
    message: string;
    emailAlreadyUsed?: boolean;
}

export interface RegistrationData {
    Email: string;
    FullName: string;
    password: string;
    Telefon?: string;
    isSubscribedToNewsletter?: boolean;
}

const BackgroundWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '90vh',
    width: '100%',
    backgroundColor: '#f2f2f2',
    padding: '2%',
});

const CenterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '45vh', //  vh для процента от высоты экрана
    minWidth: '35vw', //  vw для процента от ширины экрана
    margin: 'auto',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    padding: '2rem',
    borderRadius: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out', 
    [theme.breakpoints.up('lg')]: {
        minHeight: '45vh', 
        minWidth: '35vw', 
    },
    [theme.breakpoints.down('md')]: {
        minHeight: '45vh', 
        minWidth: '55vw', 
    },
    [theme.breakpoints.down('sm')]: {
        minHeight: '45vh', 
        minWidth: '90vw', 
    },
}));


const TabsContainer = styled(Box)({
    width: '100%', 
    borderBottom: '1px solid #e0e0e0', 
});

const ContentBox = styled(Box)({
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2rem',
});


const Auth = () => {
    const dispatch: AppDispatch = useDispatch(); 
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { authType } = useParams<AuthParams>();
    const navigate = useNavigate();
    const [value, setValue] = useState<number>(authType === 'Register' ? 0 : 1);
    const [error, setError] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setValue(authType === 'Register' ? 0 : 1);
    }, [authType]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        const path = newValue === 0 ? '/Authentication/Register' : '/Authentication/Login';
        navigate(path);
    };

    const convertTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} minute(s) ${remainingSeconds} second(s)`;
    };

    const signIn = async (Email: string, password: string) => {
        setIsDialogOpen(false); // Сбрасываем предыдущее состояние диалога
        setError(''); // Сбрасываем предыдущие ошибки
        setIsLoading(true); // Начало загрузки
        const actionResult = await dispatch(fetchAuth({ Email, password }));
    
        if (fetchAuth.fulfilled.match(actionResult)) {
            const payload = actionResult.payload;
            if (payload && 'token' in payload) {
                window.localStorage.setItem('token', payload.token); // Сохраняем токен в localStorage
                dispatch(fetchAuthMe()).then((result) => {
                    if (!result.payload.isEmailVerified) {
                        navigate("/EmailVerification"); // Перенаправляем на страницу подтверждения email
                    } else {
                        navigate("/"); // Перенаправляем на главную страницу
                    }
                })
            } else {
                setError("Failed to login. Please try again.");
                setIsDialogOpen(true);
                setDialogTitle("Login Error");
                setDialogMessage("Failed to login. Please try again.");
            }
        } else if (fetchAuth.rejected.match(actionResult)) {
            let errorMessage = "An unexpected error occurred. Please try again later.";
            if (actionResult.payload && typeof actionResult.payload === 'object') {
                const payload = actionResult.payload as PayloadError;
                
                errorMessage = payload.message;
                if (payload.attemptsLeft !== undefined) {
                    errorMessage += ` You have ${payload.attemptsLeft} attempt(s) left.`;
                }
                if (typeof payload.lockoutTime === 'number') {
                    const timeString = convertTime(payload.lockoutTime);
                    errorMessage += ` Try again in ${timeString}.`;
                }
            } else if (actionResult.error && actionResult.error.message) {
                errorMessage = actionResult.error.message;
            }
    
            setError(errorMessage);
            setIsDialogOpen(true);
            setDialogTitle("Login Error");
            setDialogMessage(errorMessage);
        }    
        setIsLoading(false);    
    };

    const signUp = async (registrationData: RegistrationData) => {
        setIsDialogOpen(false);
        setError('');
        setIsLoading(true);
        const actionResult = await dispatch(fetchRegister(registrationData));

        if (fetchRegister.fulfilled.match(actionResult)) {
            const payload = actionResult.payload;

            if (payload && 'token' in payload) {
                window.localStorage.setItem('token', payload.token); 
                dispatch(fetchAuthMe()).then((result) => {
                    if (!result.payload.isEmailVerified) {
                        navigate("/EmailVerification"); 
                    } else {
                        navigate("/");
                    }
                });
            } else {
                // Обработка случаев, когда токен отсутствует или не является строкой
                setError("Registration was successful, but there was an issue with login. Please try logging in manually.");
                setIsDialogOpen(true);
                setDialogTitle("Registration Successful");
                setDialogMessage("Please log in using your new credentials.");
            }
            } else if (fetchRegister.rejected.match(actionResult)) {

                let errorMessage = "An unexpected error occurred. Please try again later.";
            
            // Проверяем, является ли payload объектом и содержит ли он поле 'message'
            if (actionResult.payload && typeof actionResult.payload === 'object' && 'message' in actionResult.payload) {
                const errorPayload = actionResult.payload as RegistrationPayloadError; 
                errorMessage = errorPayload.message;
                // Дополнительная логика обработки, если email уже используется
                if (errorPayload.emailAlreadyUsed) {
                    errorMessage += " Email already in use.";
                }
            } else if (actionResult.error && actionResult.error.message) {
                // Обработка других типов ошибок
                errorMessage = actionResult.error.message;
            }
    
            setError(errorMessage);
            setIsDialogOpen(true);
            setDialogTitle("Registration Error");
            setDialogMessage(errorMessage);
        }
        setIsLoading(false);        
    };

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }


    return (
        <BackgroundWrapper>

        {isLoading && (
            <Box sx={{
                position: 'fixed', 
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                zIndex: 1000, 
            }}>
                <CircularProgress />
            </Box>
        )}

            <CenterBox>
                <TabsContainer>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="auth tabs"
                        variant="fullWidth"
                        centered
                        sx={{
                            '.MuiTabs-indicator': {
                            backgroundColor: '#000',
                            },
                            '.MuiTab-root.Mui-selected': { 
                            color: '#000', 
                            },
                            '.MuiTab-root': {
                            color: 'rgba(0, 0, 0, 0.7)',
                            }
                        }}
                    >
                       <Tab
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography 
                                        variant="h3" 
                                        sx={{ 
                                            marginRight: 1, 
                                            fontSize: { xs: '1.2rem', sm: '1.35rem', md: '1.5rem' }
                                        }}
                                    >
                                        JOIN
                                    </Typography>
                                    <AppRegistrationOutlinedIcon />
                                </Box>
                            }
                        />
                        <Tab
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography 
                                        variant="h3" 
                                        sx={{ 
                                            marginRight: 1, 
                                            fontSize: { xs: '1.2rem', sm: '1.35rem', md: '1.5rem' }
                                        }}
                                    >
                                        SIGN IN
                                    </Typography>
                                    <LoginOutlinedIcon />
                                </Box>
                            }
                        />

                    </Tabs>
                </TabsContainer>
                <ContentBox>
                    {value === 0 ? <Registration register={signUp} /> : <Login signIn={signIn} />}
                </ContentBox>

                <MessageDialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    title={dialogTitle}
                    message={dialogMessage}
                />
            </CenterBox>
        </BackgroundWrapper>
    );
};

export default Auth;
