import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Box, ImageList, ImageListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ImageCarouselProps {
    onImageUpload: (files: File[]) => void;
    images: File[];
    imageUrls: string[];
    onRemoveAllImages: () => void;
    onRemoveImage: (index: number) => void; 
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
    onImageUpload,
    images,
    imageUrls,
    onRemoveAllImages,
    onRemoveImage,
}) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
    const fileInputRef = useRef<HTMLInputElement>(null);
    const combinedImages = [
        ...imageUrls.map(url => `${baseUrl}${url}`), 
        ...images.map(file => URL.createObjectURL(file))
    ];    
    const hasSelectedFoto = combinedImages.length > 0;
    const [activeIndex, setActiveIndex] = useState(0);
    const maxImages = 5;

    useEffect(() => {
        return () => {
            images.forEach(image => {
                if (typeof image === 'object' && image !== null) {
                    URL.revokeObjectURL(image.name);
                }
            });
        };
    }, [images]);

    const handleAddImageClick = () => {
        if (images.length < maxImages) {
            fileInputRef.current?.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const validFiles = Array.from(event.target.files).filter(file => file.type.startsWith('image/'));
            if (validFiles.length > 0 && images.length + validFiles.length <= maxImages) {
                onImageUpload(validFiles); 
            }
            event.target.value = ''; 
        }
    };

    const handlePrevImage = () => {
        setActiveIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : combinedImages.length - 1);
    };

    const handleNextImage = () => {
        setActiveIndex(prevIndex => (prevIndex + 1) % combinedImages.length);
    };

    const handleRemoveImage = (index: number) => {
        onRemoveImage(index);
        if (index === activeIndex) {
            setActiveIndex(prev => (prev === 0 || images.length === 1) ? 0 : prev - 1);
        } else if (index < activeIndex) {
            setActiveIndex(prev => prev - 1);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleRemoveAllImages = () => {
        onRemoveAllImages(); 
        setActiveIndex(0);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',  
            width: '100%',
            overflow: 'hidden'
        }}>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
                max={maxImages - images.length}
            />

            {hasSelectedFoto ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: 'auto',
                        padding: 1
                    }}>
                        <IconButton sx={{ 
                            border: 2, 
                            borderColor: 'primary.main', 
                            borderRadius: '50%' 
                        }}
                            onClick={handleAddImageClick} 
                            color="primary" 
                            disabled={combinedImages.length >= maxImages}
                        >
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={handleRemoveAllImages} color="error" sx={{ border: 2, borderColor: 'error.main', borderRadius: '50%' }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        flexGrow: 1, 
                        display: 'flex', 
                        flexDirection: 'row',
                        justifyContent: 'center', 
                        alignItems: 'stretch',
                        width: '100%', 
                        height: '100%',
                        overflow: 'hidden', 
                        position: 'relative',
                        gap: 2,
                        p: 2
                    }}>
                        {/* Photo List*/}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            width: 110, 
                        }}>
                            <ImageList sx={{
                                width: '100%', 
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '8px'  
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent'  
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,0.4)', 
                                    borderRadius: '10px' 
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.6)'
                                }
                            }} cols={1} rowHeight={140} gap={6}>
                                {combinedImages.map((src, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={src}
                                            alt={`Image ${index + 1}`}
                                            style={{
                                                width: '100%', 
                                                height: '100%',
                                                objectFit: 'contain',
                                                cursor: 'pointer',
                                            }}
                                            onLoad={() => src.startsWith('blob:') && URL.revokeObjectURL(src)}
                                            onClick={() => setActiveIndex(index)}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>

                        {/* Photo */}
                        <Box sx={{
                            height: '100%',
                            position: 'relative', 
                            display: 'inline-block', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                        }}>

                            <img
                                src={combinedImages[activeIndex]}
                                alt={`Selected ${activeIndex + 1}`}
                                style={{
                                    maxWidth: '450px',
                                    maxHeight: '100%',
                                    objectFit: 'contain', 
                                    width: 'auto', 
                                    height: 'auto' 
                                }}
                                onLoad={() => combinedImages[activeIndex].startsWith('blob:') && URL.revokeObjectURL(combinedImages[activeIndex])}
                            />
                            <IconButton
                                onClick={() => handleRemoveImage(activeIndex)}
                                color="error"
                                sx={{ 
                                    position: 'absolute',
                                    right: 8, 
                                    top: 8, 
                                    zIndex: 10 
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>

                            {/* Иконка "Влево" */}
                            <IconButton
                                onClick={handlePrevImage}
                                sx={{ 
                                    position: 'absolute',
                                    left: 0, 
                                    top: '50%', 
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <ArrowBackIosIcon sx={{ color: 'primary.main',fontSize: '2rem' }} />
                            </IconButton>

                            {/* Иконка "Вправо" */}
                            <IconButton
                                onClick={handleNextImage}
                                sx={{ 
                                    position: 'absolute',
                                    right: 0, 
                                    top: '50%',
                                    transform: 'translateY(-50%)' 
                                }}
                            >
                                <ArrowForwardIosIcon sx={{ color: 'primary.main',fontSize: '2rem' }} />
                            </IconButton>

                        </Box>
                    </Box>
                </Box>
            ) : (
                <IconButton
                    color="primary"
                    sx={{
                        border: '2px solid',
                        borderColor: 'primary.main',
                        borderRadius: '50%',
                        transform: 'scale(2)',
                    }}
                    onClick={handleAddImageClick}
                >
                    <AddIcon />
                </IconButton>
            )}
        </Box>
    );
}

export default ImageCarousel;

