import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { Avatar, Box, CircularProgress, Divider, FormControl, IconButton, InputAdornment, MenuItem, Paper, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material'
import { OrderStatus, fetchOrderDetails, updateOrderStatus } from '../../redux/slices/order';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ProductCardOnlineOrder from '../../components/Admin/Order/ProductCardOnlineOrder';
import { unwrapResult } from '@reduxjs/toolkit';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const stringToColor = (string: string): string => {
    let hash = 0;
    let i: number;
    for (i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
};

const stringAvatar = (name: string) => {
    return {
        children: `${name.split(' ')[0][0]}`,
        sx: {
            bgcolor: stringToColor(name),
        },
    };
};
 

const OrderDetailAdmin = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const orderDetail = useAppSelector((state: RootState) => state.orders.selectedOrder.item);
    const orderLoadingStatus = useAppSelector((state: RootState) => state.orders.selectedOrder.status);
    const [codLivrareInput, setCodLivrareInput] = useState(orderDetail?.codLivrare || '');
    const [initialCodLivrare, setInitialCodLivrare] = useState(orderDetail?.codLivrare || '');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [newStatus, setNewStatus] = useState<OrderStatus | undefined>(orderDetail?.status);
    const isSaveDisabled = codLivrareInput === '' || (codLivrareInput === initialCodLivrare && newStatus === orderDetail?.status);
    const isClearDisabled = codLivrareInput === '';

    useEffect(() => {
        if (orderId) {
          const numericId = parseInt(orderId, 10);
          if (!isNaN(numericId)) {
            dispatch(fetchOrderDetails(numericId));
          } else {
            navigate('/admin/comenzi');
          }
        } else {
          navigate('/admin/comenzi');
        }
    }, [dispatch, orderId, navigate]);

    useEffect(() => {
        if (orderDetail) {
            setCodLivrareInput(orderDetail.codLivrare || '');
            setInitialCodLivrare(orderDetail.codLivrare || '');
            setNewStatus(orderDetail.status);
        }
    }, [orderDetail]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleUpdateOrderStatus = async (status?: OrderStatus, codLivrare?: string) => {
        try {
            if (orderDetail && orderDetail.Id) {
                if ((status === 'In livrare' || status === 'Livrat') && !orderDetail.codLivrare && !codLivrare) {
                    setSnackbarMessage('Trebuie să introduceți Codul de Livrare înaintе de a actualiza la acest status.');
                    setSnackbarOpen(true);
                    setSnackbarKey(prevKey => prevKey + 1);
                    return;
                }

                const actionResult = await dispatch(updateOrderStatus({
                    orderId: orderDetail.Id,
                    status: status || orderDetail.status,
                    codLivrare: codLivrare || orderDetail.codLivrare
                }));

                const result = unwrapResult(actionResult);

                if (result.success) {
                    setSnackbarMessage('Starea comenzii a fost actualizată cu succes');
                    setSnackbarOpen(true);
                    setSnackbarKey(prevKey => prevKey + 1);
                }
            }
        } catch (error) {
            setSnackbarMessage('Eroare la actualizarea stării comenzii');
            setSnackbarOpen(true);
            setSnackbarKey(prevKey => prevKey + 1);
        }
    };

    const handleStatusChange = (event: SelectChangeEvent<OrderStatus>) => {
        const newStatus = event.target.value as OrderStatus;
        setNewStatus(newStatus);

        if (codLivrareInput !== initialCodLivrare) {
            handleUpdateOrderStatus(newStatus, codLivrareInput);
        } else {
            handleUpdateOrderStatus(newStatus);
        }
    };

    const handleSave = () => {
        handleUpdateOrderStatus(newStatus, codLivrareInput);
    };

    const handleClear = () => {
        setCodLivrareInput('');
    };

    



  return (
    <Box 
      sx={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        p: '1%',
      }}
    >
        <Paper 
            elevation={6} 
            sx={{ 
                width: '100%', 
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
            }}
        >
            {orderLoadingStatus === 'loading' && (
                <CircularProgress />
            )}
            {orderLoadingStatus === 'error' && (
                <Typography variant="h6">
                    Ne pare rău, a apărut o eroare.
                </Typography>
            )}
            {orderLoadingStatus === 'loaded' && orderDetail && (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ 
                            display: 'flex', 
                            flex: 1, 
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                        }}>
                            <Typography variant="h2">Comanda Nr: {orderDetail.Id}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CalendarTodayIcon sx={{ mr: 1 }} />
                                <Typography variant="h4">{orderDetail.createdAt}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1, gap: 2 }}>
                            <FormControl sx={{ flex: 1 }}>
                                <Select
                                    value={newStatus || orderDetail.status}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Status' }}
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <DeliveryDiningOutlinedIcon />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="Nou">Nou</MenuItem>
                                    <MenuItem value="In verificare">In verificare</MenuItem>
                                    <MenuItem value="In livrare" disabled={!codLivrareInput}>In livrare</MenuItem>
                                    <MenuItem value="Livrat" disabled={!codLivrareInput}>Livrat</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 1 }}>
                                <TextField
                                    label="Cod Livrare"
                                    value={codLivrareInput}
                                    onChange={(e) => setCodLivrareInput(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClear} disabled={isClearDisabled}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton onClick={handleSave} disabled={isSaveDisabled}>
                                                    <SaveIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', height: '100%', p: 2, overflow: 'hidden'}}>
                        <Box sx={{ flex: 7, height: '100%',  borderRight: '1px solid #ccc', p: 1 }}>
                            <Paper elevation={12} sx={{ 
                                height: '100%', 
                                maxHeight: '100%',
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'flex-start', 
                                flexWrap: 'wrap',
                                overflowY: 'auto', 
                                gap: 2, 
                                p: 2,
                                '&::-webkit-scrollbar': {
                                    width: '8px', 
                                    height: '8px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888', 
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#555',
                                }
                            }}>
                                {orderDetail.orderDetails?.map(detail => (
                                    <ProductCardOnlineOrder key={detail.Id} orderDetail={detail} />
                                ))}
                            </Paper>
                        </Box>
                        <Box sx={{ flex: 3, height: '100%', p: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Paper elevation={12} sx={{ 
                                flex: 1, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                            }}>

                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '100%',
                                    py: 2,
                                }}>
                                    <Typography variant="h3" sx={{ p: 1 }}>Cumparator</Typography>
                                    <Divider sx={{ width: '100%' }} />
                                </Box>

                                <Box sx={{
                                    flex: 1,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', alignItems: 'flex-start', mb: 2, px: 4, gap: 4 }}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}>
                                            <Avatar sx={{ width: 80, height: 80, ...stringAvatar(orderDetail.User?.FullName || orderDetail.User?.Email || '').sx }}>
                                                {stringAvatar(orderDetail.User?.FullName || orderDetail.User?.Email || '').children}
                                            </Avatar>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: 2,
                                            flex: 1,
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <PersonIcon sx={{ mr: 1 }} />
                                                <Typography variant="h4">{orderDetail.User?.FullName}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <EmailIcon sx={{ mr: 1 }} />
                                                <Typography variant="h4">{orderDetail.User?.Email}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <PhoneIcon sx={{ mr: 1 }} />
                                                <Typography variant="h4">{orderDetail.deliveryPhone}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Divider sx={{ width: '100%', mb: 2 }} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: "flex-start",
                                        width: '100%',
                                        px: 4,
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <PublicIcon sx={{ mr: 1 }} />
                                            <Typography variant="h4">Țara: {orderDetail.deliveryCountry}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <LocationCityIcon sx={{ mr: 1 }} />
                                            <Typography variant="h4">Oraș: {orderDetail.deliveryCity}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <LocationOnIcon sx={{ mr: 1 }} />
                                            <Typography variant="h4">Stradă: {orderDetail.deliveryAddress}</Typography>
                                        </Box>
                                        {orderDetail.deliveryPostalCode && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                                <MarkunreadMailboxIcon sx={{ mr: 1 }} />
                                                <Typography variant="h4">Cod Poștal: {orderDetail.deliveryPostalCode}</Typography>
                                            </Box>
                                        )}
                                    </Box>

                                </Box>

                            </Paper>

                            <Paper elevation={12} sx={{ 
                                flex: 1, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                            }}>
                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '100%',
                                    py: 2,
                                }}>
                                    <Typography variant="h3" sx={{ mr: 1 }}>Tip livrare:</Typography>
                                    <Typography variant="h3">{orderDetail.deliveryType.name}</Typography>
                                </Box>

                                <Divider sx={{ width: '100%' }} />

                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '100%',
                                    py: 2,
                                }}>
                                    <Typography variant="h3" sx={{ mr: 1 }}>Metoda de plata:</Typography>
                                    <Typography variant="h3">{orderDetail.paymentType.name}</Typography>
                                </Box>

                                <Divider sx={{ width: '100%' }} />

                                <Box sx={{
                                    flex: 1, 
                                    width: '100%',
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    p: 2,
                                }}>
                                    <Typography variant="h3" sx={{ mb: 2, textAlign: 'center' }}>Sumar comanda</Typography>
                                    <Box sx={{ 
                                        flex: 1, 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center'
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            width: '100%', 
                                            mb: 1 
                                        }}>
                                            <Typography variant="h4">Pret Produse:</Typography>
                                            <Typography variant="h4">{orderDetail.originalPrice.toFixed(2)} Lei</Typography>
                                        </Box>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            width: '100%', 
                                            mb: 1 
                                        }}>
                                            <Typography variant="h4">Pret Livrare:</Typography>
                                            <Typography variant="h4">{orderDetail.deliveryType.price.toFixed(2)} Lei</Typography>
                                        </Box>
                                        {orderDetail.discount && orderDetail.discountAmount && (
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between', 
                                                width: '100%', 
                                                mb: 1 
                                            }}>
                                                <Typography variant="h4" color="error">Reducere:</Typography>
                                                <Typography variant="h4" color="error">
                                                    -{orderDetail?.discount?.discount_percentage}% ({orderDetail.discountAmount.toFixed(2)} Lei)
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            width: '100%', 
                                            mb: 1 
                                        }}>
                                            <Typography variant="h4">Pret Final:</Typography>
                                            <Typography variant="h4">{orderDetail.finalCost.toFixed(2)} Lei</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            )}
            
        </Paper>
        
        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    </Box>
  )
}

export default OrderDetailAdmin