import { useState, useEffect } from 'react';
import { Box, Button, IconButton, Paper, Typography, useMediaQuery } from "@mui/material"
import { DataGrid, GridColDef, GridRowId, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { fetchAssignedOrders } from '../../../redux/slices/order';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { useNavigate } from 'react-router-dom';
import StaffOrderListFilter from '../filtres/StaffOrderListFilter';
import { FetchAssignedOrdersParams, FilterParams } from '../../../redux/slices/order';


interface StaffOrdersListProps {
    userId: number;
  }

const StaffOrdersList: React.FC<StaffOrdersListProps> = ({ userId }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const orders = useSelector((state: RootState) => state.orders.orders.items); 
    const orderStatus = useAppSelector((state: RootState) => state.orders.orders.status);
    const Count = useAppSelector((state: RootState) => state.orders.orders.count);
    const isDataGridLoading = orderStatus === 'loading';
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
        }); 
    const [checkState, setCheckState] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
    const [filters, setFilters] = useState<FilterParams>({
        searchTerm: '',
        dateFrom: '',
        dateTo: '',
        status: undefined,
        sortByPrice: undefined,
    });


    useEffect(() => {
        // Создание объекта параметров запроса
        const queryObject: Record<string, string> = {
            page: (paginationModel.page + 1).toString(),
            perPage: paginationModel.pageSize.toString(),
        };
    
        // Добавление фильтров к объекту запроса, если они не пусты
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                queryObject[key] = value.toString();
            }
        });
    
        // Создание строки запроса
        const queryParams = new URLSearchParams(queryObject).toString();
    
        dispatch(fetchAssignedOrders({ userId, queryString: queryParams }));
    }, [dispatch, paginationModel, userId, filters]);
    
    const handleApplyFilters = (newFilters: FilterParams) => {
        setFilters(newFilters);
    };
    
    const handleResetFilters = () => {
        setFilters({
          searchTerm: '',
          dateFrom: '',
          dateTo: '',
          status: undefined,
          sortByPrice: undefined,
        });
    };

    const handleViewOrderDetails = (orderId: number) => {
        navigate(`/admin/OrderDetailAdmin/${orderId}`);
    };

    const columns: GridColDef[] = [
        {
            field: 'Comanda',
            headerName: 'Comanda Nr.',
            flex: 1,
        },
        {
            field: 'User',
            headerName: 'Utilizator',
            flex: 1,
        },
        {
            field: 'Date',
            headerName: 'Data',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                // Предполагается, что дата уже в формате, пригодном для отображения
                const formattedDateTime = params.row.Date ? params.row.Date : '-';
        
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start', 
                        }}
                    >
                        <Typography variant='h4' sx={{ textAlign: 'left' }}>{formattedDateTime}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'Cost',
            headerName: 'Totalul comenzii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.Cost.toLocaleString('ro-RO')} Lei
                    </Typography>
                );
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
                let color;
                switch (params.row.Status) {
                    case 'Nou':
                        color = 'red';
                        break;
                    case 'In verificare':
                        color = 'brown';
                        break;
                    case 'In livrare':
                        color = 'orange';
                        break;
                    case 'Livrat':
                        color = 'green';
                        break;
                    default:
                        color = 'black'; 
                }
    
                return (
                    <Typography variant='h4' style={{ color }}>
                        {params.row.Status}
                    </Typography>
                );
            },
        },
        {
            field: 'assignedUser',
            headerName: 'Raspunzator',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => {
                // Check if assignedUser exists before trying to access its properties
                if (params.row.assignedUser) {
                    return (
                        <Typography variant='h4'>
                            {params.row.assignedUser.FullName}
                        </Typography>
                    );
                } else {
                    // Return a placeholder or a default value if assignedUser is null
                    return (
                        <Typography variant='h4'>
                            Not Assigned
                        </Typography>
                    );
                }
            },
        }, 
        {
            field: 'CodLivrare',
            headerName: 'Cod Livrare',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <Typography variant='h4'>
                        {params.row.CodLivrare || '-'} 
                    </Typography>
                );
            },
        },
        {
            field: 'Detalii',
            headerName: 'Detalii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
                const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); 
                    handleViewOrderDetails(params.row.id);
                };
        
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleViewClick}
                        >
                            Vezi Comanda
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const rows = orders.map((order, index) => ({
        id: order.Id,
        Comanda: order.Id, 
        User: order.userId, 
        Date: order.createdAt, 
        Cost: order.finalCost, 
        Status: order.status, 
        CodLivrare: order.codLivrare,
        assignedUser: order.assignedUser,
    }));

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

  return (
        <Paper elevation={3} sx={{ padding: 2, width: '100%',  height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StaffOrderListFilter
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                    initialFilters={filters}
                />
            </Box>
            <Box sx={{ flexGrow: 1, height: 0 }}>
                <DataGrid 
                    paginationMode="server"
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: perPage,
                                page: page
                            },
                        },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    rowCount={Count}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    checkboxSelection={checkState}
                    onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                        setRowSelectionModel(newSelectionModel as string[]);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    loading={isDataGridLoading}
                    sx={{ fontSize: '22px' }}
                />
            </Box>
        </Paper>
  )
}

export default StaffOrdersList