import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Discount } from './discount';
import { API } from '../service';

export interface User {
  id: number;
  FullName: string;
  Email: string;
  Telefon: string;
  isVerified: boolean;
  createdAt: string;
  updatedAt: string;
  avatarUrl: string;
  isSubscribedToNewsletter: boolean;
  hasDiscount: boolean;
  discounts: Discount[];
  totalFinalCost: number;
}

export interface UserEmailInfo {
  id: number;
  Email: string;
}

export interface UserDetails extends Omit<User, 'discounts' | 'totalFinalCost'> {}


interface UserState {
  items: User[],
  emailItems: UserEmailInfo[],
  selectedUser: UserDetails | null,
  status: 'loading' | 'loaded' | 'error',
  count: number;
}

const initialState: UserState = {
  items: [],
  emailItems: [],
  selectedUser: null,
  status: 'loading',
  count: 0,
};

interface UsersRequestParams {
  page: number;
  perPage: number;
}

export const fetchUsers = createAsyncThunk<{ count: number, users: User[] }, UsersRequestParams, { rejectValue: string }>(
  'users/fetchUsers',
  async ({ page, perPage }, { rejectWithValue }) => {
    const [error, response] = await API<{ count: number, users: User[] }>('GET', '/auth/users', null, {
      page,
      perPage,
    });

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to fetch users');
    }

    return response.data;
  }
);

export const fetchUsersEmails = createAsyncThunk<UserEmailInfo[], { search?: string }, { rejectValue: string }>(
  'users/fetchUsersEmail',
  async ({ search = '' }, { rejectWithValue }) => {
    const [error, response] = await API<UserEmailInfo[]>('GET', '/auth/users/email', null, { search });

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to fetch user emails');
    }

    return response.data;
  }
);

export const fetchUserDetails = createAsyncThunk<UserDetails, number, { rejectValue: string }>(
  'users/fetchUserDetails',
  async (userId, { rejectWithValue }) => {
    const [error, response] = await API<UserDetails>('GET', `/auth/users/details/${userId}`);

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to fetch user details');
    }

    return response.data;
  }
);


const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearSelectedUser(state) {
      state.selectedUser = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<{ count: number; users: User[] }>) => {
        state.status = 'loaded';
        state.items = action.payload.users;
        state.count = action.payload.count;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchUsersEmails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersEmails.fulfilled, (state, action: PayloadAction<UserEmailInfo[]>) => {
        state.status = 'loaded';
        state.emailItems = action.payload;
      })
      .addCase(fetchUsersEmails.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<UserDetails>) => {
        state.status = 'loaded';
        state.selectedUser = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const { clearSelectedUser } = usersSlice.actions;
export const userReducer = usersSlice.reducer;
