import React, { useEffect, useState } from 'react'
import { Box, IconButton, Skeleton, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector, RootState } from '../../../redux/store';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { addToFavorites, fetchLastAddedProducts, removeFromFavorites } from '../../../redux/slices/produse';
import ProductCard from '../ProductsList/ProductCard';
import ProductCardSkeleton from '../ProductsList/ProductCardSkeleton';
import { selectIsAuthenticated } from '../../../redux/slices/auth';
import { unwrapResult } from '@reduxjs/toolkit';

const HomeSection3 = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const products = useAppSelector((state: RootState) => state.products.lastAdded.items);
    const count = useAppSelector((state: RootState) => state.products.lastAdded.count);
    const status = useAppSelector((state: RootState) => state.products.lastAdded.status);
    const [scrollIndex, setScrollIndex] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:799px)');
    const isMediumScreen = useMediaQuery('(min-width:800px) and (max-width:1249px)');
    const isLargeScreen = useMediaQuery('(min-width:1250px) and (max-width:1599px)');
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    let visibleItems = 1;
    if (isMediumScreen) visibleItems = 2;
    if (isLargeScreen) visibleItems = 3;
    if (isExtraLargeScreen) visibleItems = 4;


    useEffect(() => {
        dispatch(fetchLastAddedProducts());
    }, [dispatch]);

    useEffect(() => {
        setScrollIndex(0);
    }, [visibleItems]);

    const handleScrollLeft = () => {
        setScrollIndex((prevIndex) => Math.max(prevIndex - visibleItems, 0));
    };
    
    const handleScrollRight = () => {
        setScrollIndex((prevIndex) => Math.min(prevIndex + visibleItems, products.length - visibleItems));
    };
    

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleAddToFavorites = async (productId: number) => {
        if (!isAuthenticated) {
            alert('Please log in to add products to favorites');
            return;
        }
        try {
            const actionResult = await dispatch(addToFavorites({ productId }));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost adaugat in favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparaut o eroare in procesul de adauagre in faovorite.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveFromFavorites = async (productId: number) => {
        try {
            const actionResult = await dispatch(removeFromFavorites(productId));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
            setSnackbarOpen(true);
        }
    };
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: '1%', alignItems: 'center' }}>
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2, 
            width: '100%' ,
            gap: 2,
            px: 1
        }}>
            {isSmallScreen && (
              <IconButton 
                onClick={handleScrollLeft} 
                disabled={scrollIndex === 0}
                sx={{
                    backgroundColor: '#000', 
                    color: '#fff', 
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    },
                    '&:disabled': {
                        backgroundColor: '#cccccc'
                    }
                }}
              >
                <KeyboardArrowLeftIcon fontSize='large' />
              </IconButton>
            )}
            <Box sx={{
                backgroundColor: 'black', 
                color: 'white', 
                padding: '20px 40px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', 
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
              <Typography variant={isSmallScreen || isMediumScreen ? 'h3' : 'h2'}>
                Produse Noi
              </Typography>
            </Box>
            {isSmallScreen && (
              <IconButton 
                onClick={handleScrollRight} 
                disabled={scrollIndex >= products.length - visibleItems}
                sx={{
                    backgroundColor: '#000', 
                    color: '#fff', 
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    },
                    '&:disabled': {
                        backgroundColor: '#cccccc'
                    }
                }}
              >
                <KeyboardArrowRightIcon fontSize='large' />
              </IconButton>
            )}
            {!isSmallScreen && (
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
              }}>
                <IconButton 
                  onClick={handleScrollLeft} 
                  disabled={scrollIndex === 0}
                  sx={{
                      backgroundColor: '#000', 
                      color: '#fff', 
                      '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.8)'
                      },
                      '&:disabled': {
                          backgroundColor: '#cccccc'
                      }
                  }}
                >
                  <KeyboardArrowLeftIcon fontSize='large' />
                </IconButton>
                <IconButton 
                  onClick={handleScrollRight} 
                  disabled={scrollIndex >= products.length - visibleItems}
                  sx={{
                      backgroundColor: '#000', 
                      color: '#fff', 
                      '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.8)'
                      },
                      '&:disabled': {
                          backgroundColor: '#cccccc'
                      }
                  }}
                >
                  <KeyboardArrowRightIcon fontSize='large' />
                </IconButton>
              </Box>
            )}
        </Box>

        <Box sx={{ overflow: 'hidden', width: '100%', display: 'flex', justifyContent: 'center', p: '1%' }}>
            {status === 'loading' || count === 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        minWidth: '100%',
                    }}
                >
                    {[...Array(visibleItems)].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: `0 0 ${100 / visibleItems}%`,
                                boxSizing: 'border-box',
                                p: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <ProductCardSkeleton key={index} />
                        </Box>
                    ))}
                </Box>
                ) : (
                <Box
                    sx={{
                        display: 'flex',
                        transition: 'transform 0.5s ease-in-out',
                        transform: `translateX(-${scrollIndex * (100 / visibleItems)}%)`,
                        minWidth: `calc(100% + 1%)`, 
                    }}
                >
                    {products.map((product, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: `0 0 ${100 / visibleItems}%`,
                                boxSizing: 'border-box',
                                p: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <ProductCard
                                product={product}
                                handleAddToFavorites={handleAddToFavorites}
                                handleRemoveFromFavorites={handleRemoveFromFavorites}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
        
        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    </Box>
  )
}

export default HomeSection3