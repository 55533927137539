import React, { useEffect, useState } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { Box, IconButton,  Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { DataGrid, GridColDef, GridRowId, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';
import Spinner from '../../spinner/Spinner';
import LaunchIcon from '@mui/icons-material/Launch';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getAllMessages, createMessage, updateMessage, updateHiddenState } from '../../../redux/slices/message';
import DialogMessage from './DialogMessage';
import DialogMessageMobile from './DialogMessageMobile';

export interface MessageProps {
  ParentMessageId: number,
  RepliesMessageId: number,
  PostId: number,
  user: string,
  message: string,
  answer: string,
  ProductTitle?: String;
}

const QuestionProductAdmin = () => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector((state: RootState) => state.message.items);
    const CountMessages = useAppSelector((state: RootState) => state.message.count);
    const messagesStatus = useAppSelector((state: RootState) => state.message.status);
    const isDataGridLoading = messagesStatus === 'loading';
    const [checkState, setCheckState] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
    });  
    const [selectedDetails, setSelectedDetails] = useState<MessageProps>({
      ParentMessageId: 0,
      RepliesMessageId: 0,
      PostId: 0,
      user: '',
      message: '',
      answer: '',
    });
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [isReplyMode, setIsReplyMode] = useState(false);
    const [answerText, setAnswerText] = useState('');
    const isMobile = useMediaQuery('(max-width: 1000px)');
    const [openMobileDetailsDialog, setOpenMobileDetailsDialog] = useState(false);
    const isAnswered = selectedDetails.answer.trim() !== '';

    useEffect(() => {
      dispatch(getAllMessages({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
    }, [dispatch, paginationModel]);
    
    if (messagesStatus === 'loading') {
      return <Spinner />;
    }

  const columns: GridColDef[] = [
    {
      field: 'User',
      headerName: 'Utilizator',
      flex: 2,
    },
    {
      field: 'ProductTitle',
      headerName: 'Produs',
      flex: 2,
    },
    {
      field: 'createdAt',
      headerName: 'Creat',
      flex: 2,
    },
    {
      field: 'Info',
      headerName: 'Info',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        const handleInfoClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          handleInfoButtonClick(params.row);
        };
  
        const isHidden = params.row.isHidden; 
  
        const handleVisibilityClick = (event: React.MouseEvent) => {
          event.stopPropagation(); 
          handleVisibilityToggle(params.row);
        };
  
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={handleInfoClick}
              sx={{ color: 'blue', cursor: 'pointer' }}
            >
              <LaunchIcon />
            </IconButton>
            {params.row.Replies.length > 0 ? (
              <Tooltip title="Cu raspuns" arrow>
                <span>
                  <IconButton disabled sx={{ color: 'green', cursor: 'not-allowed' }}>
                    <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Fara raspuns" arrow>
                <span>
                  <IconButton disabled sx={{ color: 'orange', cursor: 'not-allowed' }}>
                    <QuestionMarkIcon sx={{ color: 'orange' }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
  
            {/* VisibilityOnIcon if isHidden is false, VisibilityOffIcon if isHidden is true */}
            {isHidden ? (
              <Tooltip title="Hidden" arrow>
                <span>
                  <IconButton
                    onClick={handleVisibilityClick}
                    sx={{ color: 'red', cursor: 'pointer' }}
                  >
                    <VisibilityOffIcon sx={{ color: 'red' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Visible" arrow>
                <span>
                  <IconButton
                    onClick={handleVisibilityClick}
                    sx={{ color: 'green', cursor: 'pointer' }}
                  >
                    <VisibilityIcon sx={{ color: 'green' }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const MobileColumns: GridColDef[] = [
    {
      field: 'User',
      headerName: 'Utilizator',
      flex: 2,
    },
    {
      field: 'Info',
      headerName: 'Info',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        const handleInfoClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          handleMobileDetailsClick(params.row);
        };
  
        const isHidden = params.row.isHidden; 
  
        const handleVisibilityClick = (event: React.MouseEvent) => {
          event.stopPropagation(); 
          handleVisibilityToggle(params.row);
        };
  
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={handleInfoClick}
              sx={{ color: 'blue', cursor: 'pointer' }}
            >
              <LaunchIcon />
            </IconButton>
            {params.row.Replies.length > 0 ? (
              <Tooltip title="Cu raspuns" arrow>
                <span>
                  <IconButton disabled sx={{ color: 'green', cursor: 'not-allowed' }}>
                    <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Fara raspuns" arrow>
                <span>
                  <IconButton disabled sx={{ color: 'orange', cursor: 'not-allowed' }}>
                    <QuestionMarkIcon sx={{ color: 'orange' }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
  
            {/* VisibilityOnIcon if isHidden is false, VisibilityOffIcon if isHidden is true */}
            {isHidden ? (
              <Tooltip title="Hidden" arrow>
                <span>
                  <IconButton
                    onClick={handleVisibilityClick}
                    sx={{ color: 'red', cursor: 'pointer' }}
                  >
                    <VisibilityOffIcon sx={{ color: 'red' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Visible" arrow>
                <span>
                  <IconButton
                    onClick={handleVisibilityClick}
                    sx={{ color: 'green', cursor: 'pointer' }}
                  >
                    <VisibilityIcon sx={{ color: 'green' }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const rowsMessage = messages.map((message, index) => {
    const replyIds = message.Replies?.map(reply => reply.id) || [];

    return {
      id: index,
      PostId: message.Post?.id || 0,
      MessageId: message.Id || 0,
      RepliesMessageIds: replyIds,
      User: message.User?.FullName || '',
      ProductTitle: message.Post?.title || '',
      createdAt: message.createdAt || '',
      Replies: message.Replies || [],
      text: message.text || '',
      isHidden: message.isHidden,
    };
  });

  const handleInfoButtonClick = (row: any) => {
    setSelectedDetails({
      ParentMessageId: row.MessageId,
      RepliesMessageId: row.RepliesMessageIds,
      PostId: row.PostId,
      user: row.User,
      message: row.text,
      answer: row.Replies.length > 0 ? row.Replies[0].text : '',
      ProductTitle: row.ProductTitle
    });
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setIsReplyMode(false);
    setAnswerText('');
  };

  const MyCustomToolbar = () => {
    return (
      <GridToolbarContainer>
        {isMobile ? (
          <GridToolbarColumnsButton
            style={{ fontSize: '15px' }}
          />
        ) : (
          <>
            <GridToolbarColumnsButton
              style={{ fontSize: '15px' }}
            />
            <GridToolbarFilterButton
              style={{ fontSize: '15px' }}
            />
            <GridToolbarDensitySelector
              style={{ fontSize: '15px' }}
            />
          </>
        )}
      </GridToolbarContainer>
    );
  };

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }; 

  const handleReplyButtonClick = () => {
    setIsReplyMode(true);
  };

  const handleCancelReply = () => {
    setIsReplyMode(false);
    setAnswerText('');
  };

  const handleSaveReply = async () => {
    try {
      const postId = selectedDetails.PostId;
      const parentMessageId = selectedDetails.ParentMessageId;
      const replyMessageId = selectedDetails.RepliesMessageId;
  
      if (isReplyMode) {
        // If there is already a reply, update the existing one
        if (selectedDetails.answer.trim() !== '') {
          await dispatch(updateMessage({ messageId: replyMessageId, text: answerText }));
        } else {
          // If there is no reply, create a new one
          await dispatch(createMessage({ postId, text: answerText, isAnswer: true, parentMessageId }));
        }
      }
  
      setIsReplyMode(false);
      setAnswerText('');
      isMobile ? handleCloseMobileDetailsDialog() : handleCloseDetailsDialog();
  
      setTimeout(() => {
        dispatch(
          getAllMessages({
            page: paginationModel.page + 1,
            perPage: paginationModel.pageSize,
          })
        );
      }, 500);
      } catch (error) {
        console.error('Error saving answer:', error);
      }
  };

  const handleEditAnswer = () => {
    setAnswerText(selectedDetails.answer);
    setIsReplyMode(true);
  };

  const handleVisibilityToggle = async (row: any) => {
    try {
      // Toggle the isHidden status
      const updatedIsHidden = !row.isHidden;
  
      // Dispatch the updateHiddenState action
      await dispatch(updateHiddenState({ messageId: row.MessageId, isHidden: updatedIsHidden }));
  
      // Optionally, you can refresh the messages after updating the hidden state
      dispatch(getAllMessages({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
  
    } catch (error) {
    }
  };

  {/* Mobile */}
  const handleMobileDetailsClick = (row: any) => {
    setSelectedDetails({
      ParentMessageId: row.MessageId,
      RepliesMessageId: row.RepliesMessageIds,
      PostId: row.PostId,
      user: row.User,
      message: row.text,
      answer: row.Replies.length > 0 ? row.Replies[0].text : '',
      ProductTitle: row.ProductTitle
    });
    setOpenMobileDetailsDialog(true);
  };

  const handleCloseMobileDetailsDialog = () => {
      setOpenMobileDetailsDialog(false);
      setIsReplyMode(false);
      setAnswerText('');
  };

      return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            height: '90vh',
            p: isMobile ? 0 : "3%",
            width: '100%'
        }}>
            {/* HEADER */}
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} variant="h2" id="tableTitle" component="div">
                    Intrebari
                </Typography>
            </Toolbar>

            <Box style={{ height: "100%", width: '100%' }}>
              <DataGrid
                paginationMode="server"
                rows={rowsMessage}
                columns={ isMobile ? MobileColumns : columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: perPage,
                        page: page
                    },
                    },
                }}
                pageSizeOptions={[10, 15, 25]}
                rowCount={CountMessages}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                checkboxSelection={isMobile ? false : checkState}
                onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                    setRowSelectionModel(newSelectionModel as string[]);
                }}
                rowSelectionModel={rowSelectionModel}
                loading={isDataGridLoading}
                slots={{
                    toolbar: MyCustomToolbar,
                }}
                sx={{ fontSize: '18px' }}
              />
            </Box>
            
           {/* Dialog for Question Details */}
           {isMobile ? (
            <DialogMessageMobile
              open={openMobileDetailsDialog}
              handleClose={handleCloseMobileDetailsDialog}
              isAnswered={isAnswered}
              handleEditAnswer={handleEditAnswer}
              handleReplyButtonClick={handleReplyButtonClick}
              isReplyMode={isReplyMode}
              answerText={answerText}
              setAnswerText={setAnswerText}
              handleSaveReply={handleSaveReply}
              handleCancelReply={handleCancelReply}
              selectedDetails={selectedDetails}
            />
          ) : (
              <DialogMessage
                open={openDetailsDialog}
                handleClose={handleCloseDetailsDialog}
                isAnswered={isAnswered}
                handleEditAnswer={handleEditAnswer}
                handleReplyButtonClick={handleReplyButtonClick}
                isReplyMode={isReplyMode}
                answerText={answerText}
                setAnswerText={setAnswerText}
                handleSaveReply={handleSaveReply}
                handleCancelReply={handleCancelReply}
                selectedDetails={selectedDetails}
              />
            )}

        </Box>
    );
}

export default QuestionProductAdmin