import React, { useState } from 'react';
import { Box, CircularProgress, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { Role } from '../../../redux/slices/roles';

interface AddNewUserProps {
    roles: Role[];
    loading: boolean;
    onSelectRole: (roleId: number) => void; 
    selectedRoleId: number | null;
}

const scrollbarStyles = {
    '&::-webkit-scrollbar': { width: '4px' },
    '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 5px grey', borderRadius: '10px' },
    '&::-webkit-scrollbar-thumb': { background: 'gray', borderRadius: '10px' },
    '&::-webkit-scrollbar-thumb:hover': { background: '#b3b3b3' },
};


const AddNewUser: React.FC<AddNewUserProps> = ({ roles, loading, onSelectRole, selectedRoleId }) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: 1, p: 1 }}>
    
            <Paper elevation={3} sx={{ flex: 1, overflow: 'auto', ...scrollbarStyles }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {roles.map((role) => (
                            <ListItem 
                                key={role.id} 
                                button 
                                onClick={() => onSelectRole(role.id)}
                                sx={{
                                    backgroundColor: selectedRoleId === role.id ? 'lightgreen' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'lightgray',
                                    },
                                }}
                            >
                                <ListItemText 
                                    primary={<Typography variant='h4'>{`${role.name} --- (Level: ${role.level})`}</Typography>} 
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>
            
        </Box>
      );
    };

export default AddNewUser