import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { API } from "../service";

export interface UserWithRole {
  userId: number;
  fullName: string;
  email: string;
  telefon: string;
  roleName: string;
  accessLevel: number;
  isActive: boolean;
}

export interface UserRoleCreateParams {
    userId: number;
    roleId: number;
}

interface ErrorResponse {
  message: string;
}

interface UserRoleResponse {
    message: string;
    userRole?: any;
}

interface UserRoleState {
    createUserStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    createUserError: string | null;
    message: string | null;
    usersWithRoles: UserWithRole[];
    fetchUsersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    fetchUsersError: string | null;
}
  
const initialState: UserRoleState = {
    createUserStatus: 'idle',
    createUserError: null,
    message: null,
    usersWithRoles: [],
    fetchUsersStatus: 'idle',
    fetchUsersError: null,
};

export const createUserRole = createAsyncThunk<UserRoleResponse, UserRoleCreateParams>(
    'userRole/create',
    async (params: UserRoleCreateParams, { rejectWithValue }) => {
      const [error, response] = await API<UserRoleResponse>('POST', '/role/create', params);
  
      if (error) {
        // Обработка ошибки, если ошибка связана с ответом сервера
        if ((error as AxiosError).response) {
          return rejectWithValue((error as AxiosError).response?.data);
        }
        // Для других типов ошибок возвращаем общее сообщение об ошибке
        return rejectWithValue('An unexpected error occurred');
      }
  
      return response?.data;
    }
);

export const fetchUsersWithRoles = createAsyncThunk<UserWithRole[], undefined, { rejectValue: string }>(
  'userRole/fetchUsersWithRoles',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<UserWithRole[]>('GET', '/roles/users');

    if (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue(axiosError.response?.data.message || 'An unexpected error occurred');
    }

    if (!response) {
      return rejectWithValue('No response received from server');
    }

    return response.data;
  }
);




const userRoleSlice = createSlice({
    name: 'userRole',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(createUserRole.pending, (state) => {
          state.createUserStatus = 'loading';
          state.createUserError = null;
        })
        .addCase(createUserRole.fulfilled, (state, action: PayloadAction<UserRoleResponse>) => {
          state.createUserStatus = 'succeeded';
          state.message = action.payload.message;
          if (action.payload.userRole) {
              state.usersWithRoles.push(action.payload.userRole);
          }
        })
        .addCase(createUserRole.rejected, (state, action) => {
          state.createUserStatus = 'failed';
          state.createUserError = action.payload as string; 
        })
        .addCase(fetchUsersWithRoles.pending, (state) => {
          state.fetchUsersStatus = 'loading';
        })
        .addCase(fetchUsersWithRoles.fulfilled, (state, action: PayloadAction<UserWithRole[]>) => {
          state.fetchUsersStatus = 'succeeded';
          state.usersWithRoles = action.payload;
        })
        .addCase(fetchUsersWithRoles.rejected, (state, action: PayloadAction<string | undefined>) => {
          state.fetchUsersStatus = 'failed';
          state.fetchUsersError = action.payload ?? 'An error occurred';
        });
    },
});
  
export const selectCreateUserStatus = (state: { userRole: UserRoleState }) => state.userRole.createUserStatus;
export const selectCreateUserError = (state: { userRole: UserRoleState }) => state.userRole.createUserError;
export const selectCreateUserMessage = (state: { userRole: UserRoleState }) => state.userRole.message;
export const selectUsersWithRoles = (state: { userRole: UserRoleState }) => state.userRole.usersWithRoles;
export const selectFetchUsersStatus = (state: { userRole: UserRoleState }) => state.userRole.fetchUsersStatus;
export const selectFetchUsersError = (state: { userRole: UserRoleState }) => state.userRole.fetchUsersError;


export default userRoleSlice.reducer;