import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Avatar, Box, Divider } from '@mui/material';
import { OfflineOrderDetailWithoutId } from '../../../../redux/slices/orderoffline';
import { Color } from '../../../../redux/slices/color';
import { Size } from '../../../../redux/slices/size';
import { Product } from '../../../../redux/slices/productAdmin';

interface SelectedProductInfoDialogProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number | null;
  selectedProducts: Product[];
  colors: Color[];
  sizes: Size[];
  selectedProductDetails: OfflineOrderDetailWithoutId[]; 
}

const SelectedProductInfoDialog: React.FC<SelectedProductInfoDialogProps> = ({ 
  isOpen, 
  onClose,
  selectedProducts,
  productId,
  colors,
  sizes,
  selectedProductDetails,
}) => {
  const selectedProduct = selectedProducts.find(product => product.id === productId);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
  const productDetails = selectedProductDetails.filter(detail => detail.productId === productId);

  // Функция для поиска названия цвета и размера по их ID
  const getColorName = (colorId: number) => colors.find(color => color.id === colorId)?.name || 'Unknown';
  const getSizeName = (sizeId: number) => sizes.find(size => size.id === sizeId)?.name || 'Unknown';

  const calculatePrice = (detail: OfflineOrderDetailWithoutId) => {
    // Проверяем, есть ли активная скидка на товар и рассчитываем цену с учетом скидки
    const pricePerUnit = selectedProduct && selectedProduct.hasActiveDiscount && selectedProduct.discount
      ? selectedProduct.price - (selectedProduct.price * selectedProduct.discount.percentage / 100)
      : selectedProduct ? selectedProduct.price : 0;
  
    // Умножаем цену за единицу на количество
    return pricePerUnit * detail.quantity;
  };
  
  // Рассчет общей стоимости только для выбранного товара
  const totalPrice = productDetails.reduce((acc, detail) => acc + calculatePrice(detail), 0);
  
  

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          maxWidth: "80vw",
          height: "90vh",
          overflow: "hidden",
          p: 1,
        }
      }}
    >
      <DialogTitle>
        <Typography variant='h3'>
          {selectedProduct ? `Detalii pentru: ${selectedProduct.title}` : "Product Details"}
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
        }}>
          <Box sx={{
            flex: 3, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
          }}>
            <Avatar sx={{ width: '100%', height: 'auto', borderRadius: '10%' }} 
                src={selectedProduct && selectedProduct.imageUrl.length > 0 ? `${baseUrl}${selectedProduct.imageUrl[0]}` : undefined} 
            />
          </Box>
          <Box sx={{
            flex: 7,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            padding: 2,
          }}>
            {productDetails.map((detail, index) => (
              <Typography variant='h3' key={index}>
                {`Combinația ${index + 1}: Culoare - ${
                  detail.colorId ? getColorName(detail.colorId) : "N/A"
                }, Marime - ${detail.sizeId ? getSizeName(detail.sizeId) : "N/A"}, Cantitate - ${detail.quantity}`}
              </Typography>
            ))}
            <Divider sx={{ my: 2 }}/>

            <Box sx={{ mt: 2, gap: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {selectedProduct && selectedProduct.hasActiveDiscount && (
                <Typography variant='h3' sx={{ textDecoration: 'line-through' }}>
                  Pret Vechi: {selectedProduct.price.toFixed(2)} Lei
                </Typography>
              )}
              <Typography variant='h3' sx={{ color: selectedProduct?.hasActiveDiscount ? 'red' : 'inherit' }}>
                Pret per unitate: {(selectedProduct && selectedProduct.hasActiveDiscount && selectedProduct.discount
                  ? (selectedProduct.price - (selectedProduct.price * selectedProduct.discount.percentage / 100)).toFixed(2)
                  : selectedProduct?.price.toFixed(2)) || "N/A"} Lei
              </Typography>
              <Typography variant='h3'>
                Pret Total: {totalPrice.toFixed(2)} Lei
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary" size='large' sx={{ fontWeight: 'bold' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectedProductInfoDialog;
