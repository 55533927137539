import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Divider, useMediaQuery, useTheme, Badge, Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import Search from './Search';
import MobileMenu from './MobileMenu';
import { selectHasRole, selectIsAuthenticated } from '../../../redux/slices/auth';
import { RootState, useAppSelector } from '../../../redux/store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


const NavBar = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const hasRole = useAppSelector(selectHasRole);
    const cartTotalItems = useAppSelector((state: RootState) => state.cart.totalItems);
    const favoriteTotalItems = useAppSelector((state: RootState) => state.favorites.totalFavorites);
    const ordersTotalItems = useAppSelector((state: RootState) => state.user_orders.count);
    const navigate = useNavigate();
    const location = useLocation();
    const isTabletOrMobile = useMediaQuery('(max-width:1300px)');
    const isMobile = useMediaQuery('(max-width:700px)');
    const isDesktop = useMediaQuery('(min-width:1400px)')
    const [searchOpen, setSearchOpen] = useState(false);
    
    let pages = [
        { title: "Produse", icon: <LocalMallOutlinedIcon sx={{ color: 'black' }} />, path: '/Products' },
        { title: "Comenzi", icon: <ShoppingBagOutlinedIcon sx={{ color: 'black' }} />, badgeContent: ordersTotalItems, path: '/orders' },
        { title: "Cosul meu", icon: <ListAltOutlinedIcon sx={{ color: 'black' }} />, badgeContent: cartTotalItems, path: '/shoppingcart' },
        { title: "Favorite", icon: <FavoriteBorderOutlinedIcon sx={{ color: 'black' }} />, badgeContent: favoriteTotalItems, path: '/FavoriteList' },
        { title: "Contul meu", icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />, path: '/myaccount' },
    ];

    if (isAuthenticated && hasRole) {
        pages = [
            { title: "Administrare", icon: <AdminPanelSettingsIcon sx={{ color: 'black' }} />, path: '/admin/Principala' },
            ...pages
        ];
    }
    
    const MobilePages = [
        { title: "Cosul meu", icon: <ListAltOutlinedIcon sx={{ color: 'black' }} />, badgeContent: cartTotalItems, path: '/shoppingcart' },
        { title: "Favorite", icon: <FavoriteBorderOutlinedIcon sx={{ color: 'black' }} />, badgeContent: favoriteTotalItems, path: '/FavoriteList' },
        { title: "Contul meu", icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />, path: '/myaccount' },
    ];

    const handleNavigate = (path: string): void => {
        navigate(path);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/profile.php?id=100088586350330', "_blank");
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/milivnik_shopmd/', "_blank");
    };

    const handleSearchOpen = () => {
        setSearchOpen(true);
    };

    const handleSearchClose = () => {
        setSearchOpen(false);
    };



    return (
        <AppBar position="static" elevation={2} sx={{ backgroundColor: '#fff' }}>
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', p: '1%' }}>

            {isTabletOrMobile ? (
                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <MobileMenu pages={pages} currentPath={location.pathname} />
                        <Search onOpen={handleSearchOpen} onClose={handleSearchClose} isOpen={searchOpen} />
                        <IconButton onClick={() => navigate('/Products')}>
                            <LocalMallOutlinedIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h1" sx={{ cursor: 'pointer', color: 'black' }} onClick={handleLogoClick}>
                            {isMobile ? 'MN' : 'MILIVNIK'}
                        </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {MobilePages.map(page => (
                            <IconButton key={page.title} onClick={() => navigate(page.path)}>
                                <Badge variant="dot" badgeContent={page.badgeContent} color="secondary" invisible={!page.badgeContent}>
                                    {page.icon}
                                </Badge>
                            </IconButton>
                        ))}
                    </Grid>
                </Grid>
                ) : (
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    }}>
                    {/* Logo and navigation */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h1" sx={{ flexGrow: 0, cursor: 'pointer', color: 'black' }} onClick={() => navigate('/')}>
                            MILIVNIK
                        </Typography>
                        <Search onOpen={handleSearchOpen} onClose={handleSearchClose} isOpen={searchOpen} />
                    </Box>
                    
                    {/* Page Buttons with Icons */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {pages.map(({ title, icon, badgeContent, path }) => (
                            <Box key={title} 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 1, 
                                    cursor: 'pointer',
                                    position: 'relative',
                                    borderBottom: location.pathname.includes(path) ? '2px solid black' : 'none',
                                    '&::after': {
                                        content: location.pathname.includes(path) ? 'none' : '""',
                                        position: 'absolute',
                                        bottom: '-1px',
                                        left: 0,
                                        width: '100%',
                                        height: '2px',
                                        backgroundColor: 'black',
                                        transformOrigin: 'left',
                                        transform: 'scaleX(0)',
                                        transition: 'transform 0.5s ease', 
                                    },
                                    '&:hover::after': {
                                        transform: location.pathname.includes(path) ? 'none' : 'scaleX(1)'
                                    }
                                }}
                                onClick={() => handleNavigate(path)}
                            >
                                <Typography variant='h4' sx={{ color: 'black', fontWeight: 'medium' }}>
                                    {title}
                                </Typography>
                                
                                {badgeContent !== undefined ? (
                                    <Badge variant="dot" badgeContent={badgeContent} color="secondary">
                                        {icon}
                                    </Badge>
                                ) : (
                                    icon
                                )}
                            </Box>
                        ))}

                        {/* Social icons and phone number */}
                        {isDesktop && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <IconButton onClick={handleInstagramClick}>
                                        <InstagramIcon fontSize="medium" style={{ cursor: 'pointer', color: '#E1306C' }} />
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton onClick={handleFacebookClick}>
                                        <FacebookOutlinedIcon fontSize="medium" style={{ cursor: 'pointer', color: '#1877F2' }} />
                                    </IconButton>
                                </Box>
                                <Divider flexItem />
                                <Typography variant="h4" sx={{ marginTop: '8px', cursor: 'pointer', color: 'black' }}>
                                    069927809
                                </Typography>
                            </Box>
                        )}
                        
                    </Box>
                </Box>
            )}

            </Toolbar>
        </AppBar>
    );
}

export default NavBar;
