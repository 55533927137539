import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import { fetchUsers } from '../../redux/slices/user';
import Spinner from '../../components/spinner/Spinner';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowId, GridPaginationModel } from '@mui/x-data-grid';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';


const Users = () => {
    const dispatch = useAppDispatch();
    const AllUsers = useAppSelector((state: RootState) => state.user.items);
    const userStatus = useAppSelector((state: RootState) => state.user.status);
    const Count = useAppSelector((state: RootState) => state.user.count);
    const [checkState, setCheckState] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const isDataGridLoading = userStatus === 'loading';
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
      });

      useEffect(() => {
            dispatch(fetchUsers({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
      }, [dispatch, paginationModel]);

      if (userStatus === 'loading') {
        return <Spinner />;
      }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
        },
        {
            field: 'FullName',
            headerName: 'Nume',
            flex: 2,
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 2,
        },
        {
            field: 'Telefon',
            headerName: 'Numar',
            flex: 2,
        },
        {
            field: 'isSubscribedToNewsletter',
            headerName: 'Noutati',
            flex: 2,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.isSubscribedToNewsletter ? 'abonat' : 'neabonat';
            },
        },
        {
          field: 'PretComenzi',
          headerName: 'Pret Comenzi',
          flex: 2,
          valueGetter: (params: GridValueGetterParams) =>
          `${params.value} lei`,                       
        }, 
        {
            field: 'Reducere',
            headerName: 'Reducere',
            flex: 2,                       
        },    
        {
            field: 'createdAt',
            headerName: 'Creat',
            flex: 2,
        },
    ];

    const rows = AllUsers.map((user) => {
        const userId = user.id;
        const hasDiscount = user.discounts && user.discounts.length > 0;
        let discount = '0%';
        const TotalFinalCost = user.totalFinalCost
      
        if (hasDiscount) {
          discount = user.discounts.map((discount) => `${discount.discount_percentage}%`).join(' / ');
        }
      
        return {
          id: userId,
          FullName: user.FullName,
          Email: user.Email,
          Telefon: user.Telefon,
          isSubscribedToNewsletter: user.isSubscribedToNewsletter,
          createdAt: user.createdAt,
          Reducere: discount,
          PretComenzi: TotalFinalCost,
        };
    });      

      const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
      };

      const MyCustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton
              style={{ fontSize: '15px' }}
            />
            <GridToolbarFilterButton
              style={{ fontSize: '15px' }}
            />
            <GridToolbarDensitySelector
              style={{ fontSize: '15px' }}
            />
          </GridToolbarContainer>
        );
      };
      

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            height: '100vh',
            p: "2%",
            width: '100%'
        }}>
            {/* HEADER */}
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} variant="h2" id="tableTitle" component="div">
                    Utilizatori
                </Typography>

                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon sx={{ fontSize: '1.5em', color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </Toolbar>

            <Box style={{ height: "100%", width: '100%' }}>
            <DataGrid
                paginationMode="server"
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: perPage,
                        page: page
                      },
                    },
                  }}
                pageSizeOptions={[10, 15, 25]}
                rowCount={Count}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                checkboxSelection={checkState}
                onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                    setRowSelectionModel(newSelectionModel as string[]);
                }}
                rowSelectionModel={rowSelectionModel}
                loading={isDataGridLoading}
                slots={{
                    toolbar: MyCustomToolbar,
                  }}
                sx={{ fontSize: '18px' }}
            />
            </Box>
        </Box>
    );
};

export default Users;
