import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import axios from '../axios'; 
import moment from "moment-timezone";

function convertUTCDateToLocalDate(dateString: string): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.utc(dateString).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
}

function convertUTCDateToLocalDateShort(dateString: string): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.utc(dateString).tz(timeZone).format('YYYY-MM-DD');
}

function transformResponseData(data: any): any {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      data[index] = transformResponseData(item); 
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach(key => {
      if (key === 'createdAt' || key === 'updatedAt') {
        if (typeof data[key] === 'string') {
          data[key] = convertUTCDateToLocalDate(data[key]);
        }
      } else if (key === 'startDate' || key === 'endDate') {
        if (typeof data[key] === 'string') {
          data[key] = convertUTCDateToLocalDateShort(data[key]);
        }
      } else {
        data[key] = transformResponseData(data[key]);
      }
    });
  }
  return data;
}

type MethodType = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";

export const API = async <T = any>(
  method: MethodType,
  url: string,
  body?: any,
  params?: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  let [error, response] = await to(
    axios({
      method: method,
      url: url,
      data: body,
      params: params,
    })
  );

  if (!error && response) {
    response.data = transformResponseData(response.data);
  }

  return [error, response];
};