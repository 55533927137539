import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { API } from '../service';
import { AxiosError } from 'axios';
import { DeliveryType } from './delivery';
import { PaymentType } from './payment';
import { Discount } from './discount';
import { Product } from './productAdmin';

export type OrderStatus = 'Nou' | 'In verificare' | 'In livrare' | 'Livrat';

export interface User {
  FullName: string;
  Email: string;
}

export interface AssignedUser {
  Id: number;
  FullName: string;
}

export interface FilterParams {
  searchTerm?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: OrderStatus;
  isAssigned?: boolean;
  assignedUserId?: number; 
  sortByPrice: 'ASC' | 'DESC' | '' | undefined;
}

export interface OrderParams {
  page: number;
  perPage: number;
}

export interface Order {
  Id: number;
  userId: number;
  status: OrderStatus;
  assignedUserId?: number;
  assignedUser?: AssignedUser;
  codLivrare?: string;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryPostalCode?: string;
  deliveryCountry: string;
  deliveryPhone: string;
  deliveryType: DeliveryType; 
  paymentType: PaymentType;
  nominalPrice?: number;
  originalPrice: number;
  price: number;
  discount?: Discount; 
  discountAmount?: number;
  finalCost: number;
  revenue?: number;
  createdAt: string;
  updatedAt: string;
  User: User;
  orderDetails?: OrderDetail[];     
}

export interface OrderDetail {
  Id: number;
  orderId: number;
  inventoryId: number;
  quantity: number;
  Product: Product;
  originalPrice: number; 
  hasDiscount?: boolean;
  discountedPrice?: number; 
  discountPercentage?: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreateOrderData {
  deliveryAddress: string;
  deliveryCity: string;
  deliveryPostalCode: string;
  deliveryCountry: string;
  deliveryPhone: string;
  deliveryTypeId: number; 
  paymentTypeId: number; 
  discountId?: number;                 
  items: {
    postId: number;
    colorId: number;
    sizeId: number;
    cityId: number;
    quantity: number;
  }[];
}

interface OrderByDay {
  day: string;
  count: number;
}

export interface UpdateOrderArgs {
  orderId: number;
  status: OrderStatus;
  codLivrare?: string;
}

export interface UpdateOrderResponse {
  success: boolean;
  message: string;
  orderId: number;
  status?: OrderStatus;
  codLivrare?: string;
}

export interface FetchAssignedOrdersParams extends OrderParams {
  userId: number;
  filters?: FilterParams;
}

interface OrdersState {
  orders: {
    items: Order[],
    status: 'loading' | 'loaded' | 'error',
    count: number;
  },
  selectedOrder: {
    item: Order | null;
    status: 'loading' | 'loaded' | 'error',
  }
  ordersByDay: {
    items: OrderByDay[],
    status: 'loading' | 'loaded' | 'error',
  },
  createStatus: 'idle' | 'loading' | 'success' | 'error',
}

const initialState: OrdersState = {
  orders: {
    items: [],
    status: 'loading',
    count: 0,
  },
  selectedOrder: {
    item: null,
    status: 'loading',
  },
  ordersByDay: {
    items: [],
    status: 'loading',
  },
  createStatus: 'idle',
};


export const fetchCreateOrder = createAsyncThunk<Order, CreateOrderData, { rejectValue: string }>(
  'orders/fetchCreateOrder',
  async (orderData, { rejectWithValue }) => {
    const [error, response] = await API<Order>('POST', '/user/orders', orderData);

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to create order');
    }

    return response.data;
  }
);

export const updateOrderStatus = createAsyncThunk<UpdateOrderResponse, UpdateOrderArgs, { rejectValue: string }>(
  'orders/updateOrderStatus',
  async (args, { rejectWithValue }) => {
    const [error, response] = await API<UpdateOrderResponse>('PATCH', `/admin/orderstatus/${args.orderId}`, {
      status: args.status,
      codLivrare: args.codLivrare,
    });

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to update order status');
    }

    return response.data;
  }
);



export const fetchOrderDetails = createAsyncThunk<{ order: Order }, number>(
  'orders/fetchOrderDetails',
  async (orderId, { rejectWithValue }) => {
    const [error, response] = await API<Order>('GET', `/orders/get/${orderId}`);

    if (error) {
      const axiosError = error as AxiosError;
      return rejectWithValue(axiosError.response?.data);
    }

    return response?.data;
  }
);

export const fetchRemoveOrder = createAsyncThunk<void, number, { rejectValue: string }>(
  'orders/fetchRemoveOrder',
  async (id, { rejectWithValue }) => {
    const [error] = await API<void>('DELETE', `/admin/order/${id}`);
    
    if (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  }
);

export const fetchPaginationOrders = createAsyncThunk<{ orders: Order[]; count: number }, string>(
  'orders/fetchPaginationOrders',
  async (queryString, { rejectWithValue }) => {
    const [error, response] = await API<{ orders: Order[]; count: number }>('GET', `/admin/pagination/orders?${queryString}`);
    
    if (error) {
      console.error(error);
      // Возвращаем отклоненное значение, используя rejectWithValue
      return rejectWithValue(error.message);
    }

    // Проверяем наличие данных в ответе и возвращаем их
    if (response && response.data) {
      return response.data;
    } else {
      // В случае, если ответ не содержит ожидаемых данных, возвращаем стандартный объект
      return rejectWithValue('No data returned from the API');
    }
  }
);

export const fetchUserOrderCounts = createAsyncThunk(
  'orders/fetchUserOrderCounts',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ totalOrdersCount: number, newOrdersCount: number }>('GET', '/user/orders/count');
    
    if (error) {
      return rejectWithValue(error.message);
    }

    return response?.data;
  }
);

export const assignOrderToUser = createAsyncThunk(
  'orders/assignOrderToUser',
  async (orderId: number, { rejectWithValue }) => {
    const [error, response] = await API<{ success: boolean; message?: string; order: Order }>('PATCH', `/admin/order/setUser/${orderId}`);
    
    if (error) {
      return rejectWithValue("An error occurred");
    }
    
    return response?.data; 
  }
);

export const fetchAssignedOrders = createAsyncThunk<{ orders: Order[]; count: number }, { userId: number, queryString: string }>(
  'orders/fetchAssignedOrders',
  async ({ userId, queryString }, { rejectWithValue }) => {
    try {
      const [error, response] = await API<{ orders: Order[]; count: number }>(
        'GET', 
        `/admin/pagination/staff/orders/${userId}?${queryString}`
      );

      if (error || !response) {
        return rejectWithValue("Failed to fetch assigned orders");
      }

      if (response.data) {
        return response.data;
      } else {
        return rejectWithValue("No response data");
      }
    } catch (error) {
      return rejectWithValue("Failed to fetch assigned orders");
    }
  }
);




const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOrderDetails.pending, (state) => {
        state.selectedOrder.item = null;
        state.selectedOrder.status = 'loading';
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action: PayloadAction<{ order: Order }>) => {
        state.selectedOrder.item = action.payload.order;
        state.selectedOrder.status = 'loaded';
      })
      .addCase(fetchOrderDetails.rejected, (state) => {
        state.selectedOrder.item = null;
        state.selectedOrder.status = 'error';
      })
      .addCase(fetchCreateOrder.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(fetchCreateOrder.fulfilled, (state, action: PayloadAction<Order>) => {
        state.createStatus = 'success'; 
        state.orders.items.push(action.payload);
      })
      .addCase(fetchCreateOrder.rejected, (state) => {
        state.createStatus = 'error'; 
      })
      .addCase(fetchRemoveOrder.pending, (state, action) => {
        state.orders.items = state.orders.items.filter((obj) => obj.Id !== action.meta.arg);
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.orders.status = 'loading';
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        if (action.payload.success) {
            // Проверяем, соответствует ли обновленный заказ текущему заказу в деталях заказа
            if (state.selectedOrder.item && state.selectedOrder.item.Id === action.payload.orderId) {
                // Обновляем статус и код доставки (если имеются) для текущих деталей заказа
                if (action.payload.status) {
                    state.selectedOrder.item.status = action.payload.status;
                }
                if (action.payload.codLivrare) {
                    state.selectedOrder.item.codLivrare = action.payload.codLivrare;
                }
            }
            
            const orderInList = state.orders.items.find(order => order.Id === action.payload.orderId);
            if (orderInList) {
                if (action.payload.status) {
                    orderInList.status = action.payload.status;
                }
                if (action.payload.codLivrare) {
                    orderInList.codLivrare = action.payload.codLivrare;
                }
            }
        }
      })    
      .addCase(updateOrderStatus.rejected, (state) => {
          state.orders.status = 'error';
      })
      .addCase(fetchPaginationOrders.pending, (state) => {
        state.orders.items = [];
        state.orders.status = 'loading';
      })
      .addCase(fetchPaginationOrders.fulfilled, (state, action: PayloadAction<{ orders: Order[]; count: number }>) => {
        state.orders.items = action.payload.orders;
        state.orders.status = 'loaded';
        state.orders.count = action.payload.count;
      })
      .addCase(fetchPaginationOrders.rejected, (state) => {
        state.orders.items = [];
        state.orders.status = 'error';
      })
      .addCase(assignOrderToUser.fulfilled, (state, action) => {
        const { success, order: updatedOrder } = action.payload;
        if (success) {
          // Обновление списка заказов
          const index = state.orders.items.findIndex((order) => order.Id === updatedOrder.Id);
          if (index !== -1) {
            state.orders.items[index] = updatedOrder;
          }
  
          // Обновление деталей заказа, если он текущий просматриваемый заказ
          if (state.selectedOrder.item && state.selectedOrder.item.Id === updatedOrder.Id) {
            state.selectedOrder.item.assignedUser = updatedOrder.assignedUser;
          }
        }
      })
      .addCase(fetchAssignedOrders.pending, (state) => {
        state.orders.status = 'loading';
      })
      .addCase(fetchAssignedOrders.fulfilled, (state, action) => {
        state.orders.items = action.payload.orders;
        state.orders.count = action.payload.count;
        state.orders.status = 'loaded';
      })
      .addCase(fetchAssignedOrders.rejected, (state) => {
        state.orders.status = 'error';
      });
  }
});

export const ordersReducer = ordersSlice.reducer;
