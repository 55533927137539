import { Box, Divider } from '@mui/material'
import HomeSection1 from '../../components/Client/Home/HomeSection1'
import HomeSection2 from '../../components/Client/Home/HomeSection2'
import HomeSection3 from '../../components/Client/Home/HomeSection3'

const Home = () => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh',
      width: '100%',
      gap: 2
    }}>
      <HomeSection1/>
      <HomeSection2/>
      <Divider sx={{ my: 1, height: '2px', backgroundColor: '#000' }} />
      <HomeSection3/>
    </Box>
  )
}

export default Home