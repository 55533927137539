import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { fetchProductClasses } from '../../../redux/slices/class';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';
import CategoryCard from './CategoryCard';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const HomeSection2: React.FC = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: RootState) => state.productClass.items);
  const [scrollIndex, setScrollIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:799px)');
  const isMediumScreen = useMediaQuery('(min-width:800px) and (max-width:1249px)');
  const isLargeScreen = useMediaQuery('(min-width:1250px) and (max-width:1599px)');
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  let visibleItems = 1;
  if (isMediumScreen) visibleItems = 2;
  if (isLargeScreen) visibleItems = 3;
  if (isExtraLargeScreen) visibleItems = 4;

  useEffect(() => {
    dispatch(fetchProductClasses());
  }, [dispatch]);

  useEffect(() => {
    setScrollIndex(0);
  }, [visibleItems]);

  const handleScrollLeft = () => {
    setScrollIndex((prevIndex) => Math.max(prevIndex - visibleItems, 0));
  };

  const handleScrollRight = () => {
    setScrollIndex((prevIndex) => Math.min(prevIndex + visibleItems, categories.length - visibleItems));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: '1%', alignItems: 'center' }}>
      <Box sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2, 
          width: '100%',
          gap: 2,
          px: 1
      }}>
        {isSmallScreen && (
          <IconButton 
            onClick={handleScrollLeft} 
            disabled={scrollIndex === 0}
            sx={{
                backgroundColor: '#000', 
                color: '#fff', 
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)'
                },
                '&:disabled': {
                    backgroundColor: '#cccccc'
                }
            }}
          >
            <KeyboardArrowLeftIcon fontSize='large' />
          </IconButton>
        )}
        <Box sx={{
            backgroundColor: 'black', 
            color: 'white', 
            padding: '20px 40px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', 
            borderRadius: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
          <Typography variant={isSmallScreen || isMediumScreen ? 'h3' : 'h2'}>
            {isSmallScreen ? 'Categorii' : 'Categorii disponibile'}
          </Typography>
        </Box>
        {isSmallScreen && (
          <IconButton 
            onClick={handleScrollRight} 
            disabled={scrollIndex >= categories.length - visibleItems}
            sx={{
                backgroundColor: '#000', 
                color: '#fff', 
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)'
                },
                '&:disabled': {
                    backgroundColor: '#cccccc'
                }
            }}
          >
            <KeyboardArrowRightIcon fontSize='large' />
          </IconButton>
        )}
        {!isSmallScreen && (
          <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
          }}>
            <IconButton 
              onClick={handleScrollLeft} 
              disabled={scrollIndex === 0}
              sx={{
                  backgroundColor: '#000', 
                  color: '#fff', 
                  '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)'
                  },
                  '&:disabled': {
                      backgroundColor: '#cccccc'
                  }
              }}
            >
              <KeyboardArrowLeftIcon fontSize='large' />
            </IconButton>
            <IconButton 
              onClick={handleScrollRight} 
              disabled={scrollIndex >= categories.length - visibleItems}
              sx={{
                  backgroundColor: '#000', 
                  color: '#fff', 
                  '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)'
                  },
                  '&:disabled': {
                      backgroundColor: '#cccccc'
                  }
              }}
            >
              <KeyboardArrowRightIcon fontSize='large' />
            </IconButton>
          </Box>
        )}
      </Box>
      
      <Box sx={{ overflow: 'hidden', width: '100%', display: 'flex', justifyContent: 'center', p: '1%' }}>
        <Box
            sx={{
                display: 'flex',
                transition: 'transform 0.5s ease-in-out',
                transform: `translateX(-${scrollIndex * (100 / visibleItems)}%)`,
                minWidth: `calc(100% + 1%)`, 
            }}
        >
          {categories.map((category, index) => (
              <Box
                  key={index}
                  sx={{
                      flex: `0 0 ${100 / visibleItems}%`,
                      boxSizing: 'border-box',
                      p: 1, 
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                  }}
              >
                  <CategoryCard
                    productClass={category}
                  />
              </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSection2;
