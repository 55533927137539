import Main from "./pages/Client/Main";
import MainAdmin from "./pages/Admin/MainAdmin";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthMe, logout } from "./redux/slices/auth";
import { fetchCartCount } from "./redux/slices/cart";
import { getUserOrdersCount } from "./redux/slices/userorders";
import { AppDispatch } from "./redux/store";
import { Routes, Route, Navigate } from "react-router-dom";
import { selectIsLoading, selectIsAuthenticated } from "./redux/slices/auth";
import Spinner from "./components/spinner/Spinner";
import { fetchFavoritesCount } from "./redux/slices/favorites";
import { selectHasRole } from "./redux/slices/auth";

function App() {
  const dispatch: AppDispatch = useDispatch();
  const hasRole = useSelector(selectHasRole);
  const isLoading = useSelector(selectIsLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(fetchAuthMe());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCartCount());
      dispatch(getUserOrdersCount());
      dispatch(fetchFavoritesCount());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    // Обработка события logout для сброса состояния
    const handleLogoutEvent = () => {
      dispatch(logout());
    };

    window.addEventListener('logout-event', handleLogoutEvent);

    return () => {
      window.removeEventListener('logout-event', handleLogoutEvent);
    };
  }, [dispatch]);

  if (isLoading && (token || isAuthenticated)) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path="/*" element={<Main />} />
      <Route 
        path="/admin/*" 
        element={hasRole ? <MainAdmin /> : <Navigate to="/" />}
      />
    </Routes>
  );
}

export default App;
