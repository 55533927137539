import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { API } from '../service';

export interface Size {
  id: number;
  name: string;
}

interface SizesState {
  items: Size[];
  status: 'loading' | 'loaded' | 'error';
  createStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  count: number;
}

const initialSizesState: SizesState = {
  items: [],
  status: 'loading',
  createStatus: 'idle',
  updateStatus: 'idle',
  count: 0,
};

interface SizesRequestParams {
  page: number;
  perPage: number;
}

  export const fetchSizes = createAsyncThunk<Size[], void, { rejectValue: string }>(
    'sizes/fetchSizes',
    async (_, { rejectWithValue }) => {
      const [error, response] = await API<Size[]>('GET', '/size/all');

      if (error || !response) {
        console.error(error);
        return rejectWithValue('Failed to fetch sizes');
      }

      return response.data;
    }
  );

  export const fetchSizesPagination = createAsyncThunk<{ count: number; sizes: Size[] }, SizesRequestParams, { rejectValue: string }>(
    'sizes/fetchSizesPagination',
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number; sizes: Size[] }>('GET', '/size/pagination/all', null, {
        page,
        perPage,
      });
  
      if (error || !response) {
        console.error(error);
        return rejectWithValue('Failed to fetch paginated sizes');
      }
  
      return response.data;
    }
  )

  export const createSize = createAsyncThunk<Size, { name: string }, { rejectValue: string }>(
    'sizes/createSize',
    async ({ name }, { rejectWithValue }) => {
      const [error, response] = await API<Size>('POST', '/size/create', { name });
  
      if (error || !response) {
        console.error(error);
        return rejectWithValue('Failed to create size');
      }
  
      return response.data;
    }
  );

  export const removeSize = createAsyncThunk<void, number, { rejectValue: string }>(
    'sizes/removeSize',
    async (id, { rejectWithValue }) => {
      const [error] = await API<void>('DELETE', `/size/delete/${id}`);
  
      if (error) {
        console.error(error);
        return rejectWithValue('Failed to remove size');
      }
    }
  );

  export const updateSize = createAsyncThunk<Size, { id: number; name: string }, { rejectValue: string }>(
    'sizes/updateSize',
    async ({ id, name }, { rejectWithValue }) => {
      const [error, response] = await API<Size>('PATCH', `/size/update/${id}`, { name });
  
      if (error || !response) {
        console.error(error);
        return rejectWithValue('Failed to update size');
      }
  
      return response.data;
    }
  );

  
  const sizesSlice = createSlice({
    name: 'sizes',
    initialState: initialSizesState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(fetchSizes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSizes.fulfilled, (state, action: PayloadAction<Size[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchSizes.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchSizesPagination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSizesPagination.fulfilled, (state, action: PayloadAction<{ count: number; sizes: Size[] }>) => {
        state.items = action.payload.sizes;
        state.count = action.payload.count;
        state.status = 'loaded';
      })
      .addCase(fetchSizesPagination.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createSize.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(createSize.fulfilled, (state) => {
        state.createStatus = 'succeeded';
      })
      .addCase(createSize.rejected, (state) => {
        state.createStatus = 'failed';
      })
      .addCase(removeSize.fulfilled, (state, action: PayloadAction<void, string, { arg: number }>) => {
        state.items = state.items.filter((size) => size.id !== action.meta.arg);
      })
      .addCase(updateSize.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateSize.fulfilled, (state, action: PayloadAction<Size>) => {
        const index = state.items.findIndex((size) => size.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.updateStatus = 'succeeded';
      })
      .addCase(updateSize.rejected, (state) => {
        state.updateStatus = 'failed';
      });
    },
  });
  
  export const sizesReducer = sizesSlice.reducer;
  