import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, Paper, TextField, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface CreateRoleProps {
  open: boolean;
  onClose: () => void;
  onCreate: (name: string, level: number) => void; 
}

const CreateRole: React.FC<CreateRoleProps> = ({ open, onClose, onCreate }) => {
  const [name, setName] = useState('');
  const [level, setLevel] = useState('');

  const handleChangeLevel = (event: SelectChangeEvent<string>) => {
    setLevel(event.target.value);
  };

  const handleReset = () => {
    setName('');
    setLevel('');
  };

  const handleSave = () => {
    const levelNumber = parseInt(level, 10);
    if (!isNaN(levelNumber)) {
      onCreate(name, levelNumber);
      onClose();
    }
  };

  const isSaveDisabled = name.trim() === '' || isNaN(parseInt(level, 10));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          maxWidth: "80vw",
          minHeight: "80vh",
          overflow: "hidden",
          p: 1,
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h2">Creaza un rol nou</Typography>
          <Box>
            <Button
              onClick={handleReset}
              variant="outlined"
              size="large"
              sx={{ marginRight: 1, color: 'gray', borderColor: 'gray' }}
            >
              Reset
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#2e7d32',
                '&:hover': { backgroundColor: '#1b5e20' },
              }}
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          gap: 2,
          p: 1 
        }}>
          <Paper elevation={3} sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            width: 'auto', 
            overflow: 'auto', 
            p: 3, 
            gap: 2, 
          }}>
            <TextField 
                label="Rol" 
                variant="outlined" 
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ minWidth: '250px' }}
                autoComplete='none'
            />
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="nivel-acces-select-label">Nivel Acces</InputLabel>
                <Select
                  labelId="nivel-acces-select-label"
                  id="nivel-acces-select"
                  value={level}
                  onChange={handleChangeLevel}
                  label="Nivel Acces"
                >
                  {[0, 20, 40, 60, 80].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Paper>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary" size='large' sx={{ fontWeight: 'bold' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRole;
