import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Product } from './produse';
import { API } from '../service';

export type CartItems = {
  Id: number;
  userId: number;
  productId: number;
  inventoryId: number;
  quantity: number;
  inStock: boolean;
  Product: Product;
  price: number;
};

export interface CartSummary {
  totalItems: number;
  totalPrice: number;
  items: CartItems[];
}

export interface AddToCartPayload {
  productId: number;        
  colorId: number | null; 
  sizeId: number | null;  
  cityId: number | null; 
  quantity: number;
}

interface AddToCartResponse {
  success: boolean;
  message: string;
  quantity: number;
}

interface CartState {
  cart: CartSummary;
  status: 'idle' | 'loading' | 'loaded' | 'error';
  totalItems: number;
}

const initialState: CartState = {
  cart: {
    totalItems: 0,
    totalPrice: 0,
    items: []
  },
  status: 'idle',
  totalItems: 0,
};

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async (cartData: AddToCartPayload, { rejectWithValue }) => {
    const [error, response] = await API<AddToCartResponse>('POST', '/cart', cartData);
    if (error) {
      return rejectWithValue('Failed to add to cart');
    }
    if (!response || !response.data.success) {
      return rejectWithValue('Failed to add to cart due to invalid server response');
    }
    return response.data;
  }
);

export const removeFromCart = createAsyncThunk(
  'cart/removeFromCart',
  async (cartId: number, { rejectWithValue }) => {
    const [error, response] = await API<void>('DELETE', `/cart/${cartId}`);
    if (error || !response) {
      return rejectWithValue('Failed to remove from cart');
    }
    return response.data; 
  }
);

export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ items: CartItems[], totalItems: number }>('GET', '/cart');
    if (error || !response) {
      return rejectWithValue('Failed to fetch cart items');
    }
    return response.data;
  }
);

export const fetchCartCount = createAsyncThunk(
  'cart/fetchCartCount',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{}>('GET', '/user/cart/count');
    if (error || !response) {
      return rejectWithValue('Failed to fetch cart count');
    }
    return response.data;
  }
);


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCartItems.fulfilled, (state, action: PayloadAction<{ items: CartItems[], totalItems: number, totalPrice: number }>) => {
        state.status = 'loaded';
        state.cart.items = action.payload.items; 
        state.cart.totalItems = action.payload.totalItems;
        state.cart.totalPrice = action.payload.totalPrice;
      })
      .addCase(fetchCartItems.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(addToCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addToCart.fulfilled, (state, action: PayloadAction<AddToCartResponse>) => {
        state.status = 'loaded';
        const { success } = action.payload;

        if (success) {
          state.cart.totalItems += 1;
          state.totalItems += 1; 
        } else {
          console.error('Failed to add to cart');
        }
      })
      .addCase(addToCart.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(removeFromCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFromCart.fulfilled, (state, action: PayloadAction<{ success: boolean; message: string; cartItemId: number }>) => {
        state.status = 'loaded';
        if (action.payload.success) {
          const index = state.cart.items.findIndex(item => item.Id === action.payload.cartItemId);
          if (index !== -1) {
            state.totalItems -= state.cart.items[index].quantity;
            state.cart.totalItems -= state.cart.items[index].quantity;
            state.cart.totalPrice -= state.cart.items[index].Product.price * state.cart.items[index].quantity;
            state.cart.items.splice(index, 1);
          }
        }
      })
      .addCase(removeFromCart.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchCartCount.fulfilled, (state, action: PayloadAction<{ totalCartItems: number, success: boolean }>) => {
        if (action.payload.success) {
          state.totalItems = action.payload.totalCartItems;
        }
      })
  },
});



export const cartReducer = cartSlice.reducer;
