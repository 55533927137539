import React from 'react';
import { Typography, Box, Container, Divider } from '@mui/material';

// Definirea textelor pentru fiecare secțiune
const politicaConfidentialitate: string = 
  `Magazinul MILIVNIK respectă toate drepturile clienților și vizitatorilor site-ului milivnik.com. 
  Ne angajăm să păstrăm toate informațiile personale primite de la clienții noștri în strictă confidențialitate și în niciun caz să nu le dezvăluim terților. 
  Avem nevoie doar de informațiile dvs. pentru a vă procesa comanda, a finaliza achiziția și a livra. 
  Toate datele personale pe care le furnizați pe site-ul nostru sunt stocate pe un server web securizat și în sistemul nostru intern. 
  Folosind serviciile site-ului nostru, sunteți automat de acord cu politica noastră de confidențialitate.`;


const returnareRestituire: string = 
  `În anumite condiții, magazinul MILIVNIK oferă servicii de retur. Produsul poate fi returnat în termen de 14 zile de la recepție în cazurile în care nu corespunde descrierii de pe site sau la detectarea defectelor. 
  Doar articolele noi, nefolosite, în ambalajul original vor fi acceptate pentru returnare. Nu vor fi acceptate articolele cu etichete rupte sau alte defecte. 
  Bunurile trimise cu încălcarea regulilor și termenelor limită nu vor fi acceptate. Articolele furnizate ca set trebuie returnate ca set. 
  Costurile de transport nu sunt rambursabile. Dacă articolul este deteriorat în timpul livrării, din păcate nu vom putea emite o rambursare.`;

const calitate: string = 
  `Calitatea ridicată a produselor și serviciilor este punctul principal al politicii de brand MILIVNIK. În producția de îmbrăcăminte folosim numai materiale de înaltă calitate și concepte unice. 
  Confirmăm că toate produsele marca MILIVNIK sunt fabricate din materiale ecologice, inofensive. O abordare individuală a fiecărui client constituie baza politicii noastre de tranzacționare.`;

const plataLivrare: string =
  `Puteți efectua plata pentru comanda dvs. atât în numerar, cât și cu cardul. 
  Acceptăm majoritatea cardurilor de credit/debit, inclusiv Visa, MasterCard și American Express. 
  După plasarea comenzii, veți avea opțiunea de a alege metoda de plată preferată.
  
  Oferim livrare prin Poșta Moldovei sau Nova Posta, exclusiv pe teritoriul Republicii Moldova. 
  Costul de livrare variază între 50 și 100 lei, în funcție de modalitatea de livrare aleasă și de destinația pachetului. 
  Timpul de livrare poate varia de la 1 la 7 zile lucrătoare, în funcție de locația dvs. 
  Detaliile exacte privind timpul estimat pentru livrare vor fi comunicate telefonic pentru confirmarea comenzii și prin e-mail.`;


const TermeniConditii: React.FC = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '10%',
      paddingRight: '10%',
      minHeight: '80vh',
      textAlign: 'center',
    }}>
      <Typography variant="h2" gutterBottom>
        Termeni si Conditii
      </Typography>

      {/* Container pentru Politica de Confidențialitate */}
      <Container sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h3" gutterBottom>Politica de Confidențialitate</Typography>
        <Typography variant="h4" paragraph>{politicaConfidentialitate}</Typography>
        <Divider sx={{ my: 2 }} />

        {/* Returnare și Restituire */}
        <Typography variant="h3" gutterBottom>Returnare și Restituire</Typography>
        <Typography variant="h4" paragraph>{returnareRestituire}</Typography>
        <Divider sx={{ my: 2 }} />

        {/* Calitate */}
        <Typography variant="h3" gutterBottom>Calitate</Typography>
        <Typography variant="h4" paragraph>{calitate}</Typography>
        <Divider sx={{ my: 2 }} />

        {/* Plată și Livrare */}
        <Typography variant="h3" gutterBottom>Plată și Livrare</Typography>
        <Typography variant="h4" paragraph>{plataLivrare}</Typography>
        <Divider sx={{ my: 2 }} />
      </Container>
    </Box>
  );
}

export default TermeniConditii;
