import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch, useAppSelector, RootState } from '../../../../redux/store';
import { fetchProductClasses, updateProductClass, createProductClass, CreateProductClassData, UpdateProductClassData, ProductClass } from '../../../../redux/slices/class';
import CategoryCard from './CategoryCard';
import AddNewCategory from './AddNewCategory';

const CategoryList = () => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector((state: RootState) => state.productClass.items);
    const [open, setOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState<UpdateProductClassData | null>(null);
    const [newCategoryData, setNewCategoryData] = useState<CreateProductClassData>({
        name: '',
        image: undefined
    });
    const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);

    useEffect(() => {
        dispatch(fetchProductClasses());
    }, [dispatch]);

    const handleAddCategory = () => {
        setEditingCategory(null);
        setNewCategoryData({ name: '', image: undefined });
        setCurrentImageUrl(null);
        setOpen(true);
    };

    const handleEditCategory = (category: ProductClass) => {
        setEditingCategory({ id: category.id, name: category.name });
        setNewCategoryData({ name: category.name });
        setCurrentImageUrl(category.imageUrl || null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewCategoryData({ name: '', image: undefined });
        setCurrentImageUrl(null);
    };

    const handleSave = () => {
        if (editingCategory) {
            const updatedCategory: UpdateProductClassData = {
                id: editingCategory.id,
                name: newCategoryData.name,
                image: newCategoryData.image
            };
            dispatch(updateProductClass(updatedCategory));
        } else {
            dispatch(createProductClass(newCategoryData));
        }
        handleClose();
    };

    const handleReset = () => {
        setNewCategoryData({ name: '', image: undefined });
        setCurrentImageUrl(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
            <Paper elevation={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', flexGrow: 1, overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 2 }}>
                    <IconButton onClick={handleAddCategory} sx={{
                        border: '2px solid',
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '&:hover': {
                            bgcolor: 'grey.300'
                        },
                        width: 50,
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    width: '100%',
                    p: 2,
                    flexGrow: 1,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '8px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    }
                }}>
                    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                        {categories.map((category) => (
                            <Grid item key={category.id}>
                                <CategoryCard
                                    productClass={category}
                                    onEdit={handleEditCategory}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>

            <AddNewCategory
                open={open}
                handleClose={handleClose}
                handleSave={handleSave}
                handleReset={handleReset}
                newCategoryData={newCategoryData}
                setNewCategoryData={setNewCategoryData}
                currentImageUrl={currentImageUrl}
                setCurrentImageUrl={setCurrentImageUrl}
                isEditMode={!!editingCategory}
            />
        </Box>
    );
}

export default CategoryList;
