import React from 'react';
import { Box, Typography, Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from '@mui/material';
import { CreateOfflineOrderData } from '../../../../redux/slices/orderoffline';
import { PaymentType } from '../../../../redux/slices/payment';
import { DeliveryType } from '../../../../redux/slices/delivery';
import { UserEmailInfo } from '../../../../redux/slices/user';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


interface OfflineOrderDataProps {
  offlineOrderData: CreateOfflineOrderData;
  payments: PaymentType[];
  delivery: DeliveryType[];
  totalAmount: number;
  totalQuantity: number;
  totalWithDelivery: number;
  usersEmail: UserEmailInfo[];
  selectedEmail: UserEmailInfo | null;
  handleInputChange: (field: keyof CreateOfflineOrderData, value: any) => void;
  isAllDataComplete: boolean;
  handleEmailSearch: () => void;
  handleConfirmOrder: () => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelectedEmail: (event: any, newValue: UserEmailInfo | null) => void;
}

const OfflineOrderData: React.FC<OfflineOrderDataProps> = ({
  offlineOrderData,
  payments,
  delivery,
  totalAmount,
  totalWithDelivery,
  totalQuantity,
  usersEmail,
  selectedEmail,
  handleInputChange,
  isAllDataComplete,
  handleEmailSearch,
  handleConfirmOrder,
  setSearchQuery,
  setSelectedEmail
}) => {
  

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    }}>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h3" sx={{ p: 1 }}>
          Date Comanda
        </Typography>
        <Box sx={{ flex: 1, mx: 2 }}>
        <Autocomplete
            options={usersEmail}
            getOptionLabel={(option) => option.Email}
            value={selectedEmail}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(event, newInputValue) => {
              setSearchQuery(newInputValue);
            }}
            onChange={setSelectedEmail}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Căutare Email"
                variant="outlined"
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleEmailSearch();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleEmailSearch}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            borderRadius: '20px', 
            padding: '10px 30px', 
            backgroundColor: 'black', 
            '&:hover': {
              backgroundColor: '#585858',
            },
          }}
          onClick={handleConfirmOrder}
          disabled={!isAllDataComplete}
        >
          Salvează Comanda
        </Button>
      </Box>
      <Divider sx={{ mb: 2 }}/>
      <Grid container spacing={2} sx={{ p: 2, height: '100%' }}>
        {/* Nume Cumparator */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Nume Cumparator"
            variant="outlined"
            value={offlineOrderData.customerName}
            onChange={(e) => handleInputChange('customerName', e.target.value)}
          />
        </Grid>
        {/* Numar Cumparator */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Numar Cumparator"
            variant="outlined"
            value={offlineOrderData.customerContact}
            onChange={(e) => handleInputChange('customerContact', e.target.value)}
          />
        </Grid>
        {/* Tip Plata */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tip-plata-label">Tip Plata</InputLabel>
            <Select
              value={offlineOrderData.paymentTypeId || ''}
              onChange={(e) => handleInputChange('paymentTypeId', Number(e.target.value))}
              label="Tip Plata"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {payments.map((payment) => (
                <MenuItem key={payment.id} value={payment.id}>{payment.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Tip Livrare */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tip-livrare-label">Tip Livrare</InputLabel>
            <Select
              value={offlineOrderData.deliveryTypeId || ''}
              onChange={(e) => handleInputChange('deliveryTypeId', Number(e.target.value))}
              label="Tip Livrare"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {delivery.map((deliver) => (
                <MenuItem key={deliver.id} value={deliver.id}>{deliver.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Oras */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Oras"
            variant="outlined"
            value={offlineOrderData.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
          />
        </Grid>
        {/* Adresa */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Adresa"
            variant="outlined"
            value={offlineOrderData.address} 
            onChange={(e) => handleInputChange('address', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Comentarii"
            multiline
            rows={3}
            variant="outlined"
            value={offlineOrderData.comments || ''} 
            onChange={(e) => handleInputChange('comments', e.target.value)} 
            InputProps={{
              style: {
                overflow: 'auto',
              },
            }}
          />
        </Grid>
        
        {/* Sumar и Divider после последнего поля */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h3" align="center">
            Sumar Comanda
          </Typography>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">
              Numar Produse
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {totalQuantity}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">
              Pret Produse
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {totalAmount.toFixed(2)} Lei
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">
              Pret Total
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {totalWithDelivery.toFixed(2)} Lei
            </Typography>
          </Grid>
        </Grid>
         {/* Pret Final */}
        <Grid container item xs={12} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
          <Grid item>
            <Typography variant="h3">
              Pret Final
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Pret Final"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={offlineOrderData.finalCost}
              onChange={(e) => handleInputChange('finalCost', Number(e.target.value))}
              sx={{ width: '150px' }}
            />
          </Grid>
        </Grid>

      </Grid>
    </Box>
  )
}

export default OfflineOrderData;
