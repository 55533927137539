import React, { useEffect, useState } from 'react';
import { Paper, Box, Button, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add'; 
import CreateNewUser from './CreateNewUser';
import UserWithRoleList from './UserWithRoleList';
import { fetchUsersWithRoles } from '../../../redux/slices/userrole';
import { useAppDispatch, useAppSelector } from '../../../redux/store';


const AccessUsers = () => {
    const dispatch = useAppDispatch();
    const { usersWithRoles, fetchUsersStatus } = useAppSelector((state) => state.userRole);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchUsersWithRoles());
    }, [dispatch]);

    const handleClickOpen = (): void => {
        setOpen(true); 
    };
    
    const handleClose = (): void => {
        setOpen(false); 
    };
      
  return (
    <Paper elevation={3} sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        
        {/* Верхний Box с надписью и кнопкой Delete */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Typography variant="h2">
            Admin Users
            </Typography>
            <Button 
                startIcon={<DeleteIcon />} 
                color="error" 
                variant="contained" 
                aria-label="delete"
                disabled
                >
                Delete
            </Button>
        </Box>

        {/* Содержимое списка пользователей */}
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
            <UserWithRoleList users={usersWithRoles} />
        </Box>

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                backgroundColor: 'rgba(25, 118, 210, 0.1)',
                p: 2,
                borderRadius: 2,
                width: '100%', 
                flexDirection: 'row',
            }}
            >
            {/* Обертка для иконки и текста, выделяющаяся при наведении */}
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1, 
                        cursor: 'pointer',
                        padding: '10px', 
                        borderRadius: '8px', 
                        '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.2)', 
                        }
                    }}
                    onClick={handleClickOpen}
                >
                    <IconButton aria-label="add new role" sx={{ color: '#1976d2', "&:hover": { bgcolor: 'transparent' } }}>
                        <AddIcon fontSize="large" /> 
                    </IconButton>
                    <Typography variant="h3" sx={{ color: '#1976d2' }}>
                        ADD NEW USER
                    </Typography>
                </Box>
            </Box>
        </Box>

        {/* Условное отображение компонента CreateRole */}
        <CreateNewUser open={open} onClose={handleClose} />

    </Paper>
  );
}

export default AccessUsers;
