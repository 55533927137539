import { useState, useEffect } from 'react';
import { Box, Button, IconButton } from "@mui/material"
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector, RootState } from '../../../../redux/store';
import { fetchColorsPagination } from '../../../../redux/slices/color';
import EditIcon from '@mui/icons-material/Edit';

interface ColorDataProps {
    onEditColor: (color: { id: number, name: string, colorCode: string }) => void;
  }

const ColorData: React.FC<ColorDataProps> = ({ onEditColor }) => {
    const dispatch = useAppDispatch();
    const colors = useAppSelector((state: RootState) => state.colors.items); 
    const Count = useAppSelector((state: RootState) => state.colors.count);
    const colorStatus = useAppSelector((state: RootState) => state.colors.status);
    const updateStatus = useAppSelector((state: RootState) => state.colors.updateStatus);
    const createStatus = useAppSelector((state: RootState) => state.colors.createStatus);
    const isDataGridLoading = colorStatus === 'loading';
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
    }); 


    useEffect(() => {
        dispatch(fetchColorsPagination({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
    }, [dispatch, paginationModel]);

    useEffect(() => {
        if (createStatus === 'succeeded' || updateStatus === 'succeeded') {
            dispatch(fetchColorsPagination({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
        }
    }, [createStatus, updateStatus, dispatch, paginationModel]);
    
      
    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    }; 

    const handleEditClick = (color: { id: number, name: string, colorCode: string }) => {
        onEditColor(color);
    };    

    const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'Id',
          flex: 1,
        },
        {
          field: 'name',
          headerName: 'Nume',
          flex: 1,
        },
        {
          field: 'colorCode',
          headerName: 'Codul Culoarei',
          flex: 1,
        },
        {
          field: 'actions',
          headerName: 'Acțiuni',
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
      
            return (
                <IconButton onClick={() => handleEditClick({ id: params.row.id, name: params.row.name, colorCode: params.row.colorCode })}>
                    <EditIcon />
                </IconButton>
            );
          },
        },
    ];
      
    const rows = colors.map((color) => ({
        id: color.id,
        name: color.name,
        colorCode: color.colorCode,
    }));

  return (
    <Box sx={{ height: "100%", width: '100%', p:'2%' }}>
        <DataGrid 
            paginationMode="server"
            rows={rows}
            columns={ columns}
            initialState={{
                pagination: {
                paginationModel: {
                    pageSize: perPage,
                    page: page
                },
                },
            }}
            pageSizeOptions={[10, 15, 25]}
            rowCount={Count}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            loading={isDataGridLoading}
            sx={{ fontSize: '22px' }}
        />
    </Box>
  )
}

export default ColorData