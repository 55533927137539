import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { API } from '../service';
import { DeliveryType } from './delivery';
import { PaymentType } from './payment';
import { Discount } from './discount';
import { Product } from './produse';


export type OrderStatus = 'Nou' | 'In verificare' | 'In livrare' | 'Livrat';

export interface Order {
    Id: number;
    userId: number;
    status: OrderStatus;
    codLivrare?: string;
    deliveryAddress: string;
    deliveryCity: string;
    deliveryPostalCode?: string;
    deliveryCountry: string;
    deliveryPhone: string;
    deliveryType: DeliveryType; 
    paymentType: PaymentType;
    originalPrice: number;
    price: number;
    discount?: Discount; 
    discountAmount?: number;
    finalCost: number;
    createdAt: string;
    updatedAt: string;
    orderDetails?: OrderDetail[];     
}

export interface OrderDetail {
    id: number;
    orderId: number;
    inventoryId: number;
    quantity: number;
    Product: Product;
    originalPrice: number; 
    hasDiscount?: boolean;
    discountedPrice?: number; 
    discountPercentage?: number;
    totalPrice: number;
    createdAt: string;
    updatedAt: string;
}

export interface CreateOnlineOrderData {
    deliveryAddress: string;
    deliveryCity: string;
    deliveryPostalCode: string;
    deliveryCountry: string;
    deliveryPhone: string;
    deliveryTypeId: number; 
    paymentTypeId: number; 
    discountId?: number;                 
}

interface OrdersState {
    orders: {
      items: Order[],
      status: 'loading' | 'loaded' | 'error',
      count: number;
    },
    selectedOrder: {
      item: Order | null;
      status: 'loading' | 'loaded' | 'error',
    }
    createStatus: 'idle' | 'loading' | 'success' | 'error',
}
  
const initialState: OrdersState = {
    orders: {
        items: [],
        status: 'loading',
        count: 0,
    },
    selectedOrder: {
        item: null,
        status: 'loading',
    },
    createStatus: 'idle',
};



export const fetchCreateOnlineOrder = createAsyncThunk<Order, CreateOnlineOrderData, { rejectValue: string }>(
    'orders/fetchCreateOnlineOrder',
    async (orderData, { rejectWithValue }) => {
      const [error, response] = await API<Order>('POST', '/user/orders', orderData);
  
      if (error || !response) {
        console.error(error);
        return rejectWithValue('Failed to create order');
      }
  
      return response.data;
    }
);



const orderSlice = createSlice({
    name: 'onlineOrders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreateOnlineOrder.pending, (state) => {
                state.createStatus = 'loading';
            })
            .addCase(fetchCreateOnlineOrder.fulfilled, (state, action: PayloadAction<Order>) => {
                state.orders.items.push(action.payload);
                state.createStatus = 'success';
            })
            .addCase(fetchCreateOnlineOrder.rejected, (state) => {
                state.createStatus = 'error';
            });
    },
});

export const onlineOrderReducer = orderSlice.reducer;






