import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';
import { Color } from './color';
import { Size } from './size';
import { City } from './city';


export type InventoryItem = {
  id: number;
  count: number;
  PostId: number;
  ColorId: number;
  SizeId: number;
  CityId: number;
  Color?: Color
  Size?: Size
  City?: City
};

export type InventoryDataCreate = {
  id: number;
  count: number;
  ColorId: number;
  SizeId: number;
  CityId: number;
};

interface InventoryState {
  items: InventoryItem[];
  itemsByPost: Record<number, InventoryItem[]>;
  status: 'idle' | 'loading' | 'loaded' | 'error';
}
  
const initialState: InventoryState = {
  items: [],
  itemsByPost: {}, 
  status: 'idle',
};


export const fetchInventoryItems = createAsyncThunk<InventoryItem[], void,{ rejectValue: string }>(
  'inventory/fetchInventoryItems',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<InventoryItem[]>('GET', '/inventory');

    if (error || !response) {
      return rejectWithValue('Failed to fetch inventory items');
    }

    return response.data;
  }
);

export const fetchInventoryById = createAsyncThunk<{ items: InventoryItem[]; postId: number }, number, { rejectValue: string } >(
  'inventory/fetchById',
  async (postId, { rejectWithValue }) => {
    const [error, response] = await API<InventoryItem[]>('GET', `/inventory/${postId}`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch inventory by ID');
    }

    return { items: response.data, postId };
  }
);
  
export const addToInventory = createAsyncThunk<InventoryItem, { PostId: number; ColorId: number; SizeId: number; count: number; CityId: number }>(
  'inventory/addToInventory',
  async (item, { rejectWithValue }) => {
    const [error, response] = await API<InventoryItem>('POST', '/inventory', item);

    if (error || !response) {
      return rejectWithValue('Failed to add inventory item');
    }

    return response.data;
  }
);
  
export const updateInventory = createAsyncThunk<InventoryItem, { id: number; count: number; ColorId?: number; SizeId?: number; CityId: number }>(
  'inventory/updateInventory',
  async (item, { rejectWithValue }) => {
    const [error, response] = await API<InventoryItem>('PATCH', `/inventory/${item.id}`, item);

    if (error || !response) {
      return rejectWithValue('Failed to update inventory item');
    }

    return response.data;
  }
);

export const removeFromInventory = createAsyncThunk<number, number, { rejectValue: string }>(
  'inventory/removeFromInventory',
  async (id, { rejectWithValue }) => {
    const [error] = await API<void>('DELETE', `/inventory/${id}`);

    if (error) {
      return rejectWithValue('Failed to remove item from inventory');
    }

    return id;
  }
);


  
  const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchInventoryItems.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchInventoryItems.fulfilled, (state, action: PayloadAction<InventoryItem[]>) => {
          state.status = 'loaded';
          state.items = action.payload;
      })
        .addCase(fetchInventoryItems.rejected, (state) => {
          state.status = 'error';
        })
        .addCase(fetchInventoryById.pending, (state) => {
          state.status = 'loading';
      })
      .addCase(fetchInventoryById.fulfilled, (state, action: PayloadAction<{ items: InventoryItem[], postId: number }>) => {
        state.status = 'loaded';
        state.itemsByPost[action.payload.postId] = action.payload.items;
        action.payload.items.forEach(fetchedItem => {
            const index = state.items.findIndex(item => item.id === fetchedItem.id);
            if (index !== -1) {
                state.items[index] = fetchedItem;
            } else {
                state.items.push(fetchedItem);
            }
        });
      })
    
      .addCase(fetchInventoryById.rejected, (state, action) => {
          state.status = 'error';
      })
        .addCase(addToInventory.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(addToInventory.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = 'loaded';
          state.items.push(action.payload);
        })
        .addCase(addToInventory.rejected, (state) => {
          state.status = 'error';
        })
        .addCase(updateInventory.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(updateInventory.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = 'loaded';
          const index = state.items.findIndex(item => item.id === action.payload.id);
          if (index !== -1) {
              state.items[index] = action.payload;
          }
      })
        .addCase(updateInventory.rejected, (state) => {
          state.status = 'error';
        })
        .addCase(removeFromInventory.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(removeFromInventory.fulfilled, (state, action: PayloadAction<number>) => {
          state.status = 'loaded';
          
          // Removing item from the main items list
          state.items = state.items.filter(item => item.id !== action.payload);
          
          // Updating itemsByPost
          for (const postId in state.itemsByPost) {
            state.itemsByPost[postId] = state.itemsByPost[postId].filter(item => item.id !== action.payload);
          }
        })               
        .addCase(removeFromInventory.rejected, (state) => {
          state.status = 'error';
        });
    },
  });
  
  export const inventoryReducer = inventorySlice.reducer;
  