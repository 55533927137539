import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { RootState } from '../store';
import { API } from '../service';

export interface CanReviewState {
  canReview: boolean | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CanReviewState = {
  canReview: null,
  status: 'idle',
  error: null
};

export const checkCanReview = createAsyncThunk(
  'ratings/checkCanReview',
  async (postId: number, { rejectWithValue }) => {
    const [error, response] = await API<{ canReview: boolean }>('GET', `/rating/can-review/${postId}`);

    if (error || !response) {
      const message = (error as any)?.response?.data?.message ?? 'Unknown error';
      return rejectWithValue(message);
    }

    return response.data.canReview;
  }
);

const canReviewSlice = createSlice({
  name: 'canReview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkCanReview.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(checkCanReview.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.canReview = action.payload;
      })
      .addCase(checkCanReview.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  }
});


// Селекторы
export const selectCanReview = (state: RootState) => state.canReview.canReview;
export const selectCanReviewStatus = (state: RootState) => state.canReview.status;
export const selectCanReviewError = (state: RootState) => state.canReview.error;

export const canReviewReducer =  canReviewSlice.reducer;