import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';
import { User } from './auth';
import { InventoryItem, InventoryDataCreate } from './inventory';

export type ProductsLoadingStatus = 'loading' | 'loaded' | 'error';

export interface Product {
  id: number;
  barcode: string;
  title: string;
  text: string;
  totalInventoryCount: number;
  imageUrl: string[]; 
  originalPrice: number;
  price: number;
  discount?: Discount;  
  inventories: InventoryItem[];
  hasActiveDiscount: boolean; 
  averageRating: number;
  productClassId: number;
  createdAt: string; 
  updatedAt: string; 
}

export interface Discount {
  percentage: number;
  startDate: string;
  endDate: string;
}

export interface ProductData {
  title: string;
  text: string;
  price: number;
  originalPrice: number;
  discount?: Discount;
  productClassId: number;
  inventories?: InventoryDataCreate[];
  images: File[];
  deletedImageUrl?: string[];
  updatedInventories?: InventoryDataCreate[];
  deletedInventoryIds?: number[];
}

interface UpdateProductParams {
  id: number;
  postData: ProductData;
}

export interface CreateProductResponse {
  message: string;
  success: boolean;
  productId: number;
}

export interface UpdateProductResponse {
  message: string;
  success: boolean;
  product: Product; 
}

export interface Filter {
  colorId?: number | null;
  sizeId?: number | null;
  productClassId?: number | null;
  cityId?: number | null;
  sort?: 'asc' | 'desc' | null;
  query?: string | null; 
  onSale?: boolean | null;
}
  
export interface Pagination {
  page: number;
  perPage: number;
}
  
export interface FetchPostsPaginationParams {
  pagination: Pagination;
  filters: Filter;
}

interface ProductListState {
  items: Product[];
  status: ProductsLoadingStatus;
  count: number;
  overallInventoryCount: number;
}

interface SelectedProductState {
  product: Product | null;
  status: ProductsLoadingStatus;
}

interface ProductState {
  products: ProductListState;
  selectedProduct: SelectedProductState;
  createStatus: 'idle' | 'loading' | 'success' | 'error';
  updateStatus: 'idle' | 'loading' | 'success' | 'error'; 
}

const initialState: ProductState = {
  products: {
    items: [],
    status: 'loading',
    count: 0,
    overallInventoryCount: 0, 
  },
  selectedProduct: {
    product: null,
    status: 'loading',
  },
  createStatus: 'idle',
  updateStatus: 'idle', 
};



export const CreateProduct = createAsyncThunk<{ message: string; success: boolean; productId: number }, ProductData, { rejectValue: string }>(
  'product/CreateProduct', 
  async (productData, { rejectWithValue }) => {
      const formData = new FormData();
  
      formData.append('title', productData.title);
      formData.append('text', productData.text);
      formData.append('price', productData.price.toString());
      if (productData.originalPrice) {
          formData.append('originalPrice', productData.originalPrice.toString());
      }
      formData.append('productClassId', productData.productClassId.toString());
  
      if (productData.discount) {
          formData.append('discount', JSON.stringify(productData.discount));
      }
      formData.append('inventories', JSON.stringify(productData.inventories));
  
      if (productData.images) {
          productData.images.forEach((file) => {
              formData.append('images', file, file.name);
          });
      }
  
      const [error, response] = await API<CreateProductResponse>('POST', '/product/admin/create', formData);
  
      if (error || !response) {
          return rejectWithValue('Failed to create the product');
      }
  
      return response.data;
  }
);

export const UpdateProduct = createAsyncThunk<UpdateProductResponse, UpdateProductParams, { rejectValue: string }>(
  'product/UpdateProduct',
  async (params, { rejectWithValue }) => {
    const { postData, id } = params;

    const formData = new FormData();

    // Append basic fields
    formData.append('title', postData.title);
    formData.append('text', postData.text);
    formData.append('price', postData.price.toString());
    formData.append('productClassId', postData.productClassId.toString());

    if (postData.originalPrice !== undefined) {
      formData.append('originalPrice', postData.originalPrice.toString());
    }

    if (postData.discount) {
      formData.append('discount', JSON.stringify(postData.discount));
    }

    // Handle inventories: append JSON stringified version of inventories arrays
    if (postData.inventories) {
      formData.append('inventories', JSON.stringify(postData.inventories));
    }

    if (postData.updatedInventories) {
      formData.append('updatedInventories', JSON.stringify(postData.updatedInventories));
    }

    if (postData.deletedInventoryIds) {
      formData.append('deletedInventoryIds', JSON.stringify(postData.deletedInventoryIds));
    }

    // Handle image uploads
    if (postData.images) {
      postData.images.forEach(file => {
        formData.append('images', file, file.name);
      });
    }

    // Handle image deletions
    if (postData.deletedImageUrl) {
      formData.append('deletedImageUrl', JSON.stringify(postData.deletedImageUrl));
    }

    // Send the request
    const [error, response] = await API<UpdateProductResponse>('PATCH', `/posts/${id}`, formData);

    if (error || !response) {
      return rejectWithValue('Failed to update the product');
    }

    return response.data;
  }
);

export const GetSelectedProduct = createAsyncThunk<Product, number, { rejectValue: string }>(
  'posts/fetchGetProduct', 
  async (id, { rejectWithValue }) => {
    const [error, response] = await API<Product>('GET', `/product/get/admin/${id}`);

    if (error || !response) {
      return rejectWithValue('Failed to fetch the product details');
    }

    return response.data;
  }
);

export const GetProductPagination = createAsyncThunk<{ products: Product[]; count: number; overallInventoryCount: number }, FetchPostsPaginationParams, { rejectValue: string }>(
  'product/GetProductPagination',
  async ({ pagination, filters }, { rejectWithValue }) => {
    const [error, response] = await API<{ products: Product[]; count: number; overallInventoryCount: number }>('GET', '/product/admin/pagination/all', null, { pagination, ...filters });

    if (error || !response) {
      return rejectWithValue('Failed to fetch posts for pagination');
    }

    return response.data;
  }
);

export const GetProductForOfflineOrder = createAsyncThunk<{ products: Product[]; count: number }, { filters: Filter }, { rejectValue: string }>(
    'product/GetProductForOfflineOrder', 
    async ({ filters }, { rejectWithValue }) => {
      const queryParams = {
        ...filters,
      };
  
      const [error, response] = await API<{ products: Product[]; count: number }>('GET', '/posts', null, queryParams);
      
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch posts');
      }
  
      return {
        products: response.data.products,
        count: response.data.count,
      };
    }
);




const productsAdminSlice = createSlice({
    name: 'productsAdmin',
    initialState,
    reducers: {
      resetSelectedProduct(state) {
        state.selectedProduct.product = null;
        state.selectedProduct.status = 'loading';
      },
    },
    extraReducers: builder => {
      builder
        .addCase(CreateProduct.pending, (state) => {
          state.createStatus = 'loading';
        })
        .addCase(CreateProduct.fulfilled, (state, action) => {
          state.createStatus = 'success';
        })
        .addCase(CreateProduct.rejected, (state, action) => {
          state.createStatus = 'error';
        })
        .addCase(UpdateProduct.pending, (state) => {
          state.updateStatus = 'loading'; 
        })
        .addCase(UpdateProduct.fulfilled, (state, action) => {
          state.updateStatus = 'success'; 
          const updatedProduct = action.payload.product;
          state.selectedProduct.product = updatedProduct;
        })
        .addCase(UpdateProduct.rejected, (state, action) => {
          state.updateStatus = 'error'; 
        })
        .addCase(GetSelectedProduct.pending, (state) => {
          state.selectedProduct.product = null;
          state.selectedProduct.status = 'loading';
        })
        .addCase(GetSelectedProduct.fulfilled, (state, action: PayloadAction<Product>) => {
          state.selectedProduct.product = action.payload;
          state.selectedProduct.status = 'loaded';
        })
        .addCase(GetSelectedProduct.rejected, (state, action) => {
          state.selectedProduct.status = 'error';
        })
        .addCase(GetProductPagination.pending, (state) => {
          state.products.status = 'loading';
        })
        .addCase(GetProductPagination.fulfilled, (state, action) => {
          state.products.status = 'loaded';
          state.products.items = action.payload.products;
          state.products.count = action.payload.count;
          state.products.overallInventoryCount = action.payload.overallInventoryCount;
        })
        .addCase(GetProductPagination.rejected, (state) => {
          state.products.status = 'error';
        })
        .addCase(GetProductForOfflineOrder.pending, (state) => {
            state.products.status = 'loading';
        })
        .addCase(GetProductForOfflineOrder.fulfilled, (state, action) => {
            state.products.status = 'loaded';
            state.products.items = action.payload.products;
            state.products.count = action.payload.count;
        })
        .addCase(GetProductForOfflineOrder.rejected, (state) => {
            state.products.status = 'error';
        })
    }
  });
  
  export const { resetSelectedProduct } = productsAdminSlice.actions;
  export const productsAdminReducer = productsAdminSlice.reducer;