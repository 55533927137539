import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { SketchPicker, ColorResult } from 'react-color';
import { useAppDispatch } from '../../../../redux/store';
import { createColor, updateColor } from '../../../../redux/slices/color';

interface IColorPalleteProps {
  editingColor: { id: number, name: string, colorCode: string } | null;
  resetEditing: () => void;
}

const ColorPallete: React.FC<IColorPalleteProps> = ({ editingColor, resetEditing }) => {
  const [color, setColor] = useState<string>('#fff');
  const [name, setName] = useState<string>('');
  const dispatch = useAppDispatch();

  const handleColorChange = (colorResult: ColorResult) => {
    setColor(colorResult.hex);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (editingColor) {
      setName(editingColor.name);
      setColor(editingColor.colorCode);
    } else {
      setName('');
      setColor('#fff');
    }
  }, [editingColor]);

  const handleSubmit = () => {
    if (name) {
      if (editingColor) {
        dispatch(updateColor({ id: editingColor.id, name, colorCode: color }));
        resetEditing();
      } else {
        dispatch(createColor({ name, colorCode: color }));
      }
      setName('');
      setColor('#fff');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', }}>
      <SketchPicker color={color} onChangeComplete={handleColorChange} width="75%" />
      <TextField
        fullWidth
        label="Nume Culoare"
        value={name}
        onChange={handleNameChange}
        sx={{ my: 2, width: '80%' }}
        autoComplete='none'
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSubmit}
        disabled={!name}
        sx={{ mt: 2, width: '80%' }}
      >
        {editingColor ? "Update Culoare" : "Creaza Culoare"}
      </Button>
    </Box>
  );
};

export default ColorPallete;
