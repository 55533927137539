import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';

export interface ProductClass {
  id: number;
  name: string;
  imageUrl?: string; 
}

export interface CreateProductClassData {
  name: string;
  image?: File;  
}

export interface UpdateProductClassData {
  id: number;
  name: string;
  image?: File;  
}

export interface UpdateProductClassResponse {
  message: string;
  success: boolean;
  productClass: ProductClass;
}

interface ProductClassRequestParams {
  page: number;
  perPage: number;
}

interface ProductClassState {
  items: ProductClass[];
  status: 'loading' | 'loaded' | 'error';
  createStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  count: number;
}

const initialProductClassState: ProductClassState = {
  items: [],
  status: 'loading',
  createStatus: 'idle',
  updateStatus: 'idle',
  count: 0,
};


export const fetchProductClasses = createAsyncThunk<ProductClass[], void, { rejectValue: string }>(
  'productClasses/fetchProductClasses', 
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<ProductClass[]>('GET', '/class/all');

    if (error || !response) {
      return rejectWithValue('Failed to fetch product classes');
    }

    return response.data;
  }
);

export const fetchProductClassesPagination = createAsyncThunk<{ count: number, productClasses: ProductClass[] }, ProductClassRequestParams, { rejectValue: string }>(
  'productClasses/fetchProductClassesPagination', 
  async ({ page, perPage }, { rejectWithValue }) => {
    const [error, response] = await API<{ count: number; productClasses: ProductClass[] }>('GET', '/class/pagination/all', null, {
      page,
      perPage,
    });

    if (error || !response) {
      return rejectWithValue('Failed to fetch product classes with pagination');
    }

    return response.data;
  }
);

export const createProductClass = createAsyncThunk<ProductClass, CreateProductClassData, { rejectValue: string }>(
  'productClasses/createProductClass',
  async (productClassData, { rejectWithValue }) => {
    const formData = new FormData();

    formData.append('name', productClassData.name);
    if (productClassData.image) {
      formData.append('image', productClassData.image);
    }

    const [error, response] = await API<ProductClass>('POST', '/class/create', formData);

    if (error || !response) {
      return rejectWithValue('Failed to create product class');
    }

    return response.data;
  }
);

export const updateProductClass = createAsyncThunk<UpdateProductClassResponse, UpdateProductClassData, { rejectValue: string }>(
  'productClasses/updateProductClass', 
  async (productClassData, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append('name', productClassData.name);
    if (productClassData.image) {
      formData.append('image', productClassData.image);
    }

    const [error, response] = await API<UpdateProductClassResponse>('PATCH', `/class/update/${productClassData.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to update product class');
    }

    return response.data;
  }
);

export const removeProductClass = createAsyncThunk<void, number, { rejectValue: string }>(
  'productClasses/removeProductClass', 
  async (id, { rejectWithValue }) => {
    const [error, _] = await API<void>('DELETE', `/class/delete/${id}`);

    if (error) {
      console.error(error);
      return rejectWithValue('Failed to remove product class');
    }
  }
);

const productClassSlice = createSlice({
  name: 'productClasses',
  initialState: initialProductClassState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductClasses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductClasses.fulfilled, (state, action: PayloadAction<ProductClass[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchProductClasses.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchProductClassesPagination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductClassesPagination.fulfilled, (state, action) => {
        state.items = action.payload.productClasses;
        state.count = action.payload.count;
        state.status = 'loaded';
      })
      .addCase(fetchProductClassesPagination.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createProductClass.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(createProductClass.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.createStatus = 'succeeded';
      })
      .addCase(createProductClass.rejected, (state) => {
        state.createStatus = 'failed';
      })
      .addCase(updateProductClass.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateProductClass.fulfilled, (state, action) => {
        const updatedProductClass = action.payload.productClass;
        const index = state.items.findIndex(item => item.id === updatedProductClass.id);
        if (index !== -1) {
          state.items[index] = updatedProductClass;
        } else {
          state.items.push(updatedProductClass);
        }
        state.updateStatus = 'succeeded';
      })
      .addCase(updateProductClass.rejected, (state) => {
        state.updateStatus = 'failed';
      })
      .addCase(removeProductClass.fulfilled, (state, action) => {
        state.items = state.items.filter(item => item.id !== action.meta.arg);
      });
  },
});

export const productClassReducer = productClassSlice.reducer;
