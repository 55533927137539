import React, { useState } from 'react';
import { Drawer, Box, IconButton, Paper, Typography, Button, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, InputAdornment, FormControlLabel, Checkbox, Radio, RadioGroup } from '@mui/material';
import { Color } from '../../../redux/slices/color';
import { Size } from '../../../redux/slices/size';
import { Filter } from '../../../redux/slices/posts';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

interface FilterProps {
  filters: Filter;
  colors: Color[];
  sizes: Size[];
  onFilterChange: (key: keyof Filter, value: any) => void;
  resetFilters: () => void; 
}
  

const FilterComponent: React.FC<FilterProps> = ({ 
  filters,
  colors, 
  sizes, 
  onFilterChange,
  resetFilters,
}) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSortChange = (event: SelectChangeEvent<"asc" | "desc" | null>) => {
    onFilterChange('sort', event.target.value); 
  };

  const handleResetSort = () => {
    onFilterChange('sort', null); 
  };

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    onFilterChange('colorId', event.target.value);
  };

  const handleResetColor = () => {
    onFilterChange('colorId', null);
  };

  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    onFilterChange('sizeId', event.target.value);
  };

  const handleResetSize = () => {
    onFilterChange('sizeId', null);
  };

  const handleOnSaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilterChange('onSale', value === 'all' ? null : value === 'sale');
  };


  return (
    <Box>
      <IconButton
        onClick={handleDrawerOpen}
        sx={{
          color: 'black',
          fontSize: '2rem',
          '& svg': {
            fontSize: 'inherit',
          },
        }}
      >
        <FilterListOutlinedIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        variant="temporary"
        sx={{
          '& .MuiDrawer-paper': {
          width: 300, 
          overflow: 'auto'
          },
        }}
      >
        <Paper elevation={6} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            p: 2,
        }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                <Typography variant="h3" sx={{ flexGrow: 1 }}>
                    Filtre
                </Typography>
                <Button
                  variant="contained"
                  onClick={resetFilters}
                  sx={{
                    ml: 1,
                    bgcolor: 'black',
                    color: 'white',
                    borderRadius: '20px',
                    ':hover': {
                      bgcolor: 'darkgrey',
                    },
                  }}
                >
                    Șterge toate
                </Button>
            </Box>
            <Divider sx={{ width: '100%', my: 2, }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                p: 1,
                overflowY: 'auto',
                gap: 2,
            }}>
              {/* Sort by Price Section */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: '100%',
                mb: 1,
                gap: 1,
              }}>
                <Typography variant="h4">
                  Sortare Pret:
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>Sortare după preț</InputLabel>
                  <Select
                    value={filters.sort === null ? '' : filters.sort} 
                    onChange={handleSortChange}
                    label="Sortare după preț"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleResetSort}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="asc">Preț Mic spre Mare</MenuItem>
                    <MenuItem value="desc">Preț Mare spre Mic</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                gap: 1,
              }}>
                <Typography variant="h4">
                  Promoții
                </Typography>
                <FormControl fullWidth>
                  <RadioGroup value={filters.onSale === null ? 'all' : filters.onSale ? 'sale' : 'notSale'} onChange={handleOnSaleChange}>
                    <FormControlLabel value="all" control={<Radio />} label="Toate produsele" />
                    <FormControlLabel value="sale" control={<Radio />} label="Doar la reducere" />
                    <FormControlLabel value="notSale" control={<Radio />} label="Fără reducere" />
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* Filter by Color Section */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: '100%',
                mb: 1,
                gap: 1,
              }}>
                <Typography variant="h4">
                  Filtrare după culoare:
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="color-label">Alege o culoare</InputLabel>
                  <Select
                    value={filters.colorId === null ? '' : filters.colorId?.toString()}
                    onChange={handleColorChange}
                    label="Alege o culoare"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleResetColor}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {colors.map((color) => (
                      <MenuItem key={color.id} value={color.id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box sx={{
                            width: 20, 
                            height: 20, 
                            borderRadius: '50%', 
                            bgcolor: color.colorCode,
                          }} />
                          <Typography>{color.name}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Filter by Size Section */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: '100%',
                mb: 1,
                gap: 1,
              }}>
                <Typography variant="h4">
                  Filtrare după mărime:
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="size-label">Alege o mărime</InputLabel>
                  <Select
                    value={filters.sizeId === null ? '' : filters.sizeId?.toString()}
                    onChange={handleSizeChange}
                    label="Alege o mărime"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={handleResetSize}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {sizes.map((size) => (
                      <MenuItem key={size.id} value={size.id}>{size.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Button
              variant="contained"
              onClick={handleDrawerClose}
              sx={{
                mt: 2,
                bgcolor: 'black',
                color: 'white',
                borderRadius: '20px',
                fontSize: '16px',
                ':hover': {
                  bgcolor: 'darkgrey',
                },
                alignSelf: 'center' 
              }}
            >
              Închide Filtre
            </Button>

        </Paper>
      </Drawer>
    </Box>
  );
}

export default FilterComponent;
