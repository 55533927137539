import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Box, Divider, Tooltip } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { RootState } from '../../redux/store';
import { fetchColors } from '../../redux/slices/color';
import { fetchSizes } from '../../redux/slices/size';
import OrderInfo from '../../components/Client/order/OrderInfo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fetchCities } from '../../redux/slices/city';
import { fetchOrderDetails } from '../../redux/slices/userorders';


const OrderDetails = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const dispatch = useAppDispatch();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const selectedOrder = useSelector((state: RootState) => state.user_orders.selectedOrder);
    const isMobile = useMediaQuery('(max-width:960px)');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchOrderDetails(Number(orderId))).unwrap();
            } catch (err) {
                let error = err as { message: string; status: number };
                if (error.status === 403 || error.status === 404) {
                    navigate('/');
                }
            }
        };
        fetchData();
        dispatch(fetchColors());
        dispatch(fetchSizes());
        dispatch(fetchCities());
    }, [dispatch, orderId, navigate]);
      
    const products = selectedOrder?.orderDetails.map(detail => {
        const post = detail.Post;
        const productImage = post?.imageUrl && post?.imageUrl[0] ? `${baseUrl}${post.imageUrl[0]}` : '';
        const uniqueKey = `${detail.postId}-${detail.colorId}-${detail.sizeId}-${detail.quantity}`;

        return {
            key: uniqueKey,
            id: detail.postId,
            productName: post?.title || 'Unknown Product',
            originalPrice: detail.originalPrice,
            discountedPrice: detail.discountedPrice,
            discountPercentage: detail.discountPercentage,
            productImage: productImage,
            selectedQuantity: detail.quantity,
            selectedColorId: detail.colorId,
            selectedSizeId: detail.sizeId,
            selectedCityId: detail.cityId,
        };
    }) || [];
    
    if (isMobile) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: "1%",
            }}
            >
                {/* заголовки */}
                <Box sx={{
                    padding: '1em',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Box>
                        <Typography variant="h3">
                            Detalii comanda: {orderId}
                        </Typography>
                    </Box>
        
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '1em',
                        border: '1px solid rgba(0, 0, 0, 0.2)',  
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderRadius: '8px', 
                        mt: '1em', 
                        width: '100%'
                    }}>
                        <Typography variant="h3">
                            Stare comanda:
                        </Typography>
        
                        <Typography variant="h3" color={'green'}>
                            {selectedOrder?.status}
                        </Typography>
                    </Box>
                </Box>

                {/* детали */}
                <Box sx={{
                    padding: '1em',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {/* Adresa de livrare */}
                    <Box sx={{
                        padding: '1em',
                        border: '1px solid rgba(0, 0, 0, 0.2)',  
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                        borderRadius: '8px', 
                        mt: '1em', 
                        width: '100%'

                    }}>
                        <Typography variant="h4" mb={2}>
                            Adresa de livrare:
                        </Typography>
                        <Divider />
                        <Box mt={2}>
                            <Typography variant="h5">Adresa: {selectedOrder?.deliveryAddress}</Typography>
                            <Typography variant="h5">Oras: {selectedOrder?.deliveryCity}</Typography>
                            <Typography variant="h5">Cod postal: {selectedOrder?.deliveryPostalCode}</Typography>
                            <Typography variant="h5">Tara: {selectedOrder?.deliveryCountry}</Typography>
                            <Typography variant="h5">Telefon: {selectedOrder?.deliveryPhone}</Typography>
                        </Box>
                    </Box>

                    <Box width={"100%"} mt={'1em'}>
                        <OrderInfo products={products} />
                    </Box>

                    {/* Metoda de plata / Livrare */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: '1em',
                        width: '100%'
                    }}>
                        <Box sx={{
                            padding: '1em',
                            border: '1px solid rgba(0, 0, 0, 0.2)',  
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                            borderRadius: '8px', 
                            mr: '1em', 
                            width: '58%'

                        }}>
                            <Typography variant="h4" mb={2}>
                                Metoda de livrare:
                            </Typography>
                            <Divider />
                            <Box mt={2}>
                                <Typography variant="h4">{selectedOrder?.deliveryType.name}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            padding: '1em',
                            border: '1px solid rgba(0, 0, 0, 0.2)',  
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                            borderRadius: '8px', 
                            width: '42%'
                        }}>
                            <Typography variant="h4" mb={2}>
                                Metoda de plata:
                            </Typography>
                            <Divider />
                            <Box mt={2}>
                                <Typography variant="h4">{selectedOrder?.paymentType.name}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Sumar comanda */}
                    <Box sx={{
                        padding: '1em',
                        border: '1px solid rgba(0, 0, 0, 0.2)',  
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                        borderRadius: '8px', 
                        mt: '1em', 
                        width: '100%'
                    }}>
                        <Typography variant="h4" mb={2}>
                            Sumar comanda:
                        </Typography>
                        <Divider />
                        <Box mt={2}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h4">Subtotal:</Typography>
                            <Typography variant="h4">{selectedOrder?.price.toFixed(2)} Lei</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography variant="h4">Pret Livrare:</Typography>
                            <Typography variant="h4">{selectedOrder?.deliveryType.price} Lei</Typography>
                        </Box>
                        {selectedOrder?.discount && (
                            <Box display="flex" justifyContent="space-between" mt={1}>
                                <Typography variant="h4">Reducere:</Typography>
                                <Typography variant="h4">
                                    {selectedOrder.discount.discount_percentage}
                                </Typography>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography variant="h4">Pret total:</Typography>
                            <Typography variant="h4">
                                {selectedOrder?.finalCost.toFixed(2)} Lei
                            </Typography>
                        </Box>
                    </Box>
                    </Box>

                </Box>

            </Box>
        );
    }

    return (
        <Box padding={10}>
            <Box display="flex" alignItems="center">
                {/* Левая часть с заголовками */}
                <Box flex={1}>
                    <Typography variant="h2" mb={2}>
                    Detalii comanda: {orderId}
                    </Typography>
                    <Typography variant="h3" mb={2}>
                    Produse comandate
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" ml={3} gap={3}>
                    {/* Статус заказа */}
                    <Box display="flex" gap={2} flexDirection={'column'} textAlign={"center"}>
                        <Typography variant="h3">
                            Stare comanda:
                        </Typography>
                        <Box
                            sx={{
                                border: '2px solid green', 
                                borderRadius: '10px',      
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',  
                                cursor: 'pointer',  
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', 
                                },
                                padding: '5px',
                            }}
                        >
                            <Typography variant="h3" color={'green'}>
                                {selectedOrder?.status}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Cod Livrare */}
                    {selectedOrder?.codLivrare ? (
                        <Tooltip title="Codul de livrare va fi fisponibil odata ce comanda va fi primita de curier" arrow>
                            <Box display="flex" gap={2} flexDirection={'column'} textAlign={"center"}>
                                <Typography variant="h3">
                                    Cod Livrare:
                                </Typography>
                                <Box
                                    sx={{
                                        border: '2px solid green', 
                                        borderRadius: '10px',      
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
                                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',  
                                        cursor: 'pointer',  
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', 
                                        },
                                        padding: '5px',
                                    }}
                                >
                                    <Typography variant="h3" color={'green'}>
                                        {selectedOrder.codLivrare}
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Codul de livrare va fi fisponibil odata ce comanda va fi primita de curier" arrow>
                            <Box display="flex" gap={2} flexDirection={'column'} textAlign={"center"}>
                                <Typography variant="h3">
                                    Cod Livrare:
                                </Typography>
                                <Box
                                    sx={{
                                        border: '2px solid green', 
                                        borderRadius: '10px',      
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
                                        cursor: 'not-allowed',  
                                        padding: '5px',
                                    }}
                                >
                                    <Typography variant="h3" color={'green'}>
                                        -
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </Box>

            <OrderInfo products={products} />
            
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" mt={3}>
            {/* Adresa de livrare */}
            <Box sx={{flexBasis: '23%', padding: 1, border: '1px solid black', borderRadius: '10px'}}>
                <Typography variant="h3" mb={2}>
                    Adresa de livrare:
                </Typography>
                <Divider />
                <Box mt={2}>
                    <Typography variant="h4">Adresa: {selectedOrder?.deliveryAddress}</Typography>
                    <Typography variant="h4">Oras: {selectedOrder?.deliveryCity}</Typography>
                    <Typography variant="h4">Cod postal: {selectedOrder?.deliveryPostalCode}</Typography>
                    <Typography variant="h4">Tara: {selectedOrder?.deliveryCountry}</Typography>
                    <Typography variant="h4">Telefon: {selectedOrder?.deliveryPhone}</Typography>
                </Box>
            </Box>

           {/* Metoda de livrare */}
            <Box sx={{flexBasis: '23%', padding: 1, border: '1px solid black', borderRadius: '10px'}}>
                <Typography variant="h3" mb={2}>
                    Metoda de livrare:
                </Typography>
                <Divider />
                <Box mt={2}>
                    {selectedOrder?.deliveryType ? (
                        <Typography variant="h4">{selectedOrder?.deliveryType.name}</Typography>
                    ) : (
                        <Typography variant="h4">N/A</Typography>
                    )}
                </Box>
            </Box>

            {/* Metoda de plata */}
            <Box sx={{flexBasis: '23%', padding: 1, border: '1px solid black', borderRadius: '10px'}}>
                <Typography variant="h3" mb={2}>
                    Metoda de plata:
                </Typography>
                <Divider />
                <Box mt={2}>
                    {selectedOrder?.paymentType ? (
                        <Typography variant="h4">{selectedOrder?.paymentType.name}</Typography>
                    ) : (
                        <Typography variant="h4">N/A</Typography>
                    )}
                </Box>
            </Box>

            {/* Sumar comanda */}
            <Box sx={{ flexBasis: '23%', padding: 1, border: '1px solid black', borderRadius: '10px' }}>
                <Typography variant="h3" mb={2}>
                    Sumar comanda:
                </Typography>
                <Divider />
                <Box mt={2}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h4">Subtotal:</Typography>
                        <Typography variant="h4">{selectedOrder?.originalPrice.toFixed(2)} Lei</Typography>
                    </Box>
                    {selectedOrder?.discount && (
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography variant="h4">Reducere:</Typography>
                            <Box>
                                <Typography variant="h4" component="span" color="red">
                                    {selectedOrder.discount.discount_percentage} %
                                </Typography>
                                <Typography variant="h4" component="span" color="red" sx={{ marginLeft: 1 }}>
                                    (- {selectedOrder.discountAmount?.toFixed(2)} Lei)
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box display="flex" justifyContent="space-between" mt={1}>
                        <Typography variant="h4">Pret Livrare:</Typography>
                        <Typography variant="h4">{selectedOrder?.deliveryType?.price || "N/A"} Lei</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                        <Typography variant="h4">Pret total:</Typography>
                        <Typography variant="h4">
                            {selectedOrder?.finalCost.toFixed(2)} Lei
                        </Typography>
                    </Box>
                </Box>
            </Box>

            </Box>
        </Box>
    );
};
    
    export default OrderDetails;