import React, { useEffect, useState } from 'react';
import { Box, Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountOverview from '../../components/Account/MyProfile/AccountOverview';
import DetailEdit from '../../components/Account/MyProfile/DetailEdit';
import NeedHelp from '../../components/Account/MyProfile/NeedHelp';
import FAQ from '../../components/Account/MyProfile/FAQ';
import Notifications from '../../components/Account/MyProfile/Notifications';
import Discount from '../../components/Account/MyProfile/Discount';
import { useAppDispatch } from '../../redux/store';
import { logout } from '../../redux/slices/auth';
import PasswordEdit from '../../components/Account/MyProfile/PasswordEdit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

export type ContentName = 'AccountOverview' | 'MyOrders' | 'DetailEdit' | 'PasswordEdit' | 'Notifications' | 'Discount' | 'NeedHelp' | 'FAQ';

const MyAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { section } = useParams<{ section?: ContentName }>();
  const [activeContent, setActiveContent] = useState<ContentName>(section || 'AccountOverview');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (section) {
      setActiveContent(section);
    }
  }, [section]);

  const changeContent = (contentName: ContentName) => {
    setActiveContent(contentName);
  };

  const onClickLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      dispatch(logout());
      window.localStorage.removeItem('token');
      navigate('/Authentication/Login');
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: ContentName) => {
    changeContent(newValue);
    navigate(`/MyAccount/${newValue}`);
  };

  if (isMobile) {

    return (
      <Box>

      </Box>
    );
  }

  return (
    <Container sx={{ height: '100%', position: 'relative', paddingY: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        <Box sx={{ alignSelf: 'center' }}>
          <Tabs
            value={activeContent}
            onChange={handleTabChange}
            sx={{ marginBottom: 3 }}
            centered
          >
            {/* Вкладки */}
            <Tab label={<Typography variant="h4">Detalii Cont</Typography>} value="AccountOverview" icon={<FolderSharedOutlinedIcon />} />
            <Tab label={<Typography variant="h4">Notificari</Typography>} value="Notifications" icon={<NotificationsIcon />} />
            <Tab label={<Typography variant="h4">Reduceri</Typography>} value="Discount" icon={<DiscountOutlinedIcon />} />
            <Tab label={<Typography variant="h4">Setari</Typography>} value="DetailEdit" icon={<SettingsIcon />} />
            <Tab label={<Typography variant="h4">Parola</Typography>} value="PasswordEdit" icon={<LockOutlinedIcon />} />
            <Tab label={<Typography variant="h4">Ajutor</Typography>} value="NeedHelp" icon={<ContactSupportOutlinedIcon />} />
            <Tab label={<Typography variant="h4">Intrebari</Typography>} value="FAQ" icon={<InfoOutlinedIcon />} />
            <Tab label={<Typography variant="h4">Iesire</Typography>} icon={<LogoutIcon />} onClick={onClickLogout} />
          </Tabs>
        </Box>

        <Box sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
          {activeContent === 'AccountOverview' && <AccountOverview />}
          {activeContent === 'Notifications' && <Notifications />}
          {activeContent === 'Discount' && <Discount />}
          {activeContent === 'NeedHelp' && <NeedHelp />}
          {activeContent === 'FAQ' && <FAQ />}
          {activeContent === 'DetailEdit' && <DetailEdit />}
          {activeContent === 'PasswordEdit' && <PasswordEdit />}
        </Box>
      </Box>
    </Container>
  );
};

export default MyAccount;
