import React from 'react'
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'; 
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import { Order, getUserPaginationOrders } from '../../redux/slices/userorders';
import Spinner from '../../components/spinner/Spinner';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowId, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';


const MyOrders = () => {
  const dispatch = useAppDispatch();
  const userOrdersData = useAppSelector((state: RootState) => state.user_orders.items);
  const ordersStatus = useAppSelector((state: RootState) => state.user_orders.status);
  const [checkState, setCheckState] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const Count = useAppSelector((state: RootState) => state.user_orders.count);
  const isDataGridLoading = ordersStatus === 'loading';
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
      page: page,
      pageSize: perPage,
    });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    dispatch(getUserPaginationOrders({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
  }, [dispatch, paginationModel]);


  if (ordersStatus === 'loading') {
    return <Spinner />;
  }

  const columns: GridColDef[] = [
    {
      field: 'Comanda',
      headerName: 'Comanda',
      flex: 1,
    },
    {
      field: 'Data',
      headerName: 'Data',
      flex: 2,
    },
    {
      field: 'Totalul',
        headerName: 'Totalul',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value} lei`,
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any>) => {
          let color;
          switch (params.row.Status) {
              case 'Nou':
                  color = 'red';
                  break;
              case 'In verificare':
                  color = 'brown';
                  break;
              case 'In livrare':
                  color = 'orange';
                  break;
              case 'Livrat':
                  color = 'green';
                  break;
              default:
                  color = 'black'; 
          }

          return (
              <Typography variant='h4' style={{ color }}>
                  {params.row.Status}
              </Typography>
          );
      },
    },
    {
      field: 'CodLivrare',
      headerName: 'Cod Livrare',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
          return (
              <Typography variant='h4'>
                  {params.row.CodLivrare || '-'} 
              </Typography>
          );
      },
    },
    {
      field: 'Detalii',
      headerName: 'Detalii',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/OrderDetails/${params.row.Comanda}`}
        >
          Detalii
        </Button>
      ),
    },
  ];

  const rows = userOrdersData.map((order:Order) => {
    return {
      id: order.Id,
      Comanda: order.Id,
      Data: order.createdAt,
      Totalul: order.finalCost,
      Status: order.status,
      CodLivrare: order.codLivrare,
    };
  });

  const mobilecolumns: GridColDef[] = [
    {
      field: 'Comanda',
      headerName: 'Id',
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'Totalul',
      headerName: 'Total',
      flex: 2,
      filterable: false,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value} lei`,
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: 'Detalii',
      headerName: 'Detalii',
      flex: 2,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/OrderDetails/${params.row.Comanda}`}
        >
          Detalii
        </Button>
      ),
    },
  ];

const MyCustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        style={{ fontSize: '15px' }}
      />
      <GridToolbarFilterButton
        style={{ fontSize: '15px' }}
      />
      <GridToolbarDensitySelector
        style={{ fontSize: '15px' }}
      />
    </GridToolbarContainer>
  );
};

const MyMobileCustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        style={{ fontSize: '13px' }}
      />
      <GridToolbarDensitySelector
        style={{ fontSize: '13px' }}
      />
    </GridToolbarContainer>
  );
};

const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
  setPaginationModel(newPaginationModel);
};


if (isMobile) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      height: '100vh',
      p: '2%'
    }}>
      {/* HEADER */}
      <Toolbar sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
      >
          <Typography  variant="h2" id="tableTitle" component="div">
              Comenzi
          </Typography>
      </Toolbar>
      <Box style={{ height: "100%", width: '100%' }}>
        <DataGrid
          paginationMode="server"
          rows={rows}
          columns={mobilecolumns}
          initialState={{
              pagination: {
                paginationModel: {
                  pageSize: perPage,
                  page: page
                },
              },
            }}
          pageSizeOptions={[10, 15, 25]}
          rowCount={Count}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          checkboxSelection={false}
          onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
              setRowSelectionModel(newSelectionModel as string[]);
          }}
          rowSelectionModel={rowSelectionModel}
          loading={isDataGridLoading}
          slots={{
              toolbar: MyMobileCustomToolbar,
            }}
          sx={{ fontSize: '13px' }}
        />
      </Box>
    </Box>
  );
}


return (
  <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      height: '100vh',
      p: '2%'
  }}>
      {/* HEADER */}
      <Toolbar
          sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
          }}
      >
          <Typography sx={{ flex: '1 1 100%' }} variant="h2" id="tableTitle" component="div">
              Comenzi
          </Typography>
      </Toolbar>

      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
          paginationMode="server"
          rows={rows}
          columns={columns}
          initialState={{
              pagination: {
                paginationModel: {
                  pageSize: perPage,
                  page: page
                },
              },
          }}
          rowHeight={65}
          pageSizeOptions={[10, 15, 25]}
          rowCount={Count}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          checkboxSelection={checkState}
          onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
              setRowSelectionModel(newSelectionModel as string[]);
          }}
          rowSelectionModel={rowSelectionModel}
          loading={isDataGridLoading}
          slots={{
              toolbar: MyCustomToolbar,
            }}
          sx={{ fontSize: '18px' }}
        />
      </Box>
  </Box>
);
};

export default MyOrders