import React, { useState } from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, IconButton, Rating, Typography, useTheme } from '@mui/material';
import { CartItems } from '../../../redux/slices/produse';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DiscountIcon from '@mui/icons-material/Discount';
import { selectIsAuthenticated } from '../../../redux/slices/auth';
import { useAppSelector } from '../../../redux/store';
import DeleteIcon from '@mui/icons-material/Delete';

interface CartListProps {
  cartItem: CartItems;
  handleAddToFavorites: (productId: number) => void;
  handleRemoveFromFavorites: (productId: number) => void;
  handleRemoveFromCart: (cartId: number) => void;
  handleUpdateCartQuantity: (cartId: number, quantity: number) => void;
}

const CartItem: React.FC<CartListProps> = ({ 
  cartItem,
  handleAddToFavorites,
  handleRemoveFromFavorites,
  handleRemoveFromCart,
  handleUpdateCartQuantity
}) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
  const imageUrl = cartItem.Product.imageUrl[0] ? `${baseUrl}${cartItem.Product.imageUrl[0]}` : '/path/to/default/image.jpg'; 
  const product = cartItem.Product
  const inventory = product.inventories?.find(inv => inv.id === cartItem.inventoryId);
  const discountedPrice = product.hasActiveDiscount && product.discount ? (product.price - (product.price * product.discount.percentage / 100)).toFixed(2) : product.price.toFixed(2);


  const handleImageClick = () => {
    navigate(`/ProductPage/${cartItem.productId}`);
  };

  const handleFavoriteClick = () => {
    if (product.isFavorite) {
        handleRemoveFromFavorites(product.id);
    } else {
        handleAddToFavorites(product.id);
    }
  };

  const handleDecreaseQuantity = () => {
    if (cartItem.quantity > 1) {
      handleUpdateCartQuantity(cartItem.Id, cartItem.quantity - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    const availableStock = inventory?.count ?? 0;
    if (cartItem.quantity < availableStock) {
      handleUpdateCartQuantity(cartItem.Id, cartItem.quantity + 1);
    }
  };

  return (
    <Card sx={{
      position: 'relative', 
      maxWidth: 320,
      transition: '0.3s',
      '&:hover': {
        transform: 'scale(1.03)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
        '.MuiCardHeader-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }
      }
    }}>
      {!cartItem.inStock && (
        <Box sx={{
          position: 'absolute', 
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(255, 0, 0, 0.7)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          pointerEvents: 'none'
        }}>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>Stoc Epuizat</Typography>
        </Box>
      )}

      <CardHeader
        action={
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: product.hasActiveDiscount ? 'center' : 'flex-end',
                    width: '100%' 
                }}>
                    {product.hasActiveDiscount && (
                        <IconButton aria-label="discount">
                            <DiscountIcon color="error" />
                        </IconButton>
                    )}
                      <IconButton
                        aria-label="delete product"
                        sx={{ color: 'error.main' }}
                        onClick={() => handleRemoveFromCart(cartItem.Id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                     <IconButton
                        aria-label="add to favorites"
                        onClick={handleFavoriteClick}
                        disabled={!isAuthenticated}
                    >
                        <FavoriteIcon color={product.isFavorite && isAuthenticated ? 'error' : 'disabled'} />
                    </IconButton>
                </Box>
                <Rating
                    name={`product-rating-${product.id}`}
                    value={product.averageRating}
                    precision={0.5}
                    readOnly={true}
                />
            </Box>
        }
        title={product.title}
      />

      <CardMedia
        component="img"
        height="auto"
        image={imageUrl}
        alt={cartItem.Product.title}
        onClick={handleImageClick}
        sx={{ cursor: 'pointer' }}
      />

      <CardContent>
        <Box sx={{ 
            display: 'flex',
            justifyContent: product.hasActiveDiscount ? 'space-between' : 'center',
            alignItems: 'center',
            width: '100%',
         }}>
            {product.hasActiveDiscount && (
                <Typography variant="h5" color="text.primary" sx={{ textDecoration: 'line-through', flexGrow: 1, textAlign: 'left' }}>
                    {product.price.toFixed(2)} Lei
                </Typography>
            )}
            <Typography variant="h5" color={product.hasActiveDiscount ? 'error' : 'text.primary'} sx={{ flexGrow: product.hasActiveDiscount ? 1 : 0, textAlign: 'right' }}>
                {discountedPrice} Lei
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, width: '100%' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" color="text.secondary">Culoare: {inventory?.Color?.name}</Typography>
                <Box sx={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: inventory?.Color?.colorCode, ml: 1 }} />
            </Box>
            <Typography variant="h6" color="text.secondary">Mărime: {inventory?.Size?.name}</Typography>
            <Typography variant="h6" color="text.secondary">Oras: {inventory?.City?.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleDecreaseQuantity} disabled={cartItem.quantity <= 1}>
              <RemoveIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>{cartItem.quantity}</Typography>
            <IconButton onClick={handleIncreaseQuantity} disabled={cartItem.quantity >= (inventory?.count || 1)}>
              <AddIcon />
            </IconButton>            
          </Box>
        </Box>

        <Typography variant="h5" color="text.primary" sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
          Total: {cartItem.price.toFixed(2)} Lei
        </Typography>
      </CardContent>

    </Card>
  );
};

export default CartItem;
