import axios from "axios";


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('token');
    const adminToken = window.localStorage.getItem('admin_token');
    
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (adminToken) {
        config.headers['Admin-Authorization'] = `Bearer ${adminToken}`;
    }

    return config;
});

instance.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
            // Изменено на /auth/reToken в соответствии с новым эндпоинтом
            const { data } = await axios.get(`${instance.defaults.baseURL}/auth/reToken`, {
                headers: {
                    Authorization: originalRequest.headers['Authorization'] 
                }
            });

            const newToken = data.accessToken;

            localStorage.setItem('token', newToken);

            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return instance(originalRequest);
        } catch (refreshError) {
            console.error('Не удалось обновить токен:', refreshError);
            localStorage.removeItem('token');

            // Создание и отправка события 'logout'
            window.dispatchEvent(new CustomEvent('logout'));
            
            window.location.href = '/Authentication'; 
            return Promise.reject(refreshError);
        }
    }

    return Promise.reject(error);
});

export default instance;
