import React, { useEffect, useState } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Rating, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { getProductAndRating } from '../../../redux/slices/rating'
import { DataGrid, GridColDef, GridRowId, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';
import Spinner from '../../spinner/Spinner';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { updateRatingVisibility } from '../../../redux/slices/rating';

export interface RatingProps {
  id: number;
  value: number;
  UserName: String;
  comment?: string;
  createdAt?: string;
  imageUrl: string[]; 
  ProductTitle: String;
}

const RatingProductAdmin = () => {
    const dispatch = useAppDispatch();
    const rating = useAppSelector((state: RootState) => state.rating.items);
    const Count = useAppSelector((state: RootState) => state.rating.count);
    const ratingStatus = useAppSelector((state: RootState) => state.rating.status);
    const isMobile = useMediaQuery('(max-width: 1000px)');
    const isDataGridLoading = ratingStatus === 'loading';
    const [checkState, setCheckState] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<Array<string>>([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
        });  
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState<{
        comment: string;
        photos: string[];
    }>({
        comment: '',
        photos: [],
    });
    const [openMobileDetailsDialog, setOpenMobileDetailsDialog] = useState(false);
    const [selectedMobileDetails, setSelectedMobileDetails] = useState<RatingProps>({
      id: 0,
      value: 0,
      UserName: '',
      imageUrl: [],
      comment: '',
      ProductTitle: '',
      createdAt: '',
     
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;


    useEffect(() => {
        dispatch(getProductAndRating({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
  }, [dispatch, paginationModel]);
                
      if (ratingStatus === 'loading') {
        return <Spinner />;
      }

      const handleViewDetails = (row: any) => {
        setSelectedDetails({
          comment: row.RatingText,
          photos: row.RatingPhoto,
        });
        setOpenDetailsDialog(true);
      };

      const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
      };

      const handleVisibilityToggle = async (row: any) => {
        try {
          // Toggle the isHidden status
          const updatedIsHidden = !row.isHidden;      
          // Dispatch the updateHiddenState action
          await dispatch(updateRatingVisibility({ ratingId: row.RatingId, isHidden: updatedIsHidden }));
      
          // Optionally, you can refresh the messages after updating the hidden state
          dispatch(getProductAndRating({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
      
        } catch (error) {
        }
      };

      const columns: GridColDef[] = [
        {
          field: 'User',
          headerName: 'Utilizator',
          flex: 1,
        },
        {
          field: 'ProductTitle',
          headerName: 'Produs',
          flex: 1,
        },
        {
            field: 'RatingValue',
            headerName: 'Nota',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Rating name={`rating-${params.row.id}`} value={params.row.RatingValue} readOnly />
                </Box>
              );
            },
        },
        {
          field: 'Details',
          headerName: 'Detalii',
          flex: 1,
          renderCell: (params: GridRenderCellParams<any, any>) => {
            const photos = params.row.RatingPhoto;
            const hasComment = params.row.RatingText !== '-';
            const hasPhotos = Array.isArray(photos) && photos.length > 0;
            const isHidden = params.row.isHidden; 
            
            const handleDetailsClick = (event: React.MouseEvent) => {
              event.stopPropagation();
              handleViewDetails(params.row);
            };

            const handleVisibilityClick = (event: React.MouseEvent) => {
              event.stopPropagation(); 
              handleVisibilityToggle(params.row);
            };
      
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={handleDetailsClick}
                  sx={{ color: hasComment || hasPhotos ? 'blue' : 'grey', cursor: hasComment || hasPhotos ? 'pointer' : 'not-allowed' }}
                  disabled={!hasComment && !hasPhotos}
                >
                  <LaunchIcon />
                </IconButton>
                {/* VisibilityOnIcon if isHidden is false, VisibilityOffIcon if isHidden is true */}
                {isHidden ? (
                  <Tooltip title="Hidden" arrow>
                    <span>
                      <IconButton
                        onClick={handleVisibilityClick}
                        sx={{ color: 'red', cursor: 'pointer' }}
                      >
                        <VisibilityOffIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Visible" arrow>
                    <span>
                      <IconButton
                        onClick={handleVisibilityClick}
                        sx={{ color: 'green', cursor: 'pointer' }}
                      >
                        <VisibilityIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            );
          },
        },
        {
          field: 'createdAt',
          headerName: 'Creat',
          flex: 1,
          renderCell: (params: GridRenderCellParams<any, any>) => {
            const rawDateTime = params.row.createdAt || '';
            const [date, time] = rawDateTime.split(' ');
      
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <Typography variant='h3' sx={{ flex: '1 1 100%' }}>{date}</Typography>
                <Typography variant='h3' sx={{ flex: '1 1 100%', marginLeft: '10px' }}>
                  {time}
                </Typography>
              </Box>
            );
          },
        },
      ];

      const MobileColumns: GridColDef[] = [
        {
          field: 'User',
          headerName: 'Utilizator',
          flex: 1,
        },
        {
          field: 'Details',
          headerName: 'Detalii',
          flex: 1,
          renderCell: (params: GridRenderCellParams<any, any>) => {
            const isHidden = params.row.isHidden; 

            const handleMobDetailsClick = (event: React.MouseEvent) => {
              event.stopPropagation();
              handleMobileDetailsClick(params.row);
            };

            const handleVisibilityClick = (event: React.MouseEvent) => {
              event.stopPropagation(); 
              handleVisibilityToggle(params.row);
            };
      
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={handleMobDetailsClick}
                  sx={{ color: 'blue', cursor: 'pointer' }}
                >
                  <LaunchIcon />
                </IconButton>
                {/* VisibilityOnIcon if isHidden is false, VisibilityOffIcon if isHidden is true */}
                {isHidden ? (
                  <Tooltip title="Hidden" arrow>
                    <span>
                      <IconButton
                        onClick={handleVisibilityClick}
                        sx={{ color: 'red', cursor: 'pointer' }}
                      >
                        <VisibilityOffIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Visible" arrow>
                    <span>
                      <IconButton
                        onClick={handleVisibilityClick}
                        sx={{ color: 'green', cursor: 'pointer' }}
                      >
                        <VisibilityIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            );
          },
        },
      ];

      const rows = rating ? rating.map((item, index) => {
        const product = item.Post;
        const user = item.User;
      
        return {
            id: index,
            RatingId: item.Id,
            User: user.FullName,
            ProductTitle: product?.title,
            RatingValue: item.value,
            RatingText: item.comment || '-',
            RatingPhoto: Array.isArray(item.imageUrl) ? item.imageUrl : [],
            createdAt: item.createdAt || '',
            isHidden: item.isHidden,
            };
      }) : [];

      const MyCustomToolbar = () => {
        return (
          <GridToolbarContainer>
            {isMobile ? (
              <GridToolbarColumnsButton
                style={{ fontSize: '15px' }}
              />
            ) : (
              <>
                <GridToolbarColumnsButton
                  style={{ fontSize: '15px' }}
                />
                <GridToolbarFilterButton
                  style={{ fontSize: '15px' }}
                />
                <GridToolbarDensitySelector
                  style={{ fontSize: '15px' }}
                />
              </>
            )}
          </GridToolbarContainer>
        );
      };

      const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
      }; 

    {/* Mobile */}
    const handleMobileDetailsClick = (row: any) => {
        setSelectedMobileDetails({
            id: row.id,
            value: row.RatingValue,
            UserName: row.User,
            ProductTitle: row.ProductTitle,
            comment: row.RatingText,
            imageUrl: row.RatingPhoto,
            createdAt: row.createdAt
        });
        setOpenMobileDetailsDialog(true);
    };

    const handleCloseMobileDetailsDialog = () => {
        setOpenMobileDetailsDialog(false);
    };

    const MobileDetailsDialog = () => {
        return (
            <Dialog open={openMobileDetailsDialog} onClose={handleCloseMobileDetailsDialog} PaperProps={{
              style: {
                width: '80%',
                height: '80%',
                maxHeight: '90vh',
              },
            }}>
                <DialogContent>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2
                  }}>
                      {/* User */}
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5
                      }}>
                        <Typography variant="h3">Utilizator:</Typography> 
                        <Typography variant="h3"> {selectedMobileDetails.UserName}</Typography>
                      </Box>
                      {/* Product */}
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5
                      }}>
                        <Typography variant="h3">Produs:</Typography> 
                        <Typography variant="h3"> {selectedMobileDetails.ProductTitle}</Typography>
                      </Box>
                      {/* Nota */}
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5
                      }}>
                        <Typography variant="h3">Nota:</Typography> 
                        <Rating name={`rating-${selectedMobileDetails.id}`} value={selectedMobileDetails.value} readOnly sx={{
                          fontSize: '2.5rem'
                        }} />
                      </Box>
                      {/* Comentariu */}
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5
                      }}>
                        <Typography variant="h3">Comentariu:</Typography> 
                        <Typography variant="h3"> {selectedMobileDetails.comment}</Typography>
                      </Box>
                      {/* Poze */}
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center'
                      }}>
                          {selectedMobileDetails.imageUrl.map((photo, index) => (
                              <img
                                  key={index}
                                  src={`${baseUrl}${photo}`}
                                  alt={`Photo ${index + 1}`}
                                  style={{ width: '30%', marginRight: '10px' }}
                              />
                          ))}
                      </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMobileDetailsDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

      return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            height: isMobile ? '100%' : '90vh',
            p: isMobile ? 0 : "3%",
            width:'100%'
        }}>
            {/* HEADER */}
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} variant="h2" id="tableTitle" component="div">
                    Recenzii
                </Typography>
            </Toolbar>

            <Box style={{ height: "100%", width: '100%' }}>
                <DataGrid
                    paginationMode="server"
                    rows={rows}
                    columns={ isMobile ? MobileColumns : columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: perPage,
                            page: page
                        },
                        },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    rowCount={Count}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    checkboxSelection={isMobile ? false : checkState}
                    onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                        setRowSelectionModel(newSelectionModel as string[]);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    loading={isDataGridLoading}
                    slots={{
                        toolbar: MyCustomToolbar,
                    }}
                    sx={{ fontSize: '18px' }}
                />
            </Box>

             {/* Details Dialog */}
            <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog}>
                <DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2
                }}>
                    <Typography variant="h4">Comentariu: {selectedDetails.comment}</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {selectedDetails.photos.map((photo, index) => (
                            <img
                                key={index}
                                src={`${baseUrl}${photo}`}
                                alt={`Photo ${index + 1}`}
                                style={{ width: '30%', marginRight: '10px' }}
                                />
                            ))}
                        </Box>
                </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDetailsDialog} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>

            {/* Mobile Details Dialog */}
            {isMobile && <MobileDetailsDialog />}

        </Box>
    );
};

export default RatingProductAdmin