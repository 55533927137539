import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRole } from '../redux/slices/auth';

// Определяем типы пропсов
interface ProtectedRouteProps {
  component: React.ComponentType; 
  requiredLevel: number; 
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredLevel }) => {
  const userRole = useSelector(selectRole);

  // Проверяем, достаточно ли высок уровень доступа пользователя
  const isAllowed = userRole && userRole.level >= requiredLevel;

  // Если доступ разрешен, рендерим компонент, иначе перенаправляем на главную страницу
  return isAllowed ? <Component /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
