import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useAppDispatch, useAppSelector, RootState } from '../../../redux/store';
import { updateUser } from '../../../redux/slices/auth';

const DetailEdit = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.data?.user);
  const [name, setName] = useState(user?.FullName || '');
  const [phone, setPhone] = useState(user?.Telefon || '');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setIsChanged(name !== user?.FullName || phone !== user?.Telefon);
  }, [name, phone, user]);

  const handleSave = () => {
    if (isChanged) {
      dispatch(updateUser({
        FullName: name,
        Telefon: phone
      }));
    }
  };

  const handleCancel = () => {
    setName(user?.FullName || '');
    setPhone(user?.Telefon || '');
    setIsChanged(false);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value.replace(/(?!^\+)\D/g, ''); 
    setPhone(newPhone);
  };

  const inputStyle = { fontSize: '1.15rem' };
  const buttonStyle = { fontSize: '1.15rem' };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h2" sx={{ marginBottom: 2, textAlign: 'center', fontSize: '2rem' }}>
        Editare date personale
      </Typography>
      <Box component="form" sx={{ width: '100%', maxWidth: 400 }}>
        <TextField
          fullWidth
          label="Nume"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputProps={{ style: inputStyle }}
          InputLabelProps={{ style: inputStyle }}
          autoComplete="off"
        />
        <TextField
          fullWidth
          label="Numar de telefon"
          variant="outlined"
          margin="normal"
          value={phone}
          onChange={handlePhoneChange}
          InputProps={{ style: inputStyle }}
          InputLabelProps={{ style: inputStyle }}
          autoComplete="off"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            style={buttonStyle}
            disabled={!isChanged}
          >
            Salveaza
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCancel}
            style={buttonStyle}
            disabled={!isChanged}
          >
            Renunta
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailEdit;
