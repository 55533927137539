import { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Paper, Typography } from '@mui/material';
import ImageCarousel from '../../components/Admin/EditCreateProduct/ImageCarousel';
import SetProductData from '../../components/Admin/EditCreateProduct/SetProductData';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchColors } from '../../redux/slices/color';
import { fetchSizes } from '../../redux/slices/size';
import { fetchProductClasses } from '../../redux/slices/class';
import { fetchCities } from '../../redux/slices/city';
import { InventoryItem, InventoryDataCreate } from '../../redux/slices/inventory';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CreateProduct, GetSelectedProduct, Product, ProductData, UpdateProduct, resetSelectedProduct } from '../../redux/slices/productAdmin';


const EditCreateProduct = () => {
    const { id } = useParams<{ id?: string }>();
    const [newProductId, setNewProductId] = useState<number | null>(null);
    const dispatch = useAppDispatch();
    const selectedProduct = useAppSelector((state: RootState) => state.productsAdmin.selectedProduct.product);
    const [existingInventoryItems, setExistingInventoryItems] = useState<InventoryItem[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const createStatus = useAppSelector((state: RootState) => state.posts.createStatus);
    const colors = useAppSelector((state: RootState) => state.colors.items);
    const sizes = useAppSelector((state: RootState) => state.sizes.items);
    const productClasses = useAppSelector((state: RootState) => state.productClass.items);
    const cities = useAppSelector((state: RootState) => state.cities.items);
    const [productData, setProductData] = useState<ProductData>({
        title: '',
        text: '',
        price: 0,
        originalPrice: 0,
        discount: undefined,
        productClassId: 0,
        images: [],
        deletedImageUrl: [],  
        inventories: [],
        updatedInventories: [], 
        deletedInventoryIds: []  
    });
    const [inventoryItems, setInventoryItems] = useState<InventoryDataCreate[]>([]);
    const [updateInventoryItems, setUpdateInventoryItems] = useState<InventoryDataCreate[]>([]);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const combinedInventoryItems = useMemo(() => {
        const allItems = [
            ...existingInventoryItems.map(item => ({ ...item, type: 'existing' })),
            ...inventoryItems.map(item => ({ ...item, type: 'new' })),
            ...updateInventoryItems.map(item => ({ ...item, type: 'updated' }))
        ];
    
        // Сортируем элементы по ID для сохранения порядка
        allItems.sort((a, b) => a.id - b.id);
        return allItems;
    }, [existingInventoryItems, inventoryItems, updateInventoryItems]);
    const pageTitle = id ? "Editare Produs" : "Creare Produs";

    const inventoryHasChanged = () => {
        const validInventoryItem = (item: InventoryDataCreate) => item.ColorId > 0 && item.SizeId > 0 && item.CityId > 0 && item.count > 0;
    
        if (!selectedProduct) {
            return inventoryItems.length > 0 && inventoryItems.every(validInventoryItem);
        } else {
            const originalIds = new Set(selectedProduct.inventories?.map(item => item.id));
            const allItems = [...inventoryItems, ...updateInventoryItems];
            const allItemsValid = allItems.every(validInventoryItem);
    
            const idsChanged = 
                [...allItems, ...existingInventoryItems].some(item => !originalIds.has(item.id)) ||
                [...originalIds].some(id => !allItems.find(item => item.id === id) && !existingInventoryItems.find(item => item.id === id));
    
            const attributesChanged = allItems.some(item => {
                const originalItem = selectedProduct.inventories?.find(orig => orig.id === item.id);
                return originalItem && (
                    item.ColorId !== originalItem.ColorId ||
                    item.SizeId !== originalItem.SizeId ||
                    item.CityId !== originalItem.CityId ||
                    item.count !== originalItem.count
                );
            });
    
            return idsChanged || attributesChanged || !allItemsValid;
        }
    };
    const imagesHaveChanged = () => {
        const deletedImagesSet = new Set(productData.deletedImageUrl || []);
        const initiallyHadImages = !!selectedProduct?.imageUrl?.length;
    
        const allImagesDeleted = initiallyHadImages && selectedProduct.imageUrl?.every(url => deletedImagesSet.has(url));
        const newImagesAdded = productData.images.length > 0;
        const someImagesRetained = initiallyHadImages && selectedProduct.imageUrl?.some(url => !deletedImagesSet.has(url));
    
        const currentImagesSameAsOriginal = !newImagesAdded && !allImagesDeleted &&
            selectedProduct?.imageUrl?.every(url => imageUrls.includes(url)) && 
            imageUrls.every(url => selectedProduct.imageUrl.includes(url));
    
        if ((!newImagesAdded && allImagesDeleted) || currentImagesSameAsOriginal) {
            return false;
        }
    
        return true;
    };    
    const dataHasChanged = () => {
        if (!selectedProduct) return true;
        
        return productData.title !== (selectedProduct?.title || '') ||
               productData.text !== (selectedProduct?.text || '') ||
               productData.price !== (selectedProduct?.price || 0) ||
               productData.originalPrice !== (selectedProduct?.originalPrice || 0) ||
               productData.productClassId !== (selectedProduct?.productClassId || 0) ||
               JSON.stringify(productData.discount) !== JSON.stringify(selectedProduct?.discount) ||
               imagesHaveChanged() ||
               inventoryHasChanged(); 
    };
    const isButtonDisabled = useMemo(() => {
        const validInventoryItems = inventoryItems.every(item => 
            item.ColorId > 0 && item.SizeId > 0 && item.CityId > 0 && item.count > 0
        );

        const inventoryNotEmpty = inventoryItems.length > 0 || updateInventoryItems.length > 0 || existingInventoryItems.length > 0;

        const validDiscount = productData.discount ? 
            (productData.discount.percentage > 0 &&
            new Date(productData.discount.startDate) <= new Date(productData.discount.endDate)) : 
        true;
    
        if (!id) { 
            return !productData.title || !productData.text || productData.price <= 0 ||
                   productData.originalPrice <= 0 || productData.productClassId === 0 ||
                   inventoryItems.length === 0 || !validInventoryItems || productData.images.length === 0 ||
                   !validDiscount;
        }  else {
            return !dataHasChanged() || !inventoryNotEmpty || !validInventoryItems;
        }
    }, [productData, selectedProduct, combinedInventoryItems, id]);
    
    useEffect(() => {
        dispatch(fetchColors());
        dispatch(fetchSizes());
        dispatch(fetchProductClasses());
        dispatch(fetchCities());
    }, [dispatch, id]);

    useEffect(() => {
        setProductData({
            title: '',
            text: '',
            price: 0,
            originalPrice: 0,
            discount: undefined,
            productClassId: 0,
            images: [],
            deletedImageUrl: [],
            inventories: [],
            updatedInventories: [],
            deletedInventoryIds: []
        });
        setImageUrls([]);
        setInventoryItems([]);
        setUpdateInventoryItems([]);
        setExistingInventoryItems([]);
    
        if (id) {
            // Загрузка данных продукта если id есть в URL
            dispatch(GetSelectedProduct(Number(id))).then(action => {
                const product = action.payload as Product;
                if (product) {
                    setProductData({
                        title: product.title,
                        text: product.text,
                        price: product.price,
                        originalPrice: product.originalPrice || 0,
                        discount: product.discount,
                        productClassId: product.productClassId || 0,
                        inventories: [],
                        images: [], 
                        deletedImageUrl: [],
                        updatedInventories: [],
                        deletedInventoryIds: []
                    });
                    setImageUrls(product.imageUrl || []);
                    if (product.inventories) {
                        setExistingInventoryItems(product.inventories);
                    }
                }
            });
        } else {
            resetAll();
        }
    }, [dispatch, id]); 
    

    const handleImageUpload = (files: File[]) => {
        setProductData(prev => ({
            ...prev,
            images: [...prev.images, ...files]
        }));
    };

    const handleRemoveAllImages = () => {
        setImageUrls([]);
        setProductData(prev => ({
            ...prev,
            images: [],
            deletedImageUrl: [...(prev.deletedImageUrl || []), ...imageUrls]
        }));
    };

    const handleRemoveImage = (index: number) => {
        if (index < imageUrls.length) {
            const urlToRemove = imageUrls[index];
            setImageUrls(prev => prev.filter((_, idx) => idx !== index));
            setProductData(prev => ({
                ...prev,
                deletedImageUrl: [...(prev.deletedImageUrl || []), urlToRemove]
            }));
        } else {
            const localIndex = index - imageUrls.length;
            setProductData(prev => ({
                ...prev,
                images: prev.images.filter((_, idx) => idx !== localIndex)
            }));
        }
    };

    const handleChange = (name: keyof ProductData, value: any) => {
        if (name === 'discount' && typeof value === 'object') {
            setProductData(prev => ({
                ...prev,
                discount: {
                    ...prev.discount,
                    ...value
                }
            }));
        } else {
            setProductData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleInventoryChange = (id: number, field: keyof InventoryDataCreate, value: any) => {
        // Обновление существующего элемента
        const existingIndex = existingInventoryItems.findIndex(item => item.id === id);
        if (existingIndex !== -1) {
            const item = existingInventoryItems[existingIndex];
            const updatedItem = { ...item, [field]: value };
    
            // Проверяем, есть ли элемент уже в обновленных
            const updateIndex = updateInventoryItems.findIndex(u => u.id === id);
            if (updateIndex !== -1) {
                // Обновляем существующий элемент в обновленных
                setUpdateInventoryItems(prev =>
                    prev.map((u, idx) => idx === updateIndex ? { ...u, [field]: value } : u)
                );
            } else {
                // Если элемента нет, добавляем на то же место, что и в исходном массиве
                setUpdateInventoryItems(prev => {
                    const newUpdateItems = [...prev];
                    newUpdateItems.splice(existingIndex, 0, updatedItem); 
                    return newUpdateItems;
                });
            }
            setExistingInventoryItems(prev => prev.filter((_, idx) => idx !== existingIndex));
            return;
        }
    
        // Обновление нового элемента
        const newIndex = inventoryItems.findIndex(item => item.id === id);
        if (newIndex !== -1) {
            setInventoryItems(prev => 
                prev.map((item, index) => index === newIndex ? { ...item, [field]: value } : item)
            );
            return;
        }
    
        // Обновление уже измененного элемента
        const updatedIndex = updateInventoryItems.findIndex(item => item.id === id);
        if (updatedIndex !== -1) {
            setUpdateInventoryItems(prev => 
                prev.map((item, index) => index === updatedIndex ? { ...item, [field]: value } : item)
            );
            return;
        }
    
        // Если элемент не найден
        console.warn('Item with ID not found in any lists:', id);
    };
       
    const handleAddInventoryItem = () => {
        const id = Date.now(); 
        setInventoryItems(prev => [
            ...prev,
            { id, count: 1, ColorId: 0, SizeId: 0, CityId: 0 } 
        ]);
    };

    const handleRemoveInventoryItem = (id: number) => {
        const foundInExisting = existingInventoryItems.find(item => item.id === id);
        if (foundInExisting) {
            setExistingInventoryItems(prev => prev.filter(item => item.id !== id));
            setProductData(prev => ({
                ...prev,
                deletedInventoryIds: [...(prev.deletedInventoryIds || []), id] 
            }));
            return;
        }
    
        const foundInNew = inventoryItems.find(item => item.id === id);
        if (foundInNew) {
            setInventoryItems(prev => prev.filter(item => item.id !== id));
            return;
        }
    
        const foundInUpdated = updateInventoryItems.find(item => item.id === id);
        if (foundInUpdated) {
            setUpdateInventoryItems(prev => prev.filter(item => item.id !== id));
            setProductData(prev => ({
                ...prev,
                deletedInventoryIds: [...(prev.deletedInventoryIds || []), id] 
            }));
            return;
        }
    
        console.warn('Элемент с ID не найден ни в одном списке:', id);
    };
    
    const resetFormData = () => {
        setProductData({
            title: '',
            text: '',
            price: 0,
            originalPrice: 0,
            discount: undefined,
            productClassId: 0,
            inventories: [],
            images: productData.images  
        });
        setInventoryItems([]); 
    };

    const resetAllData = () => {
        setProductData({
            title: '',
            text: '',
            price: 0,
            originalPrice: 0,
            discount: undefined,
            productClassId: 0,
            inventories: [],
            images: []  
        });
        setInventoryItems([]); 
    };

    const resetAll = () => {
        dispatch(resetSelectedProduct());
        setProductData({
            title: '',
            text: '',
            price: 0,
            originalPrice: 0,
            discount: undefined,
            productClassId: 0,
            images: [],
            deletedImageUrl: [],
            inventories: [],
            updatedInventories: [],
            deletedInventoryIds: []
        });
        setImageUrls([]);
        setInventoryItems([]);
        setUpdateInventoryItems([]);
        setExistingInventoryItems([]);
    };

    const handleSuccessClose = () => {
        setOpenSuccessDialog(false);
        if (id) {
            window.location.reload();
        } else {
            if (newProductId) {
                window.open(`/ProductPage/${newProductId}`, '_blank');
                window.location.href = `/admin/creare/${newProductId}`;
                setNewProductId(null);  
            } else {
                resetAllData();
            }
        }
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleOpenDownloadDialog = () => {
        setOpenDownloadDialog(true);
    };

    const handleCloseDownloadDialog = () => {
        setOpenDownloadDialog(false);
    };

    const handleDownloadImage = () => {
        handleCloseDownloadDialog();
    
        const link = document.createElement('a');
        link.href = `data:image/png;base64,${selectedProduct?.barcode}`;
        link.download = 'barcode.png'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadPDF = (multiple: boolean) => {
        handleCloseDownloadDialog();
    
        const pdf = new jsPDF();
        const imgData = `data:image/png;base64,${selectedProduct?.barcode}`;
    
        if (multiple) {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = 20;
            const imgWidth = (imgHeight * 1.5);
    
            const xPositions = [
                (pageWidth - 2 * imgWidth) / 3,                    
                (pageWidth - 2 * imgWidth) / 3 * 2 + imgWidth   
            ];
    
            for (let y = 10; y < 280; y += 30) {
                xPositions.forEach((x, index) => {
                    pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
                });
            }
        } else {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = 20;
            const imgWidth = (imgHeight * 1.5); 
            const x = (pageWidth - imgWidth) / 2;
            const y = 10; 
    
            pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
        }
    
        pdf.save('barcode.pdf');
    };

    const handleSubmit = () => {
        if (!isButtonDisabled) {
            const newProductData  = {
                ...productData,
                inventories: inventoryItems
            };

            const updateProductData = {
                ...productData,
                inventories: inventoryItems,
                updatedInventories: updateInventoryItems,  
                deletedInventoryIds: productData.deletedInventoryIds,  
                deletedImageUrl: productData.deletedImageUrl  
            };
    
            if (id) {
                const numericId = Number(id);
                if (!isNaN(numericId)) {
                    dispatch(UpdateProduct({ postData: updateProductData, id: numericId }))
                    .unwrap()
                    .then((result) => {
                        if (result.success) {
                            setOpenSuccessDialog(true);
                        } else {
                            setOpenErrorDialog(true);
                        }
                    })
                    .catch(() => {
                        setOpenErrorDialog(true);
                    });
                } else {
                    console.error("ID is not a valid number");
                }   
            } else {
                dispatch(CreateProduct(newProductData))
                .unwrap()
                .then((result) => {
                    if (result.success) {
                        setOpenSuccessDialog(true);
                        setNewProductId(result.productId);
                    } else {
                        setOpenErrorDialog(true);
                    }
                })
                .catch(() => {
                    setOpenErrorDialog(true);
                });
            }
        }
    };


  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      p: "1%",
      bgcolor: '#f5f5f5',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
        {createStatus === 'loading' && (
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1500
            }}>
                <CircularProgress size={68} />
            </Box>
        )}
        <Paper elevation={3} sx={{
            width: '100%', 
            height: '100%', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start', 
            alignItems: 'center',
            p: 2
        }}>
            {/* Top Box for title */}
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                p: 1
            }}>
                {/* Пустой Box для баланса */}
                <Box sx={{ flex: 1 }} />

                {/* Box для заголовка, центрированный */}
                    <Box sx={{
                        display: 'flex',   
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        p: 1
                    }}>
                        {id && (
                            <IconButton
                                onClick={() => window.location.href = '/admin/creare'}
                                sx={{
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                    marginRight: '20px' 
                                }}
                            >
                                <CancelPresentationOutlinedIcon /> 
                            </IconButton>
                        )}

                        <Typography variant="h2" component="h1" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            {pageTitle}
                        </Typography>

                        {id && (
                            <IconButton
                                onClick={() => window.open(`/FullPost/${id}`, '_blank')} 
                                sx={{
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                    marginLeft: '20px' 
                                }}
                            >
                                <OpenInNewIcon /> 
                            </IconButton>
                        )}
                    </Box>

                {/* Box для штрих-кода, если он существует */}
                {selectedProduct?.barcode && (
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{
                            display: 'inline-block',
                            position: 'relative', 
                            '&:hover .download-icon': {
                                visibility: 'visible', 
                                opacity: 1, 
                                transition: 'opacity 0.3s'
                            }
                        }}>
                            <img src={`data:image/png;base64,${selectedProduct?.barcode}`} alt="Barcode" style={{ height: 80, width: 'auto' }} />
                            <IconButton
                                onClick={handleOpenDownloadDialog}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    visibility: 'hidden',
                                    opacity: 0,
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                    transition: 'opacity 0.3s, color 0.3s'
                                }}
                                className="download-icon"
                                size="large"
                            >
                                <FileDownloadOutlinedIcon sx={{ fontSize: '3rem' }} />
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {/* Если штрих-кода нет, добавляем ещё один пустой Box для поддержания баланса */}
                {!selectedProduct?.barcode && <Box sx={{ flex: 1 }} />}
            </Box>

            <Divider sx={{ width: '100%', my: 2 }} />

            <Box sx={{
                width: '100%', 
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', 
                alignItems: 'center',
                bgcolor: 'background.paper', 
                overflow: 'auto', 
                p: 1 
            }}>
                {/* Split into two columns */}
                <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                    {/* Left Column */}
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1,
                        borderRight: 1,  
                        borderColor: 'divider'
                    }}>
                        <ImageCarousel 
                            onImageUpload={handleImageUpload}
                            images={productData.images}
                            imageUrls={imageUrls}
                            onRemoveAllImages={handleRemoveAllImages}
                            onRemoveImage={handleRemoveImage}
                        />
                    </Box>

                    {/* Right Column */}
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1
                    }}>
                    <SetProductData
                        productData={productData}
                        handleChange={handleChange}
                        combinedInventoryItems={combinedInventoryItems}
                        handleInventoryChange={handleInventoryChange}
                        handleAddInventoryItem={handleAddInventoryItem}
                        handleRemoveInventoryItem={handleRemoveInventoryItem}
                        resetFormData={resetFormData}
                        colors={colors}
                        sizes={sizes}
                        productClasses={productClasses}
                        cities={cities}
                    />
                    </Box>
                </Box>
            </Box>

            {/* Button at the bottom */}
            <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                p: 1
            }}>
                <Button sx={{ 
                    borderRadius: '20px', 
                    padding: '10px 30px', 
                    backgroundColor: 'black', 
                    '&:hover': {
                    backgroundColor: '#585858',
                    },
                }}
                    variant="contained" 
                    disabled={isButtonDisabled}
                    onClick={handleSubmit}
                >
                    Finalizeaza
                </Button>
            </Box>

        </Paper>

        {/* Success Dialog */}
        <Dialog open={openSuccessDialog} onClose={handleSuccessClose}>
            <DialogTitle>{id ? "Produsul a fost actualizat cu succes!" : "Produsul a fost creat cu succes!"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{id ? "Produsul a fost actualizat în sistem." : "Produsul a fost adaugat cu succes în sistem."}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSuccessClose}>OK</Button>
            </DialogActions>
        </Dialog>

        {/* Error Dialog */}
        <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle>{id ? "Eroare la actualizarea produsului" : "Eroare la crearea produsului"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Din păcate, a apărut o eroare. Vă rugăm să încercați din nou.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseErrorDialog}>OK</Button>
            </DialogActions>
        </Dialog>

        {/* Download Options Dialog */}
        <Dialog open={openDownloadDialog} onClose={handleCloseDownloadDialog}>
            <DialogTitle>
                <Typography variant="h3" color="primary">
                    Descarca Cod de Bare
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant='h4'>
                    Alegeti formatul Dorit:
                </Typography>
            </DialogContent>
            
            <DialogActions>
                <Button
                    onClick={handleDownloadImage}
                    variant="contained"
                    startIcon={<ImageIcon />}
                    sx={{ backgroundColor: '#2196f3', '&:hover': { backgroundColor: '#1976d2' } }}
                >
                    Descarca Imagine
                </Button>
                <Button
                    onClick={() => handleDownloadPDF(false)}
                    variant="contained"
                    startIcon={<PictureAsPdfIcon />}
                    sx={{ backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }}
                >
                    Descarca PDF Single
                </Button>
                <Button
                    onClick={() => handleDownloadPDF(true)}
                    variant="contained"
                    startIcon={<PictureAsPdfIcon />}
                    sx={{ backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#388e3c' } }}
                >
                    Descarca PDF Multiple
                </Button>
            </DialogActions>
        </Dialog>

    </Box>
  );
}

export default EditCreateProduct;
