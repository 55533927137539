import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography, Grid, Stack, IconButton, Divider } from '@mui/material'
import AdminStats from '../../statistics/AdminStats'
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import { fetchAdminGeneralStats } from '../../../../redux/slices/generalstats';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store';
import AdminOrders from './AdminOrders';
import { FilterParams } from '../../../../redux/slices/order';

const General = () => {
    const dispatch = useAppDispatch();
    const generalStats = useAppSelector((state: RootState) => state.adminStats.generalStats);
    const [filters, setFilters] = useState<FilterParams>({
        searchTerm: '',
        dateFrom: '',
        dateTo: '',
        status: 'Nou',
        sortByPrice: undefined,
        isAssigned: false, 
    });
    
    useEffect(() => {
        dispatch(fetchAdminGeneralStats());
    }, [dispatch]);
    
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', 
        width: '100%', 
        gap: 2, 
    }}>

        <Paper elevation={2} sx={{ 
            flex: '0 1 auto',
            width: '100%',
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'flex-start', 
            justifyContent: 'flex-start', 
            borderRadius: '10px', 
            padding: 4 
        }}>
            <Typography variant="h2" sx={{ mb: 4, width: '100%' }}>Statistica</Typography>

            <Grid container spacing={2} justifyContent="space-around">
            {[
                { icon: <BarChartIcon fontSize="large" sx={{ fontSize: '2.5rem' }}/>, text: generalStats?.totalOnlineOrders.toString(), subtext: "Vanzari" },
                { icon: <StackedLineChartOutlinedIcon fontSize="large" sx={{ fontSize: '2.5rem' }}/>, text: generalStats?.assignedOnlineOrdersCount.toString(), subtext: "Vanzari Personale" },
                { icon: <PersonIcon fontSize="large" sx={{ fontSize: '2.5rem' }}/>, text: generalStats?.totalUsers.toString(), subtext: "Utilizatori" },
                { icon: <ShoppingBasketIcon fontSize="large" sx={{ fontSize: '2.5rem' }}/>, text: generalStats?.totalPosts.toString(), subtext: "Produse" },
                { icon: <AttachMoneyIcon fontSize="large" sx={{ fontSize: '2.5rem' }}/>, text: "N/A", subtext: "Venituri" }, // Допустим, у нас нет данных о доходах в generalStats
            ].map((item, index) => (
                <Grid item key={index}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton size="large">
                            {item.icon}
                        </IconButton>
                        <Box>
                            <Typography variant="h6" sx={{ fontSize: '1.25rem' }}>{item.text}</Typography>
                            <Typography variant="body2" sx={{ fontSize: '1rem' }}>{item.subtext}</Typography>
                        </Box>
                    </Stack>
                </Grid>
            ))}
            </Grid>

        </Paper>

        <Box sx={{ height: "100%", width: '100%' }}>
            <AdminOrders filters={filters} />
        </Box>

    </Box>
  )
}

export default General