import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, SelectChangeEvent, InputAdornment, Grid } from '@mui/material';
import { SelectedProduct } from '../../../redux/slices/produse';
import { AddToCartPayload } from '../../../redux/slices/cart';
import { ShoppingCart, Favorite } from '@mui/icons-material';
import { Color } from '../../../redux/slices/color';
import { Size } from '../../../redux/slices/size';
import { City } from '../../../redux/slices/city';
import PaletteIcon from '@mui/icons-material/Palette';
import HeightIcon from '@mui/icons-material/Height'; 
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ExposureIcon from '@mui/icons-material/Exposure'; 


interface ExtendedSize extends Size {
  disabled: boolean;
}

interface ExtendedCity extends City {
  disabled: boolean;
}

interface ProductDataProps {
  selectedProduct: SelectedProduct;
  cartPayload: AddToCartPayload;
  handleCartPayloadChange: <K extends keyof AddToCartPayload>(key: K, value: AddToCartPayload[K]) => void;
  handleAddToFavorites: (productId: number) => void;
  handleRemoveFromFavorites: (productId: number) => void;
  handleAddToCart: () => void;
}

const ProductData: React.FC<ProductDataProps> = ({
  selectedProduct,
  cartPayload,
  handleCartPayloadChange,
  handleAddToFavorites,
  handleRemoveFromFavorites,
  handleAddToCart,
}) => {
  const [availableColors, setAvailableColors] = useState<Color[]>([]);
  const [availableSizes, setAvailableSizes] = useState<ExtendedSize[]>([]);
  const [availableCities, setAvailableCities] = useState<ExtendedCity[]>([]);  
  const [maxQuantity, setMaxQuantity] = useState<number>(1);
  const isAddToCartDisabled = !cartPayload.colorId || !cartPayload.sizeId || !cartPayload.cityId || cartPayload.quantity <= 0;

  useEffect(() => {
    // Инициализация всех уникальных цветов, размеров и городов
    const uniqueColors = new Map();
    const uniqueSizes = new Map();
    const uniqueCities = new Map();
  
    if (selectedProduct.inventories) { // Добавляем проверку на наличие inventories
      selectedProduct.inventories.forEach(inventory => {
        if (inventory.Color) uniqueColors.set(inventory.Color.id, inventory.Color);
        if (inventory.Size) uniqueSizes.set(inventory.Size.id, inventory.Size);
        if (inventory.City) uniqueCities.set(inventory.City.id, inventory.City);
      });
    }
  
    setAvailableColors([...uniqueColors.values()]);
    setAvailableSizes([...uniqueSizes.values()]);
    setAvailableCities([...uniqueCities.values()]);
  }, [selectedProduct.inventories]);
  
  useEffect(() => {
    if (selectedProduct.inventories && cartPayload.colorId) {
      const activeSizes = new Set(
        selectedProduct.inventories
        .filter(inv => inv.ColorId === cartPayload.colorId && inv.Size)
        .map(inv => inv.SizeId)
      );
  
      const activeCities = new Set(
        selectedProduct.inventories
        .filter(inv => inv.ColorId === cartPayload.colorId && inv.City)
        .map(inv => inv.CityId)
      );
  
      setAvailableSizes(prevSizes => prevSizes.map(size => ({
        ...size,
        disabled: !activeSizes.has(size.id)
      })));
  
      setAvailableCities(prevCities => prevCities.map(city => ({
        ...city,
        disabled: !activeCities.has(city.id)
      })));
    } else {
      setAvailableSizes(prevSizes => prevSizes.map(size => ({ ...size, disabled: false })));
      setAvailableCities(prevCities => prevCities.map(city => ({ ...city, disabled: false })));
    }
  }, [cartPayload.colorId, selectedProduct.inventories]);

  useEffect(() => {
    // Если выбраны цвет, размер и город
    if (selectedProduct.inventories && cartPayload.colorId && cartPayload.sizeId && cartPayload.cityId) {
      const relevantInventories = selectedProduct.inventories.filter(inventory =>
        inventory.ColorId === cartPayload.colorId &&
        inventory.SizeId === cartPayload.sizeId &&
        inventory.CityId === cartPayload.cityId
      );
  
      // Считаем максимальное количество
      const totalQuantity = relevantInventories.reduce((sum, inventory) => sum + inventory.count, 0);
      setMaxQuantity(totalQuantity);
  
      // Обновляем количество в payload, если текущее количество больше максимального
      if (cartPayload.quantity > totalQuantity) {
        handleCartPayloadChange('quantity', totalQuantity);
      }
    } else {
      // Если не все параметры выбраны, сбрасываем максимальное количество
      setMaxQuantity(1);
    }
  }, [cartPayload.colorId, cartPayload.sizeId, cartPayload.cityId, selectedProduct.inventories, handleCartPayloadChange]);
  
  
  const handleColorChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number | '';
    handleCartPayloadChange('colorId', value ? Number(value) : null);
    handleCartPayloadChange('sizeId', null);
    handleCartPayloadChange('cityId', null);
    handleCartPayloadChange('quantity', 1); 
  };

  const handleSizeChange = (event: SelectChangeEvent<number>) => {
      const value = event.target.value as number | '';
      handleCartPayloadChange('sizeId', value ? Number(value) : null);
      handleCartPayloadChange('cityId', null);
      handleCartPayloadChange('quantity', 1);  
  };

  const handleCityChange = (event: SelectChangeEvent<number>) => {
      const value = event.target.value as number | '';
      handleCartPayloadChange('cityId', value ? Number(value) : null);
      handleCartPayloadChange('quantity', 1); 
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      sheight: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      p: '4%',
    }}>
      {/* Box for Title and Price */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 2,
      }}>
        <Typography variant="h2" gutterBottom>
          {selectedProduct.title}
        </Typography>
          {selectedProduct.discount ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h3" color="text.secondary" sx={{ textDecoration: 'line-through', marginRight: 2 }}>
                  {selectedProduct.price.toFixed(2)} Lei
                </Typography>
                <Typography variant="h2" sx={{ color: 'red' }}>
                  {(selectedProduct.price * (1 - selectedProduct.discount.percentage / 100)).toFixed(2)} Lei
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ color: 'red', marginTop: 1 }}>
                Oferta valabila de la {new Date(selectedProduct.discount.startDate).toLocaleDateString()} pana la {new Date(selectedProduct.discount.endDate).toLocaleDateString()}
              </Typography>
            </Box>
          ) : (
          <Typography variant="h2" color="text.secondary">
            {selectedProduct.price.toFixed(2)} Lei
          </Typography>
        )}
      </Box>

      {/* Box for Selectors */}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Alegeti Culoarea</InputLabel>
              <Select
                value={cartPayload.colorId || ''}
                label="Alegeti Culoarea"
                onChange={handleColorChange}
                startAdornment={ 
                  <InputAdornment position="start">
                    <PaletteIcon />
                  </InputAdornment>
                }
              >
                {availableColors.map(color => (
                  <MenuItem key={color.id} value={color.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {color.name}
                      <Box sx={{
                        width: 24,
                        height: 24,
                        bgcolor: color.colorCode,
                        borderRadius: '50%',
                        ml: 1,
                        flexShrink: 0
                      }} />
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ mb: 2 }} disabled={!cartPayload.colorId}>
              <InputLabel>
                Alegeti Marimea
              </InputLabel>
              <Select
                  value={cartPayload.sizeId || ''}
                  label="Alegeti Marimea"
                  onChange={handleSizeChange}
                  disabled={!cartPayload.colorId}
                  startAdornment={ 
                    <InputAdornment position="start">
                      <HeightIcon />
                    </InputAdornment>
                  }
              >
                  {availableSizes.map(size => (
                      <MenuItem key={size.id} value={size.id} disabled={size.disabled}>
                          {size.name}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ mb: 2 }} disabled={!cartPayload.sizeId}>
              <InputLabel>
                Alegeti Orasul
              </InputLabel>
              <Select
                  value={cartPayload.cityId || ''}
                  label="Alegeti Orasul"
                  onChange={handleCityChange}
                  disabled={!cartPayload.sizeId}
                  startAdornment={ 
                    <InputAdornment position="start">
                      <LocationCityIcon />
                    </InputAdornment>
                  }
              >
                  {availableCities.map(city => (
                      <MenuItem key={city.id} value={city.id} disabled={city.disabled}>
                          {city.name}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              value={cartPayload.quantity}
              onChange={(e) => handleCartPayloadChange('quantity', Math.min(Math.max(1, parseInt(e.target.value) || 1), maxQuantity))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExposureIcon />
                  </InputAdornment>
                ),
                inputProps: { min: 1, max: maxQuantity }
              }}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Box for Add to Cart Button */}
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'center',
        width: '100%',
        mt: 2,
        gap: 2
      }}>
        <Button
          variant="contained"
          sx={{
            fontSize: '1rem',
            padding: '10px 20px',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.800',
            color: 'white',
            '&:hover': {
              bgcolor: 'grey.700',
            },
            borderRadius: '24px',
            width: '100%', 
          }}
          onClick={handleAddToCart}
          disabled={isAddToCartDisabled} 
        >
          <ShoppingCart sx={{ mr: 1 }} />Adauga in Cos
        </Button>
        <Button
          variant="contained"
          sx={{
            fontSize: '1rem',
            padding: '10px 20px',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.800',
            color: 'white',
            '&:hover': {
              bgcolor: 'grey.700',
            },
            borderRadius: '24px', 
            width: '100%', 
          }}
          onClick={() => selectedProduct.isFavorite ? handleRemoveFromFavorites(selectedProduct.id) : handleAddToFavorites(selectedProduct.id)}
        >
          <Favorite sx={{ mr: 1, color: selectedProduct.isFavorite ? 'error.main' : 'inherit' }} />Adauga in Favorite
        </Button>
      </Box>

    </Box>
  );
}

export default ProductData;
