import { useState, useEffect } from 'react';
import { Box, CircularProgress, Divider, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import ProductsComponent from '../../components/Client/ProductsList/ProductsComponent';
import CategorySelector from '../../components/Client/ProductsList/CategorySelector';
import { useAppDispatch, useAppSelector, RootState } from '../../redux/store';
import { fetchProductPagination, Filter, addToFavorites, removeFromFavorites } from '../../redux/slices/produse';
import { Color, fetchColors } from '../../redux/slices/color';
import { Size, fetchSizes } from '../../redux/slices/size';
import { ProductClass, fetchProductClasses } from '../../redux/slices/class';
import { useSearchParams } from 'react-router-dom';
import { selectIsAuthenticated } from '../../redux/slices/auth';
import FilterComponent from '../../components/Client/ProductsList/FilterComponent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SelectedFilters from '../../components/Client/ProductsList/SelectedFilters';
import { unwrapResult } from '@reduxjs/toolkit';

const ProductList = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const products = useAppSelector((state: RootState) => state.products.products.items);
    const ProductsLoadingStatus = useAppSelector((state: RootState) => state.products.products.status);
    const ProductsCount = useAppSelector((state: RootState) => state.products.products.count);
    const colors = useAppSelector((state: RootState) => state.colors.items);
    const sizes = useAppSelector((state: RootState) => state.sizes.items);
    const categories = useAppSelector((state: RootState) => state.productClass.items);
    const isLoading = useAppSelector((state: RootState) => 
    state.colors.status === 'loading' || 
    state.sizes.status === 'loading' ||
    state.productClass.status === 'loading')
    const [isFiltersInitialized, setFiltersInitialized] = useState(false);

    const [filters, setFilters] = useState<Filter>({
        colorId: null,
        sizeId: null,
        productClassId: null,
        sort: null,
        query: null,
        onSale: null
    });
    const [pagination, setPagination] = useState({
        page: parseInt(searchParams.get('page') || '1', 10),
        perPage: parseInt(searchParams.get('perPage') || '10', 10)
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function getNameById<T extends { id: number; name: string }>(data: T[], id: number | null | undefined): string | null {
        if (id == null) return null; 
        const found = data.find(item => item.id === id);
        return found ? found.name : null;
    }

    useEffect(() => {
        dispatch(fetchColors());
        dispatch(fetchSizes());
        dispatch(fetchProductClasses());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && !isFiltersInitialized) {
          const initialFilters = {
            colorId: colors.find(c => c.name === searchParams.get('color'))?.id || null,
            sizeId: sizes.find(s => s.name === searchParams.get('size'))?.id || null,
            productClassId: categories.find(pc => pc.name === searchParams.get('category'))?.id || null,
            sort: searchParams.get('sort') as 'asc' | 'desc' | null,
            query: null,
            onSale: searchParams.get('onSale') === 'true' ? true : searchParams.get('onSale') === 'false' ? false : null,
          };
    
          const initialPagination = {
            page: parseInt(searchParams.get('page') || '1', 10),
            perPage: parseInt(searchParams.get('perPage') || '10', 10)
          };
      
          setFilters(initialFilters);
          setPagination(initialPagination);
          setFiltersInitialized(true);
        }

    }, [isLoading]);

    useEffect(() => {
        if (isFiltersInitialized) {
            // Создаем URLSearchParams на основе текущих параметров URL
            const params = new URLSearchParams(searchParams);
        
            // Функция для обновления параметра, если значение не null
            const updateParamIfNotNull = (paramName: string, value: string | null | undefined) => {
                if (value != null) {
                    params.set(paramName, value);
                } else {
                    params.delete(paramName);
                }
            };
      
            // Получаем имя по ID, если значение ID не null
            let colorName = filters.colorId != null ? getNameById<Color>(colors, filters.colorId) : null;
            let sizeName = filters.sizeId != null ? getNameById<Size>(sizes, filters.sizeId) : null;
            let categoryName = filters.productClassId != null ? getNameById<ProductClass>(categories, filters.productClassId) : null;
        
        
            // Обновляем параметры URL, только если соответствующие значения не null
            updateParamIfNotNull('color', colorName);
            updateParamIfNotNull('size', sizeName);
            updateParamIfNotNull('category', categoryName);
            updateParamIfNotNull('sort', filters.sort);
            updateParamIfNotNull('onSale', filters.onSale != null ? filters.onSale.toString() : null);
        
            params.set('page', pagination.page.toString());
            params.set('perPage', pagination.perPage.toString());
        
            const newParamsString = params.toString();
            // Обновляем URL только если строка параметров изменилась
            if (newParamsString !== searchParams.toString()) {
                setSearchParams(params, { replace: true });
            }
        }
    }, [filters, pagination, searchParams, colors, sizes, categories, isLoading]);

    useEffect(() => {
        setSearchTerm(searchParams.get('search')?.toString() || ''); 
    }, [searchParams]);

    useEffect(() => {
        if (isFiltersInitialized) {  
            const fetchParams = {
                pagination: {
                    page: pagination.page,
                    perPage: pagination.perPage
                },
                filters: {
                    ...filters,
                    query: searchTerm
                }
            };
            dispatch(fetchProductPagination(fetchParams));
        }
    }, [dispatch, filters, pagination, searchTerm, isFiltersInitialized]);

    useEffect(() => {
        const maxPage = Math.ceil(ProductsCount / pagination.perPage);
        if (pagination.page > maxPage && maxPage !== 0) {
            setPagination(prev => ({
                ...prev,
                page: maxPage
            }));
        }
    }, [ProductsCount, pagination]);
    
    
    const handleFilterChange = (key: keyof Filter, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value
        }));

        setPagination(prevPagination => ({
            ...prevPagination,
            page: 1 
        }));
    };

    const handleResetAllFilters = () => {
        const initialFilters = {
          colorId: null,
          sizeId: null,
          productClassId: null,
          cityId: null,
          sort: null,
          query: null,
          onSale: null
        };
        const initialPagination = {
          page: 1,  
          perPage: 10  
        };
      
        setFilters(initialFilters);
        setPagination(initialPagination);
      
        setSearchParams({
          page: initialPagination.page.toString(),
          perPage: initialPagination.perPage.toString()
        });
    };

    const handleResetFilters = () => {
        const newFilters = {
          ...filters,
          colorId: null,
          sizeId: null,
          cityId: null,
          sort: null,
          onSale: null
        };
      
        setFilters(newFilters);
      
        const initialPagination = {
          page: 1,  
          perPage: 10  
        };
        setPagination(initialPagination);
        
    };

    const handleResetSearch = () => {
        setSearchTerm('');
        setSearchParams({ search: '' });  
    };

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setPagination(prev => ({ ...prev, page: value }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleAddToFavorites = async (productId: number) => {
        if (!isAuthenticated) {
            alert('Please log in to add products to favorites');
            return;
        }
        try {
            const actionResult = await dispatch(addToFavorites({ productId }));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost adaugat in favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparaut o eroare in procesul de adauagre in faovorite.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveFromFavorites = async (productId: number) => {
        try {
            const actionResult = await dispatch(removeFromFavorites(productId));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
            setSnackbarOpen(true);
        }
    };

    

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',  
        width: '100%',
        p: '1%',
    }}>
        <Paper elevation={24} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            height: '100%',  
            p: '1%',
        }}>

            {isLoading ? (
                <CircularProgress /> 
            ) : (
                <>
                    {/* Breadcrumbs */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        gap: 1,
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            width: '100%', 
                        }}>
                        <Box sx={{ flex: 1 }}>
                            <FilterComponent 
                                filters={filters}
                                colors={colors}
                                sizes={sizes}
                                onFilterChange={handleFilterChange}
                                resetFilters={handleResetAllFilters}
                            />
                        </Box>
                        <Typography variant="h2" sx={{ flex: 2, textAlign: 'center' }}>
                            Femei
                        </Typography>
                        <Box sx={{ flex: 1 }} /> 
                        </Box>

                        {searchTerm && (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                            }}> 
                                <Typography variant='h3'>
                                    Rezultate cautare: {searchTerm}
                                </Typography>
                                <IconButton onClick={handleResetSearch}>
                                    <DeleteForeverOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )}

                        <CategorySelector 
                            productClasses={categories}
                            onFilterChange={handleFilterChange}
                            selectedProductClassId={filters.productClassId}
                        />

                        <SelectedFilters
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            colors={colors}  
                            sizes={sizes}
                            onResetFilters={handleResetFilters}
                        />

                    </Box>

                    <Divider sx={{ width: '100%', my: 1 }} />
                    
                    {/* Products */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',  
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%', 
                        flexGrow: 1,
                    }}>
                        <ProductsComponent 
                            products={products}
                            count={ProductsCount}
                            status={ProductsLoadingStatus}
                            pagination={pagination}
                            handlePaginationChange={handlePaginationChange}
                            handleAddToFavorites={handleAddToFavorites}
                            handleRemoveFromFavorites={handleRemoveFromFavorites}
                        />
                    </Box>
                </>
            )}
        </Paper>

        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    </Box>
  );
}

export default ProductList;
