import React, { useEffect, useState } from 'react'
import { Button, Box, Typography, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme, Divider, IconButton } from '@mui/material';
import { createDiscount } from '../../../redux/slices/discount'
import { subscribeToNewsletter } from '../../../redux/slices/sendnewsletter';
import { checkUserDiscount } from '../../../redux/slices/discount';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Spinner from '../../spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const AccountOverview  = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.auth.data?.user);
  const totalOrdersCount = useAppSelector((state: RootState) => state.auth.totalOrdersCount);
  const newOrdersCount = useAppSelector((state: RootState) => state.auth.newOrdersCount);
  const [userHasDiscount, setUserHasDiscount] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubscribedDialogOpen, setIsSubscribedDialogOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(checkUserDiscount())
      .unwrap()
      .then((hasDiscount) => {
        setUserHasDiscount(hasDiscount);
      });
  }, [dispatch]);

  const handleConfirmSubscription = () => {
    const discountData = {
      discount_percentage: 10,
      is_subscribed_discount: true,
    };
    dispatch(createDiscount(discountData));
    
    setIsSubscribing(true);
    
    dispatch(subscribeToNewsletter())
      .then((action) => {
        setIsSubscribing(false);
        
        if (subscribeToNewsletter.fulfilled.match(action)) {
          const response = action.payload;
          if (response.success) {
            setIsSubscribed(true); 
            setIsSubscribedDialogOpen(true);
          }
        }
      })
      .catch((error) => {
        setIsSubscribing(false); 
      });

    setIsDialogOpen(false);
  };

  const handleCancelSubscription = () => {
    setIsDialogOpen(false);
  };

  const handleCloseSubscribedDialog = () => {
    setIsSubscribedDialogOpen(false);
    window.location.reload();
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleNavigateToOrders = () => {
    navigate('/Orders'); 
  };

  const handleEditClick = () => {
    navigate('/MyAccount/DetailEdit');
  };

  const handlePasswordEditClick = () => {
    navigate('/MyAccount/PasswordEdit');
  };

  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spinner />
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box sx={{ height: '100vh', p: '2%' }}>
      </Box>
    );
  }


  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>

      {/* Левый Box */}
      <Box sx={{ 
        width: '50%', 
        padding: '1%', 
        display: 'flex', 
        flexDirection: 'column',
        height: '100%' 
      }}>
        {/* Первый блок: Данные пользователя */}
        <Box sx={{ 
          flex: 7, 
          marginBottom: '10px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          border: '1px solid #ddd', 
          borderRadius: '8px', 
          padding: '20px', 
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="h2">Date personale</Typography>
            <Tooltip title="Redacteaza Date personale">
              <IconButton onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{ my: '10px', bgcolor: 'primary.main', width: '100%' }} />
          {/* Содержимое первого блока... */}
          <Box sx={{ marginTop: '10px' }}>
            <Typography variant="h3" sx={{ marginBottom: '5px' }}>Nume:</Typography>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>{user.FullName}</Typography>

            <Typography variant="h3" sx={{ marginBottom: '5px' }}>Email:</Typography>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>{user.Email}</Typography>

            <Typography variant="h3" sx={{ marginBottom: '5px' }}>Numar Telefon:</Typography>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>{user.Telefon}</Typography>

            <Typography variant="h3" sx={{ marginBottom: '5px' }}>Data de inregistrare:</Typography>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>{new Date(user.createdAt).toLocaleDateString()}</Typography>

            <Typography variant="h3" sx={{ marginBottom: '5px' }}>Abonat la newsletter:</Typography>
            <Typography variant="h3">{user.isSubscribedToNewsletter ? 'Da' : 'Nu'}</Typography>
            
          </Box>
          <Divider sx={{ my: '10px', bgcolor: 'primary.main', width: '100%' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <Typography variant="h3">Parola contului tau</Typography>
            <Tooltip title="Redacteaza Parola">
              <IconButton onClick={handlePasswordEditClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        
        {/* Второй блок: Детали заказов */}
        <Box sx={{ 
          flex: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          border: '1px solid #ddd', 
          borderRadius: '8px', 
          padding: '20px', 
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' 
        }}>
          <Typography variant="h2">Detalii Comenzi</Typography>
          <Divider sx={{ my: '10px', bgcolor: 'primary.main', width: '100%' }} />

          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%', 
            marginTop: '10px'
          }}>
            <Box>
              <Typography variant="h3" sx={{ marginBottom: '5px' }}>
                Comenzi: {totalOrdersCount}
              </Typography>

              <Typography variant="h3">
                Comenzi <span style={{ color: 'red' }}>Noi</span>: {newOrdersCount}
              </Typography>
            </Box>

            {/* Кнопка "Detalii" */}
            <Button
              variant="outlined"
              onClick={handleNavigateToOrders}
              sx={{ fontSize: '1rem' }}
            >
              Detalii
            </Button>
          </Box>
          
        </Box>
      </Box>

      {/* Правый Box */}
      <Box 
        sx={{ 
          width: '50%', 
          display: 'flex',
          justifyContent: 'center', 
          padding: '1%', 
        }}>
        <Box
          sx={{ 
            backgroundColor: '#424242',
            height: '70%',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            position: 'relative', 
            aspectRatio: '1 / 1',
            alignSelf: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6)', 
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s', 
            '&:hover': {
              transform: 'scale(1.025)', 
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.9)', 
            },
            cursor: userHasDiscount ? 'default' : 'pointer', 
          }}
          onClick={!userHasDiscount ? handleOpenDialog : undefined}
        >
          {/* Внутренний контейнер для текста */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
            }}
          >
            {/* Текст "REDUCERE" */}
            <Typography
              variant="h1"
              sx={{ 
                color: 'white',
                fontWeight: 'bold',
                mb: 1, 
                fontSize: '3rem',
              }}
            >
              REDUCERE
            </Typography>
            
            {/* Ряд для текста "de 10%" */}
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {/* Текст "de" */}
              <Typography
                variant="h2"
                sx={{ 
                  color: 'white',
                  fontWeight: 'light',
                  mr: 1,
                }}
              >
                de
              </Typography>
              {/* Текст "10%" */}
              <Typography
                variant="h1"
                sx={{ 
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '6rem',
                }}
              >
                10%
              </Typography>
            </Box>
          </Box>
          {userHasDiscount && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} /> 
          </Box>
        )}
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCancelSubscription} maxWidth="xs" fullWidth>
        <DialogTitle>Sunteti siguri ca doriti sa va abonati?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apasati "Da" pentru a va abona sau "Nu" pentru a anula.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleConfirmSubscription}>
            Da
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCancelSubscription}>
            Nu
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog 2 */}
      <Dialog open={isSubscribedDialogOpen} onClose={handleCloseSubscribedDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Felicitari!</DialogTitle>
        <DialogContent>
          <DialogContentText>V-ati abonat cu success.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleCloseSubscribedDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
)
}

export default AccountOverview 