import React from 'react';
import { Box, Typography, Button } from '@mui/material';

interface CheckoutProps {
  totalItems: number;
  totalPrice: number;
  onContinue: () => void; 
}

const Checkout: React.FC<CheckoutProps> = ({ 
  totalItems,
  totalPrice,
  onContinue
 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px',
        padding: '20px',
        border: '2px solid #e0e0e0',
        borderRadius: '24px',
        backgroundColor: '#f9f9f9'
      }}
    >
      <Typography variant="h3" sx={{ marginBottom: '15px' }}> 
        Numar Produse: {totalItems}
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: '25px' }}> 
        Pret Produse: {totalPrice.toFixed(2)} Lei
      </Typography>
      <Button
          variant="contained"
          sx={{
            fontSize: '1.2rem',
            padding: '8px 16px',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.800',
            color: 'white',
            '&:hover': {
              bgcolor: 'grey.700',
            },
            borderRadius: '24px',
            minWidth: '250px'
          }}
          onClick={onContinue}
        >
        Continua
      </Button>
    </Box>
  );
}

export default Checkout;
