import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { API } from '../service';


  export interface CreateColorData {
    name: string;
    colorCode: string;
  }

  export interface UpdateColorData {
    id: number;
    name: string;
    colorCode: string;
  }

  export interface Color {
    id: number;
    name: string;
    colorCode: string; 
  }
  
  interface ColorsState {
    items: Color[];
    status: 'loading' | 'loaded' | 'error';
    createStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    updateStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    count: number;
  }
  
  const initialColorsState: ColorsState = {
    items: [],
    status: 'loading',
    createStatus: 'idle',
    updateStatus: 'idle',
    count: 0,
  };
  
  interface ColorsRequestParams {
    page: number;
    perPage: number;
  }
  
  export const fetchColors = createAsyncThunk<Color[], void, { rejectValue: string }>(
    'colors/fetchColors', 
    async (_, { rejectWithValue }) => {
      const [error, response] = await API<Color[]>('GET', '/colors/all');
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch colors');
      }
  
      return response.data;
    }
  );

  export const fetchRemoveColors = createAsyncThunk<void, number, { rejectValue: string }>(
    'colors/fetchRemoveColors', 
    async (id, { rejectWithValue }) => {
      const [error, _] = await API<void>('DELETE', `/colors/delete/${id}`);
  
      if (error) {
        return rejectWithValue('Failed to remove color');
      }
    }
  );

  export const createColor = createAsyncThunk<Color, CreateColorData, { rejectValue: string }>(
    'colors/createColor', 
    async (colorData, { rejectWithValue }) => {
      const [error, response] = await API<Color>('POST', '/colors/create', colorData);
  
      if (error || !response) {
        return rejectWithValue('Failed to create color');
      }
  
      return response.data;
    }
  );
  
  export const updateColor = createAsyncThunk<Color, UpdateColorData, { rejectValue: string }>(
    'colors/updateColor', 
    async (colorData, { rejectWithValue }) => {
      const [error, response] = await API<Color>('PATCH', `/colors/update/${colorData.id}`, colorData);
  
      if (error || !response) {
        return rejectWithValue('Failed to update color');
      }
  
      return response.data;
    }
  );

  export const fetchColorsPagination = createAsyncThunk<{ count: number, colors: Color[] }, ColorsRequestParams, { rejectValue: string }>(
    'colors/fetchColorsPagination', 
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number, colors: Color[] }>('GET', '/colors/pagination/all', null, {
        page,
        perPage,
      });
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch colors with pagination');
      }
  
      return response.data;
    }
  );
  

  const colorsSlice = createSlice({
  name: 'colors',
  initialState: initialColorsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchColors.fulfilled, (state, action: PayloadAction<Color[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchColors.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createColor.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(createColor.fulfilled, (state, action: PayloadAction<Color>) => {
        state.createStatus = 'succeeded';
      })
      .addCase(createColor.rejected, (state) => {
        state.createStatus = 'failed';
      })
      .addCase(updateColor.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateColor.fulfilled, (state, action: PayloadAction<Color>) => {
        const index = state.items.findIndex(color => color.id === action.payload.id);
        if (index !== -1) {
            state.items[index] = action.payload;
        }
        state.updateStatus = 'succeeded';
      })
      .addCase(updateColor.rejected, (state) => {
        state.updateStatus = 'failed';
      })
      .addCase(fetchColorsPagination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchColorsPagination.fulfilled, (state, action: PayloadAction<{ count: number, colors: Color[] }>) => {
        state.status = 'loaded';
        state.items = action.payload.colors;
        state.count = action.payload.count;
      })
      .addCase(fetchColorsPagination.rejected, (state) => {
        state.status = 'error';
      });
  }
});

export const colorsReducer = colorsSlice.reducer;