import { useState } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Box, IconButton } from "@mui/material"
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import EmailIcon from '@mui/icons-material/Email';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CommentIcon from '@mui/icons-material/Comment';
import BarChartIcon from '@mui/icons-material/BarChart';
import PaletteIcon from '@mui/icons-material/Palette';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { useSelector } from 'react-redux';
import { selectRole } from '../../../redux/slices/auth';

interface NavigationItem {
  text: string;
  icon: React.ReactNode;
  requiredLevel: number; 
}

interface NavigationListProps {
  items: NavigationItem[];
  open: boolean;
  userRoleLevel: number; 
}

  const drawerWidth = 240;

  function NavigationList({ items, open, userRoleLevel }: NavigationListProps) {

  return (
    <List>
      {items.filter(item => item.requiredLevel <= userRoleLevel).map((item, index) => (
        <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
          <Link 
            to={item.text === 'Home' ? '/' : `${item.text.toLowerCase()}`} 
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon} 
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );
  }

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  
  const items1 = [
    { text: 'Home', icon: <HomeOutlinedIcon />, requiredLevel: 60 },
  ];
  
  const items2 = [
    { text: 'Principala', icon: <DashboardOutlinedIcon />, requiredLevel: 60 },
    { text: 'Statistica', icon: <BarChartIcon />, requiredLevel: 60 },
    { text: 'Comenzi', icon: <AddShoppingCartOutlinedIcon />, requiredLevel: 60 },
    { text: 'Produse', icon: <InventoryOutlinedIcon />, requiredLevel: 100 },
    { text: 'Users', icon: <AssignmentIndIcon />, requiredLevel: 100 },
    { text: 'Societate', icon: <CommentIcon />, requiredLevel: 100 },
  ];
  
  const items3 = [
    { text: 'Lucratori', icon: <Diversity2Icon />, requiredLevel: 100 },
    { text: 'Email', icon: <EmailIcon />, requiredLevel: 100 },
    { text: 'OfflineOrder', icon: <WifiOffIcon />, requiredLevel: 60 },
    { text: 'Creare', icon: <EditIcon />, requiredLevel: 100 },
    { text: 'Altele', icon: <PaletteIcon />, requiredLevel: 100 },
  ];
  
  export default function AdminNavBar() {
    const UserRole = useSelector(selectRole); 
    const userRoleLevel = UserRole?.level || 0; 
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: open ? 'flex-end' : 'center',
      padding: theme.spacing(0, 0),
      ...theme.mixins.toolbar,
    }));
    
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
      ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }),
    );
    
    return (
      <Box
        sx={{
          "& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper": { 
           // background: "#333333",
            //backgroundColor: "#333333"
          }
        }}
      >
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
            <DrawerHeader >
            {open ? (
              <IconButton
                onClick={handleDrawerClose}
                color="inherit"
                aria-label="close drawer"
                edge="start"
              >
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
                ) : (
                  <IconButton
                    onClick={handleDrawerOpen}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{
                        justifyContent:'center',
                        margin:0
                    }}
                  >
                    <MenuIcon/>
                  </IconButton>
            )}
            </DrawerHeader>

            <NavigationList items={items1} open={open} userRoleLevel={userRoleLevel} />
              <Divider />
            <NavigationList items={items2} open={open} userRoleLevel={userRoleLevel} />
              <Divider />
            <NavigationList items={items3} open={open} userRoleLevel={userRoleLevel} />
              <Divider />
            {/* 
            <NavigationList items={items4} open={true} />
            */}
        </Drawer>
      </Box>
  );
}