import { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { fetchDeliveryTypesPagination, createDeliveryType } from '../../../../redux/slices/delivery';
import { useAppDispatch, useAppSelector, RootState } from '../../../../redux/store';
import EditIcon from '@mui/icons-material/Edit';

const DeliveryList = () => {
    const dispatch = useAppDispatch();
    const deliveries = useAppSelector((state: RootState) => state.deliveries.items); 
    const Count = useAppSelector((state: RootState) => state.deliveries.count);
    const deliveriesStatus = useAppSelector((state: RootState) => state.deliveries.status);
    const isDataGridLoading = deliveriesStatus === 'loading';
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: page,
        pageSize: perPage,
    }); 
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');

    useEffect(() => {
        dispatch(fetchDeliveryTypesPagination({ page: paginationModel.page + 1, perPage: paginationModel.pageSize }));
    }, [dispatch, paginationModel]);

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    }; 
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCreate = () => {
        dispatch(createDeliveryType({ name, price: Number(price) }));
        handleDialogClose();
        setName('');
        setPrice('');
    };    

    const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'Id',
          flex: 1,
        },
        {
          field: 'name',
          headerName: 'Tip',
          flex: 1,
        },
        {
          field: 'price',
          headerName: 'Pret',
          flex: 1,
        },
        {
          field: 'actions',
          headerName: 'Acțiuni',
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
      
            return (
                <IconButton>
                    <EditIcon />
                </IconButton>
            );
          },
        },
    ];
      
    const rows = deliveries.map((delivery) => ({
        id: delivery.id,
        name: delivery.name,
        price: delivery.price,
    }));


  return (
    <>
        <Paper elevation={3} sx={{ padding: 2, width: '100%',  height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                    Creaza
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, height: 0 }}>
                <DataGrid 
                    paginationMode="server"
                    rows={rows}
                    columns={ columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: perPage,
                            page: page
                        },
                        },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    rowCount={Count}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    loading={isDataGridLoading}
                    sx={{ fontSize: '22px' }}
                />
            </Box>
        </Paper>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>
                <Typography variant='h3'>
                    Crează un nou tip de livrare
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nume"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete='off'
                />
                <TextField
                    margin="dense"
                    id="price"
                    label="Pret"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    autoComplete='off'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Anulați</Button>
                <Button onClick={handleCreate}>Crează</Button>
            </DialogActions>
        </Dialog>
    </>
  )
}

export default DeliveryList