import React, { useState } from 'react';
import { Box, Button, Typography} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { UserWithRole } from '../../../redux/slices/userrole';
import CircleIcon from '@mui/icons-material/Circle';
import { green, red } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';

interface UserWithRoleListProps {
    users: UserWithRole[]; 
  }

const UserWithRoleList: React.FC<UserWithRoleListProps> = ({ users }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
      page: page,
      pageSize: perPage,
    });

    const handleDetailsClick = (userId: number) => {
        navigate(`/admin/Lucratori/UserDetaliPage/${userId}`);
    }

    const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'Id',
          flex: 1,
        },
        {
          field: 'Name',
          headerName: 'Name',
          flex: 1,
        },
        {
          field: 'Rol',
          headerName: 'Rol',
          flex: 1,
        },
        {
            field: 'Level',
            headerName: 'Acces',
            flex: 1,
        },
        {
            field: 'IsActive',
            headerName: 'Statut',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => (
              params.value ? (
                <Box display="flex" alignItems="center">
                  <CircleIcon style={{ color: green[500], marginRight: 8 }} />
                  <Typography variant='h4'>Activ</Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <CircleIcon style={{ color: red[500], marginRight: 8 }} />
                  <Typography variant='h4'>Inactiv</Typography>
                </Box>
              )
            ),
        },
        {
            field: 'details',
            headerName: 'Detalii',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => (
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  startIcon={<InfoIcon />}
                  onClick={() => handleDetailsClick(params.row.id)}
                >
                  Detalii
                </Button>
              </Box>
            ),
        }
    ];

    const rows = users.map((user) => ({
        id: user.userId,
        Name: user.fullName,
        Rol: user.roleName,
        Level: user.accessLevel,
        IsActive: user.isActive
    }));

  return (
    <Box style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: perPage,
                    page: page
                  },
                },
              }}
            pageSizeOptions={[5]}
            checkboxSelection
            sx={{ fontSize: 'clamp(12px, 1.6vw, 20px)' }}
        />
    </Box>
  )
}

export default UserWithRoleList