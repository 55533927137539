import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface DialogMessageProps {
  open: boolean;
  handleClose: () => void;
  isAnswered: boolean;
  handleEditAnswer: () => void;
  handleReplyButtonClick: () => void;
  isReplyMode: boolean;
  answerText: string;
  setAnswerText: React.Dispatch<React.SetStateAction<string>>;
  handleSaveReply: () => void;
  handleCancelReply: () => void;
  selectedDetails: {
    user: string;
    message: string;
    answer: string;
  };
}

const DialogMessage: React.FC<DialogMessageProps> = ({
  open,
  handleClose,
  isAnswered,
  handleEditAnswer,
  handleReplyButtonClick,
  isReplyMode,
  answerText,
  setAnswerText,
  handleSaveReply,
  handleCancelReply,
  selectedDetails,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '70%',
          height: '70%',
          maxHeight: '60vh',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography variant="h2" textAlign="center">
          Informatii
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            alignItems: 'center',
            mb: 2,
            mt: 2,
          }}
        >
          <Typography variant="h4">Utilizator: {selectedDetails.user}</Typography>
          <Box
            sx={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              p: 1,
              '&:hover': {
                transform: 'scale(1.01)',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Typography variant="h4">Mesaj: {selectedDetails.message}</Typography>
          </Box>
          {isReplyMode ? (
            <TextField
              label="Raspuns"
              multiline
              rows={4}
              variant="outlined"
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              sx={{ mt: 2, width: '100%' }}
            />
          ) : (
            <Box
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
                p: 1,
                '&:hover': {
                  transform: 'scale(1.01)',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h4">Raspuns: {selectedDetails.answer}</Typography>
            </Box>
          )}
        </Box>

        {isReplyMode && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveReply}
              disabled={!answerText.trim()}
              sx={{ mr: 2 }}
            >
              Salveaza Raspuns
            </Button>
            <Button variant="outlined" onClick={handleCancelReply}>
              Anuleaza
            </Button>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Inchide
        </Button>

        {isAnswered && (
          <Button onClick={handleEditAnswer} color="primary" startIcon={<EditIcon />}>
            Schimba raspunsul
          </Button>
        )}
        {!isAnswered && (
          <Button onClick={handleReplyButtonClick} color="primary" disabled={isAnswered}>
            Raspunde
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogMessage;