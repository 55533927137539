import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import axios from '../../axios';

// Типы для данных запроса
interface SendEmailPayload {
  userEmail: string;
  userPhone?: string;
  userMessage: string;
}

// Типы для ответа сервера
interface EmailResponse {
  success: boolean;
  message: string;
}

interface RejectedValue {
  message: string;
}

// Начальное состояние
interface EmailState {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  message?: string;
}

const initialState: EmailState = {
  status: 'idle',
  message: '',
};

// Асинхронный экшен для отправки сообщения
export const sendEmail = createAsyncThunk<EmailResponse, SendEmailPayload, { rejectValue: RejectedValue }>(
    'email/sendEmail',
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await axios.post('/api/send-email', payload);
        return data;
      } catch (error: any) {
        if (error && error.response && error.response.data) {
          return rejectWithValue(error.response.data as RejectedValue);
        }
        return rejectWithValue({ message: error.message || 'Unexpected error occurred.' });
      }
    },
  );

  
// Создание среза
const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendEmail.fulfilled, (state, action: PayloadAction<EmailResponse>) => {
        state.status = 'loaded';
        state.message = action.payload.message;
      })
      .addCase(sendEmail.rejected, (state, action: PayloadAction<RejectedValue | undefined, string, unknown, SerializedError>) => {
        state.status = 'error';
        if (action.payload) {
          state.message = action.payload.message;
        } else if (action.error.message) {
          state.message = action.error.message;
        }
      });
  }
});

export const emailReducer = emailSlice.reducer;
