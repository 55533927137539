import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { 
  Box, Paper, Typography, IconButton, useTheme, Grid,
  Checkbox, Pagination 
} from '@mui/material';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux/store';
import { NotificationItem, addLiveNotification } from '../../../redux/slices/notification';
import { markNotificationAsViewed, fetchNotifications } from '../../../redux/slices/notification';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { selectHasRole } from '../../../redux/slices/auth';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { selectUserId } from '../../../redux/slices/auth';
import useMediaQuery from '@mui/material/useMediaQuery';

const Notifications = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.items);
  const totalNotifications = useAppSelector((state) => state.notifications.total);
  const userId = useAppSelector(selectUserId);
  const hasRole = useAppSelector(selectHasRole);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const perPage = 10; 
  const totalPages = Math.ceil(totalNotifications / perPage);
  
  
  useEffect(() => {
    dispatch(fetchNotifications({ page, perPage }));
  }, [dispatch, page, perPage]);
  
  useEffect(() => {
    const socket = io('http://localhost:4500');
    socket.on('connect', () => {
      socket.emit('subscribe', `notification-${userId}`);
    });
    socket.on(`notification-${userId}`, async (notification: NotificationItem) => {
      dispatch(addLiveNotification(notification));
      setPage(1);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleMarkAsViewed = (notificationId: number) => {
    dispatch(markNotificationAsViewed(notificationId)); 
  }

  const handleOpenOrder = (orderId: string | number) => {
    if (!orderId) return;
    const id = typeof orderId === "string" ? parseInt(orderId, 10) : orderId;
    if (isNaN(id)) return;
    if (hasRole) {
      navigate(`/admin/OrderDetailAdmin/${id}`);
    } else {
      navigate(`/OrderDetails/${id}`);
    }
  }

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  

  if (isMobile) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        p: 2, 
      }}>
        <Box>
          <Typography variant="h3" align="center" style={{ marginTop: '15px' }}>Notificari</Typography>
        </Box>
  
        {/* Уведомления */}
        <Box sx={{ 
          border: '1px solid rgba(0,0,0,0.12)', 
          mt: 2,
          overflow: 'auto',
          flexGrow: 1,
        }}>
          <Grid container direction="column" spacing={'1px'}>
          </Grid>
        </Box>

      </Box>
    );
  }


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      minHeight={'100vh'}
      width="100%" 
    >
      <Typography variant="h2" align="center" style={{ marginTop: '10px' }}>Notificari</Typography>

      <Box
        sx={{
          width: '100%', 
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          marginTop: '10px',
          marginBottom: '5px'
        }}
      >
        <Paper variant="outlined" style={{ width: '65%', padding: '10px' }}>
          <Grid container spacing={2}>
          {notifications.map((notification) => (
            <Grid item xs={12} key={notification.Id}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0,0,0,0.12)',
                  borderRadius: '4px',
                  padding: '10px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.01)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }}
              >
                <IconButton onClick={() => handleMarkAsViewed(notification.Id)}>
                  {notification.isRead ? 
                    <CheckCircleOutlineIcon color="success" /> : 
                    <RadioButtonUncheckedIcon />
                  }
                </IconButton>
                <Typography variant="h4" sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '10px' }}>
                  {notification.message}
                </Typography>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="h4">
                    {new Date(notification.createdAt).toLocaleDateString()}
                  </Typography>
                  <Typography variant="h4">
                    {new Date(notification.createdAt).toLocaleTimeString()}
                  </Typography>
                </Box>
                {notification.orderId && (
                  <IconButton onClick={() => notification.orderId && handleOpenOrder(notification.orderId)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
          </Grid>
        </Paper>
      </Box>

      {/* Пагинация */}
      <Box
        sx={{
          width: '100%', 
          display: 'flex',
          justifyContent: 'center',
          marginTop: 'auto', 
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
          showFirstButton
          showLastButton
          style={{ marginTop: '20px' }}
        />
      </Box>
    </Box>
  );
}

export default Notifications;