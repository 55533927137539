import React, { useEffect, useState } from 'react';
import { Box, Paper, Avatar, Typography, Stack, IconButton, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import General from '../../components/Admin/admindashboard/StaffDasboard/General';
import Profil from '../../components/Admin/admindashboard/StaffDasboard/Profil';
import { useAppSelector, RootState } from '../../redux/store';

const tabMap = ['General', 'Profil', 'Comenzi', 'Statistica'];

const HomeAdmin = () => {
  const User = useAppSelector((state: RootState) => state.auth.data?.user);
  const UserRole = useAppSelector((state: RootState) => state.auth.data?.role);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const tabIndex = tabMap.indexOf(tab ?? '');
    if (tabIndex !== -1) {
      setSelectedTab(tabIndex);
    }
  }, [location.search]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    const tabParam = tabMap[newValue];
    navigate(`?tab=${tabParam}`);
  };;
  
  return (
    <Box display="flex" flexDirection="column" sx={{ height: "100vh", width: '100%', bgcolor: 'grey.100', p: '1%', }}>

      <Paper elevation={2} sx={{ 
        width: '100%',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between', 
        borderRadius: '10px', 
        padding: 2,
      }}>
        <Stack direction="row" spacing={1}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: 'black', 
              },
            }}
          >
            <Tab label={<Typography variant="h4" style={{ color: 'black' }}>General</Typography>} />
            <Tab label={<Typography variant="h4" style={{ color: 'black' }}>Profil</Typography>} />
            <Tab label={<Typography variant="h4" style={{ color: 'black' }}>Comenzi</Typography>} />
            <Tab label={<Typography variant="h4" style={{ color: 'black' }}>Statistica</Typography>} />
          </Tabs>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <Typography variant="h4">{User?.FullName}</Typography>
            <Typography variant="h5">{UserRole?.name}</Typography>
          </Box>
          <Avatar />
        </Stack>
      </Paper>

      <Box sx={{ width: '100%', height: '100%', pt: 2 }}>
        {selectedTab === 0 && <General/>}
        {selectedTab === 1 &&  <Profil/>}
      </Box>

    </Box>
  );
};

export default HomeAdmin;
