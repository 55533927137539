import React from 'react';
import { Box, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { CartItems } from '../../../redux/slices/produse';
import { DeliveryType } from '../../../redux/slices/delivery';
import { PaymentType } from '../../../redux/slices/payment';
import Grid from '@mui/material/Unstable_Grid2'
import Articole from './Articole';
import { Discount } from '../../../redux/slices/discount';
import { CreateOnlineOrderData } from '../../../redux/slices/onlineOrderClient';

interface SumarProps {
  deliveryTypes: DeliveryType[];
  paymentTypes: PaymentType[];
  orderData: CreateOnlineOrderData;
  cartItems: CartItems[];
  totalItems: number;
  cartPrice: number;
  discountAmount: number;
  deliveryPrice: number;
  finalPrice: number;
  discounts: Discount[];
  handleDiscountChange: (discountId: number | undefined) => void;
  handleAddToFavorites: (productId: number) => void;
  handleRemoveFromFavorites: (productId: number) => void;
  handleRemoveFromCart: (cartId: number) => void;
  handleUpdateCartQuantity: (cartId: number, quantity: number) => void;
}


const Sumar: React.FC<SumarProps> = ({ 
  deliveryTypes, 
  paymentTypes, 
  orderData, 
  cartItems, 
  totalItems,
  cartPrice, 
  discountAmount,
  finalPrice,
  deliveryPrice,
  discounts, 
  handleDiscountChange,
  handleAddToFavorites,
  handleRemoveFromFavorites,
  handleRemoveFromCart,
  handleUpdateCartQuantity
}) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      flexGrow: 1,
      gap: 5
    }}>

      {/* Бокс с заголовком "Sumar Comanda" */}
      <Box sx={{ width: '100%', textAlign: 'center', marginBottom: 2 }}>
        <Typography variant="h2">Sumar Comanda</Typography>
      </Box>

      {/* Верхний Box с Grid */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>

          <Grid xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h4">Informatii despre livrare</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="h5">Tara: {orderData.deliveryCountry}</Typography>
              <Typography variant="h5">Oras: {orderData.deliveryCity}</Typography>
              <Typography variant="h5">Cod Postal: {orderData.deliveryPostalCode}</Typography>
              <Typography variant="h5">Adresa de livrare: {orderData.deliveryAddress}</Typography>
              <Typography variant="h5">Telefon: {orderData.deliveryPhone}</Typography>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Typography variant="h4">Modalitatea de livrare</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant='h5'>Metoda: {deliveryTypes.find(dt => dt.id === orderData.deliveryTypeId)?.name || 'Nealeasă'}</Typography>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Typography variant="h4">Modalitatea de plată</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant='h5'>Metoda: {paymentTypes.find(pt => pt.id === orderData.paymentTypeId)?.name || 'Nealeasă'}</Typography>
            </Paper>
          </Grid>

        </Grid>
      </Box>

      {/* Средний Box */}
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ width: '100%', height: '100%', padding: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
            <Typography variant="h4">Produse</Typography>
            <Typography variant="h4">Livrate prin: {deliveryTypes.find(dt => dt.id === orderData.deliveryTypeId)?.name || 'Nealeasă'}</Typography>
          </Box>
          <Box>
            <Articole 
              cartItems={cartItems} 
              totalPriceCart={cartPrice} 
              handleAddToFavorites={handleAddToFavorites}
              handleRemoveFromFavorites={handleRemoveFromFavorites}
              handleRemoveFromCart={handleRemoveFromCart}
              handleUpdateCartQuantity={handleUpdateCartQuantity}
            />
          </Box>
        </Paper>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', pt: 2 }}>
        <Typography variant="h2" sx={{ alignSelf: 'flex-start', ml: 2 }}>Sumar Comanda</Typography>

        <Paper elevation={3} sx={{ width: '100%', mt: 2, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h3">Numar Produse:</Typography>
            <Typography variant="h3">{totalItems}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h3">Subtotal:</Typography>
            <Typography variant="h3">{`${cartPrice.toFixed(2)} Lei`}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h3">Livrare:</Typography>
            <Typography variant="h3">{`${deliveryPrice.toFixed(2)} Lei`}</Typography>
          </Box>

          {discounts.some(discount => !discount.is_used) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1}}>
                <Typography variant="h3" sx={{ mr: 2 }}>Reducere:</Typography>
                <FormControl sx={{ flexGrow: 1, maxWidth: 300 }}>
                  <InputLabel id="discount-select-label">Selectati o reducere</InputLabel>
                  <Select
                    labelId="discount-select-label"
                    value={orderData.discountId || ''}
                    label="Selectati o reducere"
                    onChange={(event) => {
                      const discountId = event.target.value === '' ? undefined : Number(event.target.value);
                      handleDiscountChange(discountId); 
                    }}
                  >
                    <MenuItem value="">
                      <em>Niciuna</em>
                    </MenuItem>
                    {discounts.filter(discount => !discount.is_used).map((discount) => (
                      <MenuItem key={discount.discountId} value={discount.discountId}>
                        {`${discount.discount_percentage}%`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {(discountAmount > 0) && 
                <Typography variant="h3" sx={{color: 'red'}}>{`- ${discountAmount.toFixed(2)} Lei`}</Typography>
              }

            </Box>
          )}

          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h3">Pret total:</Typography>
            <Typography variant="h3">{`${finalPrice.toFixed(2)} Lei`}</Typography>
          </Box>
        </Paper>

      </Box>

    </Box>
  );
}

export default Sumar;
