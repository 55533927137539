import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../service";


interface AdminGeneralStats {
    totalOnlineOrders: number;
    onlineOrdersRevenue: number;
    totalOfflineOrders: number;
    assignedOnlineOrdersCount: number;
    assignedOnlineOrdersRevenue: number;
    totalUsers: number;
    totalPosts: number;
  }
  
  interface AdminStatsState {
    generalStats: AdminGeneralStats | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
  }
  
  const initialState: AdminStatsState = {
    generalStats: null,
    status: 'idle',
  };

  
  export const fetchAdminGeneralStats = createAsyncThunk(
    'admin/fetchAdminGeneralStats',
    async (_, { rejectWithValue }) => {
      const [error, response] = await API<AdminGeneralStats>('GET', '/admin/general/stats');
      
      if (error) return rejectWithValue(error.message);
      
      return response?.data;
    }
  );

  
  const adminStatsSlice = createSlice({
    name: 'adminStats',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminGeneralStats.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAdminGeneralStats.fulfilled, (state, action: PayloadAction<AdminGeneralStats>) => {
                state.status = 'succeeded';
                state.generalStats = action.payload;
            })
            .addCase(fetchAdminGeneralStats.rejected, (state) => {
                state.status = 'failed';
            });
    },
  });
  
  export default adminStatsSlice.reducer;