import React, { useEffect, useState } from 'react';
import { Box, Paper, Tabs, Tab, Typography } from '@mui/material';
import UserRoleProfil from './UserRoleProfil';
import UserRoleStatistics from './UserRoleStatistics';
import StaffOrdersList from './StaffOrdersList';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const tabMap = ['Profil', 'Comenzi', 'Statistica'];

const UserDetaliPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const tabIndex = tabMap.indexOf(tab ?? '');
    if (tabIndex !== -1) {
      setSelectedTab(tabIndex);
    }
  }, [location.search]);

  useEffect(() => {
    const userIdNum = parseInt(userId ?? '0', 10);
    if (isNaN(userIdNum) || userIdNum <= 0) {
      navigate("/admin/Lucratori");
    }
  }, [userId, navigate]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    const tabParam = tabMap[newValue];
    navigate(`?tab=${tabParam}`);
  };;

  return (
    <Box sx={{ 
      height: "100vh", 
      width: '100%', 
      overflow: "auto", 
      p: '2%', 
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: "grey.100",
      gap: 4
    }}>

      <Box sx={{
        width: '100%',
        flex: '0 1 auto',
      }}>
        <Paper elevation={3} sx={{ 
          padding: 2, 
          flex: 1, 
          borderRadius: '8px',  
          display: 'flex', 
          justifyContent: 'space-between'
        }}>

          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              sx={{
                '.MuiTabs-indicator': {
                  backgroundColor: 'black', 
                },
              }}
            >
              <Tab label={<Typography variant='h4' color="textPrimary">Profil</Typography>} />
              <Tab label={<Typography variant='h4' color="textPrimary">Comenzi</Typography>} />
              <Tab label={<Typography variant='h4' color="textPrimary">Statistica</Typography>} />
            </Tabs>    
          </Box>     
          <Box sx={{ flex: 1 }} />
        </Paper>
      </Box>
      
      <Box sx={{ flex: 1 }}>
        {selectedTab === 0 && <UserRoleProfil id={parseInt(userId ?? '0', 10)} />}
        {selectedTab === 1 && userId && <StaffOrdersList userId={parseInt(userId ?? '0', 10)} />}
        {selectedTab === 2 && <UserRoleStatistics />}
      </Box>
    </Box>
  );
}

export default UserDetaliPage;
