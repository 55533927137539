import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Box, Badge, Typography, Divider, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

interface Page {
  title: string;
  icon: React.ReactNode;
  path: string;
  badgeContent?: number;
}

interface MobileMenuProps {
  pages: Page[];
  currentPath: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ pages, currentPath }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    
    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };

    const handleNavigate = (path: string) => {
        navigate(path);
        setIsOpen(false); 
    };

  return (
    <Box>
      <IconButton onClick={() => toggleDrawer(true)}>
        <MenuIcon sx={{ color: 'black' }} />
      </IconButton>
      <Drawer
        anchor='left'
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250, 
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
        }}
      >
        <Box>
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <Typography variant="h3">Meniu</Typography>
            <Divider sx={{ my: 2 }} />
          </Box>
          <List>
            {pages.map(({ title, icon, path, badgeContent }) => (
              <ListItem button key={title} onClick={() => handleNavigate(path)}
                  sx={{
                  backgroundColor: path === currentPath ? 'rgba(0, 0, 0, 0.08)' : 'transparent', 
              }}>
                <ListItemIcon>
                  {badgeContent && badgeContent > 0 ? (
                    <Badge variant="dot" badgeContent={badgeContent} color="secondary">
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )}
                </ListItemIcon>
                <ListItemText 
                  primary={title} 
                  primaryTypographyProps={{ 
                    variant: 'h6', 
                    sx: { fontSize: '1.2rem' } 
                  }} 
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={() => toggleDrawer(false)}
            sx={{
              width: '100%',
              bgcolor: 'black',
              color: 'white',
              borderRadius: '20px',
              ':hover': {
                bgcolor: 'darkgrey',
              },
            }}
          >
            Închide
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
