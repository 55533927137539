import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../service";

export interface Role {
    id: number;
    name: string;
    level: number;
    userCount?: number;
}

interface RoleCreatePayload {
    name: string;
    level: number;
}

interface RolesState {
    roles: Role[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: RolesState = {
    roles: [],
    status: 'idle',
    error: null,
};

export const fetchRoles = createAsyncThunk<Role[], undefined, { rejectValue: string }>(
    'roles/fetchRoles',
    async (_, { rejectWithValue }) => {
        const [error, response] = await API<Role[]>('GET', '/roles');
        if (error) return rejectWithValue(error.toString());
        return response?.data ?? [];
    }
);

export const createRole = createAsyncThunk<{message: string, role: Role}, RoleCreatePayload, { rejectValue: string }>(
    'roles/createRole',
    async (roleData, { rejectWithValue }) => {
        const [error, response] = await API<{message: string, role: Role}>('POST', '/roles/create/role', roleData);
        if (error) return rejectWithValue(error.toString());
        return response?.data;
    }
);


const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
                state.status = 'succeeded';
                state.roles = action.payload;
            })
            .addCase(fetchRoles.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.status = 'failed';
                state.error = action.payload ?? null;
            })
            .addCase(createRole.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createRole.fulfilled, (state, action: PayloadAction<{ message: string, role: Role }>) => {
                state.status = 'succeeded';
                state.roles = [...state.roles, action.payload.role];
                const roleWithUserCount = { ...action.payload.role, userCount: action.payload.role.userCount || 0 };
                state.roles = [...state.roles, roleWithUserCount];
              })                    
            .addCase(createRole.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.status = 'failed';
                state.error = action.payload ?? null;
            });
    },
});

export const selectRoles = (state: { roles: RolesState }) => state.roles.roles;
export const selectRolesStatus = (state: { roles: RolesState }) => state.roles.status;
export const selectRolesError = (state: { roles: RolesState }) => state.roles.error;

export default rolesSlice.reducer;
