import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface DialogMessageMobileProps {
    open: boolean;
    handleClose: () => void;
    isAnswered: boolean;
    handleEditAnswer: () => void;
    handleReplyButtonClick: () => void;
    isReplyMode: boolean;
    answerText: string;
    setAnswerText: React.Dispatch<React.SetStateAction<string>>;
    handleSaveReply: () => void;
    handleCancelReply: () => void;
    selectedDetails: {
      ParentMessageId: number;
      RepliesMessageId: number;
      ProductTitle?: String;
      PostId: number;
      user: string;
      message: string;
      answer: string;
    };
  }

  const DialogMessageMobile: React.FC<DialogMessageMobileProps> = ({
    open,
    handleClose,
    isAnswered,
    handleEditAnswer,
    handleReplyButtonClick,
    isReplyMode,
    answerText,
    setAnswerText,
    handleSaveReply,
    handleCancelReply,
    selectedDetails,
  }) => {
    return (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '80%',
              height: '80%',
              maxHeight: '90vh',
            },
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              {/* User */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 0.5,
                }}
              >
                <Typography variant="h3">Utilizator:</Typography>
                <Typography variant="h3"> {selectedDetails.user}</Typography>
              </Box>
              {/* Product */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 0.5,
                }}
              >
                <Typography variant="h3">Produs:</Typography>
                <Typography variant="h3"> {selectedDetails.ProductTitle}</Typography>
              </Box>
              {/* Intrebare */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 0.5,
                }}
              >
                <Typography variant="h3">Intrebare:</Typography>
                <Typography variant="h3">{selectedDetails.message}</Typography>
              </Box>
    
            {isReplyMode ? (
            <TextField
                label="Raspuns"
                multiline
                rows={4}
                variant="outlined"
                value={answerText}
                onChange={(e) => setAnswerText(e.target.value)}
                sx={{ mt: 2, width: '100%' }}
            />
            ) : (
            //Raspuns
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 0.5,
              }}
            >
              <Typography variant="h3">Raspuns:</Typography>
              {selectedDetails.answer.trim() !== '' ? (
                <Typography variant="h3">{selectedDetails.answer}</Typography>
              ) : (
                <Typography variant="h3">Nu are raspuns</Typography>
              )}
            </Box>
            )}
            </Box>

                {isReplyMode && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveReply}
                      disabled={!answerText.trim()}
                      sx={{ mr: 2 }}
                    >
                    Salveaza Raspuns
                    </Button>
                    <Button variant="outlined" onClick={handleCancelReply}>
                      Anuleaza
                    </Button>
                </Box>
                )}
                
          </DialogContent>
          
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Inchide
            </Button>
            {isAnswered && (
              <Button onClick={handleEditAnswer} color="primary" startIcon={<EditIcon />}>
                Schimba raspunsul
              </Button>
            )}
            {!isAnswered && (
              <Button onClick={handleReplyButtonClick} color="primary" disabled={isAnswered}>
                Raspunde
              </Button>
            )}
          </DialogActions>
        </Dialog>
      );
    };

export default DialogMessageMobile