import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Divider, Grid, IconButton, Paper } from '@mui/material';
import { OfflineOrderDetailWithoutId } from '../../../../redux/slices/orderoffline';
import InfoIcon from '@mui/icons-material/Info';
import { Product } from '../../../../redux/slices/productAdmin';

interface SelectProductProps {
    openDialog: () => void;
    openInfoDialog: (productId: number | undefined) => void;
    selectedProductDetails: OfflineOrderDetailWithoutId[];
    selectedProducts: Product[];
}

const SelectProduct: React.FC<SelectProductProps> = ({
    openDialog,
    openInfoDialog,
    selectedProductDetails,
    selectedProducts,
}) => {
    const hasSelectedProducts = selectedProductDetails.length > 0;
    const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 

    const groupedDetails = selectedProductDetails.reduce((acc, detail) => {
        acc[detail.productId] = acc[detail.productId] || [];
        acc[detail.productId].push(detail);
        return acc;
    }, {} as Record<number, OfflineOrderDetailWithoutId[]>);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        }}>
            {hasSelectedProducts ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}>
                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ p: 1 }}>
                            Produse Selectate
                        </Typography>
                        <IconButton onClick={openDialog} color="primary" sx={{ border: 2, borderColor: 'primary.main', borderRadius: '50%' }}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 2 }}/>
                    <Grid container spacing={2} sx={{ 
                        flexGrow: 1,
                        paddingBottom: '16px'
                    }}>
                        {Object.entries(groupedDetails).map(([productId, details]) => {
                                const product = selectedProducts.find(product => product.id === Number(productId));
                                if (!product) return null;
                                const totalQuantity = details.reduce((sum, detail) => sum + detail.quantity, 0);
                                const totalPrice = product.hasActiveDiscount && product.discount
                                ? (product.price - (product.price * product.discount.percentage / 100)) * totalQuantity
                                : product.price * totalQuantity;

                                return (
                                <Grid item xs={12} sm={6} key={productId}>
                                    <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 2, borderRadius: 2, backgroundColor: '#f7f7f7', position: 'relative', gap: 2 }}>
                                        <Avatar sx={{ width: 'auto', height: 170, borderRadius: '10%' }} 
                                            src={product && product.imageUrl.length > 0 ? `${baseUrl}${product.imageUrl[0]}` : undefined} 
                                        />
                                        <Box sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            justifyContent: 'center', 
                                            height: '100%', 
                                            width: '100%',
                                        }}>
                                            <Typography variant="h4" sx={{ alignSelf: 'center' }}>
                                                {product.title}
                                            </Typography>
                                            {product.hasActiveDiscount && (
                                                <Typography variant="h5" sx={{ alignSelf: 'center', textDecoration: 'line-through' }}>
                                                    {product.price.toFixed(2)} Lei
                                                </Typography>
                                            )}
                                            <Typography variant="h5" sx={{ alignSelf: 'center', color: product.hasActiveDiscount ? 'red' : 'inherit' }}>
                                                {(product.hasActiveDiscount && product.discount 
                                                    ? product.price - (product.price * product.discount.percentage / 100)
                                                    : product.price).toFixed(2)} Lei
                                            </Typography>
                                            <Typography variant="h5" sx={{ alignSelf: 'center', mt: 1 }}>
                                                Total: {totalQuantity} produse
                                            </Typography>
                                            <Typography variant="h5" sx={{ alignSelf: 'center', mt: 1 }}>
                                                {totalPrice.toFixed(2)} Lei
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            onClick={() => openInfoDialog(product?.id)}
                                            sx={{ position: 'absolute', right: 8, top: 8 }}
                                            color="primary"
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Paper>

                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <IconButton
                        onClick={openDialog}
                        color="primary"
                        sx={{
                            border: '2px solid',
                            borderColor: 'primary.main',
                            borderRadius: '50%',
                            transform: 'scale(2)',
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default SelectProduct;
