import React, { useState } from 'react';
import { Box} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Role } from '../../../redux/slices/roles';

interface RoleListProps {
  roles: Role[]; 
}

const RoleList: React.FC<RoleListProps> = ({ roles }) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: perPage,
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Level',
      headerName: 'Nivel',
      flex: 1,
    },
    {
      field: 'Count',
      headerName: 'Utlizatori',
      flex: 1,
    },
  ];

  const rows = roles.map((role) => ({
    id: role.id,
    Name: role.name,
    Level: role.level,
    Count: role.userCount
  }));

  return (
    <Box style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: perPage,
                    page: page
                  },
                },
              }}
            pageSizeOptions={[5]}
            checkboxSelection
            sx={{ fontSize: 'clamp(12px, 1.6vw, 20px)' }}
        />
    </Box>
  );
};

export default RoleList;
