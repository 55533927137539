import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Snackbar, Paper, CircularProgress, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { RootState } from '../../redux/store';
import { selectIsAuthenticated } from '../../redux/slices/auth';
import { useNavigate } from 'react-router-dom';
import Checkout from '../../components/Client/ShoppingCart/Checkout';
import CartItem from '../../components/Client/ShoppingCart/CartItem';
import { fetchCartItems, removeFromCart, updateCartQuantity, addToFavorites, removeFromFavorites } from '../../redux/slices/produse';

import { unwrapResult } from '@reduxjs/toolkit';


const ShoppingCart: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const cartSummary = useAppSelector((state: RootState) => state.products.cart);
  const cartItems = useAppSelector((state: RootState) => state.products.cart.items);
  const cartStatus = useAppSelector((state: RootState) => state.products.cart.status);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCartItems());
    }
  }, [dispatch, isAuthenticated]);

  const handleProductsClick = () => {
    navigate('/Products');
  };

  const handleLogIn = () => {
    navigate('/Authentication');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(''); 
  };

  const handleAddToFavorites = async (productId: number) => {
    if (!isAuthenticated) {
        alert('Please log in to add products to favorites');
        return;
    }
    try {
        const actionResult = await dispatch(addToFavorites({ productId }));
        const result = unwrapResult(actionResult);
        if (result.success) {
            setSnackbarMessage('Produsul a fost adaugat in favorite!');
            setSnackbarOpen(true);
        }
    } catch (error) {
        setSnackbarMessage('A aparaut o eroare in procesul de adauagre in faovorite.');
        setSnackbarOpen(true);
    }
  };

  const handleRemoveFromFavorites = async (productId: number) => {
      try {
          const actionResult = await dispatch(removeFromFavorites(productId));
          const result = unwrapResult(actionResult);
          if (result.success) {
              setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
              setSnackbarOpen(true);
          }
      } catch (error) {
          setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
          setSnackbarOpen(true);
      }
  };

  const handleRemoveFromCart = async (cartId: number) => {
    try {
        const actionResult = await dispatch(removeFromCart(cartId));
        const result = unwrapResult(actionResult);
        if (result.success) {
          setSnackbarMessage('Produsul a fost sters cu succes din cos!');
          setSnackbarOpen(true);
          setSnackbarKey(prevKey => prevKey + 1);
        }
    } catch (error) {
        setSnackbarMessage('A aparut o problema, va rugam incercati mai tarziu.');
        setSnackbarOpen(true);
        setSnackbarKey(prevKey => prevKey + 1);
    }
  };

  const handleUpdateCartQuantity = async (cartId: number, quantity: number) => {
    try {
        const actionResult = await dispatch(updateCartQuantity({ cartId, quantity }));
        const result = unwrapResult(actionResult);
        if (result.success) {
          setSnackbarMessage('Cantitatea din coș a fost actualizată cu succes!');
          setSnackbarOpen(true);
          setSnackbarKey(prevKey => prevKey + 1);
        }
    } catch (error) {
        setSnackbarMessage('Actualizarea cantității din coș a eșuat.');
        setSnackbarOpen(true);
        setSnackbarKey(prevKey => prevKey + 1);
    }
  };

  const handleGoToDetails = () => {
    navigate('/ShoppingCart/DetaliiComanda');
  };
    
  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      width: '100%',
      p: '1%',
      flexDirection: 'column',
    }}>
      <Paper elevation={6} sx={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 1,
      }}>
        {!isAuthenticated ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: '1%' }}>
            <Typography variant="h3" align='center'>
              Autentifica-te pentru a vedea produsele favorite
            </Typography>
            <Button variant="outlined" color="primary" sx={{ marginTop: 2, borderRadius: '20px', fontSize: '1.4rem', padding: '15px 30px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)', borderColor: 'black' } }} onClick={handleLogIn}>
              Intra in cont
            </Button>
          </Box>
        ) : cartStatus === 'loading' ? (
          <CircularProgress />
        ) : cartItems.length === 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Typography variant="h3" align='center'>
              Cosul tau este gol
            </Typography>
            <Button variant="outlined" color="primary" sx={{ 
              marginTop: 2,
              borderRadius: '20px', 
              fontSize: '1.4rem', 
              padding: '15px 30px', 
              borderColor: 'black', 
              color: 'black', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)', 
              borderColor: 'black' } 
            }} 
            onClick={handleProductsClick}>
              Treci la Produse
            </Button>
          </Box>
        ) : (
          <Box sx={{ 
            flexGrow: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
          }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>   
              <Typography variant="h2" sx={{ flex: 1, textAlign: 'center' }}>
                Cosul Meu
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap', 
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              p: 1,
              gap: 4
            }}>
              {cartItems.map((item) => (
                <CartItem 
                  key={item.Id} 
                  cartItem={item}
                  handleAddToFavorites={handleAddToFavorites}
                  handleRemoveFromFavorites={handleRemoveFromFavorites}
                  handleRemoveFromCart={handleRemoveFromCart}
                  handleUpdateCartQuantity={handleUpdateCartQuantity}
                />
              ))}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mt: 1,
            }}>
                <Checkout 
                  totalItems={cartSummary.totalItems}
                  totalPrice={cartSummary.totalPrice}
                  onContinue={handleGoToDetails}
                />
            </Box>
          </Box>
        )}
      </Paper>

      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
};

export default ShoppingCart;