import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Drawer, IconButton, Button, Typography, TextField } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterParams } from '../../../redux/slices/order';

interface StaffOrderListFilterProps {
    onApplyFilters: (filters: FilterParams) => void;
    onResetFilters: () => void;
    initialFilters: FilterParams;
  }

const StaffOrderListFilter: React.FC<StaffOrderListFilterProps> = ({ onApplyFilters, onResetFilters, initialFilters }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [filters, setFilters] = useState<FilterParams>(initialFilters);

    useEffect(() => {
        setFilters(initialFilters);
    }, [initialFilters]);
  
    const handleFilterClick = () => {
      setIsDrawerOpen(true);
    };
  
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };
  
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };
  
    const applyFilters = () => {
      onApplyFilters(filters); 
      setIsDrawerOpen(false);
    };
  
    const resetFilters = () => {
        setFilters(initialFilters); 
        onResetFilters(); 
    };
  
    return (
      <Box>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon sx={{ fontSize: '2rem' }}/>
        </IconButton>
        <Drawer
          anchor='right'
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{ 
              width: 350, 
              height: '100%',
              padding: '20px',
              backgroundColor: 'grey.900', 
              color: 'white',
            }}
            role="presentation"
          >
            <Typography variant="h3" sx={{ marginBottom: '20px', textAlign: 'center' }}>Filtre</Typography>
            <TextField
              fullWidth
              label="Search Term"
              variant="filled"
              name="searchTerm"
              value={filters.searchTerm || ''}
              onChange={handleInputChange}
              sx={{ backgroundColor: 'grey.800', mb: 2, input: { color: 'white' }, '.MuiInputLabel-root': { color: 'grey.500' } }}
            />
            {/* Additional filter options here */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={applyFilters}>
                Apply Filters
              </Button>
              <Button variant="outlined" color="secondary" onClick={resetFilters}>
                Reset
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Box>
    );
  };
  
  export default StaffOrderListFilter;
