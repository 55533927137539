import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Discount } from './posts';
import { API } from '../service';

interface AddToFavoritesPayload {
  postId: number;
}
export type FavoritePostIds = number[];

export type FavoriteItem = {
  id: number;
  userId?: number;
  postId?: number;
  title: string;
  imageUrl: string[];
  price: number;
  discount?: Discount;  
  hasActiveDiscount?: boolean; 
  averageRating: number;
};

export interface AddToFavoritesResponse {
  success: boolean;
  message: string;
  postId: number;
}

export interface RemoveFromFavoritesResponse {
  success: boolean;
  message: string;
  postId: number;
}

interface FavoritesState {
  items: FavoriteItem[];
  status: 'idle' | 'loading' | 'loaded' | 'error';
  totalFavorites: number;
  isCurrentFavorite: boolean | null;
  fetchingFavoriteStatus: 'idle' | 'loading' | 'loaded' | 'error';
  favoritePostIds: FavoritePostIds;
}

const initialState: FavoritesState = {
  items: [],
  status: 'idle',
  totalFavorites: 0,
  isCurrentFavorite: null,
  fetchingFavoriteStatus: 'idle',
  favoritePostIds: [],
};


export const fetchFavorites = createAsyncThunk<{favorites: FavoriteItem[], totalFavorites: number}, void, { rejectValue: string }>(
  'favorites/fetchFavorites',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{favorites: FavoriteItem[], totalFavorites: number}>('GET', '/favorite');

    if (error || !response) {
      return rejectWithValue('Failed to fetch favorites');
    }
    return response.data;
  }
);

export const addToFavorites = createAsyncThunk<AddToFavoritesResponse, AddToFavoritesPayload, { rejectValue: string }>(
  'favorites/addToFavorites',
  async ({ postId }, { rejectWithValue }) => {
    const [error, response] = await API<AddToFavoritesResponse>('POST', '/favorite', { postId });

    if (error || !response) {
      return rejectWithValue('Failed to add to favorites');
    }

    return response.data;
  }
);

export const removeFromFavorites = createAsyncThunk<RemoveFromFavoritesResponse, number, { rejectValue: string }>(
  'favorites/removeFromFavorites',
  async (postId, { rejectWithValue }) => {
    const [error, response] = await API<RemoveFromFavoritesResponse>('DELETE', `/favorite/${postId}`);

    if (error || !response) {
      return rejectWithValue('Failed to remove from favorites');
    }

    return response.data;
  }
);

export const fetchFavoritesCount = createAsyncThunk<number, void, { rejectValue: string }>(
  'favorites/fetchFavoritesCount',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ totalFavorites: number }>('GET', '/user/favorite/count');
    
    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to fetch favorite count');
    }
    
    return response.data.totalFavorites;
  }
);




const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setIsCurrentFavorite: (state, action: PayloadAction<boolean>) => {
      state.isCurrentFavorite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFavorites.fulfilled, (state, action: PayloadAction<{favorites: FavoriteItem[], totalFavorites: number}>) => {
        state.status = 'loaded';
        state.items = action.payload.favorites;
        state.totalFavorites = action.payload.totalFavorites;  
      })
      .addCase(fetchFavorites.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(addToFavorites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addToFavorites.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.status = 'loaded';
          state.favoritePostIds.push(action.payload.postId);
          state.isCurrentFavorite = true;
          state.totalFavorites += 1;
        } else {
          state.status = 'error';
        }
      })
      .addCase(addToFavorites.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(removeFromFavorites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFromFavorites.fulfilled, (state, action: PayloadAction<{ success: boolean; message: string; postId: number }>) => {
        if (action.payload.success) {
          state.status = 'loaded';
          const removedPostId = action.payload.postId;
          state.items = state.items.filter(item => item.id !== removedPostId);
          state.favoritePostIds = state.favoritePostIds.filter(id => id !== removedPostId);
          state.totalFavorites -= 1;
        } else {
          state.status = 'error';
        }
      })      
      .addCase(removeFromFavorites.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchFavoritesCount.fulfilled, (state, action: PayloadAction<number>) => {
        state.totalFavorites = action.payload;
      })
  },
});

export const { setIsCurrentFavorite } = favoritesSlice.actions;

export const favoritesReducer = favoritesSlice.reducer;
