import React from 'react';
import { Card, CardMedia, Typography, Box, IconButton } from '@mui/material';
import { ProductClass } from '../../../../redux/slices/class';
import EditIcon from '@mui/icons-material/Edit';

interface CategoryCardProps {
  productClass: ProductClass;
  onEdit: (category: ProductClass) => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ productClass, onEdit }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || '';

  return (
    <Card
      sx={{
        width: 330,
        height: 330,
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.025)',
        },
        position: 'relative'
      }}
    >
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={() => onEdit(productClass)} sx={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' } }}>
            <EditIcon />
          </IconButton>
        </Box>
        <CardMedia
          component="img"
          height="100%"
          image={productClass.imageUrl ? `${baseUrl}${productClass.imageUrl}` : 'path/to/placeholder-image.jpg'} 
          alt={productClass.name}
          sx={{ objectFit: 'cover' }}
        />
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '10px',
          textAlign: 'center',
        }}>
          <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
            {productClass.name}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CategoryCard;
