import { useMediaQuery, Box, useTheme  } from '@mui/material';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHasRole } from '../../redux/slices/auth';
import AdminNavBar from '../../components/Admin/adminnavbar/AdminNav';
import AdminNavBarMobile from '../../components/Admin/adminnavbar/AdminNavBarMobile';
import HomeAdmin from './HomeAdmin';
import Comenzi from './Comenzi';
import OrderDetailAdmin from './OrderDetailAdmin';
import SendEmail from './SendEmail';
import CreateOfflineOrder from './CreateOfflineOrder';
import OfflineOrderDetailAdmin from './OfflineOrderDetailAdmin';
import Users from './Users';
import DepozitProduse from './DepozitProduse';
import Coments from './Coments';
import Statistic from './Statistic';
import ColorAndSize from './ColorAndSize';
import Role from './Role';
import UserDetaliPage from '../../components/Admin/roles/UserDetaliPage';
import ProtectedRoute from '../../utils/ProtectedRoute';
import EditCreateProduct from './EditCreateProduct';

const MainAdmin = () => {
    const hasRole = useSelector(selectHasRole);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!hasRole) {
      return <Navigate to="/" />;
    }
  
    return (
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', overflow: 'hidden' }}>
        {isMobile ? (
          <Box sx={{
            height: "7vh",
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <AdminNavBarMobile />
          </Box>
        ) : (
          <Box>
            <AdminNavBar />
          </Box>
        )}
        
        <Box sx={{ width: '100%' }}> 
          <Routes> 
            <Route path="Principala/:tab?" element={<ProtectedRoute component={HomeAdmin} requiredLevel={60} />} />
            <Route path="Statistica" element={<ProtectedRoute component={Statistic} requiredLevel={60} />} />
            <Route path="Societate" element={<ProtectedRoute component={Coments} requiredLevel={100} />} />
            <Route path="Altele" element={<ProtectedRoute component={ColorAndSize} requiredLevel={100} />} />
            <Route path="Email" element={<ProtectedRoute component={SendEmail} requiredLevel={100} />} />
            <Route path="Comenzi" element={<ProtectedRoute component={Comenzi} requiredLevel={60} />} />
            <Route path="Lucratori" element={<ProtectedRoute component={Role} requiredLevel={100} />} />
            <Route path="Lucratori/UserDetaliPage/:userId/:tab?" element={<ProtectedRoute component={UserDetaliPage} requiredLevel={100} />} />
            <Route path="Produse" element={<ProtectedRoute component={DepozitProduse} requiredLevel={100} />} />
            <Route path="OfflineOrder" element={<ProtectedRoute component={CreateOfflineOrder} requiredLevel={60} />} />
            <Route path="Creare/:id?" element={<ProtectedRoute component={EditCreateProduct} requiredLevel={100} />} />
            <Route path="/OrderDetailAdmin/:orderId?" element={<ProtectedRoute component={OrderDetailAdmin} requiredLevel={100} />} />
            <Route path="/OfflineOrderDetailAdmin/:orderId?" element={<ProtectedRoute component={OfflineOrderDetailAdmin} requiredLevel={100} />} />
            <Route path="Users" element={<ProtectedRoute component={Users} requiredLevel={100} />} />
          </Routes>
        </Box>
      </Box>
    );
}

export default MainAdmin;
