import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateRole from './CreateRole';
import RoleList from './RoleList';
import { fetchRoles, createRole } from '../../../redux/slices/roles';
import { useAppDispatch, useAppSelector } from '../../../redux/store';


const Roles = () => {
    const dispatch = useAppDispatch();
    const { roles, status: rolesStatus } = useAppSelector((state) => state.roles);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

    const handleCreateRole = (name: string, level: number) => {
      dispatch(createRole({ name, level }));
    };

    const handleClickOpen = (): void => {
      setOpen(true); 
    };
  
    const handleClose = (): void => {
      setOpen(false); 
    };


  return (
    <Paper elevation={3} sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Верхний Box с надписью и кнопкой Delete */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h2">
          Roluri
        </Typography>
        <Button 
          startIcon={<DeleteIcon />} 
          color="error" 
          variant="contained" 
          aria-label="delete"
          disabled
        >
          Delete
        </Button>
      </Box>

      {/* Содержимое списка ролей */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
        <RoleList roles={roles}/>
      </Box>

      {/* Нижний Box */}
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
            p: 2,
            borderRadius: 2,
            width: '100%', 
            flexDirection: 'row',
          }}
        >
          {/* Обертка для иконки и текста, выделяющаяся при наведении */}
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1, 
                    cursor: 'pointer',
                    padding: '10px', 
                    borderRadius: '8px', 
                    '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.2)', 
                    }
                }}
                onClick={handleClickOpen}
            >
                <IconButton aria-label="add new role" sx={{ color: '#1976d2', "&:hover": { bgcolor: 'transparent' } }}>
                    <AddIcon fontSize="large" /> 
                </IconButton>
                <Typography variant="h3" sx={{ color: '#1976d2' }}>
                    ADD NEW ROLE
                </Typography>
            </Box>
        </Box>
      </Box>

      {/* Условное отображение компонента CreateRole */}
      <CreateRole open={open} onClose={handleClose} onCreate={handleCreateRole} />
    </Paper>
  );
};

export default Roles;
