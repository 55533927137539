import React from 'react';
import { Box, Card, CardContent, CardHeader, Skeleton } from '@mui/material';

const ProductCardSkeleton: React.FC = () => {
  return (
    <Card sx={{
        width: 320,
        transition: '0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          '.MuiCardHeader-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }
        }
      }}>
      <CardHeader
        title={<Skeleton width="80%" />}
        action={
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
          }}>
              <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  justifyContent: 'flex-end',
                  width: '100%' 
              }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Skeleton width="60%" />
          </Box>
        }
      />
      <Skeleton variant="rectangular" height={194} />
      <CardContent>
        <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
         }}>
            <Skeleton width="40%" />
            <Skeleton width="40%" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCardSkeleton;
