import React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Input, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface AddNewCategoryProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  handleReset: () => void;
  newCategoryData: {
    name: string;
    image?: File;
  };
  setNewCategoryData: (data: { name: string; image?: File }) => void;
  currentImageUrl: string | null;
  setCurrentImageUrl: (url: string | null) => void;
  isEditMode: boolean;
}

const AddNewCategory: React.FC<AddNewCategoryProps> = ({ 
  open, 
  handleClose, 
  handleSave, 
  handleReset, 
  newCategoryData, 
  setNewCategoryData,
  currentImageUrl,
  setCurrentImageUrl,
  isEditMode
}) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || '';

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setNewCategoryData({ ...newCategoryData, image: file });
      setCurrentImageUrl(null);
    }
  };

  const handleRemoveImage = () => {
    setNewCategoryData({ ...newCategoryData, image: undefined });
    setCurrentImageUrl(null);
  };

  const isSaveDisabled = !newCategoryData.name || (!newCategoryData.image && !currentImageUrl);
  const isResetDisabled = !newCategoryData.name && !newCategoryData.image && !currentImageUrl;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          maxWidth: "80vw", 
          minHeight: "80vh", 
          maxHeight: "80vh", 
          overflow: "hidden",
          p: 1
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>{isEditMode ? 'Editează Categoria' : 'Categorie Noua'}</Typography>
        <Button onClick={handleReset} color="error" variant="contained" disabled={isResetDisabled} sx={{ textTransform: 'uppercase' }}>Reset</Button>
      </Box>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 130px)' }}>
        <Box sx={{ flexGrow: 1, mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!newCategoryData.image && !currentImageUrl ? (
            <label htmlFor="upload-image">
              <Input
                id="upload-image"
                type="file"
                inputProps={{ accept: 'image/*' }}
                onChange={handleImageChange}
                sx={{ display: 'none' }}
              />
              <IconButton component="span" sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                color: 'primary.main',
                '&:hover': {
                  bgcolor: 'grey.300'
                },
                width: 50,
                height: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <AddIcon />
              </IconButton>
            </label>
          ) : (
            <Box sx={{ position: 'relative', textAlign: 'center' }}>
              <img
                src={newCategoryData.image ? URL.createObjectURL(newCategoryData.image) : (currentImageUrl ? `${baseUrl}${currentImageUrl}` : undefined)}
                alt="Preview"
                style={{ maxHeight: '400px', marginBottom: '10px' }}
              />
              <Button 
                onClick={handleRemoveImage} 
                color="error" 
                variant="contained" 
                sx={{ position: 'absolute', top: '10px', right: '10px' }}
              >
                Remove Image
              </Button>
            </Box>
          )}
        </Box>
        <TextField
          margin="dense"
          label="Category Name"
          fullWidth
          value={newCategoryData.name}
          onChange={(e) => setNewCategoryData({ ...newCategoryData, name: e.target.value })}
          inputProps={{ style: { textTransform: 'uppercase' } }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="error" variant="contained" sx={{ textTransform: 'uppercase' }}>Anulare</Button>
        <Button onClick={handleSave} color="success" variant="contained" disabled={isSaveDisabled} sx={{ textTransform: 'uppercase' }}>Salveaza</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewCategory;
