import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Grid, IconButton, Stack, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import MasterCard from './../../../image/MaibPay/master.png';
import Visa from './../../../image/MaibPay/visawhite.png';
import Maib from './../../../image/MaibPay/maib.png';
import Amex from './../../../image/MaibPay/Ammmerx.png';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/store';
import { checkUserDiscount, createDiscount } from '../../../redux/slices/discount';
import { subscribeToNewsletter } from '../../../redux/slices/sendnewsletter';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../redux/slices/auth';


const Footer = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const [userHasDiscount, setUserHasDiscount] = useState(false);
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery('(max-width:1300px)');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSubscribedDialogOpen, setIsSubscribedDialogOpen] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
          dispatch(checkUserDiscount())
            .unwrap()
            .then((hasDiscount) => {
              setUserHasDiscount(hasDiscount);
            });
        }
    }, [dispatch, isAuthenticated]);

    const handleConfirmSubscription = () => {
        const discountData = {
          discount_percentage: 10,
          is_subscribed_discount: true,
        };
        dispatch(createDiscount(discountData));
        
        dispatch(subscribeToNewsletter())
          .then((action) => {
            if (subscribeToNewsletter.fulfilled.match(action)) {
              const response = action.payload;
              if (response.success) {
                setIsSubscribedDialogOpen(true);
              }
            }
          })
          .catch((error) => {
          });
      
        setIsDialogOpen(false);
    };

    const handleCancelSubscription = () => {
        setIsDialogOpen(false);
    };

    const handleCloseSubscribedDialog = () => {
        setIsSubscribedDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };


    const handleTermsClick = () => {
        navigate('/Termeni&Conditii');
    };

    const handleProductsClick = () => {
        navigate('/Products');
    };

    const handleMyAccountClick = () => {
        navigate('/myaccount');
    };

    const handleFavoriteListClick = () => {
        navigate('/FavoriteList');
    };
    
    const handleShoppingCartClick = () => {
        navigate('/shoppingcart');
    };
    
    const handleOrdersClick = () => {
        navigate('/orders');
    };

    const handleHelpClick = () => {
        navigate('/myaccount/NeedHelp');
    };

    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/profile.php?id=100088586350330', "_blank");
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/milivnik_shopmd/', "_blank");
    };

    const handleRegisterClick = () => {
        navigate('/Authentication');
    };

  return (
    <Box sx={{ backgroundColor: '#1c1c1e', color: '#ffffff', p: 4, height: '100%', width: '100%', }}>
        <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h1" gutterBottom>
                    MILIVNIK
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PhoneIcon sx={{ mr: 1 }} />
                    <Typography variant='h5'>MD (+373) 69927805</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <EmailIcon sx={{ mr: 1 }} />
                    <Typography variant='h5'>mariuscibotaruuaic@gmail.com</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocationOnIcon sx={{ mr: 1 }} />
                    <Typography variant='h5'>Balti</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <Typography variant="h3" gutterBottom>
                    Informatii
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }} onClick={handleMyAccountClick}>
                    Contul Meu
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }} onClick={handleShoppingCartClick}>
                    Cosul Meu
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }} onClick={handleFavoriteListClick}>
                    Favorite
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={handleOrdersClick}>
                    Comenzi
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <Typography variant="h3" gutterBottom>
                    Servicii
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }} onClick={handleProductsClick}>
                    Produse
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }}>
                    Despre Noi
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, mb: 1 }} onClick={handleTermsClick}>
                    Termeni si conditii
                </Typography>
                <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={handleHelpClick}>
                    Suport Clienti
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" gutterBottom>
                    Abonează-te
                </Typography>
                <Box sx={{
                    position: 'relative',
                    width: '100%',
                    flexGrow: 1,
                    border: '1px solid #ffffff',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    boxSizing: 'border-box'
                }}>
                    <Typography variant="h2">Reducere 10%</Typography>
                    <IconButton sx={{ color: '#ffffff' }} disabled={!isAuthenticated || userHasDiscount} onClick={handleOpenDialog}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                    {(userHasDiscount || !isAuthenticated) && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '8px',
                            cursor: !isAuthenticated ? 'pointer' : 'default'
                        }}
                        onClick={!isAuthenticated ? handleRegisterClick : undefined} 
                        >
                            {!isAuthenticated ? (
                                <PersonAddIcon sx={{ color: '#fff', fontSize: 48 }} />
                            ) : (
                                <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: 48 }} />
                            )}
                        </Box>
                    )}

                </Box>
            </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: '#ffffff' }} />

        <Box
            sx={{
            display: 'flex',
            flexDirection: isTabletOrMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: isTabletOrMobile ? 'center' : 'space-between',
            textAlign: isTabletOrMobile ? 'center' : 'left',
            }}
        >
            <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: isTabletOrMobile ? 'center' : 'flex-start',
                mb: isTabletOrMobile ? 2 : 0,
            }}
            >
            <Stack direction="row" spacing={2} alignItems="center">
                <Box
                sx={{
                    width: '65px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid black',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <img src={Visa} alt="Visa" width="100%" height="auto" />
                </Box>
                <Box
                sx={{
                    width: '65px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid black',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <img src={MasterCard} alt="MasterCard" width="100%" height="auto" />
                </Box>
                <Box
                sx={{
                    width: '65px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid black',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <img src={Maib} alt="Maib" width="120%" height="auto" />
                </Box>
                <Box
                sx={{
                    width: '65px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid black',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <img src={Amex} alt="Amex" width="100%" height="auto" />
                </Box>
            </Stack>
            </Box>

            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: isTabletOrMobile ? 2 : 0,
                textAlign: 'center',
            }}
            >
            <Typography variant="h4">
                © 2023 Toate drepturile rezervate
                <br />
                LIA_FASHION SRL
            </Typography>
            </Box>

            <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
                gap: 2,
            }}
            >
            <Stack direction="row" spacing={4} alignItems="center">
                <FacebookOutlinedIcon
                fontSize="large"
                onClick={handleFacebookClick}
                style={{ cursor: 'pointer', color: '#fff' }}
                />
                <InstagramIcon
                fontSize="large"
                onClick={handleInstagramClick}
                style={{ cursor: 'pointer', color: '#fff' }}
                />
            </Stack>
            </Box>
        </Box>

        {/* Dialog */}
        <Dialog open={isDialogOpen} onClose={handleCancelSubscription} maxWidth="xs" fullWidth>
            <DialogTitle>Sunteti siguri ca doriti sa va abonati?</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Apasati "Da" pentru a va abona sau "Nu" pentru a anula.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" color="primary" onClick={handleConfirmSubscription}>
                Da
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancelSubscription}>
                Nu
            </Button>
            </DialogActions>
        </Dialog>

        {/* Dialog 2 */}
        <Dialog open={isSubscribedDialogOpen} onClose={handleCloseSubscribedDialog} maxWidth="xs" fullWidth>
            <DialogTitle>Felicitari!</DialogTitle>
            <DialogContent>
            <DialogContentText>V-ati abonat cu success.</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" color="primary" onClick={handleCloseSubscribedDialog}>
                OK
            </Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
};

export default Footer;

