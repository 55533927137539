import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service';
import { InventoryItem } from './inventory';
import { User } from './auth';

export interface Product {
  id: number;
  title: string;
  text: string;
  imageUrl: string[]; 
  price: number;
  discount?: Discount;  
  inventories?: InventoryItem[];
  hasActiveDiscount?: boolean; 
  isFavorite?: boolean;
  averageRating: number;
  productClassId?: number;
  createdAt: string; 
  updatedAt: string; 
}

export interface Discount {
  percentage: number;
  startDate: string;
  endDate: string;
}

export interface Rating {
  Id: number;
  value: number;
  User: User;
  UserId?: number;
  comment?: string;
  createdAt?: string;
  updatedAt?: string;
  imageUrl: string[];
}

export interface Replies {
  id: number;
  text: string;
  createdAt: string;
  User: User;
}

export interface Message {
  Id: number;
  text: string;
  userId: number;
  User: User;
  productId: number;
  isAnswer: boolean;
  parentMessageId: number | null;
  createdAt: string;
  Replies?: Replies[];
}

export interface SelectedProduct extends Product {
  Ratings: Rating[];
  Messages: Message[];
}

export interface Filter {
  colorId?: number | null;
  sizeId?: number | null;
  productClassId?: number | null;
  cityId?: number | null;
  sort?: 'asc' | 'desc' | null;
  query?: string | null; 
  onSale?: boolean | null;
}

export interface Pagination {
  page: number;
  perPage: number;
}

export interface FetchPostsPaginationParams {
  pagination: Pagination;
  filters: Filter;
}

export type ProductsLoadingStatus = 'loading' | 'loaded' | 'error';

interface AddToFavoritesPayload {
  productId: number;
}

interface AddToFavoritesResponse {
  message: string;
  productId: number;
  success: boolean;
}

interface RemoveFromFavoritesResponse {
  message: string;
  productId: number;
  success: boolean;
}

interface UpdateCartQuantityParams {
  cartId: number;
  quantity: number;
}

export interface CartItems {
  Id: number;
  userId: number;
  productId: number;
  inventoryId: number;
  quantity: number;
  inStock: boolean;
  Product: Product;
  price: number;
}

interface ProductListState {
  items: Product[];
  status: ProductsLoadingStatus;
  count: number;
}

interface FavoriteListState {
  items: Product[];
  status: ProductsLoadingStatus;
  favoriteStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  count: number;
}

interface SelectedProductState {
  product: SelectedProduct | null;
  status: ProductsLoadingStatus;
}

interface CartState {
  totalItems: number;
  totalPrice: number;
  items: CartItems[];
  status: 'idle' | 'loading' | 'loaded' | 'error';
}

interface ProductState {
  products: ProductListState;
  favorites: FavoriteListState;
  selectedProduct: SelectedProductState;
  cart: CartState;
  lastAdded: ProductListState;
}

const initialState: ProductState = {
  products: {
    items: [],
    status: 'loading',
    count: 0,
  },
  favorites: {
    items: [],
    status: 'loading',
    favoriteStatus: 'idle',
    count: 0,
  },
  selectedProduct: {
    product: null,
    status: 'loading',
  },
  cart: {
    totalItems: 0,
    totalPrice: 0,
    items: [],
    status: 'idle'
  },
  lastAdded: { 
    items: [],
    status: 'loading',
    count: 0,
  }
};



export const fetchProductPagination = createAsyncThunk<{ products: Product[]; count: number }, FetchPostsPaginationParams, { rejectValue: string }>(
  'posts/fetchProductPagination',
  async ({ pagination, filters }, { rejectWithValue }) => {
    const [error, response] = await API<{ products: Product[]; count: number }>('GET', '/product/pagination/all', null, { pagination, ...filters });

    if (error || !response) {
      return rejectWithValue('Failed to fetch posts for pagination');
    }

    return {
      products: response.data.products,
      count: response.data.count,
    };
  }
);

export const fetchLastAddedProducts = createAsyncThunk<{ products: Product[], count: number }, void, { rejectValue: string }>(
  'products/fetchLastAddedProducts',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ products: Product[], count: number }>('GET', '/product/last');

    if (error || !response) {
      return rejectWithValue('Failed to fetch last added products');
    }

    return {
      products: response.data.products,
      count: response.data.count,
    };
  }
);

export const addToFavorites = createAsyncThunk<AddToFavoritesResponse, AddToFavoritesPayload, { rejectValue: string }>(
  'favorites/addToFavorites',
  async ({ productId }, { rejectWithValue }) => {
    const [error, response] = await API<AddToFavoritesResponse>('POST', '/favorite', { productId });

    if (error || !response) {
      return rejectWithValue('Failed to add to favorites');
    }

    return response.data;
  }
);

export const removeFromFavorites = createAsyncThunk<RemoveFromFavoritesResponse, number, { rejectValue: string }>(
  'favorites/removeFromFavorites',
  async (productId, { rejectWithValue }) => {
    const [error, response] = await API<RemoveFromFavoritesResponse>('DELETE', `/favorite/${productId}`);

    if (error || !response) {
      return rejectWithValue('Failed to remove from favorites');
    }

    return response.data;
  }
);

export const fetchFavoritesPagination = createAsyncThunk<{ favorites: Product[]; count: number }, Pagination, { rejectValue: string }>(
  'favorites/fetchFavoritesPagination',
  async (pagination, { rejectWithValue }) => {
    const [error, response] = await API<{ favorites: Product[]; count: number }>('GET', '/product/favorites', null, { pagination });
    
    if (error || !response) {
      return rejectWithValue('Failed to fetch favorite products');
    }

    if (response.data) {
      return {
        favorites: response.data.favorites,
        count: response.data.count,
      };
    } else {
      return rejectWithValue('No data found in the response');
    }
  }
);

export const fetchSelectedProduct = createAsyncThunk<SelectedProduct, number, { rejectValue: string }>(
  'products/fetchSelectedProduct',
  async (productId, { rejectWithValue }) => {
    const [error, response] = await API<SelectedProduct>('GET', `/product/get/${productId}`);
    if (error || !response) {
      return rejectWithValue('Failed to fetch product details');
    }
    return response.data;
  }
);

export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ items: CartItems[], totalItems: number }>('GET', '/cart');
    if (error || !response) {
      return rejectWithValue('Failed to fetch cart items');
    }
    return response.data;
  }
);

export const GetStockCartItems = createAsyncThunk(
  'cart/GetStockCartItems',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<{ items: CartItems[], totalItems: number }>('GET', '/cart/buy');
    if (error || !response) {
      return rejectWithValue('Failed to fetch cart items');
    }
    return response.data;
  }
);

export const removeFromCart = createAsyncThunk(
  'cart/removeFromCart',
  async (cartId: number, { rejectWithValue }) => {
    const [error, response] = await API<void>('DELETE', `/cart/${cartId}`);
    if (error || !response) {
      return rejectWithValue('Failed to remove from cart');
    }
    return response.data; 
  }
);

export const updateCartQuantity = createAsyncThunk(
  'cart/updateCartQuantity',
  async ({ cartId, quantity }: UpdateCartQuantityParams, { rejectWithValue }) => {
    const [error, response] = await API('POST', '/user/cart/update', { cartId, quantity });
    if (error || !response) {
      return rejectWithValue('Failed to add to favorites');
    }

    return response.data;
  }
);



const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductPagination.pending, (state) => {
        state.products.status = 'loading';
      })
      .addCase(fetchProductPagination.fulfilled, (state, action: PayloadAction<{ products: Product[]; count: number }>) => {
        state.products.status = 'loaded';
        state.products.items = action.payload.products;
        state.products.count = action.payload.count;
      })
      .addCase(fetchProductPagination.rejected, (state) => {
        state.products.status = 'error';
        state.products.items = [];
        state.products.count = 0;
      })
      .addCase(fetchLastAddedProducts.pending, (state) => {
        state.lastAdded.status = 'loading';
      })
      .addCase(fetchLastAddedProducts.fulfilled, (state, action: PayloadAction<{ products: Product[]; count: number }>) => {
        state.lastAdded.status = 'loaded';
        state.lastAdded.items = action.payload.products;
        state.lastAdded.count = action.payload.count;
      })
      .addCase(fetchLastAddedProducts.rejected, (state) => {
        state.lastAdded.status = 'error';
        state.lastAdded.items = [];
        state.lastAdded.count = 0;
      })
      .addCase(addToFavorites.pending, (state) => {
        state.favorites.favoriteStatus = 'loading';
      })
      .addCase(addToFavorites.fulfilled, (state, action: PayloadAction<AddToFavoritesResponse>) => {
        // Обновляем продукт в основном списке
        const index = state.products.items.findIndex(product => product.id === action.payload.productId);
        if (index !== -1) {
            state.products.items[index].isFavorite = true;
        }

        if (state.selectedProduct.product && state.selectedProduct.product.id === action.payload.productId) {
          state.selectedProduct.product.isFavorite = true;
        }
    
        // Добавляем продукт в список избранных, если его там ещё нет
        const favIndex = state.favorites.items.findIndex(product => product.id === action.payload.productId);
        if (favIndex === -1) {
            const product = state.products.items.find(product => product.id === action.payload.productId);
            if (product) {
                state.favorites.items.push({...product, isFavorite: true});
            }
        }

        const lastAddedIndex = state.lastAdded.items.findIndex(product => product.id === action.payload.productId);
        if (lastAddedIndex !== -1) {
            state.lastAdded.items[lastAddedIndex].isFavorite = true;
        }

         // Update product in the cart
        state.cart.items.forEach(item => {
          if (item.productId === action.payload.productId) {
              item.Product.isFavorite = true;
          }
        });

        state.favorites.favoriteStatus = 'succeeded';
      })
      .addCase(addToFavorites.rejected, (state, action) => {
        state.favorites.favoriteStatus = 'failed';
      })
      .addCase(removeFromFavorites.pending, (state) => {
          state.favorites.favoriteStatus = 'loading';
      })
      .addCase(removeFromFavorites.fulfilled, (state, action: PayloadAction<RemoveFromFavoritesResponse>) => {
        // Обновляем продукт в основном списке
        const index = state.products.items.findIndex(product => product.id === action.payload.productId);
        if (index !== -1) {
            state.products.items[index].isFavorite = false;
        }

        if (state.selectedProduct.product && state.selectedProduct.product.id === action.payload.productId) {
          state.selectedProduct.product.isFavorite = false;
        }
    
        // Удаляем продукт из списка избранных
        const favIndex = state.favorites.items.findIndex(product => product.id === action.payload.productId);
        if (favIndex !== -1) {
            state.favorites.items.splice(favIndex, 1);
        }

        const lastAddedIndex = state.lastAdded.items.findIndex(product => product.id === action.payload.productId);
        if (lastAddedIndex !== -1) {
            state.lastAdded.items[lastAddedIndex].isFavorite = false;
        }

        // Update product in the cart
        state.cart.items.forEach(item => {
          if (item.productId === action.payload.productId) {
              item.Product.isFavorite = false;
          }
        });

        state.favorites.favoriteStatus = 'succeeded';
      })
      .addCase(fetchFavoritesPagination.pending, (state) => {
        state.favorites.status = 'loading';
      })
      .addCase(fetchFavoritesPagination.fulfilled, (state, action: PayloadAction<{ favorites: Product[]; count: number }>) => {
        state.favorites.status = 'loaded';
        state.favorites.items = action.payload.favorites;
        state.favorites.count = action.payload.count;
      })
      .addCase(fetchFavoritesPagination.rejected, (state) => {
        state.favorites.status = 'error';
        state.favorites.items = [];
        state.favorites.count = 0;
      })
      .addCase(fetchSelectedProduct.pending, (state) => {
        state.selectedProduct.status = 'loading';
      })
      .addCase(fetchSelectedProduct.fulfilled, (state, action: PayloadAction<SelectedProduct>) => {
        state.selectedProduct.product = action.payload;
        state.selectedProduct.status = 'loaded';
      })
      .addCase(fetchSelectedProduct.rejected, (state, action) => {
        state.selectedProduct.status = 'error';
        state.selectedProduct.product = null;
      })
      .addCase(fetchCartItems.pending, (state) => {
        state.cart.status = 'loading';
      })
      .addCase(fetchCartItems.fulfilled, (state, action: PayloadAction<{ items: CartItems[], totalItems: number, totalPrice: number }>) => {
        state.cart.status = 'loaded';
        state.cart.items = action.payload.items;
        state.cart.totalItems = action.payload.totalItems; 
        state.cart.totalPrice = action.payload.totalPrice;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.cart.status = 'error';
      })
      .addCase(GetStockCartItems.pending, (state) => {
        state.cart.status = 'loading';
      })
      .addCase(GetStockCartItems.fulfilled, (state, action: PayloadAction<{ items: CartItems[], totalItems: number, totalPrice: number }>) => {
        state.cart.status = 'loaded';
        state.cart.items = action.payload.items;
        state.cart.totalItems = action.payload.totalItems; 
        state.cart.totalPrice = action.payload.totalPrice;
      })
      .addCase(GetStockCartItems.rejected, (state, action) => {
        state.cart.status = 'error';
      })
      .addCase(removeFromCart.pending, (state) => {
        state.cart.status = 'loading';
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.cart.status = 'loaded';
        state.cart.items = state.cart.items.filter(item => item.Id !== action.payload.cartItemId);
        state.cart.totalItems = action.payload.totalItems;
        state.cart.totalPrice = action.payload.totalPrice;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.cart.status = 'error';
      })
      .addCase(updateCartQuantity.pending, (state) => {
        state.cart.status = 'loading';
      })
      .addCase(updateCartQuantity.fulfilled, (state, action) => {
        let cartItem = state.cart.items.find(item => item.Id === action.payload.cartItemId);
        if (cartItem) {
            cartItem.quantity = action.payload.newQuantity;
        }
        state.cart.totalPrice = action.payload.totalPrice;
        console.log(action.payload.totalPrice)
        state.cart.totalItems = action.payload.totalItems;
        state.cart.status = 'loaded';
    })
      .addCase(updateCartQuantity.rejected, (state) => {
        state.cart.status = 'error';
      })

  },
});

export const productsReducer = productsSlice.reducer;
