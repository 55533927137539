import { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Paper, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchSelectedProduct, addToFavorites, removeFromFavorites } from '../../redux/slices/produse';
import ImageCarousel from '../../components/Client/ProductPage/ImageCarousel';
import ProductData from '../../components/Client/ProductPage/ProductData';
import DetailSection from '../../components/Client/ProductPage/DetailSection';
import { selectIsAuthenticated } from '../../redux/slices/auth';
import { selectCanReview, selectCanReviewStatus, checkCanReview } from '../../redux/slices/checkCanReview';
import { addToCart, AddToCartPayload } from '../../redux/slices/cart';
import { unwrapResult } from '@reduxjs/toolkit';

const ProductPage = () => {
    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const canReviewStatus = useAppSelector(selectCanReviewStatus);
    const canReviewValue = useAppSelector(selectCanReview);
    const selectedProduct = useAppSelector((state: RootState) => state.products.selectedProduct.product);
    const productStatus = useAppSelector((state: RootState) => state.products.selectedProduct.status);
    const isSmallScreen = useMediaQuery('(max-width:1300px)');
    const [cartPayload, setCartPayload] = useState<AddToCartPayload>({
        productId: selectedProduct?.id || 0,
        colorId: null,
        sizeId: null,
        cityId: null,
        quantity: 1
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    
    useEffect(() => {
        if (id) {
            dispatch(fetchSelectedProduct(Number(id)));
        } else {
            navigate('/');
        }
    }, [id, dispatch, navigate]);

    useEffect(() => {
        if (selectedProduct && isAuthenticated) {
            dispatch(checkCanReview(selectedProduct.id));
        }
    }, [selectedProduct, isAuthenticated, dispatch]);

    useEffect(() => {
        if (selectedProduct) {
            setCartPayload(prev => ({ ...prev, productId: selectedProduct.id }));
        }
    }, [selectedProduct]);


    const handleCartPayloadChange = <K extends keyof AddToCartPayload>(key: K, value: AddToCartPayload[K]) => {
        setCartPayload(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage(''); 
    };

    const handleAddToFavorites = async (productId: number) => {
        if (!isAuthenticated) {
            alert('Please log in to add products to favorites');
            return;
        }
        try {
            const actionResult = await dispatch(addToFavorites({ productId }));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost adaugat in favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparaut o eroare in procesul de adauagre in faovorite.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveFromFavorites = async (productId: number) => {
        try {
            const actionResult = await dispatch(removeFromFavorites(productId));
            const result = unwrapResult(actionResult);
            if (result.success) {
                setSnackbarMessage('Produsul a fost sters cu succes din favorite!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('A aparut o eroare in procesul de sterge a produsului din favorite.');
            setSnackbarOpen(true);
        }
    };

    const handleAddToCart = async () => {
        if (!cartPayload.productId || cartPayload.quantity <= 0 || !(cartPayload.colorId && cartPayload.sizeId && cartPayload.cityId)) {
          setSnackbarMessage('Invalid cart data');
          setSnackbarOpen(true);
          return;
        }
      
        try {
          const actionResult = await dispatch(addToCart(cartPayload));
          const result = unwrapResult(actionResult);
          if (result && result.success) {
            setSnackbarMessage('Produsul a fost adaugat cu succes in cos');
            setSnackbarOpen(true);
            setCartPayload({
                productId: selectedProduct?.id || 0,
                colorId: null,
                sizeId: null,
                cityId: null,
                quantity: 1
            });
          } else {
            throw new Error('Server responded without success');
          }
        } catch (error) {
          setSnackbarMessage('A aparaut o eroare in procesul de adauagre in cos.');
          setSnackbarOpen(true);
        }
    };
    
    

  return (
    <Box sx={{
        display: 'flex', 
        minHeight: '100vh',
        width: '100%',
        p: '1%',
        flexDirection: 'column'
    }}>
        <Paper elevation={6} sx={{
            flexGrow: 1, 
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
        }}>
            {productStatus === 'loading' ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : selectedProduct ? (
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%', 
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        width: '100%',
                        height: isSmallScreen ? '100%' : '90vh',
                        justifyContent: 'center', 
                        alignItems: 'center',
                        bgcolor: 'background.paper', 
                        overflow: 'auto', 
                    }}>
                        {/* Image Carousel */}
                        <Box sx={{
                            flex: 1,
                            width: '100%', 
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                            borderRight: isSmallScreen ? 0 : 1,  
                            borderColor: 'divider'
                        }}>
                            <ImageCarousel
                                imageUrls={selectedProduct.imageUrl}
                            />
                        </Box>
                        
                        {/* Product Data */}
                        <Box sx={{
                            flex: 1,
                            width: '100%',  
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2
                        }}>
                            <ProductData
                                selectedProduct={selectedProduct}
                                cartPayload={cartPayload}
                                handleCartPayloadChange={handleCartPayloadChange}
                                handleAddToFavorites={handleAddToFavorites}
                                handleRemoveFromFavorites={handleRemoveFromFavorites}
                                handleAddToCart={handleAddToCart}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{ width: '100%', my: 2 }} />
            
                    <Box sx={{ 
                        display: 'flex', 
                        flexGrow: 1, 
                        width: '100%',
                        minHeight: '40vh',  
                        maxHeight: '70vh',
                    }}>
                        <DetailSection
                            selectedProduct={selectedProduct}
                        />
                    </Box>

                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body1">Product not found or failed to load.</Typography>
                </Box>
            )}
        </Paper>

        <Snackbar
            key={snackbarKey}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    </Box>
  );
}

export default ProductPage;
