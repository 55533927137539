import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axios';

// Ответ сервера после попытки подписки на рассылку
interface NewsletterResponse {
  success: boolean;
  message: string;
}

interface SendNewsletterPayload {
    text: string;
    postIds: number[]; 
  }

interface RejectedValue {
    message: string;
  }

interface NewsletterState {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  success?: boolean;
  message?: string;
}

const initialState: NewsletterState = {
  status: 'idle',
  success: false, 
  message: '',
};

export const sendNewsletter = createAsyncThunk<NewsletterResponse, SendNewsletterPayload>(
    'newsletter/sendNewsletter',
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await axios.post('/api/send-newsletter', payload);
        return data;
      } catch (error: any) {
        if (error && error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        }
        return rejectWithValue({ message: error.message || 'Unexpected error occurred.' });
      }
    },
);
  

export const subscribeToNewsletter = createAsyncThunk<NewsletterResponse>(
  'newsletter/subscribeToNewsletter',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/auth/news');
      return data;
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message || 'Unexpected error occurred.' });
    }
  },
);

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribeToNewsletter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(subscribeToNewsletter.fulfilled, (state, action: PayloadAction<NewsletterResponse>) => {
        state.status = 'loaded';
        state.message = action.payload.message;
        state.success = action.payload.success
      })
      .addCase(subscribeToNewsletter.rejected, (state, action) => {
        state.status = 'error';
        if (action.payload) {
          state.message = (action.payload as RejectedValue).message;
        } else if (action.error.message) {
          state.message = action.error.message;
        }
      })   
      .addCase(sendNewsletter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendNewsletter.fulfilled, (state, action: PayloadAction<NewsletterResponse>) => {
        state.status = 'loaded';
        state.message = action.payload.message;
      })
      .addCase(sendNewsletter.rejected, (state, action) => {
        state.status = 'error';
        if (action.payload) {
          state.message = (action.payload as RejectedValue).message;
        } else if (action.error.message) {
          state.message = action.error.message;
        }
      });
    }
});

export const newsletterReducer = newsletterSlice.reducer;
