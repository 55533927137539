import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyUserEmail, fetchAuthMe, selectIsEmailVerified, selectIsAuthenticated, resendVerificationEmail } from '../../redux/slices/auth';
import { Box, Typography, Button, DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AppDispatch } from '../../redux/store';


const VerifyEmailPage = () => {
    const dispatch: AppDispatch = useDispatch();
    const isEmailVerified = useSelector(selectIsEmailVerified);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const location = useLocation();
    const navigate = useNavigate();
    const token = new URLSearchParams(location.search).get("token");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resendDialogOpen, setResendDialogOpen] = useState(false);

    useEffect(() => {
        // Проверяем, аутентифицирован ли пользователь
        if (isAuthenticated) {
            // Затем проверяем, подтвержден ли email
            if (!isEmailVerified) {
                // Если есть токен, пытаемся верифицировать email
                if (token) {
                    dispatch(verifyUserEmail(token))
                        .unwrap()
                        .then(() => {
                            // После успешной верификации открываем модальное окно
                            setDialogOpen(true);
                            // Через 3 секунды перенаправляем на главную страницу
                            setTimeout(() => {
                                navigate('/');
                            }, 3000);
                        })
                        .catch((error) => {
                            console.error('Ошибка верификации email:', error);
                            // Здесь можно обработать ошибку верификации
                        });
                }
            } else {
                // Если email уже верифицирован, перенаправляем на главную страницу
                navigate('/');
            }
        } else {
            // Если пользователь не аутентифицирован, загружаем его данные
            dispatch(fetchAuthMe());
        }
    }, [dispatch, token, isAuthenticated, isEmailVerified, navigate, setDialogOpen]);

    const handleResendEmail = async () => {
        try {
            await dispatch(resendVerificationEmail()).unwrap();
            // При успешном выполнении открываем диалоговое окно
            setResendDialogOpen(true);
        } catch (error) {
            console.error('Ошибка при повторной отправке письма:', error);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleCloseResendDialog = () => {
        setResendDialogOpen(false);
    }

  return (
    <Box sx={{
      minHeight: '90vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      p: "2%",
    }}>
      <Typography variant="h1" component="h1" gutterBottom>
        Confirmarea adresei dvs. de email
      </Typography>
      <Typography variant="h3" sx={{ mb: 2 }}>
        V-am trimis un email pentru confirmare. Vă rugăm să verificați inbox-ul și să urmați instrucțiunile din email pentru a activa contul.
      </Typography>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Dacă nu vedeți mesajul în inbox, verificați și folderul de spam. Dacă mesajul nu se află nici acolo, încercați să retrimiteți emailul apăsând pe butonul de mai jos sau contactați-ne pentru asistență.
      </Typography>
      <Button variant="contained" size="large" sx={{ fontSize: '1.25rem' }} onClick={handleResendEmail}>
        Retrimiteți emailul
      </Button>

        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirmare reușită"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Adresa dvs. de email a fost verificată cu succes. Veți fi redirecționat(ă) pe pagina principală în câteva momente.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Închide</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={resendDialogOpen}
            onClose={handleCloseResendDialog}
            aria-labelledby="resend-email-dialog-title"
            aria-describedby="resend-email-dialog-description"
        >
            <DialogTitle id="resend-email-dialog-title">
                {"Email de verificare retrimis"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="resend-email-dialog-description">
                    Un nou email de verificare a fost trimis. Vă rugăm să verificați inbox-ul pentru a vă verifica adresa de email.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseResendDialog}>Închide</Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
}

export default VerifyEmailPage;
