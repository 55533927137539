import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { CartItems } from '../../../redux/slices/cart';
import CartItem from '../ShoppingCart/CartItem';

interface ArticoleProps {
    cartItems: CartItems[];
    totalPriceCart: number;
    handleAddToFavorites: (productId: number) => void;
    handleRemoveFromFavorites: (productId: number) => void;
    handleRemoveFromCart: (cartId: number) => void;
    handleUpdateCartQuantity: (cartId: number, quantity: number) => void;
}

const Articole: React.FC<ArticoleProps> = ({ 
    cartItems, 
    totalPriceCart,
    handleAddToFavorites,
    handleRemoveFromFavorites,
    handleRemoveFromCart,
    handleUpdateCartQuantity
}) => {
          
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                width: '100%', 
                height: '100%', 
                border: '1px solid #ccc',
                borderRadius: '4px', 
                overflowY: 'hidden', 
            }}
        >
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                p: 2,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                    width: '100%',
                    gap: 4
                }}>
                    {cartItems.map((item) => (
                        <CartItem 
                            key={item.Id} 
                            cartItem={item}
                            handleAddToFavorites={handleAddToFavorites}
                            handleRemoveFromFavorites={handleRemoveFromFavorites}
                            handleRemoveFromCart={handleRemoveFromCart}
                            handleUpdateCartQuantity={handleUpdateCartQuantity}
                        />
                    ))}
                </Box>

            </Box>

            {/* Wrapper Box to push summary to the bottom */}
            <Box sx={{ mt: 'auto', width: '100%', p: 2 }}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" sx={{ textAlign: 'center' }}>Pret articole: {totalPriceCart.toFixed(2)} Lei</Typography>
            </Box>
        </Box>
    );
}

export default Articole;
