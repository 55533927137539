import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Post } from './posts';
import { API } from '../service';
import { AxiosError } from 'axios';
import { AssignedUser } from './order';
import { Discount } from './discount';
import { DeliveryType } from './delivery';
import { PaymentType } from './payment';

  export type OrderStatus = 'Nou' | 'In verificare' | 'In livrare' | 'Livrat';

  export interface User {
    Email: string;
  }

  export interface Order {
    Id: number;
    userId: number;
    status: OrderStatus;
    assignedUserId?: number;
    assignedUser?: AssignedUser;
    codLivrare?: string;
    deliveryAddress: string;
    deliveryCity: string;
    deliveryPostalCode?: string;
    deliveryCountry: string;
    deliveryPhone: string;
    deliveryType: DeliveryType; 
    paymentType: PaymentType;
    nominalPrice?: number;
    originalPrice: number;
    price: number;
    discount?: Discount; 
    discountAmount?: number;
    finalCost: number;
    revenue?: number;
    createdAt: string;
    updatedAt: string;
    User: User;
    orderDetails: OrderDetail[];     
  }

  export interface OrderDetail {
    id: number;
    orderId: number;
    postId: number;
    Post: Post;
    colorId: number;
    sizeId: number;
    cityId: number;
    quantity: number;
    originalPrice: number; 
    discountedPrice?: number; 
    discountPercentage?: number;
    createdAt: string;
    updatedAt: string;
  }
 
  interface UserOrdersRequestParams {
    page: number;
    perPage: number;
  }

  interface OrdersState {
    items: Order[],
    selectedOrder: Order | null,
    status: 'loading' | 'loaded' | 'error',
    count: number;
  }

  const initialState: OrdersState = {
    items: [],
    selectedOrder: null, 
    status: 'loading',
    count: 0,
  }

  interface ApiErrorResponse {
    message: string;
    status: number;
  }

  export const getUserPaginationOrders = createAsyncThunk<{ count: number, orders: Order[] }, UserOrdersRequestParams, { rejectValue: string }>(
    'orders/getUserPaginationOrders',
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number, orders: Order[] }>('GET', `/user/user_orders`, null, {
        page,
        perPage,
      });
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch user pagination orders');
      }
  
      return response.data;
    }
  );

  export const getUserOrdersCount = createAsyncThunk(
    'orders/getUserOrdersCount',
    async (_, { rejectWithValue }) => {
      const [error, response] = await API<{ totalOrders: number }>(
        'GET',
        '/user/orders/count'
      );
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch orders count');
      }
  
      return { count: response.data.count };
    }
  );

  export const fetchOrderDetails = createAsyncThunk<{ order: Order }, number, { rejectValue: ApiErrorResponse }>(  
    'orders/fetchOrderDetails',
    async (orderId, { rejectWithValue }) => {
        const [error, response] = await API<Order>('GET', `/orders/${orderId}`);
  
        if (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            if (axiosError.response) {
                // Теперь TypeScript знает структуру axiosError.response.data
                let errorMessage = axiosError.response.data.message || "Unknown error";
                let errorStatus = axiosError.response.status;
                return rejectWithValue({ message: errorMessage, status: errorStatus });
            } else {
                return rejectWithValue({ message: "Network error", status: 500 });
            }
        }
  
        return response?.data;
    }
  );


  const userordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getUserPaginationOrders.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getUserPaginationOrders.fulfilled, (state, action: PayloadAction<{ count: number, orders: Order[] }>) => {
          state.status = 'loaded';
          state.items = action.payload.orders;
          state.count = action.payload.count;
        })
        .addCase(getUserPaginationOrders.rejected, (state) => {
          state.status = 'error';
        })
        .addCase(getUserOrdersCount.fulfilled, (state, action: PayloadAction<{ count: number }>) => {
          state.count = action.payload.count;
        })
        .addCase(fetchOrderDetails.pending, (state) => {
          state.status = 'loading';
          state.selectedOrder = null;
        })
        .addCase(fetchOrderDetails.fulfilled, (state, action: PayloadAction<{ order: Order }>) => {
          state.status = 'loaded';
          state.selectedOrder = action.payload.order;
        })      
        .addCase(fetchOrderDetails.rejected, (state) => {
            state.status = 'error';
            state.selectedOrder = null;
        });
    }
  });
  
  export const userordersReducer = userordersSlice.reducer;
  