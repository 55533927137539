import React from 'react';
import { Box, Typography, CardMedia, Card, CardContent, CardHeader, IconButton} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DiscountIcon from '@mui/icons-material/Discount';
import { OfflineOrderDetail } from '../../../../redux/slices/orderoffline';

interface ProductCardProps {
  orderDetail: OfflineOrderDetail;
}

const ProductCardOfflineOrder: React.FC<ProductCardProps> = ({ 
  orderDetail,
}) => {
  const navigate = useNavigate();
  const { Product: product, inventoryId, originalPrice, discountedPrice, hasDiscount, discountPercentage } = orderDetail;
  const baseUrl = process.env.REACT_APP_API_BASE_URL || ''; 
  const imageUrl = product.imageUrl[0] ? `${baseUrl}${product.imageUrl[0]}` : '/path/to/default/image.jpg'; 
  const inventory = product.inventories.find(inv => inv.id === inventoryId);
  const finalPrice = discountedPrice ? Number(discountedPrice).toFixed(2) : Number(originalPrice).toFixed(2);

  const handleImageClick = () => {
    navigate(`/ProductPage/${product.id}`);
  };
  
  return (
    <Card sx={{
        maxWidth: 300,
        transition: '0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          '.MuiCardHeader-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }
        }
      }}>
         <CardHeader
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          title={product.title}
          action={
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1
            }}>
              {hasDiscount && discountPercentage && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <IconButton aria-label="discount">
                          <DiscountIcon color="error" />
                      </IconButton>
                      <Typography variant="h6" color="error">
                          -{discountPercentage}%
                      </Typography>
                  </Box>
              )}
          </Box>
          }
        />  
        
        <CardMedia
          component="img"
          height="auto"
          image={imageUrl}
          alt={product.title}
          onClick={handleImageClick}
          sx={{ cursor: 'pointer' }}
        />

        <CardContent>
          <Box sx={{ 
              display: 'flex',
              justifyContent: hasDiscount ? 'space-between' : 'center',
              alignItems: 'center',
              width: '100%',
          }}>
              {hasDiscount && (
                  <Typography variant="h5" color="text.primary" sx={{ textDecoration: 'line-through', flexGrow: 1, textAlign: 'left' }}>
                      {Number(originalPrice).toFixed(2)} Lei
                  </Typography>
              )}
              <Typography variant="h5" color={hasDiscount ? 'error' : 'text.primary'} sx={{ flexGrow: hasDiscount ? 1 : 0, textAlign: 'right' }}>
                  {finalPrice} Lei
              </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, width: '100%' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" color="text.secondary">Culoare: {inventory?.Color?.name}</Typography>
                  <Box sx={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: inventory?.Color?.colorCode, ml: 1 }} />
              </Box>
              <Typography variant="h6" color="text.secondary">Mărime: {inventory?.Size?.name}</Typography>
              <Typography variant="h6" color="text.secondary">Oras: {inventory?.City?.name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mx: 2 }}>{orderDetail.quantity}</Typography>         
            </Box>
          </Box>

          <Typography variant="h5" color="text.primary" sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
            Total: {orderDetail.totalPrice.toFixed(2)} Lei
          </Typography>
        </CardContent>

    </Card>
  )
}

export default ProductCardOfflineOrder;
