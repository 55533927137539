import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Avatar, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

interface ProductProps {
    products: {
      key: string;
      id: number;
      productName: string;
      originalPrice: number; 
      discountedPrice?: number; 
      discountPercentage?: number; 
      productImage: string;
      selectedQuantity: number;
      selectedColorId?: number;  
      selectedSizeId?: number;
      selectedCityId?: number;
    }[];
  }
const OrderInfo: React.FC<ProductProps> = ({ products }) => {
    const navigate = useNavigate();
    const colors = useSelector((state: RootState) => state.colors.items);  
    const sizes = useSelector((state: RootState) => state.sizes.items);
    const cities = useSelector((state: RootState) => state.cities.items);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const handleProductClick = (id: number) => {
        navigate(`/FullPost/${id}`);
    };

    const calculateFinalPrice = (price: number, discountPercentage?: number): number => {
        if (discountPercentage) {
            const discountAmount = price * (discountPercentage / 100);
            return price - discountAmount;
        }
        return price;
    };

    const totalSum = products.reduce((sum, product) => {
        const finalPrice = calculateFinalPrice(product.originalPrice, product.discountPercentage);
        return sum + finalPrice * product.selectedQuantity;
    }, 0);


if (isMobile) {
    return (
        <Paper variant="outlined" sx={{ width: "100%", padding: '1em', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            {products.map(product => (
                <Box
                    key={product.key}
                    sx={{
                        padding: '1em',
                        marginBottom: '1em',
                        border: '1px solid rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#FFF',
                        borderRadius: '8px'
                    }}
                >
                    <Box 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="center"
                    >
                        <Typography 
                            variant="h3"
                            mb={2}
                        >
                            Produs
                        </Typography>
                        <Avatar 
                                src={product.productImage} 
                                alt={product.productName} 
                                sx={{ width: 200, height: 'auto', borderRadius: '4px', cursor: 'pointer' }}  
                                onClick={() => handleProductClick(product.id)}
                            />
                        <Typography variant="h4">{product.productName}</Typography>
                        <Typography variant="h4">Culoare: {colors.find(color => color.id === product.selectedColorId)?.name || "Unknown Color"}</Typography>
                        <Typography variant="h4">Marime: {sizes.find(size => size.id === product.selectedSizeId)?.name || "Unknown Size"}</Typography>
                        <Typography variant="h4">Oras: {cities.find(city => city.id === product.selectedCityId)?.name || "Unknown City"}</Typography>
                        <Typography 
                            variant="h4"
                            mt={2}
                        >
                            Cantitate: {product.selectedQuantity}
                        </Typography>
                        <Typography 
                            variant="h4"
                            mt={2}
                        >
                            {product.originalPrice} Lei
                        </Typography>
                    </Box>
                </Box>
            ))}
            <Box 
                display="flex" 
                justifyContent="flex-end" 
                mt={2}
            >
                <Typography variant="h4">
                    Total: {totalSum.toFixed(2)} Lei
                </Typography>
            </Box>
        </Paper>
    );
}
    

return (
    <Paper variant="outlined">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontSize: '1.6em' }}>Produs</TableCell> 
                    <TableCell align="center" sx={{ fontSize: '1.6em' }}>Cantitate</TableCell>
                    <TableCell align="right" sx={{ fontSize: '1.6em' }}>Subtotal</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map(product => (
                    <TableRow key={product.key}>
                        <TableCell sx={{ fontSize: '1.6em' }}>
                            <Box display="flex" alignItems="center"> 
                                <Avatar 
                                    src={product.productImage} 
                                    alt={product.productName} 
                                    sx={{ width: 110, height: 'auto', marginRight: 2, borderRadius: '4px', cursor: 'pointer' }}  
                                    onClick={() => handleProductClick(product.id)}
                                />
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start">
                                    <Typography variant="h4">{product.productName}</Typography>
                                    <Typography variant="h4">Culoare: {colors.find(color => color.id === product.selectedColorId)?.name || "Unknown Color"}</Typography>
                                    <Typography variant="h4">Marime: {sizes.find(size => size.id === product.selectedSizeId)?.name || "Unknown Size"}</Typography>
                                    <Typography variant="h4">Oras: {cities.find(city => city.id === product.selectedCityId)?.name || "Unknown City"}</Typography>
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.6em', verticalAlign: 'middle' }}>
                            {product.selectedQuantity}
                        </TableCell>

                        <TableCell align="right" sx={{ fontSize: '1.6em' }}>
                            <Box>
                                {/* Проверяем, что discountPercentage определен и не равен нулю */}
                                {product.discountPercentage !== undefined && product.discountPercentage > 0 ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Typography variant="h4" component="span" sx={{ textDecoration: 'line-through' }}>
                                                {product.originalPrice.toFixed(2)} Lei
                                            </Typography>
                                            <Typography variant="h4" component="span" sx={{ color: 'red', display: 'block' }}>
                                                {calculateFinalPrice(product.originalPrice, product.discountPercentage).toFixed(2)} Lei
                                            </Typography>
                                        </Box>
                                        <Box component="span" sx={{ backgroundColor: 'red', color: 'white', p: '2px 4px', borderRadius: '4px', ml: 2 }}>
                                            -{product.discountPercentage}%
                                        </Box>
                                    </Box>
                                ) : (
                                    // Если discountPercentage не определен или равен нулю, отображаем только основную цену
                                    <Typography variant="h4">
                                        {product.originalPrice.toFixed(2)} Lei
                                    </Typography>
                                )}
                            </Box>
                        </TableCell>

                    </TableRow>
                ))}
                <TableRow>
                    <TableCell sx={{ fontSize: '1.6em' }} align="left">Total:</TableCell>
                    <TableCell sx={{ fontSize: '1.6em' }}></TableCell> 
                    <TableCell sx={{ fontSize: '1.6em' }} align="right">{totalSum.toFixed(2)} Lei</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Paper>
    );
};

export default OrderInfo;
