import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { API } from "../service";
import { User } from "./auth";
import { Role } from './roles';

export interface SelectedWorker {
  user?: User;
  hasRole?: boolean;
  role?: Role; 
}

export interface SelectedWorkerState {
  selectedWorker: SelectedWorker | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SelectedWorkerState = {
  selectedWorker: null,
  status: 'idle',
  error: null,
};

// Асинхронный экшен для получения данных сотрудника по ID
export const fetchSelectedWorker = createAsyncThunk<SelectedWorker, number, { rejectValue: string }>(
    'selectedWorker/fetchSelectedWorker',
    async (userId, { rejectWithValue }) => {
      const [error, response] = await API<SelectedWorker>('GET', `/roles/user/${userId}`);
      if (error) {
        // Если ошибка - возвращаем ее текст
        return rejectWithValue((error as AxiosError).message);
      }
      if (response && response.data) {
        // Если есть ответ и в нем есть данные - возвращаем их
        return response.data;
      } else {
        // Иначе - сообщаем об отсутствии ответа
        return rejectWithValue('No response from server');
      }
    }
);

const selectedWorkerSlice = createSlice({
  name: 'selectedWorker',
  initialState,
  reducers: {
    resetSelectedWorker(state) {
      state.selectedWorker = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedWorker.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSelectedWorker.fulfilled, (state, action: PayloadAction<SelectedWorker>) => {
        state.selectedWorker = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchSelectedWorker.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || 'An unknown error occurred';
        state.status = 'failed';
      });
  },
});

export const { resetSelectedWorker } = selectedWorkerSlice.actions;

export default selectedWorkerSlice.reducer;
