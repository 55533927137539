import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../axios'
import { API } from '../service';

export interface City {
  id: number;
  name: string;
}

interface CitiesState {
  items: City[];
  status: 'loading' | 'loaded' | 'error';
}

const initialCitiesState: CitiesState = {
  items: [],
  status: 'loading',
};

export const fetchCities = createAsyncThunk<City[], void, { rejectValue: string }>(
  'cities/fetchCities', 
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<City[]>('GET', '/city');

    if (error || !response) {
      console.error(error);
      return rejectWithValue('Failed to fetch cities');
    }

    return response.data;
  }
);

export const fetchRemoveCity = createAsyncThunk<void, string, { rejectValue: string }>(
  'cities/fetchRemoveCity', 
  async (id, { rejectWithValue }) => {
    const [error, _] = await API<void>('DELETE', `/city/${id}`);

    if (error) {
      console.error(error);
      return rejectWithValue(`Failed to remove city with ID: ${id}`);
    }
  }
);

const citiesSlice = createSlice({
  name: 'cities',
  initialState: initialCitiesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.items = [];
        state.status = 'loading';
      })
      .addCase(fetchCities.fulfilled, (state, action: PayloadAction<City[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchCities.rejected, (state) => {
        state.items = [];
        state.status = 'error';
      });
  }
});

export const citiesReducer = citiesSlice.reducer;
