import React from 'react'
import { Box, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Product } from '../../../redux/slices/productAdmin';
import InventoryIcon from '@mui/icons-material/Inventory';

interface ProductDepozitProps {
    product: Product;
    onOpenNewTab: (id: number) => void;
    onEdit: (id: number) => void;
}

const ProductDepozit: React.FC<ProductDepozitProps> = ({ 
    product,
    onOpenNewTab,
    onEdit
 }) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    return (
        <Box sx={{ 
            width: '300px', 
            position: 'relative', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            borderRadius: '8px', 
            backgroundColor: '#e0e0e0',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            margin: '10px', 
            '&:hover': { 
              transform: 'scale(1.025)', 
              transition: 'transform 0.3s ease-in-out',
            },
            cursor: 'pointer',
        }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                width: '100%', 
                height: '350px', 
                overflow: 'hidden', 
                borderRadius: '8px', 
                backgroundColor: '#e0e0e0',
                padding: '10px',  
            }}>
                <Box 
                    component="img" 
                    src={product.imageUrl && product.imageUrl[0] ? `${baseUrl}${product.imageUrl[0]}` : ''} 
                    alt={product.title} 
                    sx={{ 
                        width: 'auto', 
                        height: '100%', 
                        maxWidth: '100%', 
                        maxHeight: '100%', 
                        objectFit: 'contain', 
                        borderRadius: '8px',
                    }}
                />
                {product.totalInventoryCount === 0 && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        borderRadius: '8px',
                    }}>
                        Stoc Epuizat
                    </Box>
                )}

                {product.hasActiveDiscount && (product.totalInventoryCount ?? 0) > 0 && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 255, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        borderRadius: '8px',
                    }}>
                        Reducere
                    </Box>
                )}
            </Box>

            <Typography 
                variant="h3" 
                component="div" 
                gutterBottom 
                sx={{ textAlign: 'center', padding: '10px 0' }}
            >
                {product.title}
            </Typography>

            {/* Action Icons Row */}
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <IconButton>
                    <InfoIcon />
                </IconButton>
                <IconButton onClick={() => onEdit(product.id)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => onOpenNewTab(product.id)}>
                    <OpenInNewIcon />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    <InventoryIcon />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        {product.totalInventoryCount}
                    </Typography>
                </Box>
            </Box>
        
        </Box>
    )
}

export default ProductDepozit;
