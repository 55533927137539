import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../service'; 

export interface DeliveryType {
  id: number;
  name: string;
  price: number;
}

interface DeliveryTypesRequestParams {
    page: number;
    perPage: number;
}

interface DeliveryTypesState {
  items: DeliveryType[];
  status: 'loading' | 'loaded' | 'error';
  count: number;
}

const initialDeliveryTypesState: DeliveryTypesState = {
  items: [],
  status: 'loading',
  count: 0,
};

export const fetchDeliveryTypes = createAsyncThunk<DeliveryType[], void, { rejectValue: string }>(
  'deliveryTypes/fetchDeliveryTypes',
  async (_, { rejectWithValue }) => {
    const [error, response] = await API<DeliveryType[]>('GET', '/delivery');
    if (error || !response) {
      return rejectWithValue('Failed to fetch delivery types');
    }
    return response.data;
  }
);

export const fetchDeliveryTypesPagination = createAsyncThunk<{ count: number, deliveryTypes: DeliveryType[] }, DeliveryTypesRequestParams, { rejectValue: string }>(
    'deliveryTypes/fetchDeliveryTypesPagination', 
    async ({ page, perPage }, { rejectWithValue }) => {
      const [error, response] = await API<{ count: number, deliveryTypes: DeliveryType[] }>('GET', '/delivery/pagiantion', null, {
        page,
        perPage,
      });
  
      if (error || !response) {
        return rejectWithValue('Failed to fetch delivery types with pagination');
      }
  
      return response.data;
    }
);

export const createDeliveryType = createAsyncThunk<DeliveryType, Omit<DeliveryType, 'id'>, { rejectValue: string }>(
  'deliveryTypes/createDeliveryType',
  async (deliveryTypeData, { rejectWithValue }) => {
    const [error, response] = await API<DeliveryType>('POST', '/delivery/create', deliveryTypeData);
    if (error || !response) {
      return rejectWithValue('Failed to create delivery type');
    }
    return response.data;
  }
);

const deliveryTypesSlice = createSlice({
  name: 'deliveryTypes',
  initialState: initialDeliveryTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeliveryTypes.fulfilled, (state, action: PayloadAction<DeliveryType[]>) => {
        state.items = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchDeliveryTypes.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchDeliveryTypesPagination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeliveryTypesPagination.fulfilled, (state, action: PayloadAction<{ count: number, deliveryTypes: DeliveryType[] }>) => {
        state.items = action.payload.deliveryTypes;
        state.count = action.payload.count;
        state.status = 'loaded';
      })
      .addCase(fetchDeliveryTypesPagination.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createDeliveryType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDeliveryType.fulfilled, (state, action: PayloadAction<DeliveryType>) => {
        state.items.push(action.payload);
        state.status = 'loaded';
      })
      .addCase(createDeliveryType.rejected, (state) => {
        state.status = 'error';
      });
  }
});

export const deliveryTypesReducer = deliveryTypesSlice.reducer;
